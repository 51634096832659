.q-trailering-towing-vehicle-capability-tile {
	padding: 0 1rem;

	.q-content-section {
		float: left;
		width: 100%;

		.q-title-section {
			.q-title {
				@extend %q-descriptive2;
				padding-top: .667rem;
				text-transform: uppercase;
			}

			.q-secondary-title {
				.q-number {
					@extend %q-bold1;
					text-transform: uppercase;
				}

				.q-unit {
					@extend %q-descriptive1;

					span:hover {
						color: $gold-light;
						cursor: pointer;
					}

					p {
						display: inline;
					}
				}
			}
		}

		.q-description {
			@extend %q-body2;
			margin-bottom: 2.667rem;
		}

		@media #{$large-up} {
			.q-description {
				padding-top: 3rem;
			}

			.q-title-section,
			.q-description {
				float: left;
				vertical-align: middle;
				width: 50%;
			}
		}
	}

	.q-img-container {
		img {
			min-width: 100%;
		}
	}
}
