.q-button {
	.columns > & {
		&.q-link,
		&.q-icon-only {
			display: block;
		}
	}
}

@media #{$large-up} {
	.q-button {
		&.q-primary-button {
			border-width: 3px;
			font-size: .7rem;
			padding-block: .8rem;
		}

		&.q-secondary-button {
			border-width: 3px;
		}
	}
}

@media #{$xlarge-up} {
	.q-button {
		&.q-primary-button {
			padding-block: .783rem;
		}
	}
}

@media #{$xxlarge-up} {
	.q-button {
		&.q-primary-button {
			font-size: .696rem;
		}
	}
}
