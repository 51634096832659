/* extend solar nav_secondary for brand-specific styles */

.q-nav-anchor {
	.q-nav-container {
		min-height: 3rem;

		.q-year-toggle {
			margin: $nav-secondary-year-toggle-margin;
			order: 2;

			.q-year-toggle-list-item {
				color: $grey;
				line-height: 1.8rem;

				a {
					color: inherit;
					display: block;
					font-size: .75rem;
					font-weight: $font-weight-bold;
					letter-spacing: .075rem;
					padding-left: .625rem;
					padding-right: .625rem;
					text-align: center;
				}

				&.focus,
				&:hover {
					background-color: $gold;
					color: $black;
				}

				&.active {
					background-color: $grey;
					color: $white;

					&:hover {
						background-color: $grey;
						color: $white;
					}
				}
			}
		}

		.q-sibling-nav-container {
			order: 3;

			.q-sibling-nav-list {
				li:not(.q-cta-list-item) {
					a {
						&::after {
							@include easein-middle;
						}
					}
				}
			}
		}

		&>.q-secondary-nav-button {
			order: 1;
			padding: map-get($nav-secondary-dropdown-text-padding-map, small);

			&.q-dropdown-has-content {
				background-color: $white;
				border-right: $nav-secondary-nav-anchor-border-right;
				cursor: pointer;
				min-height: 3.75rem;

				&:hover,
				&:focus {
					&::after {
						@include easein-middle;
						background-color: $grey-dark;
					}

				}

				&::after {
					background: $grey get-element(carat-down-white) no-repeat center;
					background-size: map-get($nav-secondary-dropdown-icon-size-map, small);
					content: '';
					height: map-get($nav-secondary-dropdown-icon-container-height-map, small);
					position: absolute;
					right: .3125rem;
					width: map-get($nav-secondary-dropdown-icon-container-width-map, small);
				}

				&.q-dropdown-open {
					&::after {
						background-color: $grey-dark;
						transform: rotateX(180deg);
					}
				}
			}

			.q-dropdown-arrow {
				max-width: 75%;
			}
		}

		.q-sibling-nav-active-anchor {
			border-left-color: $gold;
		}

		.q-sibling-nav-container {
			.q-sibling-nav-list {
				li:not(.q-cta-list-item) {
					a {
						&::after {
							@include easein-middle;
						}
					}
				}
			}
		}
	}

	@media #{$large-up} {
		.q-nav-container {
			&>.q-secondary-nav-button {
				padding: map-get($nav-secondary-dropdown-text-padding-map, large);

				&.q-dropdown-has-content {
					&::after {
						background-size: map-get($nav-secondary-dropdown-icon-size-map, large);
						height: map-get($nav-secondary-dropdown-icon-container-height-map, large);
						width: map-get($nav-secondary-dropdown-icon-container-width-map, large);
					}
				}
			}
		}
	}

	@media #{$xxlarge-up} {
		.q-nav-container {
			&>.q-secondary-nav-button {
				padding: map-get($nav-secondary-dropdown-text-padding-map, xxlarge);

				&.q-dropdown-has-content {
					&::after {
						background-size: map-get($nav-secondary-dropdown-icon-size-map, xxlarge);
						height: map-get($nav-secondary-dropdown-icon-container-height-map, xxlarge);
						width: map-get($nav-secondary-dropdown-icon-container-width-map, xxlarge);
					}
				}
			}
		}
	}
}
