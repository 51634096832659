.q-rep-scroller {
	position: relative;

	.slick-arrow {
		align-items: center;
		border: 0;
		display: flex !important; // sass-lint:disable-line no-important
		justify-content: center;
		outline: 0;
		padding: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;

		&::before {
			content: '';
			display: block;
		}

		&.slick-prev {
			left: 0;

			&::before {
				transform: rotate(180deg);
			}
		}

		&.slick-next {
			right: 0;
		}

		&.slick-disabled {
			display: none !important; // sass-lint:disable-line no-important
		}

		~ .slick-dots {
			display: flex;
		}
	}

	.slick-slide {
		display: flex;

		// Allows text to properly wrap in IE11
		> * {
			max-width: 100%;
		}

		// Prevents images within slider from growing to crazy heights in IE11
		img {
			min-height: 1px;
		}
	}

	.slick-track {
		display: flex;
		margin: 0 auto;
	}

	.slick-dots {
		align-items: center;
		display: none;
		justify-content: center;
		left: 0;
		list-style: none;
		margin: 0;
		position: absolute;
		text-align: center;
		width: 100%;

		li {
			button {
				border:	0;
				margin: 0;
				padding: 0;
			}
		}
	}

	&.show-dots {
		.slick-dots {
			li {
				cursor: pointer;

				button {
					border-radius: 50%;
					display: block;
				}
			}
		}
	}

	&.show-numbers {
		.slick-dots {
			counter-reset: num-dots;
			pointer-events: none;

			li {
				counter-increment: num-dots 1;

				button {
					display: none;

					&:focus {
						outline: 0;
					}
				}

				&.slick-active {
					button {
						display: inline-block;
					}
				}
			}

			&::after {
				content: '/' counter(num-dots);
			}
		}
	}
}
