// Button Placeholders
%button {
	@include hard-easeout(background, $anim-fast);
	background-color: $button-background-color;
	border-style: solid;
	border-width: $button-border-width;
	color: $button-color;
	display: block;
	font: $button-font;
	letter-spacing: $button-letter-spacing;
	line-height: $button-line-height;
	margin-bottom: 0;
	padding: $button-padding;
	text-align: center;
	width: 100%;
}

%button-no-border {
	border: 0;
	display: inline-block;
	text-align: inherit;
	width: $button-no-border-width;

	&.gb-target-zone-button {
		background-color: transparent;
	}
}

%q-button-large {
	padding-bottom: $button-large-size-padding;
	padding-top: $button-large-size-padding;
}

%button-list-item {
	border: 0;
	border-left: $button-list-item-padding-border-left;
	text-align: inherit;

	&.q-button-large {
		@extend %q-button-large;
	}

	&.q-button-padding-none {
		padding-left: 0;
		padding-right: 0;
	}

	&.q-button-padding {
		padding-left: $button-list-padding-left;
		padding-right: $button-list-padding-right;
	}
}

%list-item-primary {
	@include button-list-item($button-list-primary-bg-color, $button-list-primary-color, $button-list-primary-line-height, $button-list-primary-text-align, $button-list-primary-border-bottom);

	&.active,
	&:hover {
		background-color: $tertiary-list-item-hover-color;
	}
}

%q-invert {
	border-color: $button-secondary-invert-border-color;
	color: $button-secondary-invert-color;
}

%q-primary-button {
	@include button-border($button-primary-border-primary-color);

	&.q-invert {
		background-color: $button-primary-invert-background-color;
		border-color: $button-primary-invert-border-color;
		color: $button-primary-invert-color;
	}

	&.gb-target-zone-button-active {
		background-color: $button-primary-border-primary-color;
		color: $button-primary-border-hover-color;
	}

	&.q-invert {
		&.gb-target-zone-button-active {
			background-color: $button-primary-invert-hover-background-color;
			color: $button-primary-invert-hover-color;
		}
	}
}

%q-primary-button-touch {
	@include button-hover($button-primary-border-primary-color, $button-primary-border-fill, $fill-color: $button-primary-border-fill-color, $hover-color: $button-primary-border-hover-color);

	// Inverted Styling
	&.q-invert {
		&:active,
		&:hover,
		&:focus {
			background-color: $button-primary-invert-hover-background-color;
			color: $button-primary-invert-hover-color;
		}
	}
}

%q-secondary-button {
	@include button-border($button-secondary-border-primary-color, $button-secondary-border-background-color, $button-secondary-border-color);

	&.gb-target-zone-button-active {
		background-color: $button-secondary-background-color-hover;
		border-color: $button-secondary-border-primary-color;
		color: $button-secondary-color;
	}

	// Inverted Styling
	&.q-invert {
		@extend %q-invert;

		&.gb-target-zone-button-active {
			background-color: $button-secondary-invert-hover-background-color;
			border-color: $button-secondary-invert-border-color;
			color: $button-secondary-invert-color;
		}
	}
}

%q-secondary-button-touch {
	@include button-hover($button-secondary-border-primary-color, $button-secondary-border-fill, $button-secondary-border-fill-color, $button-secondary-border-hover-color);

	// Inverted Styling
	&.q-invert {
		&:active,
		&:hover,
		&:focus,
		&.gb-target-zone-button-active {
			background-color: $button-secondary-invert-hover-background-color;
			border-color: $button-secondary-invert-hover-background-color;
			color: $button-secondary-invert-hover-color;
		}
	}
}

%q-quaternary-button {
	background-color: $button-quaternary-background-color;
	border: 0;
	border-radius: $button-quaternary-border-radius;
	color: $button-quaternary-color;
	padding-inline: $button-quaternary-inline-padding;

	&.gb-target-zone-button-active {
		background-color: $button-quaternary-fill-color;
		color: $button-quaternary-hover-color;

		.icon-normal {
			display: none;
		}

		.icon-hover {
			display: inline;
		}

		img:only-child {
			display: inline;
		}

		&::after {
			background-image: get-element($button-quaternary-active-icon);
			background-position: center right;
			background-repeat: no-repeat;
			content: '';
			display: inline-block;
			height: $button-quaternary-active-icon-height;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: $button-quaternary-active-icon-width;
		}

		&::after {
			right: $button-quaternary-active-icon-position;
		}
	}

	.move-text {
		padding-inline-end: $button-quaternary-text-padding;
	}
}

%q-quaternary-button-touch {
	@include button-hover($button-quaternary-primary-color, $button-quaternary-fill, $button-quaternary-fill-color, $button-quaternary-hover-color);
}

%q-list-item-primary {
	@include button-list-item($button-list-primary-bg-color, $button-list-primary-color, $button-list-primary-line-height, $button-list-primary-text-align, $button-list-primary-border-bottom);
}

%q-list-item-primary-no-touch {
	&:active,
	&:hover {
		background-color: $button-list-primary-hover-bg-color;
		border-left-color: $button-list-primary-hover-left-bg-color;
	}
}

%q-list-item {
	@extend %button-list-item;

	&.q-list-item-primary,
	&.q-list-item-icon-primary {
		@extend %q-list-item-primary;
	}

	&.q-list-item-secondary,
	&.q-list-item-icon-secondary {
		@include button-list-item($button-list-secondary-bg-color, $button-list-secondary-color, $button-list-secondary-line-height, $button-list-secondary-text-align, $button-list-secondary-border-bottom);
	}

	&.q-list-item-tertiary,
	&.q-list-item-icon-tertiary {
		@include button-list-item($button-list-tertiary-bg-color, $button-list-tertiary-color, $button-list-tertiary-line-height, $button-list-tertiary-text-align, $button-list-tertiary-border-bottom);
		border-left: $button-list-item-tertiary-left-border;
	}

	&.q-list-item-icon-secondary {
		color: $button-list-item-icon-tertiary-color;
	}

	&.q-tertiary-nav-button {
		@extend %tertiary-nav-button;
	}
}
