// Allows the use of rem-calc() or lower-bound() in your variables & settings
@import '../../../../solar/frontend/solar-aem-ui/scss/variables';
@import '../../../../solar/frontend/solar-aem-ui/scss/functions';

$brand-name: 'chevrolet';

// Variable for Base Font Size
$base-font-size-xs: 17px;
$base-font-size-m: 20px;
$base-font-size-xl: 23px;

// Korea specific fonts
$font-family-primary: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
$font-family-secondary: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;

// Grid Full Width
$gridwidth-max: 156.25rem;
$gridwidth-full: $gridwidth-max;

/*************************
 * For backwards compatibility of previously authored grids, the key and value pair
 * should not be changed. New values will have the key out of order if the values
 * are ordered from smallest to largest.
 *************************/
$advgrid-padding-group-sizes: (
	'no': 0,
	7: .5rem,
	1: .625rem,
	8: 1rem,
	2: 1.25rem,
	9: 1.5rem,
	10: 2rem,
	3: 2.5rem,
	4: 3.75rem,
	5: 5rem,
	6: 10rem
);

$advgrid-scroller-trackbar-size-webkit: 8px;
$advgrid-scroller-trackbar-bg: rgba(0, 0, 0, 0);
$advgrid-scroller-thumb-bg: $grey-mid;
$advgrid-scroller-thumb-bg-hover: $grey;

//Colors specific to Chevy
$dim-grey: #6e6e6e;
$gold-ui: #b3842d;
$gold-light: $gold-ui;
$grey-button: #8c8c8c;
$grey-button-invert: #737373;
$gold-text: #947029;

// Generic Icons -- defined in /frontend/solar-aem-ui/scss/_icons_extend.scss
//  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
$generic-icon-minus: minus-dark;
$generic-icon-minus-hover: minus;
$generic-icon-minus-size: 2rem;

$generic-icon-plus: plus-dark;
$generic-icon-plus-hover: plus-hover;
$generic-icon-plus-size: 2rem;

$generic-icon-search-size: 2rem;

//Headline Font Sizes
$brand-headline-color: $grey-dark;
$brand-headline-heavy-weight-bold: $font-weight-xbold;
$brand-headline-heavy-weight-xbold: $font-weight-xbold;
$brand-heavy-padding-bottom: 0;
$brand-use-underline: false;
$brand-headline-text-decoration: none;
$use-underline: true;
$no-underline: false;

//Body
$body1-font-family: $font-family-secondary;
$body1-font-weight: $font-weight-normal;
$body1-font-color: $grey-dark;
$body1-font-size-xs: .9rem;
$body1-font-size-lm: .9rem;
$body1-font-size-xl: .9rem;
$body1-line-height-xs: 1.5rem;
$body1-line-height-lm: 1.5rem;
$body1-line-height-xl: 1.5rem;
$body1-visited-color: inherit;
$body1-visited-font-weight: $font-weight-medium;
$body1-invert-color: $white;
$body1-invert-visited: $white;
$body1-invert-hover-active: $gold-light;

$body1-font-size-xs-rtl: .9rem;
$body1-font-size-lm-rtl: .9rem;
$body1-font-size-xl-rtl: .9rem;

//Body2
$body2-font-family: $font-family-secondary;
$body2-font-weight: $font-weight-normal;
$body2-font-color: $dim-grey;
$body2-font-size-xs: .8rem;
$body2-font-size-lm: .8rem;
$body2-font-size-xl: .8rem;
$body2-line-height-xs: 1.25rem;
$body2-line-height-lm: 1.25rem;
$body2-line-height-xl: 1.25rem;
$body2-link-color: inherit;
$body2-link-font-weight: $font-weight-medium;
$body2-link-hover: $gold;
$body2-invert-color: $grey-light;
$body2-invert-link-color: inherit;
$body2-invert-hover: $gold-light;

$body2-font-size-xs-rtl: .8rem;
$body2-font-size-lm-rtl: .8rem;
$body2-font-size-xl-rtl: .8rem;

//Body3
$body3-font-family: $font-family-secondary;
$body3-font-weight: $font-weight-normal;
$body3-font-color: $dim-grey;
$body3-font-size-xs: .8rem;
$body3-font-size-lm: .7rem;
$body3-font-size-xl: .7rem;
$body3-line-height-xs: 1rem;
$body3-line-height-lm: .75rem;
$body3-line-height-xl: .75rem;
$body3-link-color: inherit;
$body3-link-font-weight: $font-weight-medium;
$body3-link-hover: $gold;

$body2-font-size-xs-rtl: .7rem;
$body2-font-size-lm-rtl: .7rem;
$body2-font-size-xl-rtl: .8rem;

$bold1-font-size-xs: 2rem;
$bold1-font-size-lm: 2rem;
$bold1-font-size-xl: 2rem;
$bold1-line-height-xs: 2.5rem;
$bold1-line-height-lm: 2.5rem;
$bold1-line-height-xl: 2.5rem;
$bold1-font-family: $font-family-primary;
$bold1-font-style: $font-style-inherit;
$bold1-font-weight: $font-weight-bold;
$bold1-use-underline: $brand-use-underline;
$bold1-font-color: $brand-headline-color;

$bold2-font-size-xs: 1.333rem;
$bold2-font-size-lm: 1.333rem;
$bold2-font-size-xl: 1.333rem;
$bold2-line-height-xs: 1.6rem;
$bold2-line-height-lm: 1.6rem;
$bold2-line-height-xl: 1.6rem;
$bold2-font-family: $bold1-font-family;
$bold2-font-style: $bold1-font-style;
$bold2-font-weight: $bold1-font-weight;
$bold2-use-underline: $brand-use-underline;
$bold2-font-color: $brand-headline-color;

$descriptive1-font-size-xs: 1rem;
$descriptive1-font-size-lm: 1rem;
$descriptive1-font-size-xl: 1rem;

$descriptive1-line-height-xs: 1.5rem;
$descriptive1-line-height-lm: 1.5rem;
$descriptive1-line-height-xl: 1.5rem;

$descriptive1-letter-spacing: .15rem;
$descriptive1-letter-spacing-rtl: 0;
$descriptive1-font-family: $font-family-primary;
$descriptive1-font-family-rtl: $font-family-secondary;
$descriptive1-font-style: $font-style-inherit;
$descriptive1-font-weight: $font-weight-normal;
$descriptive1-use-underline: $brand-use-underline;

$descriptive2-font-size-xs: .8rem;
$descriptive2-font-size-lm: .8rem;
$descriptive2-font-size-xl: .8rem;

$descriptive2-line-height-xs: 1.333rem;
$descriptive2-line-height-lm: 1.333rem;
$descriptive2-line-height-xl: 1.333rem;

$descriptive2-letter-spacing: $descriptive1-letter-spacing;
$descriptive2-font-family: $descriptive1-font-family;
$descriptive2-font-style: $descriptive1-font-style;
$descriptive2-font-weight: $font-weight-normal;
$descriptive2-font-color: $dim-grey;
$descriptive2-use-underline: $brand-use-underline;

$descriptive3-font-size-xs: .7rem;
$descriptive3-font-size-lm: .7rem;
$descriptive3-font-size-xl: .7rem;

$descriptive3-line-height-xs: 1rem;
$descriptive3-line-height-lm: 1rem;
$descriptive3-line-height-xl: 1rem;

$descriptive3-font-family: $font-family-primary;
$descriptive3-font-family-rtl: $font-family-secondary;
$descriptive3-font-style: $font-style-inherit;
$descriptive3-font-weight: $font-weight-normal;
$descriptive3-font-color: $dim-grey;
$descriptive3-use-underline: $brand-use-underline;
$descriptive3-letter-spacing-map: (
	small: .15rem,
	medium: .15rem,
	large: .15rem
);

$descriptive-section-line-height-xs: 1.333rem;
$descriptive-section-line-height-lm: 1.333rem;
$descriptive-section-line-height-xl: 1.333rem;

$descriptive-section-font-size-xs: .866rem;
$descriptive-section-font-size-lm:  .866rem;
$descriptive-section-font-size-xl: .866rem;

$descriptive-section-letter-spacing: .3rem;
$descriptive-section-font-family: $font-family-primary;
$descriptive-section-font-style: $descriptive1-font-style;
$descriptive-section-font-weight: $font-weight-bold;
$descriptive-section-use-underline: $brand-use-underline;
$descriptive-section-font-color: $dim-grey;

$display1-font-size-xs: 3rem;
$display1-font-size-lm: 3rem;
$display1-font-size-xl: 3rem;
$display1-line-height-xs: 3rem;
$display1-line-height-lm: 3rem;
$display1-line-height-xl: 3rem;
$display1-letter-spacing: null;
$display1-font-color: $grey-dark;
$display1-font-family: $font-family-primary;
$display1-font-style: $font-style-normal;
$display1-font-weight: $font-weight-bold;
$display1-heavy-weight: $brand-headline-heavy-weight-xbold;
$display1-use-underline: $brand-use-underline;
$display1-letter-spacing-map: (
	small: .1rem,
	medium: .1rem,
	xlarge: .1rem
);

$display2-font-size-xs: 2.25rem;
$display2-font-size-lm: 2.25rem;
$display2-font-size-xl: 2.25rem;
$display2-line-height-xs: 2.25rem;
$display2-line-height-lm: 2.25rem;
$display2-line-height-xl: 2.25rem;
$display1-letter-spacing: null;
$display2-font-color: $grey-dark;
$display2-font-family: $display1-font-family;
$display2-font-style: $display1-font-style;
$display2-font-weight: $display1-font-weight;
$display2-heavy-weight: $brand-headline-heavy-weight-xbold;
$display2-use-underline: $brand-use-underline;
$display2-letter-spacing-map: (
	small: .15rem,
	medium: .15rem,
	xlarge: .15rem
);

$display3-font-size-xs: 1.5rem;
$display3-font-size-lm: 1.5rem;
$display3-font-size-xl: 1.5rem;
$display3-line-height-xs: 1.5rem;
$display3-line-height-lm: 1.5rem;
$display3-line-height-xl: 1.5rem;
$display3-font-family: $display1-font-family;
$display3-font-style: $display1-font-style;
$display3-font-weight: $display1-font-weight;
$display3-heavy-weight: $brand-headline-heavy-weight-xbold;
$display3-font-color: $grey-dark;
$display3-use-underline: $brand-use-underline;
$display3-letter-spacing-map: (
	small: .15rem,
	medium: .15rem,
	xlarge: .15rem
);

$display4-font-size-xs: 4rem;
$display4-font-size-lm: 6rem;
$display4-font-size-xl: $display4-font-size-lm;
$display4-line-height-xs: 3rem;
$display4-line-height-lm: 5rem;
$display4-line-height-xl: $display4-line-height-lm;
$display4-letter-spacing: 2px;
$display4-font-family: $font-family-primary;
$display4-font-style: $font-style-normal;
$display4-font-weight: $font-weight-ultra;
$display4-heavy-weight: $font-weight-ultra;
$display4-use-underline: false;

$headline1-font-size-xs: 1.5rem;
$headline1-font-size-lm: 1.5rem;
$headline1-font-size-xl: 1.5rem;
$headline1-letter-spacing: .04rem;
$headline1-line-height-xs: 2rem;
$headline1-line-height-lm: 2rem;
$headline1-line-height-xl: 2rem;
$headline1-font-family: $font-family-primary;
$headline1-font-style: $font-style-inherit;
$headline1-font-weight: $font-weight-normal;
$headline1-use-underline: $brand-use-underline;

$headline2-font-size-xs: 1.25rem;
$headline2-font-size-lm: 1.25rem;
$headline2-font-size-xl: 1.25rem;
$headline2-line-height-xs: 1.5rem;
$headline2-line-height-lm: 1.5rem;
$headline2-line-height-xl: 1.5rem;
$headline2-letter-spacing-map: (
	small: .04rem,
	large: .04rem,
	xlarge: .04rem
);
$headline2-font-family: $headline1-font-family;
$headline2-font-style: $headline1-font-style;
$headline2-font-weight: $headline1-font-weight;
$headline2-use-underline: $brand-use-underline;

$headline3-font-size-xs: 1rem;
$headline3-font-size-lm: 1rem;
$headline3-font-size-xl: 1rem;
$headline3-line-height-xs: 1.5rem;
$headline3-line-height-lm: 1.5rem;
$headline3-line-height-xl: 1.5rem;
$headline3-letter-spacing: .04rem;
$headline3-font-family: $headline1-font-family;
$headline3-font-style: $headline1-font-style;
$headline3-font-weight: $headline1-font-weight;
$headline3-heavy-style : null;
$headline3-use-underline: $brand-use-underline;

$headline4-font-size-xs: 1rem;
$headline4-font-size-lm: $headline4-font-size-xs;
$headline4-font-size-xl: $headline4-font-size-xs;
$headline4-line-height-xs: 1.625rem;
$headline4-line-height-lm: $headline4-line-height-xs;
$headline4-line-height-xl: $headline4-line-height-xs;
$headline4-letter-spacing: .071rem;
$headline4-font-family: $font-family-primary;
$headline4-font-style: $font-style-normal;
$headline4-font-weight: $font-weight-normal;
$headline4-use-underline: $no-underline;

$headline5-font-size-xs: 2.125rem;
$headline5-font-size-lm: $headline5-font-size-xs;
$headline5-font-size-xl: $headline5-font-size-xs;
$headline5-line-height-xs: 3rem;
$headline5-line-height-lm: $headline5-line-height-xs;
$headline5-line-height-xl: $headline5-line-height-xs;
$headline5-letter-spacing: .04375rem;
$headline5-font-family: $font-family-secondary;
$headline5-font-style: $font-style-normal;
$headline5-font-weight: $font-weight-normal;
$headline5-use-underline: $no-underline;

$headline6-font-size-xs: 1.75rem;
$headline6-font-size-lm: $headline6-font-size-xs;
$headline6-font-size-xl: $headline6-font-size-xs;
$headline6-line-height-xs: 2.5rem;
$headline6-line-height-lm: $headline6-line-height-xs;
$headline6-line-height-xl: $headline6-line-height-xs;
$headline6-letter-spacing: .214rem;
$headline6-font-family: $font-family-primary;
$headline6-font-style: $font-style-normal;
$headline6-font-weight: $font-weight-book;
$headline6-use-underline: $no-underline;

$label1-color: $grey-dark;
$label1-font-family: $font-family-primary;
$label1-font-size: 1.5rem;
$label1-line-height: 2.5rem;

$label2-color: $grey;
$label2-font-family: $font-family-secondary;
$label2-font-size: 1rem;
$label2-line-height: 1.25rem;

$retail-body-line-height-xs: 1rem;
$retail-body-line-height-lm: 1rem;
$retail-body-line-height-xl: 1rem;

$retail-body-font-size-xs: .75rem;
$retail-body-font-size-lm:  .75rem;
$retail-body-font-size-xl: .75rem;

$retail-body-font-family: $font-family-primary;
$retail-body-font-style: $display1-font-style;
$retail-body-font-weight: $display1-font-weight;
$retail-body-use-underline: $brand-use-underline;
$retail-body-font-color: $grey-dark;

$retail-label-line-height-xs: 1.3rem;
$retail-label-line-height-lm: 1.3rem;
$retail-label-line-height-xl: 1.3rem;

$retail-label-font-size-xs: 1rem;
$retail-label-font-size-lm: 1rem;
$retail-label-font-size-xl: 1rem;

$retail-label-font-family: $font-family-primary;
$retail-label-font-style: $display1-font-style;
$retail-label-font-weight: $display1-font-weight;
$retail-label-use-underline: $brand-use-underline;
$retail-label-font-color: $grey-dark;

$retail-numeral-line-height-xs: 1.75rem;
$retail-numeral-line-height-lm: 1.75rem;
$retail-numeral-line-height-xl: 1.75rem;

$retail-numeral-font-size-xs: 1.75rem;
$retail-numeral-font-size-lm: 1.75rem;
$retail-numeral-font-size-xl: 1.75rem;

$retail-numeral-font-family: $font-family-primary;
$retail-numeral-font-style: $display1-font-style;
$retail-numeral-font-weight: $display1-font-weight;
$retail-numeral-use-underline: $brand-use-underline;
$retail-numeral-font-color: $gold;

$descriptive-letter-spacing-rtl: 0;
$display-letter-spacing-rtl: 0;
$headline-letter-spacing-rtl: 0;

// Forms Input Font Styling
$forms-input-line-height: 1.6667;
$forms-input-letter-spacing: normal;
$forms-input-font-weight: $font-weight-normal;

// Separator
$separator-primary: $grey-mid;
$separator-secondary: $grey;
$separator-tertiary: $grey-dark;
$separator-margin: .3125rem;

// Multimedia Scroller
$mms-slick-dots-color: transparent;
$mms-svg-icon-size: 2rem;
$scroller-label-margin-rtl: 0 0 0 .125rem;
$slick-dot-margins: .4375rem;
$slick-dot-diameter-active: 1.18rem;
$slick-dot-diameter: .94rem;
$slick-dot-border-width: 2px;
$slick-dot-li-size: 1.18rem;
$slick-dot-margin: 0 .41rem;
$content-title-font-size-large: 1.25rem;
$content-title-font-size-small: .9rem;

$slick-dot-margin-top: (
	small: -.625rem,
	large: 1.4rem
);

// Buttons
$button-icon-size: 1.176rem;
$button-icon-size-large: 1.304rem;
$button-icon-size-stacked: 1.304rem;
$button-icon-size-stacked-large: 1.304rem;

// Forms
$form-background: $white;
$form-border-primary: $grey-mid;
$form-interactive: $grey;
$form-interactive-hover: $grey-dark;
$form-interactive-disabled: $grey-light;

// Table
$table-brand-subheading: $grey-dark;
$table-text-padding-bottom: 0;

// Trim Info
$trim-info-headline-style: q-headline1;
$trim-info-text-price-float: right;
$trim-info-text-price-letter-spacing: .09rem;
$trim-info-text-from-visibility: visible;
$trim-info-text-from-display: block;
$trim-info-text-msrp-display: block;
$trim-info-text-msrp-visibility: visible;
$trim-info-body-description-style: q-body2;
$trim-info-full-container-float: left;
$trim-info-full-container-width: 100%;
$trim-info-half-container-float: left;
$trim-info-half-container-text-transform: none;
$trim-info-half-container-width: 50%;
$trim-info-button-secondary-margin-top: 1.25rem;
$trim-info-button-secondary-padding-left: .625rem;
$trim-info-button-primary-margin-top: 1.25rem;
$trim-info-button-primary-margin-padding-right: .625rem;

// External Site Styling
$external-site-popup-text-box-description: q-body2;
$external-site-popup-close-button-color: $grey-dark;

// Expander
$expander-margin-right: 0;
$expander-collapse-anim-speed: .01;
$expander-expand-anim-speed: .01;
$expander-button-padding: .875rem 2.8235rem .875rem 1.875rem;

//Tertiary Navigation
$tertiary-button-padding: 1.875rem 1.75rem;

%tertiary-nav-button {
	@extend %list-item-tertiary;
}

// Video Youtube
%video-info-font {
	@extend %q-caps !optional;
}

// Radio button
$radio-button-bg-color: $grey;

// disclosure
$disclosure-close-insert-icon-mq-use: false;
$disclosure-toast-height: 1.25rem;
$disclosure-toast-font-size: 1.25rem;
$disclosure-toast-right-map: (
	small: 1.25rem,
	large: 3.875rem
);
$disclosure-close-small-icon-right: 1.875rem;
$disclosure-close-small-icon-top: 1.75rem;
$disclosure-modal-close-right: 4.375rem;
$disclosure-modal-close-top: 1.875rem;
$disclosure-toast-padding-map: (
	small: .875rem .875rem,
	large: 1.25rem 4.875rem
);
$disclosure-modal-content-padding-map: (
	small: 2.5rem 4.688rem .9375rem .875rem,
	large: 1.875rem 11.25rem 3.75rem 3.75rem,
	xlarge: 2.5rem 11.25rem 3.75rem 8.75rem
);
$gl-disclosure-right-position-small: calc(.625rem + 17px);
$gl-disclosure-padding-small: 0 0 .3125rem;
$gl-button-label-font-size-small: .71rem;
$gl-button-label-font-size-large: .6rem;
$gl-button-label-font-size-xxlarge: .783rem;
$gl-q-text-font-size: .7rem;
$gl-category-title-font-size: 1.4rem;
$gl-slider-arrow-height-map: (
	small: 5rem,
	large: 7.5rem,
	xlarge: 6.521rem
);
$gl-slider-arrow-width-map: (
	small: 2.352rem,
	large: 4rem,
	xlarge: 3.478rem

);

$gl-slider-arrow-height-before-map: (
	small: 2.64rem,
	large: 3.8rem,
	xlarge: 3.3rem
);

$gl-slider-arrow-width-before-map: (
	small: 1.35rem,
	large: 2rem,
	xlarge: 1.74rem
);

$gl-thumbnail-margin-map: (
	small: 0 7% 1.1765rem 0,
	medium: 0 4.6% 1.5rem 0
);
$gl-thumbnail-width-map: (
	small: 28.5%,
	medium: 21.5%
);

$responsive-image-disclosure-bottom-padding: 1.56rem;
$responsive-image-disclosure-side-padding: 1.88rem;
$responsive-image-disclosure-mms-dots-offset: 2.5rem;

//Primary Nav
$nav-primary-top-bar-padding-map: (
	small: .25rem,
	large: .25rem,
	xlarge: .25rem
);

$nav-primary-content-nav-padding-map: (
	small: .25rem,
	large: .25rem,
	xlarge: .25rem
);

$nav-primary-link-label-font-size-map: (
	small: .9rem,
	xlarge: .9rem,
	xxlarge: .9rem
);

$nav-primary-nav-list-item-padding-map: (
	small: 0 .294rem,
	xlarge: 0 .783rem
);

//Secondary Nav
$nav-secondary-button-font-weight: $font-weight-bold;
$nav-secondary-button-font-size-map: (
	desktop: 1.333rem,
	tablet: 1.333rem,
	mobile: 1.333rem
);
$nav-secondary-button-letter-spacing-map: (
	small: 0,
	medium: 0,
	large: 0,
	xlarge: 0
);

$nav-secondary-nav-anchor-border-bottom: .0625rem solid $nav-secondary-border-color;
$nav-secondary-nav-anchor-border-right: $nav-secondary-nav-anchor-border-bottom;
$nav-secondary-nav-button-line-height: 1.6rem;
$nav-secondary-nav-button-padding: 0;
$nav-secondary-nav-button-padding-rtl: 0;
$nav-secondary-year-toggle-margin: 0 0 0 .75rem;
$nav-secondary-year-toggle-margin-rtl: 0 .75rem 0 0;
$nav-secondary-dropdown-box-width-map: (
	xlarge: 400px,
	large: 400px,
	medium: 100%,
	small: 100%
);

$nav-secondary-dropdown-icon-size-map: (
	xxlarge: .7rem .52rem,
	large: .8rem .6rem,
	small: .94rem .71rem
);

$nav-secondary-dropdown-icon-container-height-map: (
	xxlarge: 2.17rem,
	large: 2.5rem,
	small: 2.94rem
);

$nav-secondary-dropdown-icon-container-width-map: (
	xxlarge: 3.04rem,
	large: 3.5rem,
	small: 4.12rem
);

$nav-secondary-dropdown-text-padding-map: (
	xxlarge: .22rem .22rem .22rem 1.3rem,
	large: .25rem .25rem .25rem 1.5rem,
	small: .29rem .29rem .29rem 1.76rem
);

$nav-secondary-dropdown-text-padding-map-rtl: (
	xxlarge: 0 1.3rem 0 0,
	large: 0 1.5rem 0 0,
	small: 0 1.76rem 0 0
);

$nav-secondary-nav-title-year-toggle-width-map: (
	xlarge: 255px,
	large: 255px,
	medium: 255px,
	small: 255px
);

$nav-secondary-sibling-font-size-map: (
	xxlarge: .9rem,
	xlarge: .9rem,
	small: .9rem
);
$nav-secondary-sibling-nav-item-margin: 0 1.563rem 0 0;

$nav-secondary-button-font-weight: $font-weight-bold;
$nav-secondary-anchor-list-item-font-size: .9375rem;

// Tab Nav
$tab-nav-font-color-active: $grey;
$tab-nav-font-color-hover: $grey-dark;

//vehicle-info-block
$vehicle-block-title-display-map: (
	small: inline-block,
	large: inline-block
);
$vehicle-block-title-padding-bottom-map: (
	small: .25rem,
	large: .3125rem
);
$vehicle-block-title-width-map: (
	small: 100%,
	large: 100%
);
$vehicle-block-price-display-map: (
	small: inline-block,
	large: inline-block
);
$vehicle-block-price-width-map: (
	small: 100%,
	large: 100%
);

// Gallery Preview ($gp-)
$gp-responsive-img-duration: 0;

//Vehicle Info Tile - HMF
$vehicle-info-tile-hmf-text-style: q-body2;
$vehicle-info-tile-hmf-text-color: $dim-grey;
$vehicle-info-tile-hmf-text-font-size-xs: .65625rem;
$vehicle-info-tile-hmf-text-font-size-lm: $body2-font-size-lm;
$vehicle-info-tile-hmf-text-line-height-xs: .9375rem;
$vehicle-info-tile-hmf-text-line-height-lm: $body2-line-height-lm;
$vehicle-info-tile-hmf-text-margin-bottom: 0;
$vehicle-info-tile-hmf-alt-text-color: $dim-grey;
$vehicle-info-tile-hmf-alt-text-font-size-xs: .65625rem;
$vehicle-info-tile-hmf-alt-text-line-height-xs: .9375rem;
$vehicle-info-tile-hmf-alt-text-font-size-lm: $body2-font-size-lm;
$vehicle-info-tile-hmf-alt-text-line-height-lm: $body2-line-height-lm;

//Vehicle Info Tile HMF - As Shown Price
$vehicle-info-tile-as-shown-price-font-size-xs: .65625rem;
$vehicle-info-tile-as-shown-price-line-height-xs: .9375rem;

//Help Me Find
$hmf-results-tag-icon: close-grey-light;
$hmf-results-tag-icon-hover: close-grey-hover;
$hmf-results-tag-icon-margin: 1.875rem;
$hmf-results-tag-icon-size: .7rem;
$hmf-filter-checkbox-margin: .1rem 0 0 0;
$hmf-filter-checkmark-icon-size: 1.3rem;
$hmf-filter-checkmark-icon: check;

// Payment Estimator
$gmst-postalcode-container-height: 3.125rem;
$gmst-postalcode-container-xlarge-up-height: 3.75rem;
$pe-finance-additional-cost-typography: q-body2;

// Dealer Locator
$dl-dealer-search-button-background-color-hover: $grey-dark;
$dl-dealer-search-button-position-top: .85rem;
$dl-dealer-search-button-position-left: .9rem;
$dl-info-icon: info-icon;
$dl-info-icon-bg-color: transparent;
$dl-info-icon-font-size: 1.5rem;
$dl-location-pin: dealer-pin-map;
$dl-location-pin-active: dealer-pin-active;
$dl-location-pin-active-size: 2.25rem;
$dl-location-pin-height: 2.25rem;
$dl-location-pin-size: 1.5rem;
$dl-checkmark: check;
$dl-checkmark-height: 1.25rem;
$dl-checkmark-width: 1.25rem;
$dl-checkmark-left: .6rem;
$dl-checkmark-top: .45rem;
$dl-search-column-width: 50%;

// Legal Notification
$legal-notification-consent-close-size: 1.25rem;
$legal-notification-consent-minus: minus-white;
$legal-notification-consent-plus: plus-white;

// Global Forms
$gf-button-line-height: 1.6667;
$gf-vehicle-selector-headline-font: q-descriptive1;
$gf-vehicle-selector-subheadline-font: q-body2;
$gf-label-text-color: $dim-grey;
$gf-label-line-height: 1.6667;
$body-font-color: $grey-dark;
$gf-label-text-weight: $font-weight-normal;
$body-font-family: $font-family-secondary;
$gf-label-text-font-size-map: (
	small: .8rem,
	medium: .8rem,
	large: .8rem,
	xlarge: .8rem
);
$gf-input-font-size-map: (
	small: .8rem,
	medium: .8rem,
	large: .8rem,
	xxlarge: .8rem
);
$gf-label-text-letter-spacing-map: (
	small: .1406rem,
	medium: .1875rem,
	xlarge: .2531rem
);
$gf-label-bottom-margin-map: (
	small: 0
);
$gf-optional-details-icon-bottom: -.45rem;
$gf-optional-details-icon-left: 8.125rem;
$gf-layer-window-modal-close-icon: close-circle-dark;
$gf-layer-window-modal-close-icon-size: 0 .95rem;
$gf-layer-window-modal-close-icon-size-mobile: 0 .65rem;

// Global Forms Focused Font Size
$gf-mobile-focus-font-size: .875rem;

//Global Forms Line Height
$gf-input-line-height: 1.6667;

//Global Forms Height
$gf-input-height-map: (
	small: 1.7398rem,
	medium: 1.7398rem,
	large: 1.7398rem
);

//Global Forms Padding
$gf-input-padding-map: (
	small: .0625rem .0625rem .0625rem .625rem,
	medium: .0625rem .0625rem .0625rem .625rem,
	large: .0625rem .0625rem .0625rem 1.25rem,
);

// Global Forms Datepicker
$gf-datepicker-arrow: hotspot-caret-gold;
$gf-datepicker-arrow-size: 1.5rem;

// Global Forms Print Icons
$gf-print-icon-link: print;
$gf-print-icon-button: print;
$gf-print-icon-button-hover: print;

//Global Forms Progress Tracker
$gf-drmp-grey-dark: #262626;

//----------------------------------------------------------------------------------------------------------------------
// Quantum Forms 2.0
//----------------------------------------------------------------------------------------------------------------------
$qf-font-family-primary: $font-family-primary;
$qf-component-line-height: 1.6667;
$qf-input-component-border: 1px solid $grey-mid;
$qf-component-placeholder-color: $grey-mid;
$qf-root-panel-line-height: 1;

$qf-paragraph-text-color: $grey-dark;
$qf-paragraph-text-font-size: .875rem;
$qf-paragraph-text-line-height: 1.14;

$qf-header-color: $grey-dark;
$qf-header-font-size: 1rem;
$qf-header-font-weight: $font-weight-book;
$qf-header-line-height: 1;
$qf-header-text-transform: uppercase;

//Chevrolet Adyen Styling
$qf-adyen-widget-margin: 1rem;
$qf-adyen-border-radius: 8px;

$qf-adyen-text-field-border: 1px solid $grey-mid;
$qf-adyen-text-field-background-color: $white;
$qf-adyen-zip-mobile-margin: 5.5rem;
$qf-adyen-zip-mobile-margin-top: 1rem;
$qf-adyen-input-margin-bottom: 3rem;
$qf-adyen-text-height: 1.5rem;

$qf-button-bg-color: transparent;
$qf-button-border: .125rem solid $gold;
$qf-button-text-color: $grey-dark;
$qf-button-font-family: $font-family-secondary;
$qf-button-font-size: .7rem;
$qf-button-font-weight: $font-weight-bold;
$qf-button-letter-spacing: $font-style-normal;
$qf-button-line-height: 1.375;
$qf-button-padding: .8rem 0;
$qf-button-text-transform: none;

$qf-button-text-color-hover: $qf-button-text-color;
$qf-button-bg-color-hover: $gold;
$qf-button-border-hover: $qf-button-border;
$qf-button-border-text-color: $grey-dark;

$qf-button-bg-color-secondary: transparent;
$qf-button-border-secondary: .125rem solid $grey-mid;
$qf-button-color-secondary: $qf-button-text-color;
$qf-button-bg-color-hover-secondary: $grey-mid;
$qf-button-border-hover-secondary: $qf-button-border-secondary;
$qf-button-color-hover-secondary: $qf-button-text-color;

$qf-button-bg-color-primary-invert: transparent;
$qf-button-border-primary-invert: $qf-button-border;
$qf-button-color-primary-invert: $white;
$qf-button-bg-color-hover-primary-invert: $qf-button-bg-color-hover;
$qf-button-border-hover-primary-invert: $qf-button-border-hover;
$qf-button-color-hover-primary-invert: $qf-button-color-primary-invert;

$qf-button-bg-color-secondary-invert: transparent;
$qf-button-border-secondary-invert: .125rem solid $grey-button-invert;
$qf-button-color-secondary-invert: $white;
$qf-button-bg-color-hover-secondary-invert: $grey-button-invert;
$qf-button-border-hover-secondary-invert: $qf-button-border-secondary-invert;
$qf-button-color-hover-secondary-invert: $qf-button-color-secondary-invert;

$qf-button-bg-color-link: transparent;
$qf-button-border-link: none;
$qf-button-color-link: $grey-dark;
$qf-button-bg-color-hover-link: transparent;
$qf-button-border-hover-link: $qf-button-border-link;
$qf-button-color-hover-link: $gold;

$qf-button-bg-color-link-invert: transparent;
$qf-button-border-link-invert: $qf-button-border-link;
$qf-button-color-link-invert: $white;
$qf-button-bg-color-hover-link-invert: $qf-button-bg-color-link-invert;
$qf-button-border-hover-link-invert: $qf-button-border-hover-link;
$qf-button-color-hover-link-invert: $gold-light;

$qf-drop-down-bg-image: get-element('arrow-small-down-white'), linear-gradient(to right, $grey, $grey);
$qf-drop-down-bg-position: calc(100% - 30px) calc(.6em + 2px), 100% 0;
$qf-drop-down-bg-size: 10px 10px, 70px 100%;
$qf-drop-down-border: $qf-input-component-border;
$qf-drop-down-border-radius: 0;
$qf-drop-down-font-size: .8428rem;
$qf-drop-down-height: 40px;
$qf-drop-down-line-height: 1.5;
$qf-drop-down-padding: 4px 70px 4px 20px;

$qf-drop-down-bg-image-hover: get-element('arrow-small-down-white'), linear-gradient(to right, $grey-dark, $grey-dark);

$qf-textarea-border: $qf-input-component-border;
$qf-textarea-text-color: $grey-dark;
$qf-textarea-font-size: 1.125rem;
$qf-textarea-line-height: $qf-component-line-height;
$qf-textarea-margin-bottom: 1.25rem;
$qf-textarea-padding: 1rem;

$qf-radio-button-label-text-color: $dim-grey;
$qf-radio-button-label-font-size: .75rem;
$qf-radio-button-label-font-weight: $font-weight-bold;
$qf-radio-button-label-letter-spacing: .09em;
$qf-radio-button-label-line-height: 1.375;
$qf-radio-button-label-padding: 0;//0 .5rem 1rem 1.5rem
$qf-radio-button-label-text-transform: uppercase;
$qf-radio-button-border: 1px solid $grey;
$qf-radio-button-border-radius: .625rem;
$qf-radio-button-box-shadow: inset 0 0 0 .2rem $white;
$qf-radio-button-height: 20px;
$qf-radio-button-width: $qf-radio-button-height;
$qf-radio-button-margin-right: .25rem;
$qf-radio-button-label-margin: .325rem .325rem .325rem 0;;

$qf-radio-button-bg-color-active: $grey;
$qf-radio-button-bg-box-shadow-active: $qf-radio-button-box-shadow;

$qf-text-field-height: 40px;
$qf-text-field-padding: 1px 0 1px 20px;

$qf-text-field-label-color: $dim-grey;
$qf-text-field-label-font-size: .8rem;
$qf-text-field-label-font-weight: $body2-font-weight;
$qf-text-field-label-letter-spacing: .15rem;
$qf-text-field-label-text-transform: uppercase;
$qf-text-field-label-line-height: 1.333rem;

$qf-input-border-color: $grey-mid;
$qf-input-box-shadow: none;
$qf-input-background-color-focus: $white;
$qf-input-border-color-focus: $grey;

$qf-check-box-group-color: #4d4d4d;
$qf-check-box-group-cursor: pointer;
$qf-check-box-group-height: 1.25rem;
$qf-check-box-group-margin: 0;
$qf-check-box-font-weight: $font-weight-normal;
$qf-check-box-group-line-height: 1.6667;

$qf-check-box-group-container-padding: .625rem 0;
$qf-check-box-group-container-margin: 0 0 .3rem;

$qf-check-box-label-color: $dim-grey;
$qf-check-box-label-font-size: .75rem;
$qf-check-box-label-font-weight: $font-weight-bold;
$qf-check-box-label-height: auto;
$qf-check-box-label-margin-left: 0;
$qf-check-box-label-text-transform: none;
$qf-check-box-label-padding: 0;
$qf-check-box-label-margin-bottom: 0;
$qf-check-box-label-letter-spacing: .09em;
$qf-check-box-label-line-height: 1.333rem;

$qf-check-box-bg-color: $grey-light;
$qf-check-box-border: 1px solid $grey-light;
$qf-check-box-border-selected: 0;
$qf-check-box-border-radius: 1px;
$qf-check-box-height: 20px;
$qf-check-box-vertical-align: middle;
$qf-check-box-width: $qf-check-box-height;
$qf-check-box-top: 0;
$qf-check-box-top-position: 5px;

$qf-check-box-bg-color-active: $qf-check-box-bg-color;
$qf-check-box-border-color-active: $qf-check-box-bg-color;

$qf-check-box-check-background: get-element('check') no-repeat;
$qf-check-box-check-background-white: get-element('check-white') no-repeat;
$qf-check-box-check-right: calc(100% - 1rem);
$qf-check-box-check-width: 19px;
$qf-check-box-check-height: 22px;
$qf-check-box-check-left: 3.5px;
$qf-check-box-check-top: -2px;

$qf-validation-error-font-size: .875rem;
$qf-validation-error-line-height: 1.25rem;
$qf-validation-error-text-font-family: $body2-font-family;
$qf-validation-error-text-font-size: $body2-font-size-xl;
$qf-validation-error-text-font-weight: $body2-font-weight;
$qf-validation-error-text-line-height: $body2-line-height-xl;
$qf-validation-error-text-padding: 0;
$qf-validation-error-text-margin: 0 0 0 20px;

$qf-expand-border-top: 1px solid $grey-mid;
$qf-expand-border-bottom: 1px solid $grey-mid;
$qf-expand-border-left: 1px solid $grey-mid;
$qf-expand-border-right: 1px solid $grey-mid;
$qf-expand-icon: get-element('plus') no-repeat;
$qf-expand-icon-hover:  get-element('plus-gold') no-repeat;
$qf-expand-padding: .61rem 1.305rem;

$qf-collapse-border-top: 1px solid $grey-mid;
$qf-collapse-border-bottom: 1px solid $grey-mid;
$qf-collapse-border-left: 1px solid $grey-mid;
$qf-collapse-border-right: 1px solid $grey-mid;
$qf-collapse-icon: get-element('minus') no-repeat;
$qf-collapse-icon-hover: get-element('minus') no-repeat;

$qf-korea-sign-in-logout-button-color: $grey-dark;
$qf-korea-sign-in-logout-button-text-decoration-color: $grey-dark;
$qf-korea-sign-in-logout-button-color-hover: $gold;
$qf-korea-sign-in-logout-button-text-decoration-color-hover: $gold;

// <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="%23373637" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 6L18 18" stroke="%23373637" stroke-linecap="round" stroke-linejoin="round"/></svg>
$qf-close-x: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M18%206L6%2018%22%20stroke%3D%22%23373637%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3Cpath%20d%3D%22M6%206L18%2018%22%20stroke%3D%22%23373637%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
$qf-modal-close-btn-background: no-repeat 12px 12px $white $qf-close-x;

$qf-ds-dealer-option-text: q-body2;
$qf-dealer-selector-result-label: q-descriptive2;

$qf-error-red: $red;
$qf-success-green: $green;

// Hotspot
$hotspot-content-layer-bg-color: rgba(6, 5, 5, .8);
$hotspot-content-layer-bg-color-invert: rgba(255, 255, 255, .8);

// Table Builder
$table-builder-gradient: rgba(255, 255, 255, 0);

// Content Modal
$modal-header-background-color: $white;
$modal-header-title-color: $grey-dark;
$modal-header-position: relative;
$modal-title-text-align: left;
$modal-title-width: 100%;
$modal-use-header: true;

//Segment Navigation
$nav-segment-content-item-hover-background-color: $white;

// Regional Cash Incentive
$regional-cash-incentive-chevy-darker-grey: #262626;

$chevy-regional-cash-incentive-font-size-map: (
	x-small: .750rem,
	small: .875rem,
	medium: 1rem,
	medium-plus: 1.03125rem,
	large: 1.25rem,
	x-large: 1.375rem,
	xx-large: 1.625rem
);

$chevy-regional-cash-incentive-font-spacing-map: (
	small: .0625rem,
	large: .09rem
);

$chevy-regional-cash-incentive-inline-font-size-map: (
	small: .875rem,
	large: 1rem
);

// Region Selector
$rs-dropdown-border-bkgrnd-color: $grey;
$rs-dropdown-border-bkgrnd-color-focus: $grey-dark;
$chevy-rs-font-size-map: (
	x-small: .750rem,
	small: .875rem,
	medium: 1.000rem,
	large: 1.125rem,
	x-large: 1.25rem,
	xx-large: 1.5rem
);
$rs-dropdown-height-map: (
	small: 4rem,
	medium: 7rem,
	large: 8rem,
	x-large: 11rem
);

//Buttons
$button-primary-border-primary-color: $gold;
$button-q-link-font-size: .7rem;
$button-q-link-text-hover: $gold-text;
$button-q-link-q-invert-text-hover: $gold-light;
$button-q-link-q-invert-color: $white;
$button-font: $font-weight-bold .7rem $font-family-secondary;
$button-q-link-padding: 10px 0;
$button-padding: .8rem 0;
$button-quaternary-primary-color: $white;
$button-quaternary-fill: 1;
$button-quaternary-fill-color: $grey-dark;
$button-quaternary-background-color: $white;
$button-quaternary-color: $grey-dark;
$button-quaternary-hover-color: $white;
$button-quaternary-border-radius: 8px;
$button-quaternary-active-icon: 'chevron-white';
$button-quaternary-active-icon-height: 24px;
$button-quaternary-active-icon-width: 24px;
$button-quaternary-active-icon-position: 1rem;
$button-quaternary-inline-padding: 1rem;
$button-icon-inline-quaternary-icon-margin: 1rem;
$button-quaternary-text-padding: 1rem;
$button-secondary-background-color: transparent;
$button-secondary-background-color-hover: $grey-button;
$button-secondary-border-primary-color: $grey-button;
$button-secondary-color: $grey-dark;
$button-secondary-invert-color: $white;
$button-secondary-invert-background-color: transparent;
$button-secondary-invert-border-color: $grey-button-invert;
$button-secondary-invert-hover-background-color: $grey-button-invert;
$button-secondary-invert-hover-color: $white;
$button-secondary-border-fill: 1;
$button-secondary-border-fill-color: $grey-button-invert;

// Multi media slider
$slider-dropdown-item-height: (
	none: 0,
	small: 3.438rem,
	large: 3.938rem,
	xxlarge: 4.7rem
);

$slider-dropdown-item-width: (
	none: 0,
	small: 100%,
	large: 13.1rem,
	xlarge: 13.3rem,
	xxlarge: 20.9rem
);

// 360 colorizer
$colorizer-360-tooltip-background-color: $black;
$colorizer-360-tooltip-text-color: $body1-invert-color;

// colorizer
$colorizer-chip-active-display: flex;
$colorizer-chip-padding: 0;
$colorizer-chip-active-padding: 0;
$colorizer-xlarge-chip-padding: 0;
$colorizer-xlarge-active-chip-padding: 0;
$colorizer-xlarge-hover-chip-padding: 0;
$colorizer-chip-height: 1.882rem;
$colorizer-chip-hover-height: 1.882rem;
$colorizer-chip-width: 1.882rem;
$colorizer-chip-hover-width: 1.882rem;
$colorizer-chip-active-width: 1.882rem;
$colorizer-chip-active-height: 1.882rem;
$colorizer-panel-padding: 0 3px 0 0;

//Colors to match dev IT colors
$ev-blue: #3078bf;
$ev-hover: #139ded;
$ev-dark-grey: #202020;
$ev-light-grey: #afafaf;
$ev-light-grey-bdr: #f7f7f7;

// Slick Slider (multimedia scroller)
$scroller-overlay-more-bottom: .9rem;
$scroller-common-padding: 1rem;
$scroller-overlay-more-height-medium: auto;

// Buttons Brand mixin variables
$button-background-color: transparent;
$button-border-width: 2px;
$button-no-border-width: auto;
$button-color: $grey-dark;
$button-font: $font-weight-bold .706rem $font-family-secondary;
$button-line-height: 2;
$button-letter-spacing: normal;
$button-padding: .824rem 0;
$button-hover-color: $grey-dark;

// Primary
$button-primary-border-fill: false;
$button-primary-border-fill-color: $gold-light;
$button-primary-border-hover-color: $grey-dark;
$button-primary-border-primary-color: $gold-light;
$button-primary-border-fill: $gold-light;
$button-primary-invert-hover-background-color: $gold-light;
$button-primary-invert-hover-color: $white;
$button-primary-active-zone-fill-color: $gold-light;
$button-primary-active-zone-border-color: $gold-light;
$button-primary-active-zone-hover-color: $white;
