.q-headline {
	.q-headline-icon {
		.q-headline-icon {
			transform: translateY(-.25rem);
		}
	}

	.q-headline-icon.text-right {
		display: block;
	}
}
