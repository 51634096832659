//Brand Specific Typography Rules
//1. Supserscript/Subscript Sitewide Styles

// Per the Chevrolet Styleguide, at X-small, body2 takes on the appearance of body1.
// The sizes are taken care of but the color needs to be overwritten here
%q-body2 {
	color: $dim-grey;

	a {
		font-weight: $font-weight-bold;
	}

	@media #{$small-only} {
		color: $brand-body1-color;
	}
}

.q-headline1,
.q-headline2,
.q-headline3,
.q-descriptive1,
.q-descriptive2 {
	margin-bottom: 0;
	margin-top: 0;
}

.q-descriptive-section {
	@include q-headline-values($descriptive-section-font-color, $descriptive-section-font-style, $descriptive-section-font-weight, null, null, $descriptive-section-letter-spacing, $descriptive-section-font-size-xs, $descriptive-section-line-height-xs, $descriptive-section-font-size-lm, $descriptive-section-line-height-lm, $descriptive-section-font-size-xl, $descriptive-section-line-height-xl, $descriptive-section-font-family, $descriptive-section-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
	margin-bottom: 0;
}

.q-retail-body {
	@include q-headline-values($retail-body-font-color, $retail-body-font-style, $retail-body-font-weight, null, null, null, $retail-body-font-size-xs, $retail-body-line-height-xs, $retail-body-font-size-lm, $retail-body-line-height-lm, $retail-body-font-size-xl, $retail-body-line-height-xl, $retail-body-font-family, $retail-body-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

.q-retail-label {
	@include q-headline-values($retail-label-font-color, $retail-label-font-style, $retail-label-font-weight, null, null, null, $retail-label-font-size-xs, $retail-label-line-height-xs, $retail-label-font-size-lm, $retail-label-line-height-lm, $retail-label-font-size-xl, $retail-label-line-height-xl, $retail-label-font-family, $retail-label-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
 }

.q-retail-numeral {
	@include q-headline-values($retail-numeral-font-color, $retail-numeral-font-style, $retail-numeral-font-weight, null, null, null, $retail-numeral-font-size-xs, $retail-numeral-line-height-xs, $retail-numeral-font-size-lm, $retail-numeral-line-height-lm, $retail-numeral-font-size-xl, $retail-numeral-line-height-xl, $retail-numeral-font-family, $retail-numeral-use-underline, $body-baseline-value, $baseline-body-ratio-primary);
}

.q-body3 {
	@extend %q-body;

	@include font-queries($body3-font-size-xs, $body3-line-height-xs, $body3-font-size-lm, $body3-line-height-lm,
		$body3-font-size-xl, $body3-line-height-xl, $baseline-body-ratio-primary, $body-baseline-value);

	color: $body3-font-color;

	@media #{$small-up} {
		margin-bottom: 1rem;
	}

	ul,
	ol {
		margin: $body2-list-item-margin;
	}
}

// Adding override to placeholder so that any element that extends this will auto inherit
%q-descriptive2 {
	margin-bottom: 0 !important; // sass-lint:disable-line no-important
}
