@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Flex Mixin */
.no-touch .q-button.q-list-item.q-tertiary-nav-button, .q-tertiary-nav-button.q-button.q-list-item {
  padding: 1.875rem 1.75rem;
}

.q-sticky.bottom .sticky-child, .q-sticky.top .sticky-child {
  left: 0;
  position: fixed;
  right: 0;
  transform: translateZ(0);
}

/* Flex Mixin */
/*************************
 * For backwards compatibility of previously authored grids, the key and value pair
 * should not be changed. New values will have the key out of order if the values
 * are ordered from smallest to largest.
 *************************/
/* Flex Mixin */
meta.foundation-version {
  font-family: "/5.5.3/";
}

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0;
}

meta.foundation-mq-small-only {
  font-family: "/only screen and (max-width: 419px)/";
  width: 0;
}

meta.foundation-mq-medium {
  font-family: "/print, only screen and (min-width:420px)/";
  width: 420px;
}

meta.foundation-mq-medium-only {
  font-family: "/print, only screen and (min-width:420px) and (max-width:599px)/";
  width: 420px;
}

meta.foundation-mq-large {
  font-family: "/print, only screen and (min-width:600px)/";
  width: 600px;
}

meta.foundation-mq-large-only {
  font-family: "/print, only screen and (min-width:600px) and (max-width:959px)/";
  width: 600px;
}

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:960px)/";
  width: 960px;
}

meta.foundation-mq-xlarge-only {
  font-family: "/only screen and (min-width:960px) and (max-width:1499px)/";
  width: 960px;
}

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:1500px)/";
  width: 1500px;
}

meta.foundation-data-attribute-namespace {
  font-family: false;
}

html, body {
  height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-size: 100%;
}

body {
  background: #fff;
  color: #262626;
  cursor: auto;
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
  padding: 0;
  position: relative;
}

a:hover {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
}

textarea {
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
}

.row {
  margin: 0 auto;
  max-width: 156.25rem;
  width: 100%;
}
.row:before, .row:after {
  content: " ";
  display: table;
}
.row:after {
  clear: both;
}
.row.collapse > .column,
.row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}
.row.collapse .row {
  margin-left: 0;
  margin-right: 0;
}
.row .row {
  margin: 0 -2.5vw;
  max-width: none;
  width: auto;
}
.row .row:before, .row .row:after {
  content: " ";
  display: table;
}
.row .row:after {
  clear: both;
}
.row .row.collapse {
  margin: 0;
  max-width: none;
  width: auto;
}
.row .row.collapse:before, .row .row.collapse:after {
  content: " ";
  display: table;
}
.row .row.collapse:after {
  clear: both;
}

.column,
.columns {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  width: 100%;
  float: left;
}

.column + .column:last-child,
.column + .columns:last-child,
.columns + .column:last-child,
.columns + .columns:last-child {
  float: right;
}
.column + .column.end,
.column + .columns.end,
.columns + .column.end,
.columns + .columns.end {
  float: left;
}

@media only screen {
  .small-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .small-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .small-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }

  .small-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }

  .small-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }

  .small-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }

  .small-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .small-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }

  .small-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }

  .small-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }

  .small-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }

  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .small-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }

  .small-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }

  .small-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }

  .small-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }

  .small-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .small-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }

  .small-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }

  .small-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }

  .small-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }

  .column,
.columns {
    position: relative;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }

  .small-1 {
    width: 8.3333333333%;
  }

  .small-2 {
    width: 16.6666666667%;
  }

  .small-3 {
    width: 25%;
  }

  .small-4 {
    width: 33.3333333333%;
  }

  .small-5 {
    width: 41.6666666667%;
  }

  .small-6 {
    width: 50%;
  }

  .small-7 {
    width: 58.3333333333%;
  }

  .small-8 {
    width: 66.6666666667%;
  }

  .small-9 {
    width: 75%;
  }

  .small-10 {
    width: 83.3333333333%;
  }

  .small-11 {
    width: 91.6666666667%;
  }

  .small-12 {
    width: 100%;
  }

  .small-offset-0 {
    margin-left: 0 !important;
  }

  .small-offset-1 {
    margin-left: 8.3333333333% !important;
  }

  .small-offset-2 {
    margin-left: 16.6666666667% !important;
  }

  .small-offset-3 {
    margin-left: 25% !important;
  }

  .small-offset-4 {
    margin-left: 33.3333333333% !important;
  }

  .small-offset-5 {
    margin-left: 41.6666666667% !important;
  }

  .small-offset-6 {
    margin-left: 50% !important;
  }

  .small-offset-7 {
    margin-left: 58.3333333333% !important;
  }

  .small-offset-8 {
    margin-left: 66.6666666667% !important;
  }

  .small-offset-9 {
    margin-left: 75% !important;
  }

  .small-offset-10 {
    margin-left: 83.3333333333% !important;
  }

  .small-offset-11 {
    margin-left: 91.6666666667% !important;
  }

  .small-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .column.small-centered,
.columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.small-uncentered,
.columns.small-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .column.small-centered:last-child,
.columns.small-centered:last-child {
    float: none;
  }

  .column.small-uncentered:last-child,
.columns.small-uncentered:last-child {
    float: left;
  }

  .column.small-uncentered.opposite,
.columns.small-uncentered.opposite {
    float: right;
  }

  .row.small-collapse > .column,
.row.small-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.small-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.small-uncollapse > .column,
.row.small-uncollapse > .columns {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }
}
@media print, only screen and (min-width:420px) {
  .medium-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .medium-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .medium-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }

  .medium-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }

  .medium-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }

  .medium-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }

  .medium-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .medium-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }

  .medium-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }

  .medium-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }

  .medium-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }

  .medium-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .medium-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }

  .medium-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }

  .medium-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }

  .medium-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }

  .medium-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .medium-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }

  .medium-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }

  .medium-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }

  .medium-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }

  .column,
.columns {
    position: relative;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }

  .medium-1 {
    width: 8.3333333333%;
  }

  .medium-2 {
    width: 16.6666666667%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-4 {
    width: 33.3333333333%;
  }

  .medium-5 {
    width: 41.6666666667%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-7 {
    width: 58.3333333333%;
  }

  .medium-8 {
    width: 66.6666666667%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-10 {
    width: 83.3333333333%;
  }

  .medium-11 {
    width: 91.6666666667%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-offset-0 {
    margin-left: 0 !important;
  }

  .medium-offset-1 {
    margin-left: 8.3333333333% !important;
  }

  .medium-offset-2 {
    margin-left: 16.6666666667% !important;
  }

  .medium-offset-3 {
    margin-left: 25% !important;
  }

  .medium-offset-4 {
    margin-left: 33.3333333333% !important;
  }

  .medium-offset-5 {
    margin-left: 41.6666666667% !important;
  }

  .medium-offset-6 {
    margin-left: 50% !important;
  }

  .medium-offset-7 {
    margin-left: 58.3333333333% !important;
  }

  .medium-offset-8 {
    margin-left: 66.6666666667% !important;
  }

  .medium-offset-9 {
    margin-left: 75% !important;
  }

  .medium-offset-10 {
    margin-left: 83.3333333333% !important;
  }

  .medium-offset-11 {
    margin-left: 91.6666666667% !important;
  }

  .medium-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .column.medium-centered,
.columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.medium-uncentered,
.columns.medium-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .column.medium-centered:last-child,
.columns.medium-centered:last-child {
    float: none;
  }

  .column.medium-uncentered:last-child,
.columns.medium-uncentered:last-child {
    float: left;
  }

  .column.medium-uncentered.opposite,
.columns.medium-uncentered.opposite {
    float: right;
  }

  .row.medium-collapse > .column,
.row.medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.medium-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.medium-uncollapse > .column,
.row.medium-uncollapse > .columns {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }

  .push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }

  .pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }

  .pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }

  .pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }

  .pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }

  .pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }

  .pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }

  .pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }

  .pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }
}
@media print, only screen and (min-width:600px) {
  .large-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .large-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .large-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }

  .large-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }

  .large-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }

  .large-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }

  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .large-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }

  .large-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }

  .large-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }

  .large-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }

  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .large-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }

  .large-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }

  .large-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }

  .large-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }

  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .large-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }

  .large-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }

  .large-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }

  .large-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }

  .column,
.columns {
    position: relative;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }

  .large-1 {
    width: 8.3333333333%;
  }

  .large-2 {
    width: 16.6666666667%;
  }

  .large-3 {
    width: 25%;
  }

  .large-4 {
    width: 33.3333333333%;
  }

  .large-5 {
    width: 41.6666666667%;
  }

  .large-6 {
    width: 50%;
  }

  .large-7 {
    width: 58.3333333333%;
  }

  .large-8 {
    width: 66.6666666667%;
  }

  .large-9 {
    width: 75%;
  }

  .large-10 {
    width: 83.3333333333%;
  }

  .large-11 {
    width: 91.6666666667%;
  }

  .large-12 {
    width: 100%;
  }

  .large-offset-0 {
    margin-left: 0 !important;
  }

  .large-offset-1 {
    margin-left: 8.3333333333% !important;
  }

  .large-offset-2 {
    margin-left: 16.6666666667% !important;
  }

  .large-offset-3 {
    margin-left: 25% !important;
  }

  .large-offset-4 {
    margin-left: 33.3333333333% !important;
  }

  .large-offset-5 {
    margin-left: 41.6666666667% !important;
  }

  .large-offset-6 {
    margin-left: 50% !important;
  }

  .large-offset-7 {
    margin-left: 58.3333333333% !important;
  }

  .large-offset-8 {
    margin-left: 66.6666666667% !important;
  }

  .large-offset-9 {
    margin-left: 75% !important;
  }

  .large-offset-10 {
    margin-left: 83.3333333333% !important;
  }

  .large-offset-11 {
    margin-left: 91.6666666667% !important;
  }

  .large-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .column.large-centered,
.columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.large-uncentered,
.columns.large-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .column.large-centered:last-child,
.columns.large-centered:last-child {
    float: none;
  }

  .column.large-uncentered:last-child,
.columns.large-uncentered:last-child {
    float: left;
  }

  .column.large-uncentered.opposite,
.columns.large-uncentered.opposite {
    float: right;
  }

  .row.large-collapse > .column,
.row.large-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.large-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.large-uncollapse > .column,
.row.large-uncollapse > .columns {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }

  .push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }

  .pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }

  .pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }

  .pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }

  .pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }

  .pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }

  .pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }

  .pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }

  .pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }
}
adv-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2.5vw;
}
adv-grid.legacy-grid-shim.grid-bg-transparent {
  background-color: transparent;
}
adv-grid.legacy-grid-shim.grid-bg-color-two {
  background-color: #f2f2f2;
}
adv-grid.legacy-grid-shim.grid-bg-color-three {
  background-color: #e6e6e6;
}
adv-grid.legacy-grid-shim.grid-bg-color-four {
  background-color: #262626;
}
adv-grid.legacy-grid-shim.grid-bg-color-five {
  background-color: #1a1a1a;
}
adv-grid.legacy-grid-shim.grid-bg-color-six {
  background-color: #ffffff;
}
adv-grid.legacy-grid-shim.legacy-grid-shim,
adv-grid.legacy-grid-shim .legacy-grid-shim.collapse > adv-col {
  padding-left: 0;
  padding-right: 0;
}
adv-grid.legacy-grid-shim.grid-sm-col-pad-lt-rt-no > adv-col > .col-con {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
adv-grid.legacy-grid-shim.grid-sm-col-pad-up-dn-no > adv-col > .col-con {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
adv-grid.legacy-grid-shim > adv-col > .col-con, adv-grid.legacy-grid-shim > adv-col .q-button.q-link {
  display: block;
}
adv-grid.legacy-grid-shim > adv-col:last-of-type {
  margin-left: auto;
}
adv-grid > adv-scrl {
  display: flex;
  position: relative;
  width: 100%;
}
adv-grid > adv-scrl > .scrl-btn.scrl-disabled {
  display: none !important;
}
adv-grid > adv-scrl > adv-slides {
  display: flex;
  width: 100%;
  scrollbar-color: #b3b3b3 rgba(0, 0, 0, 0);
  scrollbar-width: auto;
}
adv-grid > adv-scrl > adv-slides:hover {
  scrollbar-color: #808080 rgba(0, 0, 0, 0);
}
adv-grid > adv-scrl > adv-slides::-webkit-scrollbar {
  height: 8px;
}
adv-grid > adv-scrl > adv-slides::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
adv-grid > adv-scrl > adv-slides::-webkit-scrollbar-thumb {
  background: #b3b3b3;
  border: 0;
  border-radius: 4px;
}
adv-grid > adv-scrl > adv-slides::-webkit-scrollbar-thumb:hover {
  background: #808080;
}
@media only screen {
  adv-grid > adv-scrl.scrl-sm-dir-hoz > adv-slides {
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
  }
  adv-grid > adv-scrl.scrl-sm-dir-hoz > adv-slides > .scrl-btn {
    display: flex;
  }
  adv-grid > adv-scrl.scrl-sm-ali-s > adv-slides > adv-col {
    scroll-snap-align: start;
  }
  adv-grid > adv-scrl.scrl-sm-ali-c > adv-slides > adv-col {
    scroll-snap-align: center;
  }
  adv-grid > adv-scrl.scrl-sm-ali-e > adv-slides > adv-col {
    scroll-snap-align: end;
  }
  adv-grid > adv-scrl.scrl-sm-pgn-vis-bar-hide > adv-slides {
    scrollbar-width: none;
  }
  adv-grid > adv-scrl.scrl-sm-pgn-vis-bar-hide > adv-slides::-webkit-scrollbar {
    height: 0;
  }
  adv-grid > adv-scrl.scrl-sm-pgn-vis-bar-show > adv-slides::-webkit-scrollbar {
    height: 8px;
  }
  adv-grid > adv-scrl.scrl-sm-btn-vis-hide > .scrl-btn {
    display: none !important;
  }
  adv-grid > adv-scrl.scrl-sm-btn-vis-show > .scrl-btn:not(.scrl-disabled) {
    display: inline-block !important;
  }
  adv-grid > adv-scrl.scrl-sm-dir-no > adv-slides {
    overflow: hidden;
    scroll-padding: 0;
    scroll-snap-type: none;
    flex-wrap: wrap;
  }
  adv-grid > adv-scrl.scrl-sm-dir-no > adv-slides > adv-col {
    min-width: auto;
    scroll-snap-align: none;
  }
  adv-grid > adv-scrl.scrl-sm-dir-no > .scrl-btn {
    display: none !important;
  }
  adv-grid > adv-scrl.scrl-sm-dir-no > adv-slides > adv-col {
    min-width: auto;
  }
}
@media print, only screen and (min-width:420px) {
  adv-grid > adv-scrl.scrl-md-dir-hoz > adv-slides {
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
  }
  adv-grid > adv-scrl.scrl-md-dir-hoz > adv-slides > .scrl-btn {
    display: flex;
  }
  adv-grid > adv-scrl.scrl-md-ali-no > adv-slides > adv-col {
    scroll-snap-align: none;
  }
  adv-grid > adv-scrl.scrl-md-ali-s > adv-slides > adv-col {
    scroll-snap-align: start;
  }
  adv-grid > adv-scrl.scrl-md-ali-c > adv-slides > adv-col {
    scroll-snap-align: center;
  }
  adv-grid > adv-scrl.scrl-md-ali-e > adv-slides > adv-col {
    scroll-snap-align: end;
  }
  adv-grid > adv-scrl.scrl-md-pgn-vis-bar-hide > adv-slides {
    scrollbar-width: none;
  }
  adv-grid > adv-scrl.scrl-md-pgn-vis-bar-hide > adv-slides::-webkit-scrollbar {
    height: 0;
  }
  adv-grid > adv-scrl.scrl-md-pgn-vis-bar-show > adv-slides::-webkit-scrollbar {
    height: 8px;
  }
  adv-grid > adv-scrl.scrl-md-btn-vis-hide > .scrl-btn {
    display: none !important;
  }
  adv-grid > adv-scrl.scrl-md-btn-vis-show > .scrl-btn:not(.scrl-disabled) {
    display: inline-block !important;
  }
  adv-grid > adv-scrl.scrl-md-dir-no > adv-slides {
    overflow: hidden;
    scroll-padding: 0;
    scroll-snap-type: none;
    flex-wrap: wrap;
  }
  adv-grid > adv-scrl.scrl-md-dir-no > adv-slides > adv-col {
    min-width: auto;
    scroll-snap-align: none;
  }
  adv-grid > adv-scrl.scrl-md-dir-no > .scrl-btn {
    display: none !important;
  }
  adv-grid > adv-scrl.scrl-md-dir-no > adv-slides > adv-col {
    min-width: auto !important;
  }
}
@media only screen and (min-width:960px) {
  adv-grid > adv-scrl.scrl-xl-dir-hoz > adv-slides {
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
  }
  adv-grid > adv-scrl.scrl-xl-dir-hoz > adv-slides > .scrl-btn {
    display: flex;
  }
  adv-grid > adv-scrl.scrl-xl-ali-no > adv-slides > adv-col {
    scroll-snap-align: none;
  }
  adv-grid > adv-scrl.scrl-xl-ali-s > adv-slides > adv-col {
    scroll-snap-align: start;
  }
  adv-grid > adv-scrl.scrl-xl-ali-c > adv-slides > adv-col {
    scroll-snap-align: center;
  }
  adv-grid > adv-scrl.scrl-xl-ali-e > adv-slides > adv-col {
    scroll-snap-align: end;
  }
  adv-grid > adv-scrl.scrl-xl-pgn-vis-bar-hide > adv-slides {
    scrollbar-width: none;
  }
  adv-grid > adv-scrl.scrl-xl-pgn-vis-bar-hide > adv-slides::-webkit-scrollbar {
    height: 0;
  }
  adv-grid > adv-scrl.scrl-xl-pgn-vis-bar-show > adv-slides::-webkit-scrollbar {
    height: 8px;
  }
  adv-grid > adv-scrl.scrl-xl-btn-vis-hide > .scrl-btn {
    display: none !important;
  }
  adv-grid > adv-scrl.scrl-xl-btn-vis-show > .scrl-btn:not(.scrl-disabled) {
    display: inline-block !important;
  }
  adv-grid > adv-scrl.scrl-xl-dir-no > adv-slides {
    overflow: hidden;
    scroll-padding: 0;
    scroll-snap-type: none;
    flex-wrap: wrap;
  }
  adv-grid > adv-scrl.scrl-xl-dir-no > adv-slides > adv-col {
    min-width: auto;
    scroll-snap-align: none;
  }
  adv-grid > adv-scrl.scrl-xl-dir-no > .scrl-btn {
    display: none !important;
  }
  adv-grid > adv-scrl.scrl-xl-dir-no > adv-slides > adv-col {
    min-width: auto !important;
  }
}
@media only screen {
  adv-grid.grid-sm-fw, adv-grid.grid-sm-fw > adv-scrl > adv-slides {
    padding: 0;
  }
  adv-grid.grid-sm-jc-end, adv-grid.grid-sm-jc-end > adv-scrl > adv-slides {
    justify-content: flex-end;
  }
  adv-grid.grid-sm-jc-center, adv-grid.grid-sm-jc-center > adv-scrl > adv-slides {
    justify-content: center;
  }
  adv-grid.grid-sm-jc-between, adv-grid.grid-sm-jc-between > adv-scrl > adv-slides {
    justify-content: space-between;
  }
  adv-grid.grid-sm-jc-around, adv-grid.grid-sm-jc-around > adv-scrl > adv-slides {
    justify-content: space-around;
  }
  adv-grid.grid-sm-jc-evenly, adv-grid.grid-sm-jc-evenly > adv-scrl > adv-slides {
    justify-content: space-evenly;
  }
  adv-grid.grid-sm-jc-no, adv-grid.grid-sm-jc-no > adv-scrl > adv-slides {
    justify-content: initial;
  }
  adv-grid.grid-sm-ai-top, adv-grid.grid-sm-ai-top > adv-scrl > adv-slides {
    align-items: flex-start;
  }
  adv-grid.grid-sm-ai-bottom, adv-grid.grid-sm-ai-bottom > adv-scrl > adv-slides {
    align-items: flex-end;
  }
  adv-grid.grid-sm-ai-center, adv-grid.grid-sm-ai-center > adv-scrl > adv-slides {
    align-items: center;
  }
  adv-grid.grid-sm-ai-stch, adv-grid.grid-sm-ai-stch > adv-scrl > adv-slides {
    align-items: stretch;
  }
  adv-grid.grid-sm-ca-lt, adv-grid.grid-sm-ca-lt > adv-scrl > adv-slides {
    text-align: left;
  }
  adv-grid.grid-sm-ca-rt, adv-grid.grid-sm-ca-rt > adv-scrl > adv-slides {
    text-align: right;
  }
  adv-grid.grid-sm-ca-c, adv-grid.grid-sm-ca-c > adv-scrl > adv-slides {
    text-align: center;
  }
  adv-grid.grid-sm-col-pad-no > adv-col > .col-con, adv-grid.grid-sm-col-pad-no > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 0;
  }
  adv-grid.grid-sm-col-pad-7 > adv-col > .col-con, adv-grid.grid-sm-col-pad-7 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 0.5rem;
  }
  adv-grid.grid-sm-col-pad-1 > adv-col > .col-con, adv-grid.grid-sm-col-pad-1 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 0.625rem;
  }
  adv-grid.grid-sm-col-pad-8 > adv-col > .col-con, adv-grid.grid-sm-col-pad-8 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 1rem;
  }
  adv-grid.grid-sm-col-pad-2 > adv-col > .col-con, adv-grid.grid-sm-col-pad-2 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 1.25rem;
  }
  adv-grid.grid-sm-col-pad-9 > adv-col > .col-con, adv-grid.grid-sm-col-pad-9 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 1.5rem;
  }
  adv-grid.grid-sm-col-pad-10 > adv-col > .col-con, adv-grid.grid-sm-col-pad-10 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 2rem;
  }
  adv-grid.grid-sm-col-pad-3 > adv-col > .col-con, adv-grid.grid-sm-col-pad-3 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 2.5rem;
  }
  adv-grid.grid-sm-col-pad-4 > adv-col > .col-con, adv-grid.grid-sm-col-pad-4 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 3.75rem;
  }
  adv-grid.grid-sm-col-pad-5 > adv-col > .col-con, adv-grid.grid-sm-col-pad-5 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 5rem;
  }
  adv-grid.grid-sm-col-pad-6 > adv-col > .col-con, adv-grid.grid-sm-col-pad-6 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 10rem;
  }
  adv-grid.grid-sm-col-gut-no > adv-col, adv-grid.grid-sm-col-gut-no > adv-scrl > adv-slides > adv-col {
    padding: 0;
  }
  adv-grid.grid-sm-col-gut-def > adv-col, adv-grid.grid-sm-col-gut-def > adv-scrl > adv-slides > adv-col {
    padding: 0 2.5vw;
  }
  adv-grid.grid-sm-col-gut-lt-def > adv-col, adv-grid.grid-sm-col-gut-lt-def > adv-scrl > adv-slides > adv-col {
    padding: 0 0 0 2.5vw;
  }
  adv-grid.grid-sm-col-gut-rt-def > adv-col, adv-grid.grid-sm-col-gut-rt-def > adv-scrl > adv-slides > adv-col {
    padding: 0 2.5vw 0 0;
  }
  adv-grid.grid-sm-br-dn-lt-none {
    border-bottom-left-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-dn-lt-15 {
    border-bottom-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-dn-lt-48 {
    border-bottom-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-dn-rt-none {
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-dn-rt-15 {
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-dn-rt-48 {
    border-bottom-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-up-lt-none {
    border-top-left-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-up-lt-15 {
    border-top-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-up-lt-48 {
    border-top-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-up-rt-none {
    border-top-right-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-up-rt-15 {
    border-top-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-sm-br-up-rt-48 {
    border-top-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-sm-bw-up-0 {
    border-top-width: 0;
  }
  adv-grid.grid-sm-bw-up-1 {
    border-top-width: 1px;
  }
  adv-grid.grid-sm-bw-up-2 {
    border-top-width: 2px;
  }
  adv-grid.grid-sm-bw-up-3 {
    border-top-width: 3px;
  }
  adv-grid.grid-sm-bw-up-4 {
    border-top-width: 4px;
  }
  adv-grid.grid-sm-bs-up-none {
    border-top-style: none;
  }
  adv-grid.grid-sm-bs-up-dotted {
    border-top-style: dotted;
  }
  adv-grid.grid-sm-bs-up-dashed {
    border-top-style: dashed;
  }
  adv-grid.grid-sm-bs-up-solid {
    border-top-style: solid;
  }
  adv-grid.grid-sm-bw-rt-0 {
    border-right-width: 0;
  }
  adv-grid.grid-sm-bw-rt-1 {
    border-right-width: 1px;
  }
  adv-grid.grid-sm-bw-rt-2 {
    border-right-width: 2px;
  }
  adv-grid.grid-sm-bw-rt-3 {
    border-right-width: 3px;
  }
  adv-grid.grid-sm-bw-rt-4 {
    border-right-width: 4px;
  }
  adv-grid.grid-sm-bs-rt-none {
    border-right-style: none;
  }
  adv-grid.grid-sm-bs-rt-dotted {
    border-right-style: dotted;
  }
  adv-grid.grid-sm-bs-rt-dashed {
    border-right-style: dashed;
  }
  adv-grid.grid-sm-bs-rt-solid {
    border-right-style: solid;
  }
  adv-grid.grid-sm-bw-dn-0 {
    border-bottom-width: 0;
  }
  adv-grid.grid-sm-bw-dn-1 {
    border-bottom-width: 1px;
  }
  adv-grid.grid-sm-bw-dn-2 {
    border-bottom-width: 2px;
  }
  adv-grid.grid-sm-bw-dn-3 {
    border-bottom-width: 3px;
  }
  adv-grid.grid-sm-bw-dn-4 {
    border-bottom-width: 4px;
  }
  adv-grid.grid-sm-bs-dn-none {
    border-bottom-style: none;
  }
  adv-grid.grid-sm-bs-dn-dotted {
    border-bottom-style: dotted;
  }
  adv-grid.grid-sm-bs-dn-dashed {
    border-bottom-style: dashed;
  }
  adv-grid.grid-sm-bs-dn-solid {
    border-bottom-style: solid;
  }
  adv-grid.grid-sm-bw-lt-0 {
    border-left-width: 0;
  }
  adv-grid.grid-sm-bw-lt-1 {
    border-left-width: 1px;
  }
  adv-grid.grid-sm-bw-lt-2 {
    border-left-width: 2px;
  }
  adv-grid.grid-sm-bw-lt-3 {
    border-left-width: 3px;
  }
  adv-grid.grid-sm-bw-lt-4 {
    border-left-width: 4px;
  }
  adv-grid.grid-sm-bs-lt-none {
    border-left-style: none;
  }
  adv-grid.grid-sm-bs-lt-dotted {
    border-left-style: dotted;
  }
  adv-grid.grid-sm-bs-lt-dashed {
    border-left-style: dashed;
  }
  adv-grid.grid-sm-bs-lt-solid {
    border-left-style: solid;
  }
}
@media print, only screen and (min-width:420px) {
  adv-grid.grid-md-fw, adv-grid.grid-md-fw > adv-scrl > adv-slides {
    padding: 0;
  }
  adv-grid.grid-md-jc-end, adv-grid.grid-md-jc-end > adv-scrl > adv-slides {
    justify-content: flex-end;
  }
  adv-grid.grid-md-jc-center, adv-grid.grid-md-jc-center > adv-scrl > adv-slides {
    justify-content: center;
  }
  adv-grid.grid-md-jc-between, adv-grid.grid-md-jc-between > adv-scrl > adv-slides {
    justify-content: space-between;
  }
  adv-grid.grid-md-jc-around, adv-grid.grid-md-jc-around > adv-scrl > adv-slides {
    justify-content: space-around;
  }
  adv-grid.grid-md-jc-evenly, adv-grid.grid-md-jc-evenly > adv-scrl > adv-slides {
    justify-content: space-evenly;
  }
  adv-grid.grid-md-jc-no, adv-grid.grid-md-jc-no > adv-scrl > adv-slides {
    justify-content: initial;
  }
  adv-grid.grid-md-ai-top, adv-grid.grid-md-ai-top > adv-scrl > adv-slides {
    align-items: flex-start;
  }
  adv-grid.grid-md-ai-bottom, adv-grid.grid-md-ai-bottom > adv-scrl > adv-slides {
    align-items: flex-end;
  }
  adv-grid.grid-md-ai-center, adv-grid.grid-md-ai-center > adv-scrl > adv-slides {
    align-items: center;
  }
  adv-grid.grid-md-ai-stch, adv-grid.grid-md-ai-stch > adv-scrl > adv-slides {
    align-items: stretch;
  }
  adv-grid.grid-md-ca-lt, adv-grid.grid-md-ca-lt > adv-scrl > adv-slides {
    text-align: left;
  }
  adv-grid.grid-md-ca-rt, adv-grid.grid-md-ca-rt > adv-scrl > adv-slides {
    text-align: right;
  }
  adv-grid.grid-md-ca-c, adv-grid.grid-md-ca-c > adv-scrl > adv-slides {
    text-align: center;
  }
  adv-grid.grid-md-col-pad-no > adv-col > .col-con, adv-grid.grid-md-col-pad-no > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 0;
  }
  adv-grid.grid-md-col-pad-7 > adv-col > .col-con, adv-grid.grid-md-col-pad-7 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 0.5rem;
  }
  adv-grid.grid-md-col-pad-1 > adv-col > .col-con, adv-grid.grid-md-col-pad-1 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 0.625rem;
  }
  adv-grid.grid-md-col-pad-8 > adv-col > .col-con, adv-grid.grid-md-col-pad-8 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 1rem;
  }
  adv-grid.grid-md-col-pad-2 > adv-col > .col-con, adv-grid.grid-md-col-pad-2 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 1.25rem;
  }
  adv-grid.grid-md-col-pad-9 > adv-col > .col-con, adv-grid.grid-md-col-pad-9 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 1.5rem;
  }
  adv-grid.grid-md-col-pad-10 > adv-col > .col-con, adv-grid.grid-md-col-pad-10 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 2rem;
  }
  adv-grid.grid-md-col-pad-3 > adv-col > .col-con, adv-grid.grid-md-col-pad-3 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 2.5rem;
  }
  adv-grid.grid-md-col-pad-4 > adv-col > .col-con, adv-grid.grid-md-col-pad-4 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 3.75rem;
  }
  adv-grid.grid-md-col-pad-5 > adv-col > .col-con, adv-grid.grid-md-col-pad-5 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 5rem;
  }
  adv-grid.grid-md-col-pad-6 > adv-col > .col-con, adv-grid.grid-md-col-pad-6 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 10rem;
  }
  adv-grid.grid-md-col-gut-no > adv-col, adv-grid.grid-md-col-gut-no > adv-scrl > adv-slides > adv-col {
    padding: 0;
  }
  adv-grid.grid-md-col-gut-def > adv-col, adv-grid.grid-md-col-gut-def > adv-scrl > adv-slides > adv-col {
    padding: 0 2.5vw;
  }
  adv-grid.grid-md-col-gut-lt-def > adv-col, adv-grid.grid-md-col-gut-lt-def > adv-scrl > adv-slides > adv-col {
    padding: 0 0 0 2.5vw;
  }
  adv-grid.grid-md-col-gut-rt-def > adv-col, adv-grid.grid-md-col-gut-rt-def > adv-scrl > adv-slides > adv-col {
    padding: 0 2.5vw 0 0;
  }
  adv-grid.grid-md-br-dn-lt-none {
    border-bottom-left-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-md-br-dn-lt-15 {
    border-bottom-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-md-br-dn-lt-48 {
    border-bottom-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-md-br-dn-rt-none {
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-md-br-dn-rt-15 {
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-md-br-dn-rt-48 {
    border-bottom-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-md-br-up-lt-none {
    border-top-left-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-md-br-up-lt-15 {
    border-top-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-md-br-up-lt-48 {
    border-top-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-md-br-up-rt-none {
    border-top-right-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-md-br-up-rt-15 {
    border-top-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-md-br-up-rt-48 {
    border-top-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-md-bw-up-0 {
    border-top-width: 0;
  }
  adv-grid.grid-md-bw-up-1 {
    border-top-width: 1px;
  }
  adv-grid.grid-md-bw-up-2 {
    border-top-width: 2px;
  }
  adv-grid.grid-md-bw-up-3 {
    border-top-width: 3px;
  }
  adv-grid.grid-md-bw-up-4 {
    border-top-width: 4px;
  }
  adv-grid.grid-md-bs-up-none {
    border-top-style: none;
  }
  adv-grid.grid-md-bs-up-dotted {
    border-top-style: dotted;
  }
  adv-grid.grid-md-bs-up-dashed {
    border-top-style: dashed;
  }
  adv-grid.grid-md-bs-up-solid {
    border-top-style: solid;
  }
  adv-grid.grid-md-bw-rt-0 {
    border-right-width: 0;
  }
  adv-grid.grid-md-bw-rt-1 {
    border-right-width: 1px;
  }
  adv-grid.grid-md-bw-rt-2 {
    border-right-width: 2px;
  }
  adv-grid.grid-md-bw-rt-3 {
    border-right-width: 3px;
  }
  adv-grid.grid-md-bw-rt-4 {
    border-right-width: 4px;
  }
  adv-grid.grid-md-bs-rt-none {
    border-right-style: none;
  }
  adv-grid.grid-md-bs-rt-dotted {
    border-right-style: dotted;
  }
  adv-grid.grid-md-bs-rt-dashed {
    border-right-style: dashed;
  }
  adv-grid.grid-md-bs-rt-solid {
    border-right-style: solid;
  }
  adv-grid.grid-md-bw-dn-0 {
    border-bottom-width: 0;
  }
  adv-grid.grid-md-bw-dn-1 {
    border-bottom-width: 1px;
  }
  adv-grid.grid-md-bw-dn-2 {
    border-bottom-width: 2px;
  }
  adv-grid.grid-md-bw-dn-3 {
    border-bottom-width: 3px;
  }
  adv-grid.grid-md-bw-dn-4 {
    border-bottom-width: 4px;
  }
  adv-grid.grid-md-bs-dn-none {
    border-bottom-style: none;
  }
  adv-grid.grid-md-bs-dn-dotted {
    border-bottom-style: dotted;
  }
  adv-grid.grid-md-bs-dn-dashed {
    border-bottom-style: dashed;
  }
  adv-grid.grid-md-bs-dn-solid {
    border-bottom-style: solid;
  }
  adv-grid.grid-md-bw-lt-0 {
    border-left-width: 0;
  }
  adv-grid.grid-md-bw-lt-1 {
    border-left-width: 1px;
  }
  adv-grid.grid-md-bw-lt-2 {
    border-left-width: 2px;
  }
  adv-grid.grid-md-bw-lt-3 {
    border-left-width: 3px;
  }
  adv-grid.grid-md-bw-lt-4 {
    border-left-width: 4px;
  }
  adv-grid.grid-md-bs-lt-none {
    border-left-style: none;
  }
  adv-grid.grid-md-bs-lt-dotted {
    border-left-style: dotted;
  }
  adv-grid.grid-md-bs-lt-dashed {
    border-left-style: dashed;
  }
  adv-grid.grid-md-bs-lt-solid {
    border-left-style: solid;
  }
}
@media only screen and (min-width:960px) {
  adv-grid.grid-xl-fw, adv-grid.grid-xl-fw > adv-scrl > adv-slides {
    padding: 0;
  }
  adv-grid.grid-xl-jc-end, adv-grid.grid-xl-jc-end > adv-scrl > adv-slides {
    justify-content: flex-end;
  }
  adv-grid.grid-xl-jc-center, adv-grid.grid-xl-jc-center > adv-scrl > adv-slides {
    justify-content: center;
  }
  adv-grid.grid-xl-jc-between, adv-grid.grid-xl-jc-between > adv-scrl > adv-slides {
    justify-content: space-between;
  }
  adv-grid.grid-xl-jc-around, adv-grid.grid-xl-jc-around > adv-scrl > adv-slides {
    justify-content: space-around;
  }
  adv-grid.grid-xl-jc-evenly, adv-grid.grid-xl-jc-evenly > adv-scrl > adv-slides {
    justify-content: space-evenly;
  }
  adv-grid.grid-xl-jc-no, adv-grid.grid-xl-jc-no > adv-scrl > adv-slides {
    justify-content: initial;
  }
  adv-grid.grid-xl-ai-top, adv-grid.grid-xl-ai-top > adv-scrl > adv-slides {
    align-items: flex-start;
  }
  adv-grid.grid-xl-ai-bottom, adv-grid.grid-xl-ai-bottom > adv-scrl > adv-slides {
    align-items: flex-end;
  }
  adv-grid.grid-xl-ai-center, adv-grid.grid-xl-ai-center > adv-scrl > adv-slides {
    align-items: center;
  }
  adv-grid.grid-xl-ai-stch, adv-grid.grid-xl-ai-stch > adv-scrl > adv-slides {
    align-items: stretch;
  }
  adv-grid.grid-xl-ca-lt, adv-grid.grid-xl-ca-lt > adv-scrl > adv-slides {
    text-align: left;
  }
  adv-grid.grid-xl-ca-rt, adv-grid.grid-xl-ca-rt > adv-scrl > adv-slides {
    text-align: right;
  }
  adv-grid.grid-xl-ca-c, adv-grid.grid-xl-ca-c > adv-scrl > adv-slides {
    text-align: center;
  }
  adv-grid.grid-xl-col-pad-no > adv-col > .col-con, adv-grid.grid-xl-col-pad-no > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 0;
  }
  adv-grid.grid-xl-col-pad-7 > adv-col > .col-con, adv-grid.grid-xl-col-pad-7 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 0.5rem;
  }
  adv-grid.grid-xl-col-pad-1 > adv-col > .col-con, adv-grid.grid-xl-col-pad-1 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 0.625rem;
  }
  adv-grid.grid-xl-col-pad-8 > adv-col > .col-con, adv-grid.grid-xl-col-pad-8 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 1rem;
  }
  adv-grid.grid-xl-col-pad-2 > adv-col > .col-con, adv-grid.grid-xl-col-pad-2 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 1.25rem;
  }
  adv-grid.grid-xl-col-pad-9 > adv-col > .col-con, adv-grid.grid-xl-col-pad-9 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 1.5rem;
  }
  adv-grid.grid-xl-col-pad-10 > adv-col > .col-con, adv-grid.grid-xl-col-pad-10 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 2rem;
  }
  adv-grid.grid-xl-col-pad-3 > adv-col > .col-con, adv-grid.grid-xl-col-pad-3 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 2.5rem;
  }
  adv-grid.grid-xl-col-pad-4 > adv-col > .col-con, adv-grid.grid-xl-col-pad-4 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 3.75rem;
  }
  adv-grid.grid-xl-col-pad-5 > adv-col > .col-con, adv-grid.grid-xl-col-pad-5 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 5rem;
  }
  adv-grid.grid-xl-col-pad-6 > adv-col > .col-con, adv-grid.grid-xl-col-pad-6 > adv-scrl > adv-slides > adv-col > .col-con {
    padding: 10rem;
  }
  adv-grid.grid-xl-col-gut-no > adv-col, adv-grid.grid-xl-col-gut-no > adv-scrl > adv-slides > adv-col {
    padding: 0;
  }
  adv-grid.grid-xl-col-gut-def > adv-col, adv-grid.grid-xl-col-gut-def > adv-scrl > adv-slides > adv-col {
    padding: 0 2.5vw;
  }
  adv-grid.grid-xl-col-gut-lt-def > adv-col, adv-grid.grid-xl-col-gut-lt-def > adv-scrl > adv-slides > adv-col {
    padding: 0 0 0 2.5vw;
  }
  adv-grid.grid-xl-col-gut-rt-def > adv-col, adv-grid.grid-xl-col-gut-rt-def > adv-scrl > adv-slides > adv-col {
    padding: 0 2.5vw 0 0;
  }
  adv-grid.grid-xl-br-dn-lt-none {
    border-bottom-left-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-dn-lt-15 {
    border-bottom-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-dn-lt-48 {
    border-bottom-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-dn-rt-none {
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-dn-rt-15 {
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-dn-rt-48 {
    border-bottom-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-up-lt-none {
    border-top-left-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-up-lt-15 {
    border-top-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-up-lt-48 {
    border-top-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-up-rt-none {
    border-top-right-radius: 0;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-up-rt-15 {
    border-top-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid.grid-xl-br-up-rt-48 {
    border-top-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid.grid-xl-bw-up-0 {
    border-top-width: 0;
  }
  adv-grid.grid-xl-bw-up-1 {
    border-top-width: 1px;
  }
  adv-grid.grid-xl-bw-up-2 {
    border-top-width: 2px;
  }
  adv-grid.grid-xl-bw-up-3 {
    border-top-width: 3px;
  }
  adv-grid.grid-xl-bw-up-4 {
    border-top-width: 4px;
  }
  adv-grid.grid-xl-bs-up-none {
    border-top-style: none;
  }
  adv-grid.grid-xl-bs-up-dotted {
    border-top-style: dotted;
  }
  adv-grid.grid-xl-bs-up-dashed {
    border-top-style: dashed;
  }
  adv-grid.grid-xl-bs-up-solid {
    border-top-style: solid;
  }
  adv-grid.grid-xl-bw-rt-0 {
    border-right-width: 0;
  }
  adv-grid.grid-xl-bw-rt-1 {
    border-right-width: 1px;
  }
  adv-grid.grid-xl-bw-rt-2 {
    border-right-width: 2px;
  }
  adv-grid.grid-xl-bw-rt-3 {
    border-right-width: 3px;
  }
  adv-grid.grid-xl-bw-rt-4 {
    border-right-width: 4px;
  }
  adv-grid.grid-xl-bs-rt-none {
    border-right-style: none;
  }
  adv-grid.grid-xl-bs-rt-dotted {
    border-right-style: dotted;
  }
  adv-grid.grid-xl-bs-rt-dashed {
    border-right-style: dashed;
  }
  adv-grid.grid-xl-bs-rt-solid {
    border-right-style: solid;
  }
  adv-grid.grid-xl-bw-dn-0 {
    border-bottom-width: 0;
  }
  adv-grid.grid-xl-bw-dn-1 {
    border-bottom-width: 1px;
  }
  adv-grid.grid-xl-bw-dn-2 {
    border-bottom-width: 2px;
  }
  adv-grid.grid-xl-bw-dn-3 {
    border-bottom-width: 3px;
  }
  adv-grid.grid-xl-bw-dn-4 {
    border-bottom-width: 4px;
  }
  adv-grid.grid-xl-bs-dn-none {
    border-bottom-style: none;
  }
  adv-grid.grid-xl-bs-dn-dotted {
    border-bottom-style: dotted;
  }
  adv-grid.grid-xl-bs-dn-dashed {
    border-bottom-style: dashed;
  }
  adv-grid.grid-xl-bs-dn-solid {
    border-bottom-style: solid;
  }
  adv-grid.grid-xl-bw-lt-0 {
    border-left-width: 0;
  }
  adv-grid.grid-xl-bw-lt-1 {
    border-left-width: 1px;
  }
  adv-grid.grid-xl-bw-lt-2 {
    border-left-width: 2px;
  }
  adv-grid.grid-xl-bw-lt-3 {
    border-left-width: 3px;
  }
  adv-grid.grid-xl-bw-lt-4 {
    border-left-width: 4px;
  }
  adv-grid.grid-xl-bs-lt-none {
    border-left-style: none;
  }
  adv-grid.grid-xl-bs-lt-dotted {
    border-left-style: dotted;
  }
  adv-grid.grid-xl-bs-lt-dashed {
    border-left-style: dashed;
  }
  adv-grid.grid-xl-bs-lt-solid {
    border-left-style: solid;
  }
}
adv-grid > adv-col,
adv-grid > adv-scrl > adv-slides > adv-col {
  padding: 0 2.5vw;
  -webkit-overflow-scrolling: touch;
}
adv-grid > adv-col > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col > .col-con {
  height: 100%;
  position: relative;
}
@media only screen {
  adv-grid > adv-col.col-sm-as-start,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-as-start {
    align-self: flex-start;
  }
  adv-grid > adv-col.col-sm-as-end,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-as-end {
    align-self: flex-end;
  }
  adv-grid > adv-col.col-sm-as-center,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-as-center {
    align-self: center;
  }
  adv-grid > adv-col.col-sm-as-stch,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-as-stch {
    align-self: stretch;
  }
  adv-grid > adv-col.col-sm-ca-lt,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-ca-lt {
    text-align: left;
  }
  adv-grid > adv-col.col-sm-ca-rt,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-ca-rt {
    text-align: right;
  }
  adv-grid > adv-col.col-sm-ca-c,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-ca-c {
    text-align: center;
  }
  adv-grid > adv-col.col-sm-pad-up-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-no > .col-con {
    padding-top: 0;
  }
  adv-grid > adv-col.col-sm-pad-up-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-7 > .col-con {
    padding-top: 0.5rem;
  }
  adv-grid > adv-col.col-sm-pad-up-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-1 > .col-con {
    padding-top: 0.625rem;
  }
  adv-grid > adv-col.col-sm-pad-up-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-8 > .col-con {
    padding-top: 1rem;
  }
  adv-grid > adv-col.col-sm-pad-up-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-2 > .col-con {
    padding-top: 1.25rem;
  }
  adv-grid > adv-col.col-sm-pad-up-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-9 > .col-con {
    padding-top: 1.5rem;
  }
  adv-grid > adv-col.col-sm-pad-up-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-10 > .col-con {
    padding-top: 2rem;
  }
  adv-grid > adv-col.col-sm-pad-up-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-3 > .col-con {
    padding-top: 2.5rem;
  }
  adv-grid > adv-col.col-sm-pad-up-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-4 > .col-con {
    padding-top: 3.75rem;
  }
  adv-grid > adv-col.col-sm-pad-up-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-5 > .col-con {
    padding-top: 5rem;
  }
  adv-grid > adv-col.col-sm-pad-up-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-up-6 > .col-con {
    padding-top: 10rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-no > .col-con {
    padding-right: 0;
  }
  adv-grid > adv-col.col-sm-pad-rt-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-7 > .col-con {
    padding-right: 0.5rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-1 > .col-con {
    padding-right: 0.625rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-8 > .col-con {
    padding-right: 1rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-2 > .col-con {
    padding-right: 1.25rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-9 > .col-con {
    padding-right: 1.5rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-10 > .col-con {
    padding-right: 2rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-3 > .col-con {
    padding-right: 2.5rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-4 > .col-con {
    padding-right: 3.75rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-5 > .col-con {
    padding-right: 5rem;
  }
  adv-grid > adv-col.col-sm-pad-rt-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-rt-6 > .col-con {
    padding-right: 10rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-no > .col-con {
    padding-bottom: 0;
  }
  adv-grid > adv-col.col-sm-pad-dn-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-7 > .col-con {
    padding-bottom: 0.5rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-1 > .col-con {
    padding-bottom: 0.625rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-8 > .col-con {
    padding-bottom: 1rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-2 > .col-con {
    padding-bottom: 1.25rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-9 > .col-con {
    padding-bottom: 1.5rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-10 > .col-con {
    padding-bottom: 2rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-3 > .col-con {
    padding-bottom: 2.5rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-4 > .col-con {
    padding-bottom: 3.75rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-5 > .col-con {
    padding-bottom: 5rem;
  }
  adv-grid > adv-col.col-sm-pad-dn-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-dn-6 > .col-con {
    padding-bottom: 10rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-no > .col-con {
    padding-left: 0;
  }
  adv-grid > adv-col.col-sm-pad-lt-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-7 > .col-con {
    padding-left: 0.5rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-1 > .col-con {
    padding-left: 0.625rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-8 > .col-con {
    padding-left: 1rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-2 > .col-con {
    padding-left: 1.25rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-9 > .col-con {
    padding-left: 1.5rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-10 > .col-con {
    padding-left: 2rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-3 > .col-con {
    padding-left: 2.5rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-4 > .col-con {
    padding-left: 3.75rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-5 > .col-con {
    padding-left: 5rem;
  }
  adv-grid > adv-col.col-sm-pad-lt-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-pad-lt-6 > .col-con {
    padding-left: 10rem;
  }
  adv-grid > adv-col.col-sm-gut-no,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-gut-no {
    padding: 0;
  }
  adv-grid > adv-col.col-sm-gut-def,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-gut-def {
    padding: 0 2.5vw;
  }
  adv-grid > adv-col.col-sm-gut-lt-def,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-gut-lt-def {
    padding: 0 0 0 2.5vw;
  }
  adv-grid > adv-col.col-sm-gut-rt-def,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-gut-rt-def {
    padding: 0 2.5vw 0 0;
  }
  adv-grid > adv-col.col-sm-br-dn-lt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-dn-lt-none {
    border-bottom-left-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-dn-lt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-dn-lt-15 {
    border-bottom-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-dn-lt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-dn-lt-48 {
    border-bottom-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-dn-rt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-dn-rt-none {
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-dn-rt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-dn-rt-15 {
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-dn-rt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-dn-rt-48 {
    border-bottom-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-up-lt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-up-lt-none {
    border-top-left-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-up-lt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-up-lt-15 {
    border-top-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-up-lt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-up-lt-48 {
    border-top-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-up-rt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-up-rt-none {
    border-top-right-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-up-rt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-up-rt-15 {
    border-top-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-br-up-rt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-br-up-rt-48 {
    border-top-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-sm-bw-up-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-up-0 {
    border-top-width: 0;
  }
  adv-grid > adv-col.col-sm-bw-up-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-up-1 {
    border-top-width: 1px;
  }
  adv-grid > adv-col.col-sm-bw-up-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-up-2 {
    border-top-width: 2px;
  }
  adv-grid > adv-col.col-sm-bw-up-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-up-3 {
    border-top-width: 3px;
  }
  adv-grid > adv-col.col-sm-bw-up-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-up-4 {
    border-top-width: 4px;
  }
  adv-grid > adv-col.col-sm-bs-up-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-up-none {
    border-top-style: none;
  }
  adv-grid > adv-col.col-sm-bs-up-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-up-dotted {
    border-top-style: dotted;
  }
  adv-grid > adv-col.col-sm-bs-up-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-up-dashed {
    border-top-style: dashed;
  }
  adv-grid > adv-col.col-sm-bs-up-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-up-solid {
    border-top-style: solid;
  }
  adv-grid > adv-col.col-sm-bw-rt-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-rt-0 {
    border-right-width: 0;
  }
  adv-grid > adv-col.col-sm-bw-rt-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-rt-1 {
    border-right-width: 1px;
  }
  adv-grid > adv-col.col-sm-bw-rt-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-rt-2 {
    border-right-width: 2px;
  }
  adv-grid > adv-col.col-sm-bw-rt-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-rt-3 {
    border-right-width: 3px;
  }
  adv-grid > adv-col.col-sm-bw-rt-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-rt-4 {
    border-right-width: 4px;
  }
  adv-grid > adv-col.col-sm-bs-rt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-rt-none {
    border-right-style: none;
  }
  adv-grid > adv-col.col-sm-bs-rt-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-rt-dotted {
    border-right-style: dotted;
  }
  adv-grid > adv-col.col-sm-bs-rt-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-rt-dashed {
    border-right-style: dashed;
  }
  adv-grid > adv-col.col-sm-bs-rt-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-rt-solid {
    border-right-style: solid;
  }
  adv-grid > adv-col.col-sm-bw-dn-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-dn-0 {
    border-bottom-width: 0;
  }
  adv-grid > adv-col.col-sm-bw-dn-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-dn-1 {
    border-bottom-width: 1px;
  }
  adv-grid > adv-col.col-sm-bw-dn-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-dn-2 {
    border-bottom-width: 2px;
  }
  adv-grid > adv-col.col-sm-bw-dn-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-dn-3 {
    border-bottom-width: 3px;
  }
  adv-grid > adv-col.col-sm-bw-dn-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-dn-4 {
    border-bottom-width: 4px;
  }
  adv-grid > adv-col.col-sm-bs-dn-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-dn-none {
    border-bottom-style: none;
  }
  adv-grid > adv-col.col-sm-bs-dn-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-dn-dotted {
    border-bottom-style: dotted;
  }
  adv-grid > adv-col.col-sm-bs-dn-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-dn-dashed {
    border-bottom-style: dashed;
  }
  adv-grid > adv-col.col-sm-bs-dn-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-dn-solid {
    border-bottom-style: solid;
  }
  adv-grid > adv-col.col-sm-bw-lt-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-lt-0 {
    border-left-width: 0;
  }
  adv-grid > adv-col.col-sm-bw-lt-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-lt-1 {
    border-left-width: 1px;
  }
  adv-grid > adv-col.col-sm-bw-lt-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-lt-2 {
    border-left-width: 2px;
  }
  adv-grid > adv-col.col-sm-bw-lt-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-lt-3 {
    border-left-width: 3px;
  }
  adv-grid > adv-col.col-sm-bw-lt-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bw-lt-4 {
    border-left-width: 4px;
  }
  adv-grid > adv-col.col-sm-bs-lt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-lt-none {
    border-left-style: none;
  }
  adv-grid > adv-col.col-sm-bs-lt-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-lt-dotted {
    border-left-style: dotted;
  }
  adv-grid > adv-col.col-sm-bs-lt-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-lt-dashed {
    border-left-style: dashed;
  }
  adv-grid > adv-col.col-sm-bs-lt-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-sm-bs-lt-solid {
    border-left-style: solid;
  }
}
@media print, only screen and (min-width:420px) {
  adv-grid > adv-col.col-md-as-start,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-as-start {
    align-self: flex-start;
  }
  adv-grid > adv-col.col-md-as-end,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-as-end {
    align-self: flex-end;
  }
  adv-grid > adv-col.col-md-as-center,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-as-center {
    align-self: center;
  }
  adv-grid > adv-col.col-md-as-stch,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-as-stch {
    align-self: stretch;
  }
  adv-grid > adv-col.col-md-ca-lt,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-ca-lt {
    text-align: left;
  }
  adv-grid > adv-col.col-md-ca-rt,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-ca-rt {
    text-align: right;
  }
  adv-grid > adv-col.col-md-ca-c,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-ca-c {
    text-align: center;
  }
  adv-grid > adv-col.col-md-pad-up-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-no > .col-con {
    padding-top: 0;
  }
  adv-grid > adv-col.col-md-pad-up-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-7 > .col-con {
    padding-top: 0.5rem;
  }
  adv-grid > adv-col.col-md-pad-up-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-1 > .col-con {
    padding-top: 0.625rem;
  }
  adv-grid > adv-col.col-md-pad-up-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-8 > .col-con {
    padding-top: 1rem;
  }
  adv-grid > adv-col.col-md-pad-up-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-2 > .col-con {
    padding-top: 1.25rem;
  }
  adv-grid > adv-col.col-md-pad-up-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-9 > .col-con {
    padding-top: 1.5rem;
  }
  adv-grid > adv-col.col-md-pad-up-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-10 > .col-con {
    padding-top: 2rem;
  }
  adv-grid > adv-col.col-md-pad-up-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-3 > .col-con {
    padding-top: 2.5rem;
  }
  adv-grid > adv-col.col-md-pad-up-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-4 > .col-con {
    padding-top: 3.75rem;
  }
  adv-grid > adv-col.col-md-pad-up-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-5 > .col-con {
    padding-top: 5rem;
  }
  adv-grid > adv-col.col-md-pad-up-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-up-6 > .col-con {
    padding-top: 10rem;
  }
  adv-grid > adv-col.col-md-pad-rt-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-no > .col-con {
    padding-right: 0;
  }
  adv-grid > adv-col.col-md-pad-rt-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-7 > .col-con {
    padding-right: 0.5rem;
  }
  adv-grid > adv-col.col-md-pad-rt-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-1 > .col-con {
    padding-right: 0.625rem;
  }
  adv-grid > adv-col.col-md-pad-rt-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-8 > .col-con {
    padding-right: 1rem;
  }
  adv-grid > adv-col.col-md-pad-rt-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-2 > .col-con {
    padding-right: 1.25rem;
  }
  adv-grid > adv-col.col-md-pad-rt-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-9 > .col-con {
    padding-right: 1.5rem;
  }
  adv-grid > adv-col.col-md-pad-rt-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-10 > .col-con {
    padding-right: 2rem;
  }
  adv-grid > adv-col.col-md-pad-rt-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-3 > .col-con {
    padding-right: 2.5rem;
  }
  adv-grid > adv-col.col-md-pad-rt-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-4 > .col-con {
    padding-right: 3.75rem;
  }
  adv-grid > adv-col.col-md-pad-rt-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-5 > .col-con {
    padding-right: 5rem;
  }
  adv-grid > adv-col.col-md-pad-rt-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-rt-6 > .col-con {
    padding-right: 10rem;
  }
  adv-grid > adv-col.col-md-pad-dn-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-no > .col-con {
    padding-bottom: 0;
  }
  adv-grid > adv-col.col-md-pad-dn-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-7 > .col-con {
    padding-bottom: 0.5rem;
  }
  adv-grid > adv-col.col-md-pad-dn-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-1 > .col-con {
    padding-bottom: 0.625rem;
  }
  adv-grid > adv-col.col-md-pad-dn-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-8 > .col-con {
    padding-bottom: 1rem;
  }
  adv-grid > adv-col.col-md-pad-dn-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-2 > .col-con {
    padding-bottom: 1.25rem;
  }
  adv-grid > adv-col.col-md-pad-dn-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-9 > .col-con {
    padding-bottom: 1.5rem;
  }
  adv-grid > adv-col.col-md-pad-dn-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-10 > .col-con {
    padding-bottom: 2rem;
  }
  adv-grid > adv-col.col-md-pad-dn-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-3 > .col-con {
    padding-bottom: 2.5rem;
  }
  adv-grid > adv-col.col-md-pad-dn-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-4 > .col-con {
    padding-bottom: 3.75rem;
  }
  adv-grid > adv-col.col-md-pad-dn-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-5 > .col-con {
    padding-bottom: 5rem;
  }
  adv-grid > adv-col.col-md-pad-dn-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-dn-6 > .col-con {
    padding-bottom: 10rem;
  }
  adv-grid > adv-col.col-md-pad-lt-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-no > .col-con {
    padding-left: 0;
  }
  adv-grid > adv-col.col-md-pad-lt-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-7 > .col-con {
    padding-left: 0.5rem;
  }
  adv-grid > adv-col.col-md-pad-lt-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-1 > .col-con {
    padding-left: 0.625rem;
  }
  adv-grid > adv-col.col-md-pad-lt-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-8 > .col-con {
    padding-left: 1rem;
  }
  adv-grid > adv-col.col-md-pad-lt-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-2 > .col-con {
    padding-left: 1.25rem;
  }
  adv-grid > adv-col.col-md-pad-lt-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-9 > .col-con {
    padding-left: 1.5rem;
  }
  adv-grid > adv-col.col-md-pad-lt-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-10 > .col-con {
    padding-left: 2rem;
  }
  adv-grid > adv-col.col-md-pad-lt-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-3 > .col-con {
    padding-left: 2.5rem;
  }
  adv-grid > adv-col.col-md-pad-lt-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-4 > .col-con {
    padding-left: 3.75rem;
  }
  adv-grid > adv-col.col-md-pad-lt-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-5 > .col-con {
    padding-left: 5rem;
  }
  adv-grid > adv-col.col-md-pad-lt-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-pad-lt-6 > .col-con {
    padding-left: 10rem;
  }
  adv-grid > adv-col.col-md-gut-no,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-gut-no {
    padding: 0;
  }
  adv-grid > adv-col.col-md-gut-def,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-gut-def {
    padding: 0 2.5vw;
  }
  adv-grid > adv-col.col-md-gut-lt-def,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-gut-lt-def {
    padding: 0 0 0 2.5vw;
  }
  adv-grid > adv-col.col-md-gut-rt-def,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-gut-rt-def {
    padding: 0 2.5vw 0 0;
  }
  adv-grid > adv-col.col-md-br-dn-lt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-dn-lt-none {
    border-bottom-left-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-dn-lt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-dn-lt-15 {
    border-bottom-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-dn-lt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-dn-lt-48 {
    border-bottom-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-dn-rt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-dn-rt-none {
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-dn-rt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-dn-rt-15 {
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-dn-rt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-dn-rt-48 {
    border-bottom-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-up-lt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-up-lt-none {
    border-top-left-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-up-lt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-up-lt-15 {
    border-top-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-up-lt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-up-lt-48 {
    border-top-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-up-rt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-up-rt-none {
    border-top-right-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-up-rt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-up-rt-15 {
    border-top-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-br-up-rt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-br-up-rt-48 {
    border-top-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-md-bw-up-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-up-0 {
    border-top-width: 0;
  }
  adv-grid > adv-col.col-md-bw-up-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-up-1 {
    border-top-width: 1px;
  }
  adv-grid > adv-col.col-md-bw-up-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-up-2 {
    border-top-width: 2px;
  }
  adv-grid > adv-col.col-md-bw-up-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-up-3 {
    border-top-width: 3px;
  }
  adv-grid > adv-col.col-md-bw-up-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-up-4 {
    border-top-width: 4px;
  }
  adv-grid > adv-col.col-md-bs-up-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-up-none {
    border-top-style: none;
  }
  adv-grid > adv-col.col-md-bs-up-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-up-dotted {
    border-top-style: dotted;
  }
  adv-grid > adv-col.col-md-bs-up-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-up-dashed {
    border-top-style: dashed;
  }
  adv-grid > adv-col.col-md-bs-up-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-up-solid {
    border-top-style: solid;
  }
  adv-grid > adv-col.col-md-bw-rt-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-rt-0 {
    border-right-width: 0;
  }
  adv-grid > adv-col.col-md-bw-rt-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-rt-1 {
    border-right-width: 1px;
  }
  adv-grid > adv-col.col-md-bw-rt-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-rt-2 {
    border-right-width: 2px;
  }
  adv-grid > adv-col.col-md-bw-rt-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-rt-3 {
    border-right-width: 3px;
  }
  adv-grid > adv-col.col-md-bw-rt-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-rt-4 {
    border-right-width: 4px;
  }
  adv-grid > adv-col.col-md-bs-rt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-rt-none {
    border-right-style: none;
  }
  adv-grid > adv-col.col-md-bs-rt-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-rt-dotted {
    border-right-style: dotted;
  }
  adv-grid > adv-col.col-md-bs-rt-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-rt-dashed {
    border-right-style: dashed;
  }
  adv-grid > adv-col.col-md-bs-rt-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-rt-solid {
    border-right-style: solid;
  }
  adv-grid > adv-col.col-md-bw-dn-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-dn-0 {
    border-bottom-width: 0;
  }
  adv-grid > adv-col.col-md-bw-dn-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-dn-1 {
    border-bottom-width: 1px;
  }
  adv-grid > adv-col.col-md-bw-dn-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-dn-2 {
    border-bottom-width: 2px;
  }
  adv-grid > adv-col.col-md-bw-dn-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-dn-3 {
    border-bottom-width: 3px;
  }
  adv-grid > adv-col.col-md-bw-dn-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-dn-4 {
    border-bottom-width: 4px;
  }
  adv-grid > adv-col.col-md-bs-dn-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-dn-none {
    border-bottom-style: none;
  }
  adv-grid > adv-col.col-md-bs-dn-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-dn-dotted {
    border-bottom-style: dotted;
  }
  adv-grid > adv-col.col-md-bs-dn-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-dn-dashed {
    border-bottom-style: dashed;
  }
  adv-grid > adv-col.col-md-bs-dn-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-dn-solid {
    border-bottom-style: solid;
  }
  adv-grid > adv-col.col-md-bw-lt-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-lt-0 {
    border-left-width: 0;
  }
  adv-grid > adv-col.col-md-bw-lt-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-lt-1 {
    border-left-width: 1px;
  }
  adv-grid > adv-col.col-md-bw-lt-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-lt-2 {
    border-left-width: 2px;
  }
  adv-grid > adv-col.col-md-bw-lt-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-lt-3 {
    border-left-width: 3px;
  }
  adv-grid > adv-col.col-md-bw-lt-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bw-lt-4 {
    border-left-width: 4px;
  }
  adv-grid > adv-col.col-md-bs-lt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-lt-none {
    border-left-style: none;
  }
  adv-grid > adv-col.col-md-bs-lt-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-lt-dotted {
    border-left-style: dotted;
  }
  adv-grid > adv-col.col-md-bs-lt-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-lt-dashed {
    border-left-style: dashed;
  }
  adv-grid > adv-col.col-md-bs-lt-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-md-bs-lt-solid {
    border-left-style: solid;
  }
}
@media only screen and (min-width:960px) {
  adv-grid > adv-col.col-xl-as-start,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-as-start {
    align-self: flex-start;
  }
  adv-grid > adv-col.col-xl-as-end,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-as-end {
    align-self: flex-end;
  }
  adv-grid > adv-col.col-xl-as-center,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-as-center {
    align-self: center;
  }
  adv-grid > adv-col.col-xl-as-stch,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-as-stch {
    align-self: stretch;
  }
  adv-grid > adv-col.col-xl-ca-lt,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-ca-lt {
    text-align: left;
  }
  adv-grid > adv-col.col-xl-ca-rt,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-ca-rt {
    text-align: right;
  }
  adv-grid > adv-col.col-xl-ca-c,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-ca-c {
    text-align: center;
  }
  adv-grid > adv-col.col-xl-pad-up-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-no > .col-con {
    padding-top: 0;
  }
  adv-grid > adv-col.col-xl-pad-up-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-7 > .col-con {
    padding-top: 0.5rem;
  }
  adv-grid > adv-col.col-xl-pad-up-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-1 > .col-con {
    padding-top: 0.625rem;
  }
  adv-grid > adv-col.col-xl-pad-up-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-8 > .col-con {
    padding-top: 1rem;
  }
  adv-grid > adv-col.col-xl-pad-up-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-2 > .col-con {
    padding-top: 1.25rem;
  }
  adv-grid > adv-col.col-xl-pad-up-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-9 > .col-con {
    padding-top: 1.5rem;
  }
  adv-grid > adv-col.col-xl-pad-up-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-10 > .col-con {
    padding-top: 2rem;
  }
  adv-grid > adv-col.col-xl-pad-up-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-3 > .col-con {
    padding-top: 2.5rem;
  }
  adv-grid > adv-col.col-xl-pad-up-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-4 > .col-con {
    padding-top: 3.75rem;
  }
  adv-grid > adv-col.col-xl-pad-up-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-5 > .col-con {
    padding-top: 5rem;
  }
  adv-grid > adv-col.col-xl-pad-up-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-up-6 > .col-con {
    padding-top: 10rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-no > .col-con {
    padding-right: 0;
  }
  adv-grid > adv-col.col-xl-pad-rt-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-7 > .col-con {
    padding-right: 0.5rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-1 > .col-con {
    padding-right: 0.625rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-8 > .col-con {
    padding-right: 1rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-2 > .col-con {
    padding-right: 1.25rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-9 > .col-con {
    padding-right: 1.5rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-10 > .col-con {
    padding-right: 2rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-3 > .col-con {
    padding-right: 2.5rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-4 > .col-con {
    padding-right: 3.75rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-5 > .col-con {
    padding-right: 5rem;
  }
  adv-grid > adv-col.col-xl-pad-rt-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-rt-6 > .col-con {
    padding-right: 10rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-no > .col-con {
    padding-bottom: 0;
  }
  adv-grid > adv-col.col-xl-pad-dn-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-7 > .col-con {
    padding-bottom: 0.5rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-1 > .col-con {
    padding-bottom: 0.625rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-8 > .col-con {
    padding-bottom: 1rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-2 > .col-con {
    padding-bottom: 1.25rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-9 > .col-con {
    padding-bottom: 1.5rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-10 > .col-con {
    padding-bottom: 2rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-3 > .col-con {
    padding-bottom: 2.5rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-4 > .col-con {
    padding-bottom: 3.75rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-5 > .col-con {
    padding-bottom: 5rem;
  }
  adv-grid > adv-col.col-xl-pad-dn-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-dn-6 > .col-con {
    padding-bottom: 10rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-no > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-no > .col-con {
    padding-left: 0;
  }
  adv-grid > adv-col.col-xl-pad-lt-7 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-7 > .col-con {
    padding-left: 0.5rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-1 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-1 > .col-con {
    padding-left: 0.625rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-8 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-8 > .col-con {
    padding-left: 1rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-2 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-2 > .col-con {
    padding-left: 1.25rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-9 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-9 > .col-con {
    padding-left: 1.5rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-10 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-10 > .col-con {
    padding-left: 2rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-3 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-3 > .col-con {
    padding-left: 2.5rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-4 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-4 > .col-con {
    padding-left: 3.75rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-5 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-5 > .col-con {
    padding-left: 5rem;
  }
  adv-grid > adv-col.col-xl-pad-lt-6 > .col-con,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-pad-lt-6 > .col-con {
    padding-left: 10rem;
  }
  adv-grid > adv-col.col-xl-gut-no,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-gut-no {
    padding: 0;
  }
  adv-grid > adv-col.col-xl-gut-def,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-gut-def {
    padding: 0 2.5vw;
  }
  adv-grid > adv-col.col-xl-gut-lt-def,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-gut-lt-def {
    padding: 0 0 0 2.5vw;
  }
  adv-grid > adv-col.col-xl-gut-rt-def,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-gut-rt-def {
    padding: 0 2.5vw 0 0;
  }
  adv-grid > adv-col.col-xl-br-dn-lt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-dn-lt-none {
    border-bottom-left-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-dn-lt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-dn-lt-15 {
    border-bottom-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-dn-lt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-dn-lt-48 {
    border-bottom-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-dn-rt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-dn-rt-none {
    border-bottom-right-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-dn-rt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-dn-rt-15 {
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-dn-rt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-dn-rt-48 {
    border-bottom-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-up-lt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-up-lt-none {
    border-top-left-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-up-lt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-up-lt-15 {
    border-top-left-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-up-lt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-up-lt-48 {
    border-top-left-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-up-rt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-up-rt-none {
    border-top-right-radius: 0;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-up-rt-15,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-up-rt-15 {
    border-top-right-radius: 15px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-br-up-rt-48,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-br-up-rt-48 {
    border-top-right-radius: 48px;
    overflow: hidden;
  }
  adv-grid > adv-col.col-xl-bw-up-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-up-0 {
    border-top-width: 0;
  }
  adv-grid > adv-col.col-xl-bw-up-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-up-1 {
    border-top-width: 1px;
  }
  adv-grid > adv-col.col-xl-bw-up-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-up-2 {
    border-top-width: 2px;
  }
  adv-grid > adv-col.col-xl-bw-up-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-up-3 {
    border-top-width: 3px;
  }
  adv-grid > adv-col.col-xl-bw-up-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-up-4 {
    border-top-width: 4px;
  }
  adv-grid > adv-col.col-xl-bs-up-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-up-none {
    border-top-style: none;
  }
  adv-grid > adv-col.col-xl-bs-up-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-up-dotted {
    border-top-style: dotted;
  }
  adv-grid > adv-col.col-xl-bs-up-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-up-dashed {
    border-top-style: dashed;
  }
  adv-grid > adv-col.col-xl-bs-up-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-up-solid {
    border-top-style: solid;
  }
  adv-grid > adv-col.col-xl-bw-rt-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-rt-0 {
    border-right-width: 0;
  }
  adv-grid > adv-col.col-xl-bw-rt-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-rt-1 {
    border-right-width: 1px;
  }
  adv-grid > adv-col.col-xl-bw-rt-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-rt-2 {
    border-right-width: 2px;
  }
  adv-grid > adv-col.col-xl-bw-rt-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-rt-3 {
    border-right-width: 3px;
  }
  adv-grid > adv-col.col-xl-bw-rt-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-rt-4 {
    border-right-width: 4px;
  }
  adv-grid > adv-col.col-xl-bs-rt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-rt-none {
    border-right-style: none;
  }
  adv-grid > adv-col.col-xl-bs-rt-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-rt-dotted {
    border-right-style: dotted;
  }
  adv-grid > adv-col.col-xl-bs-rt-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-rt-dashed {
    border-right-style: dashed;
  }
  adv-grid > adv-col.col-xl-bs-rt-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-rt-solid {
    border-right-style: solid;
  }
  adv-grid > adv-col.col-xl-bw-dn-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-dn-0 {
    border-bottom-width: 0;
  }
  adv-grid > adv-col.col-xl-bw-dn-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-dn-1 {
    border-bottom-width: 1px;
  }
  adv-grid > adv-col.col-xl-bw-dn-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-dn-2 {
    border-bottom-width: 2px;
  }
  adv-grid > adv-col.col-xl-bw-dn-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-dn-3 {
    border-bottom-width: 3px;
  }
  adv-grid > adv-col.col-xl-bw-dn-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-dn-4 {
    border-bottom-width: 4px;
  }
  adv-grid > adv-col.col-xl-bs-dn-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-dn-none {
    border-bottom-style: none;
  }
  adv-grid > adv-col.col-xl-bs-dn-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-dn-dotted {
    border-bottom-style: dotted;
  }
  adv-grid > adv-col.col-xl-bs-dn-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-dn-dashed {
    border-bottom-style: dashed;
  }
  adv-grid > adv-col.col-xl-bs-dn-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-dn-solid {
    border-bottom-style: solid;
  }
  adv-grid > adv-col.col-xl-bw-lt-0,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-lt-0 {
    border-left-width: 0;
  }
  adv-grid > adv-col.col-xl-bw-lt-1,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-lt-1 {
    border-left-width: 1px;
  }
  adv-grid > adv-col.col-xl-bw-lt-2,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-lt-2 {
    border-left-width: 2px;
  }
  adv-grid > adv-col.col-xl-bw-lt-3,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-lt-3 {
    border-left-width: 3px;
  }
  adv-grid > adv-col.col-xl-bw-lt-4,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bw-lt-4 {
    border-left-width: 4px;
  }
  adv-grid > adv-col.col-xl-bs-lt-none,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-lt-none {
    border-left-style: none;
  }
  adv-grid > adv-col.col-xl-bs-lt-dotted,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-lt-dotted {
    border-left-style: dotted;
  }
  adv-grid > adv-col.col-xl-bs-lt-dashed,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-lt-dashed {
    border-left-style: dashed;
  }
  adv-grid > adv-col.col-xl-bs-lt-solid,
adv-grid > adv-scrl > adv-slides > adv-col.col-xl-bs-lt-solid {
    border-left-style: solid;
  }
}
@media only screen {
  adv-grid > adv-col.col-sm-1 {
    width: 8.3333333333%;
  }
  adv-grid > adv-col.col-sm-2 {
    width: 16.6666666667%;
  }
  adv-grid > adv-col.col-sm-3 {
    width: 25%;
  }
  adv-grid > adv-col.col-sm-4 {
    width: 33.3333333333%;
  }
  adv-grid > adv-col.col-sm-5 {
    width: 41.6666666667%;
  }
  adv-grid > adv-col.col-sm-6 {
    width: 50%;
  }
  adv-grid > adv-col.col-sm-7 {
    width: 58.3333333333%;
  }
  adv-grid > adv-col.col-sm-8 {
    width: 66.6666666667%;
  }
  adv-grid > adv-col.col-sm-9 {
    width: 75%;
  }
  adv-grid > adv-col.col-sm-10 {
    width: 83.3333333333%;
  }
  adv-grid > adv-col.col-sm-11 {
    width: 91.6666666667%;
  }
  adv-grid > adv-col.col-sm-12 {
    width: 100%;
  }
}
@media print, only screen and (min-width:420px) {
  adv-grid > adv-col.col-md-1 {
    width: 8.3333333333%;
  }
  adv-grid > adv-col.col-md-o-1 {
    order: 1;
  }
  adv-grid > adv-col.col-md-2 {
    width: 16.6666666667%;
  }
  adv-grid > adv-col.col-md-o-2 {
    order: 2;
  }
  adv-grid > adv-col.col-md-3 {
    width: 25%;
  }
  adv-grid > adv-col.col-md-o-3 {
    order: 3;
  }
  adv-grid > adv-col.col-md-4 {
    width: 33.3333333333%;
  }
  adv-grid > adv-col.col-md-o-4 {
    order: 4;
  }
  adv-grid > adv-col.col-md-5 {
    width: 41.6666666667%;
  }
  adv-grid > adv-col.col-md-o-5 {
    order: 5;
  }
  adv-grid > adv-col.col-md-6 {
    width: 50%;
  }
  adv-grid > adv-col.col-md-o-6 {
    order: 6;
  }
  adv-grid > adv-col.col-md-7 {
    width: 58.3333333333%;
  }
  adv-grid > adv-col.col-md-o-7 {
    order: 7;
  }
  adv-grid > adv-col.col-md-8 {
    width: 66.6666666667%;
  }
  adv-grid > adv-col.col-md-o-8 {
    order: 8;
  }
  adv-grid > adv-col.col-md-9 {
    width: 75%;
  }
  adv-grid > adv-col.col-md-o-9 {
    order: 9;
  }
  adv-grid > adv-col.col-md-10 {
    width: 83.3333333333%;
  }
  adv-grid > adv-col.col-md-o-10 {
    order: 10;
  }
  adv-grid > adv-col.col-md-11 {
    width: 91.6666666667%;
  }
  adv-grid > adv-col.col-md-o-11 {
    order: 11;
  }
  adv-grid > adv-col.col-md-12 {
    width: 100%;
  }
  adv-grid > adv-col.col-md-o-12 {
    order: 12;
  }
}
@media only screen and (min-width:960px) {
  adv-grid > adv-col.col-xl-1 {
    width: 8.3333333333%;
  }
  adv-grid > adv-col.col-xl-o-1 {
    order: 1;
  }
  adv-grid > adv-col.col-xl-2 {
    width: 16.6666666667%;
  }
  adv-grid > adv-col.col-xl-o-2 {
    order: 2;
  }
  adv-grid > adv-col.col-xl-3 {
    width: 25%;
  }
  adv-grid > adv-col.col-xl-o-3 {
    order: 3;
  }
  adv-grid > adv-col.col-xl-4 {
    width: 33.3333333333%;
  }
  adv-grid > adv-col.col-xl-o-4 {
    order: 4;
  }
  adv-grid > adv-col.col-xl-5 {
    width: 41.6666666667%;
  }
  adv-grid > adv-col.col-xl-o-5 {
    order: 5;
  }
  adv-grid > adv-col.col-xl-6 {
    width: 50%;
  }
  adv-grid > adv-col.col-xl-o-6 {
    order: 6;
  }
  adv-grid > adv-col.col-xl-7 {
    width: 58.3333333333%;
  }
  adv-grid > adv-col.col-xl-o-7 {
    order: 7;
  }
  adv-grid > adv-col.col-xl-8 {
    width: 66.6666666667%;
  }
  adv-grid > adv-col.col-xl-o-8 {
    order: 8;
  }
  adv-grid > adv-col.col-xl-9 {
    width: 75%;
  }
  adv-grid > adv-col.col-xl-o-9 {
    order: 9;
  }
  adv-grid > adv-col.col-xl-10 {
    width: 83.3333333333%;
  }
  adv-grid > adv-col.col-xl-o-10 {
    order: 10;
  }
  adv-grid > adv-col.col-xl-11 {
    width: 91.6666666667%;
  }
  adv-grid > adv-col.col-xl-o-11 {
    order: 11;
  }
  adv-grid > adv-col.col-xl-12 {
    width: 100%;
  }
  adv-grid > adv-col.col-xl-o-12 {
    order: 12;
  }
}
adv-grid > adv-scrl > adv-slides > adv-col {
  overflow: hidden;
}
@media only screen {
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-1 {
    flex: 0 0 8.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-2 {
    flex: 0 0 16.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-3 {
    flex: 0 0 25%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-4 {
    flex: 0 0 33.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-5 {
    flex: 0 0 41.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-6 {
    flex: 0 0 50%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-7 {
    flex: 0 0 58.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-8 {
    flex: 0 0 66.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-9 {
    flex: 0 0 75%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-10 {
    flex: 0 0 83.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-11 {
    flex: 0 0 91.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-sm-12 {
    flex: 0 0 100%;
  }
}
@media print, only screen and (min-width:420px) {
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-1 {
    flex: 0 0 8.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-1 {
    order: 1;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-2 {
    flex: 0 0 16.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-2 {
    order: 2;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-3 {
    flex: 0 0 25%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-3 {
    order: 3;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-4 {
    flex: 0 0 33.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-4 {
    order: 4;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-5 {
    flex: 0 0 41.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-5 {
    order: 5;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-6 {
    flex: 0 0 50%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-6 {
    order: 6;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-7 {
    flex: 0 0 58.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-7 {
    order: 7;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-8 {
    flex: 0 0 66.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-8 {
    order: 8;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-9 {
    flex: 0 0 75%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-9 {
    order: 9;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-10 {
    flex: 0 0 83.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-10 {
    order: 10;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-11 {
    flex: 0 0 91.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-11 {
    order: 11;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-12 {
    flex: 0 0 100%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-md-o-12 {
    order: 12;
  }
}
@media only screen and (min-width:960px) {
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-1 {
    flex: 0 0 8.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-1 {
    order: 1;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-2 {
    flex: 0 0 16.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-2 {
    order: 2;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-3 {
    flex: 0 0 25%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-3 {
    order: 3;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-4 {
    flex: 0 0 33.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-4 {
    order: 4;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-5 {
    flex: 0 0 41.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-5 {
    order: 5;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-6 {
    flex: 0 0 50%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-6 {
    order: 6;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-7 {
    flex: 0 0 58.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-7 {
    order: 7;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-8 {
    flex: 0 0 66.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-8 {
    order: 8;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-9 {
    flex: 0 0 75%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-9 {
    order: 9;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-10 {
    flex: 0 0 83.3333333333%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-10 {
    order: 10;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-11 {
    flex: 0 0 91.6666666667%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-11 {
    order: 11;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-12 {
    flex: 0 0 100%;
  }
  adv-grid > adv-scrl > adv-slides > adv-col.col-xl-o-12 {
    order: 12;
  }
}

@media only screen and (min-width:960px) {
  .xlarge-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .xlarge-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .xlarge-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }

  .xlarge-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }

  .xlarge-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }

  .xlarge-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }

  .xlarge-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .xlarge-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .xlarge-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }

  .xlarge-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }

  .xlarge-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }

  .xlarge-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }

  .xlarge-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .xlarge-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .xlarge-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }

  .xlarge-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }

  .xlarge-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }

  .xlarge-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }

  .xlarge-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .xlarge-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .xlarge-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }

  .xlarge-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }

  .xlarge-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }

  .xlarge-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }

  .column,
.columns {
    position: relative;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }

  .xlarge-1 {
    width: 8.3333333333%;
  }

  .xlarge-2 {
    width: 16.6666666667%;
  }

  .xlarge-3 {
    width: 25%;
  }

  .xlarge-4 {
    width: 33.3333333333%;
  }

  .xlarge-5 {
    width: 41.6666666667%;
  }

  .xlarge-6 {
    width: 50%;
  }

  .xlarge-7 {
    width: 58.3333333333%;
  }

  .xlarge-8 {
    width: 66.6666666667%;
  }

  .xlarge-9 {
    width: 75%;
  }

  .xlarge-10 {
    width: 83.3333333333%;
  }

  .xlarge-11 {
    width: 91.6666666667%;
  }

  .xlarge-12 {
    width: 100%;
  }

  .xlarge-offset-0 {
    margin-left: 0 !important;
  }

  .xlarge-offset-1 {
    margin-left: 8.3333333333% !important;
  }

  .xlarge-offset-2 {
    margin-left: 16.6666666667% !important;
  }

  .xlarge-offset-3 {
    margin-left: 25% !important;
  }

  .xlarge-offset-4 {
    margin-left: 33.3333333333% !important;
  }

  .xlarge-offset-5 {
    margin-left: 41.6666666667% !important;
  }

  .xlarge-offset-6 {
    margin-left: 50% !important;
  }

  .xlarge-offset-7 {
    margin-left: 58.3333333333% !important;
  }

  .xlarge-offset-8 {
    margin-left: 66.6666666667% !important;
  }

  .xlarge-offset-9 {
    margin-left: 75% !important;
  }

  .xlarge-offset-10 {
    margin-left: 83.3333333333% !important;
  }

  .xlarge-offset-11 {
    margin-left: 91.6666666667% !important;
  }

  .xlarge-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .column.xlarge-centered,
.columns.xlarge-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.xlarge-uncentered,
.columns.xlarge-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .column.xlarge-centered:last-child,
.columns.xlarge-centered:last-child {
    float: none;
  }

  .column.xlarge-uncentered:last-child,
.columns.xlarge-uncentered:last-child {
    float: left;
  }

  .column.xlarge-uncentered.opposite,
.columns.xlarge-uncentered.opposite {
    float: right;
  }

  .row.xlarge-collapse > .column,
.row.xlarge-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.xlarge-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.xlarge-uncollapse > .column,
.row.xlarge-uncollapse > .columns {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }
}
@media only screen and (min-width:1500px) {
  .xxlarge-push-0 {
    position: relative;
    left: 0;
    right: auto;
  }

  .xxlarge-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .xxlarge-push-1 {
    position: relative;
    left: 8.3333333333%;
    right: auto;
  }

  .xxlarge-pull-1 {
    position: relative;
    right: 8.3333333333%;
    left: auto;
  }

  .xxlarge-push-2 {
    position: relative;
    left: 16.6666666667%;
    right: auto;
  }

  .xxlarge-pull-2 {
    position: relative;
    right: 16.6666666667%;
    left: auto;
  }

  .xxlarge-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .xxlarge-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .xxlarge-push-4 {
    position: relative;
    left: 33.3333333333%;
    right: auto;
  }

  .xxlarge-pull-4 {
    position: relative;
    right: 33.3333333333%;
    left: auto;
  }

  .xxlarge-push-5 {
    position: relative;
    left: 41.6666666667%;
    right: auto;
  }

  .xxlarge-pull-5 {
    position: relative;
    right: 41.6666666667%;
    left: auto;
  }

  .xxlarge-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .xxlarge-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .xxlarge-push-7 {
    position: relative;
    left: 58.3333333333%;
    right: auto;
  }

  .xxlarge-pull-7 {
    position: relative;
    right: 58.3333333333%;
    left: auto;
  }

  .xxlarge-push-8 {
    position: relative;
    left: 66.6666666667%;
    right: auto;
  }

  .xxlarge-pull-8 {
    position: relative;
    right: 66.6666666667%;
    left: auto;
  }

  .xxlarge-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .xxlarge-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .xxlarge-push-10 {
    position: relative;
    left: 83.3333333333%;
    right: auto;
  }

  .xxlarge-pull-10 {
    position: relative;
    right: 83.3333333333%;
    left: auto;
  }

  .xxlarge-push-11 {
    position: relative;
    left: 91.6666666667%;
    right: auto;
  }

  .xxlarge-pull-11 {
    position: relative;
    right: 91.6666666667%;
    left: auto;
  }

  .column,
.columns {
    position: relative;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }

  .xxlarge-1 {
    width: 8.3333333333%;
  }

  .xxlarge-2 {
    width: 16.6666666667%;
  }

  .xxlarge-3 {
    width: 25%;
  }

  .xxlarge-4 {
    width: 33.3333333333%;
  }

  .xxlarge-5 {
    width: 41.6666666667%;
  }

  .xxlarge-6 {
    width: 50%;
  }

  .xxlarge-7 {
    width: 58.3333333333%;
  }

  .xxlarge-8 {
    width: 66.6666666667%;
  }

  .xxlarge-9 {
    width: 75%;
  }

  .xxlarge-10 {
    width: 83.3333333333%;
  }

  .xxlarge-11 {
    width: 91.6666666667%;
  }

  .xxlarge-12 {
    width: 100%;
  }

  .xxlarge-offset-0 {
    margin-left: 0 !important;
  }

  .xxlarge-offset-1 {
    margin-left: 8.3333333333% !important;
  }

  .xxlarge-offset-2 {
    margin-left: 16.6666666667% !important;
  }

  .xxlarge-offset-3 {
    margin-left: 25% !important;
  }

  .xxlarge-offset-4 {
    margin-left: 33.3333333333% !important;
  }

  .xxlarge-offset-5 {
    margin-left: 41.6666666667% !important;
  }

  .xxlarge-offset-6 {
    margin-left: 50% !important;
  }

  .xxlarge-offset-7 {
    margin-left: 58.3333333333% !important;
  }

  .xxlarge-offset-8 {
    margin-left: 66.6666666667% !important;
  }

  .xxlarge-offset-9 {
    margin-left: 75% !important;
  }

  .xxlarge-offset-10 {
    margin-left: 83.3333333333% !important;
  }

  .xxlarge-offset-11 {
    margin-left: 91.6666666667% !important;
  }

  .xxlarge-reset-order {
    float: left;
    left: auto;
    margin-left: 0;
    margin-right: 0;
    right: auto;
  }

  .column.xxlarge-centered,
.columns.xxlarge-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.xxlarge-uncentered,
.columns.xxlarge-uncentered {
    float: left;
    margin-left: 0;
    margin-right: 0;
  }

  .column.xxlarge-centered:last-child,
.columns.xxlarge-centered:last-child {
    float: none;
  }

  .column.xxlarge-uncentered:last-child,
.columns.xxlarge-uncentered:last-child {
    float: left;
  }

  .column.xxlarge-uncentered.opposite,
.columns.xxlarge-uncentered.opposite {
    float: right;
  }

  .row.xxlarge-collapse > .column,
.row.xxlarge-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }
  .row.xxlarge-collapse .row {
    margin-left: 0;
    margin-right: 0;
  }
  .row.xxlarge-uncollapse > .column,
.row.xxlarge-uncollapse > .columns {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    float: left;
  }
}
/* Flex Mixin */
.accordion {
  margin-bottom: 0;
  margin-left: 0;
}
.accordion:before, .accordion:after {
  content: " ";
  display: table;
}
.accordion:after {
  clear: both;
}
.accordion .accordion-navigation, .accordion dd {
  display: block;
  margin-bottom: 0 !important;
}
.accordion .accordion-navigation.active > a, .accordion dd.active > a {
  background: #e8e8e8;
  color: #222222;
}
.accordion .accordion-navigation > a, .accordion dd > a {
  background: #EFEFEF;
  color: #222222;
  display: block;
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-size: 1rem;
  padding: 1rem;
}
.accordion .accordion-navigation > a:hover, .accordion dd > a:hover {
  background: #e3e3e3;
}
.accordion .accordion-navigation > .content, .accordion dd > .content {
  display: none;
  padding: 2.5vw;
}
.accordion .accordion-navigation > .content.active, .accordion dd > .content.active {
  background: #ffffff;
  display: block;
}

/* Flex Mixin */
button, .button {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 1rem 2rem 1.0625rem 2rem;
  font-size: 1rem;
  background-color: #cd9834;
  border-color: #a57a29;
  color: #ffffff;
  transition: background-color 300ms ease-out;
}
button:hover, button:focus, .button:hover, .button:focus {
  background-color: #a57a29;
}
button:hover, button:focus, .button:hover, .button:focus {
  color: #ffffff;
}
button.secondary, .button.secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333333;
}
button.secondary:hover, button.secondary:focus, .button.secondary:hover, .button.secondary:focus {
  background-color: #b9b9b9;
}
button.secondary:hover, button.secondary:focus, .button.secondary:hover, .button.secondary:focus {
  color: #333333;
}
button.success, .button.success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #ffffff;
}
button.success:hover, button.success:focus, .button.success:hover, .button.success:focus {
  background-color: #368a55;
}
button.success:hover, button.success:focus, .button.success:hover, .button.success:focus {
  color: #ffffff;
}
button.alert, .button.alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  color: #ffffff;
}
button.alert:hover, button.alert:focus, .button.alert:hover, .button.alert:focus {
  background-color: #cf2a0e;
}
button.alert:hover, button.alert:focus, .button.alert:hover, .button.alert:focus {
  color: #ffffff;
}
button.warning, .button.warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #ffffff;
}
button.warning:hover, button.warning:focus, .button.warning:hover, .button.warning:focus {
  background-color: #cf6e0e;
}
button.warning:hover, button.warning:focus, .button.warning:hover, .button.warning:focus {
  color: #ffffff;
}
button.info, .button.info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333333;
}
button.info:hover, button.info:focus, .button.info:hover, .button.info:focus {
  background-color: #61b6d9;
}
button.info:hover, button.info:focus, .button.info:hover, .button.info:focus {
  color: #ffffff;
}
button.large, .button.large {
  padding: 1.125rem 2.25rem 1.1875rem 2.25rem;
  font-size: 1.25rem;
}
button.small, .button.small {
  padding: 0.875rem 1.75rem 0.9375rem 1.75rem;
  font-size: 0.8125rem;
}
button.tiny, .button.tiny {
  padding: 0.625rem 1.25rem 0.6875rem 1.25rem;
  font-size: 0.6875rem;
}
button.expand, .button.expand {
  padding: 1rem 2rem 1.0625rem 2rem;
  font-size: 1rem;
  padding-bottom: 1.0625rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
button.left-align, .button.left-align {
  text-align: left;
  text-indent: 0.75rem;
}
button.right-align, .button.right-align {
  text-align: right;
  padding-right: 0.75rem;
}
button.radius, .button.radius {
  border-radius: 3px;
}
button.round, .button.round {
  border-radius: 1000px;
}
button.disabled, button[disabled], .button.disabled, .button[disabled] {
  background-color: #cd9834;
  border-color: #a57a29;
  color: #ffffff;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}
button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  background-color: #a57a29;
}
button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  color: #ffffff;
}
button.disabled:hover, button.disabled:focus, button[disabled]:hover, button[disabled]:focus, .button.disabled:hover, .button.disabled:focus, .button[disabled]:hover, .button[disabled]:focus {
  background-color: #cd9834;
}
button.disabled.secondary, button[disabled].secondary, .button.disabled.secondary, .button[disabled].secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9;
  color: #333333;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}
button.disabled.secondary:hover, button.disabled.secondary:focus, button[disabled].secondary:hover, button[disabled].secondary:focus, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #b9b9b9;
}
button.disabled.secondary:hover, button.disabled.secondary:focus, button[disabled].secondary:hover, button[disabled].secondary:focus, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  color: #333333;
}
button.disabled.secondary:hover, button.disabled.secondary:focus, button[disabled].secondary:hover, button[disabled].secondary:focus, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #e7e7e7;
}
button.disabled.success, button[disabled].success, .button.disabled.success, .button[disabled].success {
  background-color: #43AC6A;
  border-color: #368a55;
  color: #ffffff;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}
button.disabled.success:hover, button.disabled.success:focus, button[disabled].success:hover, button[disabled].success:focus, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #368a55;
}
button.disabled.success:hover, button.disabled.success:focus, button[disabled].success:hover, button[disabled].success:focus, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  color: #ffffff;
}
button.disabled.success:hover, button.disabled.success:focus, button[disabled].success:hover, button[disabled].success:focus, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #43AC6A;
}
button.disabled.alert, button[disabled].alert, .button.disabled.alert, .button[disabled].alert {
  background-color: #f04124;
  border-color: #cf2a0e;
  color: #ffffff;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}
button.disabled.alert:hover, button.disabled.alert:focus, button[disabled].alert:hover, button[disabled].alert:focus, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cf2a0e;
}
button.disabled.alert:hover, button.disabled.alert:focus, button[disabled].alert:hover, button[disabled].alert:focus, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  color: #ffffff;
}
button.disabled.alert:hover, button.disabled.alert:focus, button[disabled].alert:hover, button[disabled].alert:focus, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #f04124;
}
button.disabled.warning, button[disabled].warning, .button.disabled.warning, .button[disabled].warning {
  background-color: #f08a24;
  border-color: #cf6e0e;
  color: #ffffff;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}
button.disabled.warning:hover, button.disabled.warning:focus, button[disabled].warning:hover, button[disabled].warning:focus, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #cf6e0e;
}
button.disabled.warning:hover, button.disabled.warning:focus, button[disabled].warning:hover, button[disabled].warning:focus, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  color: #ffffff;
}
button.disabled.warning:hover, button.disabled.warning:focus, button[disabled].warning:hover, button[disabled].warning:focus, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #f08a24;
}
button.disabled.info, button[disabled].info, .button.disabled.info, .button[disabled].info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333333;
  box-shadow: none;
  cursor: default;
  opacity: 0.7;
}
button.disabled.info:hover, button.disabled.info:focus, button[disabled].info:hover, button[disabled].info:focus, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info:hover, .button[disabled].info:focus {
  background-color: #61b6d9;
}
button.disabled.info:hover, button.disabled.info:focus, button[disabled].info:hover, button[disabled].info:focus, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info:hover, .button[disabled].info:focus {
  color: #ffffff;
}
button.disabled.info:hover, button.disabled.info:focus, button[disabled].info:hover, button[disabled].info:focus, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info:hover, .button[disabled].info:focus {
  background-color: #a0d3e8;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

@media print, only screen and (min-width:420px) {
  button, .button {
    display: inline-block;
  }
}
/* Flex Mixin */
/* Foundation Dropdowns */
.f-dropdown {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #ffffff;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  width: 100%;
  z-index: 89;
  margin-top: 2px;
  max-width: 200px;
}
.f-dropdown.open {
  display: block;
}
.f-dropdown > *:first-child {
  margin-top: 0;
}
.f-dropdown > *:last-child {
  margin-bottom: 0;
}
.f-dropdown:before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent #ffffff transparent;
  border-bottom-style: solid;
  position: absolute;
  top: -12px;
  left: 10px;
  z-index: 89;
}
.f-dropdown:after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent #cccccc transparent;
  border-bottom-style: solid;
  position: absolute;
  top: -14px;
  left: 9px;
  z-index: 88;
}
.f-dropdown.right:before {
  left: auto;
  right: 10px;
}
.f-dropdown.right:after {
  left: auto;
  right: 9px;
}
.f-dropdown.drop-right {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #ffffff;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  width: 100%;
  z-index: 89;
  margin-top: 0;
  margin-left: 2px;
  max-width: 200px;
}
.f-dropdown.drop-right.open {
  display: block;
}
.f-dropdown.drop-right > *:first-child {
  margin-top: 0;
}
.f-dropdown.drop-right > *:last-child {
  margin-bottom: 0;
}
.f-dropdown.drop-right:before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent #ffffff transparent transparent;
  border-right-style: solid;
  position: absolute;
  top: 10px;
  left: -12px;
  z-index: 89;
}
.f-dropdown.drop-right:after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent #cccccc transparent transparent;
  border-right-style: solid;
  position: absolute;
  top: 9px;
  left: -14px;
  z-index: 88;
}
.f-dropdown.drop-left {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #ffffff;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  width: 100%;
  z-index: 89;
  margin-top: 0;
  margin-left: -2px;
  max-width: 200px;
}
.f-dropdown.drop-left.open {
  display: block;
}
.f-dropdown.drop-left > *:first-child {
  margin-top: 0;
}
.f-dropdown.drop-left > *:last-child {
  margin-bottom: 0;
}
.f-dropdown.drop-left:before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #ffffff;
  border-left-style: solid;
  position: absolute;
  top: 10px;
  right: -12px;
  left: auto;
  z-index: 89;
}
.f-dropdown.drop-left:after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: transparent transparent transparent #cccccc;
  border-left-style: solid;
  position: absolute;
  top: 9px;
  right: -14px;
  left: auto;
  z-index: 88;
}
.f-dropdown.drop-top {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #ffffff;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  width: 100%;
  z-index: 89;
  margin-left: 0;
  margin-top: -2px;
  max-width: 200px;
}
.f-dropdown.drop-top.open {
  display: block;
}
.f-dropdown.drop-top > *:first-child {
  margin-top: 0;
}
.f-dropdown.drop-top > *:last-child {
  margin-bottom: 0;
}
.f-dropdown.drop-top:before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: #ffffff transparent transparent transparent;
  border-top-style: solid;
  bottom: -12px;
  position: absolute;
  top: auto;
  left: 10px;
  right: auto;
  z-index: 89;
}
.f-dropdown.drop-top:after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: #cccccc transparent transparent transparent;
  border-top-style: solid;
  bottom: -14px;
  position: absolute;
  top: auto;
  left: 9px;
  right: auto;
  z-index: 88;
}
.f-dropdown li {
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
}
.f-dropdown li:hover, .f-dropdown li:focus {
  background: #EEEEEE;
}
.f-dropdown li a {
  display: block;
  padding: 0.5rem;
  color: #555555;
}
.f-dropdown.content {
  display: none;
  left: -9999px;
  list-style: none;
  margin-left: 0;
  position: absolute;
  background: #ffffff;
  border: solid 1px #cccccc;
  font-size: 0.875rem;
  height: auto;
  max-height: none;
  padding: 1.25rem;
  width: 100%;
  z-index: 89;
  max-width: 200px;
}
.f-dropdown.content.open {
  display: block;
}
.f-dropdown.content > *:first-child {
  margin-top: 0;
}
.f-dropdown.content > *:last-child {
  margin-bottom: 0;
}
.f-dropdown.radius {
  border-radius: 3px;
}
.f-dropdown.tiny {
  max-width: 200px;
}
.f-dropdown.small {
  max-width: 300px;
}
.f-dropdown.medium {
  max-width: 500px;
}
.f-dropdown.large {
  max-width: 800px;
}
.f-dropdown.mega {
  width: 100% !important;
  max-width: 100% !important;
}
.f-dropdown.mega.open {
  left: 0 !important;
}

/* Flex Mixin */
.dropdown.button, button.dropdown {
  position: relative;
  padding-right: 3.5625rem;
}
.dropdown.button::after, button.dropdown::after {
  border-color: #ffffff transparent transparent transparent;
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  position: absolute;
  top: 50%;
  width: 0;
}
.dropdown.button::after, button.dropdown::after {
  border-width: 0.375rem;
  right: 1.40625rem;
  margin-top: -0.15625rem;
}
.dropdown.button::after, button.dropdown::after {
  border-color: #ffffff transparent transparent transparent;
}
.dropdown.button.tiny, button.dropdown.tiny {
  padding-right: 2.625rem;
}
.dropdown.button.tiny:after, button.dropdown.tiny:after {
  border-width: 0.375rem;
  right: 1.125rem;
  margin-top: -0.125rem;
}
.dropdown.button.tiny::after, button.dropdown.tiny::after {
  border-color: #ffffff transparent transparent transparent;
}
.dropdown.button.small, button.dropdown.small {
  padding-right: 3.0625rem;
}
.dropdown.button.small::after, button.dropdown.small::after {
  border-width: 0.4375rem;
  right: 1.3125rem;
  margin-top: -0.15625rem;
}
.dropdown.button.small::after, button.dropdown.small::after {
  border-color: #ffffff transparent transparent transparent;
}
.dropdown.button.large, button.dropdown.large {
  padding-right: 3.625rem;
}
.dropdown.button.large::after, button.dropdown.large::after {
  border-width: 0.3125rem;
  right: 1.71875rem;
  margin-top: -0.15625rem;
}
.dropdown.button.large::after, button.dropdown.large::after {
  border-color: #ffffff transparent transparent transparent;
}
.dropdown.button.secondary:after, button.dropdown.secondary:after {
  border-color: #333333 transparent transparent transparent;
}

/* Flex Mixin */
/* Flex Mixin */
/* Standard Forms */
form {
  margin: 0 0 1rem;
}

/* Using forms within rows, we need to set some defaults */
form .row .row {
  margin: 0 -0.5rem;
}
form .row .row .column,
form .row .row .columns {
  padding: 0 0.5rem;
}
form .row .row.collapse {
  margin: 0;
}
form .row .row.collapse .column,
form .row .row.collapse .columns {
  padding: 0;
}
form .row .row.collapse input {
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
form .row input.column,
form .row input.columns,
form .row textarea.column,
form .row textarea.columns {
  padding-left: 0.5rem;
}

/* Label Styles */
label {
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  /* Styles for required inputs */
}
label.right {
  float: none !important;
  text-align: right;
}
label.inline {
  margin: 0 0 1rem 0;
  padding: 0.5625rem 0;
}
label small {
  text-transform: capitalize;
  color: #676767;
}

/* Attach elements to the beginning or end of an input */
.prefix,
.postfix {
  border-style: solid;
  border-width: 1px;
  display: block;
  font-size: 0.875rem;
  height: 2.3125rem;
  line-height: 2.3125rem;
  overflow: visible;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;
}

/* Adjust padding, alignment and radius if pre/post element is a button */
.postfix.button {
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
}

.prefix.button {
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
  text-align: center;
}

.prefix.button.radius {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.postfix.button.radius {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.prefix.button.round {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}

.postfix.button.round {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

/* Separate prefix and postfix styles when on span or label so buttons keep their own */
span.prefix, label.prefix {
  background: #f2f2f2;
  border-right: none;
  color: #333333;
  border-color: #cccccc;
}

span.postfix, label.postfix {
  background: #f2f2f2;
  border-left: none;
  color: #333333;
  border-color: #cccccc;
}

/* We use this to get basic styling on all basic form elements */
input:not([type]), input[type=text], input[type=password], input[type=date], input[type=datetime], input[type=datetime-local], input[type=month], input[type=week], input[type=email], input[type=number], input[type=search], input[type=tel], input[type=time], input[type=url], input[type=color], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  height: 2.3125rem;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border-color 0.15s linear, background 0.15s linear;
  -moz-transition: border-color 0.15s linear, background 0.15s linear;
  -ms-transition: border-color 0.15s linear, background 0.15s linear;
  -o-transition: border-color 0.15s linear, background 0.15s linear;
  transition: border-color 0.15s linear, background 0.15s linear;
}
input:not([type]):focus, input[type=text]:focus, input[type=password]:focus, input[type=date]:focus, input[type=datetime]:focus, input[type=datetime-local]:focus, input[type=month]:focus, input[type=week]:focus, input[type=email]:focus, input[type=number]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=time]:focus, input[type=url]:focus, input[type=color]:focus, textarea:focus {
  background: #fafafa;
  border-color: #999999;
  outline: none;
}
input:not([type]):disabled, input[type=text]:disabled, input[type=password]:disabled, input[type=date]:disabled, input[type=datetime]:disabled, input[type=datetime-local]:disabled, input[type=month]:disabled, input[type=week]:disabled, input[type=email]:disabled, input[type=number]:disabled, input[type=search]:disabled, input[type=tel]:disabled, input[type=time]:disabled, input[type=url]:disabled, input[type=color]:disabled, textarea:disabled {
  background-color: #DDDDDD;
  cursor: default;
}
input:not([type])[disabled], input:not([type])[readonly], fieldset[disabled] input:not([type]), input[type=text][disabled], input[type=text][readonly], fieldset[disabled] input[type=text], input[type=password][disabled], input[type=password][readonly], fieldset[disabled] input[type=password], input[type=date][disabled], input[type=date][readonly], fieldset[disabled] input[type=date], input[type=datetime][disabled], input[type=datetime][readonly], fieldset[disabled] input[type=datetime], input[type=datetime-local][disabled], input[type=datetime-local][readonly], fieldset[disabled] input[type=datetime-local], input[type=month][disabled], input[type=month][readonly], fieldset[disabled] input[type=month], input[type=week][disabled], input[type=week][readonly], fieldset[disabled] input[type=week], input[type=email][disabled], input[type=email][readonly], fieldset[disabled] input[type=email], input[type=number][disabled], input[type=number][readonly], fieldset[disabled] input[type=number], input[type=search][disabled], input[type=search][readonly], fieldset[disabled] input[type=search], input[type=tel][disabled], input[type=tel][readonly], fieldset[disabled] input[type=tel], input[type=time][disabled], input[type=time][readonly], fieldset[disabled] input[type=time], input[type=url][disabled], input[type=url][readonly], fieldset[disabled] input[type=url], input[type=color][disabled], input[type=color][readonly], fieldset[disabled] input[type=color], textarea[disabled], textarea[readonly], fieldset[disabled] textarea {
  background-color: #DDDDDD;
  cursor: default;
}
input:not([type]).radius, input[type=text].radius, input[type=password].radius, input[type=date].radius, input[type=datetime].radius, input[type=datetime-local].radius, input[type=month].radius, input[type=week].radius, input[type=email].radius, input[type=number].radius, input[type=search].radius, input[type=tel].radius, input[type=time].radius, input[type=url].radius, input[type=color].radius, textarea.radius {
  border-radius: 3px;
}

form .row .prefix-radius.row.collapse input,
form .row .prefix-radius.row.collapse textarea,
form .row .prefix-radius.row.collapse select,
form .row .prefix-radius.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
form .row .prefix-radius.row.collapse .prefix {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
form .row .postfix-radius.row.collapse input,
form .row .postfix-radius.row.collapse textarea,
form .row .postfix-radius.row.collapse select,
form .row .postfix-radius.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
form .row .postfix-radius.row.collapse .postfix {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
form .row .prefix-round.row.collapse input,
form .row .prefix-round.row.collapse textarea,
form .row .prefix-round.row.collapse select,
form .row .prefix-round.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}
form .row .prefix-round.row.collapse .prefix {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}
form .row .postfix-round.row.collapse input,
form .row .postfix-round.row.collapse textarea,
form .row .postfix-round.row.collapse select,
form .row .postfix-round.row.collapse button {
  border-radius: 0;
  -webkit-border-bottom-left-radius: 1000px;
  -webkit-border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
  border-top-left-radius: 1000px;
}
form .row .postfix-round.row.collapse .postfix {
  border-radius: 0;
  -webkit-border-bottom-right-radius: 1000px;
  -webkit-border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
  border-top-right-radius: 1000px;
}

input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
}

/* Respect enforced amount of rows for textarea */
textarea[rows] {
  height: auto;
}

/* Not allow resize out of parent */
textarea {
  max-width: 100%;
}

::-webkit-input-placeholder {
  color: #666666;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
}

:-ms-input-placeholder {
  color: #666666;
}

/* Add height value for select elements to match text input height */
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: #FAFAFA;
  border-radius: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+");
  background-position: 100% center;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  color: rgba(0, 0, 0, 0.75);
  font-family: inherit;
  font-size: 0.875rem;
  line-height: normal;
  padding: 0.5rem;
  border-radius: 0;
  height: 2.3125rem;
}
select::-ms-expand {
  display: none;
}
select.radius {
  border-radius: 3px;
}
select:focus {
  background-color: #f3f3f3;
  border-color: #999999;
}
select:disabled {
  background-color: #DDDDDD;
  cursor: default;
}
select[multiple] {
  height: auto;
}

/* Adjust margin for form elements below */
input[type=file],
input[type=checkbox],
input[type=radio],
select {
  margin: 0 0 1rem 0;
}

input[type=checkbox] + label,
input[type=radio] + label {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: baseline;
}

/* Normalize file input width */
input[type=file] {
  width: 100%;
}

/* HTML5 Number spinners settings */
/* We add basic fieldset styling */
fieldset {
  border: 1px solid #DDDDDD;
  margin: 1.125rem 0;
  padding: 1.25rem;
}
fieldset legend {
  font-weight: 700;
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
}

/* Error Handling */
[data-abide] .error small.error, [data-abide] .error span.error, [data-abide] span.error, [data-abide] small.error {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
  background: #f04124;
  color: #ffffff;
}
[data-abide] span.error, [data-abide] small.error {
  display: none;
}

span.error, small.error {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
  background: #f04124;
  color: #ffffff;
}

.error input,
.error textarea,
.error select {
  margin-bottom: 0;
}
.error input[type=checkbox],
.error input[type=radio] {
  margin-bottom: 1rem;
}
.error label,
.error label.error {
  color: #f04124;
}
.error small.error {
  display: block;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 1rem;
  margin-top: -1px;
  padding: 0.375rem 0.5625rem 0.5625rem;
  background: #f04124;
  color: #ffffff;
}
.error > label > small {
  background: transparent;
  color: #676767;
  display: inline;
  font-size: 60%;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
}
.error span.error-message {
  display: block;
}

input.error,
textarea.error,
select.error {
  margin-bottom: 0;
}

label.error {
  color: #f04124;
}

/* Flex Mixin */
.inline-list {
  list-style: none;
  margin-top: 0;
  margin-bottom: 1.0625rem;
  margin-left: -1.375rem;
  margin-right: 0;
  overflow: hidden;
  padding: 0;
}
.inline-list > li {
  display: block;
  float: left;
  list-style: none;
  margin-left: 1.375rem;
}
.inline-list > li > * {
  display: block;
}

/* Flex Mixin */
ul.pagination {
  display: block;
  margin-left: -0.3125rem;
  min-height: 1.5rem;
}
ul.pagination li {
  color: #222222;
  font-size: 0.875rem;
  height: 1.5rem;
  margin-left: 0.3125rem;
}
ul.pagination li a, ul.pagination li button {
  border-radius: 3px;
  transition: background-color 300ms ease-out;
  background: none;
  color: #999999;
  display: block;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  padding: 0.0625rem 0.625rem 0.0625rem;
}
ul.pagination li:hover a,
ul.pagination li a:focus, ul.pagination li:hover button,
ul.pagination li button:focus {
  background: #e6e6e6;
}
ul.pagination li.unavailable a, ul.pagination li.unavailable button {
  cursor: default;
  color: #999999;
  pointer-events: none;
}
ul.pagination li.unavailable:hover a, ul.pagination li.unavailable a:focus, ul.pagination li.unavailable:hover button, ul.pagination li.unavailable button:focus {
  background: transparent;
}
ul.pagination li.current a, ul.pagination li.current button {
  background: #cd9834;
  color: #ffffff;
  cursor: default;
  font-weight: 700;
}
ul.pagination li.current a:hover, ul.pagination li.current a:focus, ul.pagination li.current button:hover, ul.pagination li.current button:focus {
  background: #cd9834;
}
ul.pagination li {
  display: block;
  float: left;
}

/* Pagination centred wrapper */
.pagination-centered {
  text-align: center;
}
.pagination-centered ul.pagination li {
  display: inline-block;
  float: none;
}

/* Flex Mixin */
.range-slider {
  border: 1px solid #DDDDDD;
  margin: 1.25rem 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  display: block;
  height: 1rem;
  width: 100%;
  background: #FAFAFA;
}
.range-slider.vertical-range {
  border: 1px solid #DDDDDD;
  margin: 1.25rem 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  display: inline-block;
  height: 12.5rem;
  width: 1rem;
}
.range-slider.vertical-range .range-slider-handle {
  bottom: -10.5rem;
  margin-left: -0.5rem;
  margin-top: 0;
  position: absolute;
}
.range-slider.vertical-range .range-slider-active-segment {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top-left-radius: initial;
  bottom: 0;
  height: auto;
  width: 0.875rem;
}
.range-slider.radius {
  background: #FAFAFA;
  border-radius: 3px;
}
.range-slider.radius .range-slider-handle {
  background: #cd9834;
  border-radius: 3px;
}
.range-slider.radius .range-slider-handle:hover {
  background: #b5862d;
}
.range-slider.round {
  background: #FAFAFA;
  border-radius: 1000px;
}
.range-slider.round .range-slider-handle {
  background: #cd9834;
  border-radius: 1000px;
}
.range-slider.round .range-slider-handle:hover {
  background: #b5862d;
}
.range-slider.disabled, .range-slider[disabled] {
  background: #FAFAFA;
  cursor: not-allowed;
  opacity: 0.7;
}
.range-slider.disabled .range-slider-handle, .range-slider[disabled] .range-slider-handle {
  background: #cd9834;
  cursor: default;
  opacity: 0.7;
}
.range-slider.disabled .range-slider-handle:hover, .range-slider[disabled] .range-slider-handle:hover {
  background: #b5862d;
}

.range-slider-active-segment {
  background: #e5e5e5;
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
  display: inline-block;
  height: 0.875rem;
  position: absolute;
}

.range-slider-handle {
  border: 1px solid none;
  cursor: pointer;
  display: inline-block;
  height: 1.375rem;
  position: absolute;
  top: -0.3125rem;
  width: 2rem;
  z-index: 1;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #cd9834;
}
.range-slider-handle:hover {
  background: #b5862d;
}

/* Flex Mixin */
/* Flex Mixin */
.reveal-modal-bg {
  background: #000000;
  background: rgba(0, 0, 0, 0.45);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1004;
  left: 0;
}

.reveal-modal {
  border-radius: 3px;
  display: none;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1005;
  left: 0;
  background-color: #ffffff;
  padding: 1.875rem;
  border: solid 1px #666666;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
@media only screen and (max-width: 419px) {
  .reveal-modal {
    min-height: 100vh;
  }
}
.reveal-modal .column, .reveal-modal .columns {
  min-width: 0;
}
.reveal-modal > :first-child {
  margin-top: 0;
}
.reveal-modal > :last-child {
  margin-bottom: 0;
}
@media print, only screen and (min-width:420px) {
  .reveal-modal {
    left: 0;
    margin: 0 auto;
    max-width: 156.25rem;
    right: 0;
    width: 80%;
  }
}
@media print, only screen and (min-width:420px) {
  .reveal-modal {
    top: 6.25rem;
  }
}
.reveal-modal.radius {
  box-shadow: none;
  border-radius: 3px;
}
.reveal-modal.round {
  box-shadow: none;
  border-radius: 1000px;
}
.reveal-modal.collapse {
  padding: 0;
  box-shadow: none;
}
@media print, only screen and (min-width:420px) {
  .reveal-modal.tiny {
    left: 0;
    margin: 0 auto;
    max-width: 156.25rem;
    right: 0;
    width: 30%;
  }
}
@media print, only screen and (min-width:420px) {
  .reveal-modal.small {
    left: 0;
    margin: 0 auto;
    max-width: 156.25rem;
    right: 0;
    width: 40%;
  }
}
@media print, only screen and (min-width:420px) {
  .reveal-modal.medium {
    left: 0;
    margin: 0 auto;
    max-width: 156.25rem;
    right: 0;
    width: 60%;
  }
}
@media print, only screen and (min-width:420px) {
  .reveal-modal.large {
    left: 0;
    margin: 0 auto;
    max-width: 156.25rem;
    right: 0;
    width: 70%;
  }
}
@media print, only screen and (min-width:420px) {
  .reveal-modal.xlarge {
    left: 0;
    margin: 0 auto;
    max-width: 156.25rem;
    right: 0;
    width: 95%;
  }
}
.reveal-modal.full {
  height: 100vh;
  height: 100%;
  left: 0;
  margin-left: 0 !important;
  max-width: none !important;
  min-height: 100vh;
  top: 0;
}
@media print, only screen and (min-width:420px) {
  .reveal-modal.full {
    left: 0;
    margin: 0 auto;
    max-width: 156.25rem;
    right: 0;
    width: 100%;
  }
}
.reveal-modal.toback {
  z-index: 1003;
}
.reveal-modal .close-reveal-modal {
  color: #AAAAAA;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  top: 0.625rem;
  right: 1.375rem;
}

/* Flex Mixin */
table {
  background: #ffffff;
  border: solid 1px #ffffff;
  margin-bottom: 1.25rem;
  table-layout: auto;
}
table caption {
  background: transparent;
  color: #222222;
  font-size: 1rem;
  font-weight: bold;
}
table thead {
  background: #F5F5F5;
}
table thead tr th,
table thead tr td {
  color: #222222;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.5rem 0.625rem 0.625rem;
}
table tfoot {
  background: #F5F5F5;
}
table tfoot tr th,
table tfoot tr td {
  color: #222222;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.5rem 0.625rem 0.625rem;
}
table tr th,
table tr td {
  color: #222222;
  font-size: 0.875rem;
  padding: 0.5625rem 0.625rem;
  text-align: left;
}
table tr.even, table tr.alt, table tr:nth-of-type(even) {
  background: #F9F9F9;
}
table thead tr th,
table tfoot tr th,
table tfoot tr td,
table tbody tr th,
table tbody tr td,
table tr td {
  display: table-cell;
  line-height: 1.125rem;
}

/* Flex Mixin */
/* Flex Mixin */
.tabs {
  margin-bottom: 0 !important;
  margin-left: 0;
}
.tabs:before, .tabs:after {
  content: " ";
  display: table;
}
.tabs:after {
  clear: both;
}
.tabs dd,
.tabs .tab-title {
  float: left;
  list-style: none;
  margin-bottom: 0 !important;
  position: relative;
}
.tabs dd > a,
.tabs .tab-title > a {
  display: block;
  background-color: #EFEFEF;
  color: #222222;
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-size: 1rem;
  padding: 1rem 2rem;
}
.tabs dd > a:hover,
.tabs .tab-title > a:hover {
  background-color: #e1e1e1;
}
.tabs dd.active > a,
.tabs .tab-title.active > a {
  background-color: #ffffff;
  color: #222222;
}
.tabs.radius dd:first-child a,
.tabs.radius .tab:first-child a {
  -webkit-border-bottom-left-radius: 3px;
  -webkit-border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.tabs.radius dd:last-child a,
.tabs.radius .tab:last-child a {
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.tabs.vertical dd,
.tabs.vertical .tab-title {
  position: inherit;
  float: none;
  display: block;
  top: auto;
}

.tabs-content {
  margin-bottom: 1.5rem;
  width: 100%;
}
.tabs-content:before, .tabs-content:after {
  content: " ";
  display: table;
}
.tabs-content:after {
  clear: both;
}
.tabs-content > .content {
  display: none;
  float: left;
  padding: 2.5vw 0;
  width: 100%;
}
.tabs-content > .content.active {
  display: block;
  float: none;
}
.tabs-content > .content.contained {
  padding: 2.5vw;
}
.tabs-content.vertical {
  display: block;
}
.tabs-content.vertical > .content {
  padding: 0 2.5vw;
}

@media print, only screen and (min-width:420px) {
  .tabs.vertical {
    float: left;
    margin: 0;
    margin-bottom: 1.25rem !important;
    max-width: 20%;
    width: 20%;
  }

  .tabs-content.vertical {
    float: left;
    margin-left: -1px;
    max-width: 80%;
    padding-left: 1rem;
    width: 80%;
  }
}
.no-js .tabs-content > .content {
  display: block;
  float: none;
}

/* Flex Mixin */
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

@media only screen and (max-width: 419px) {
  .small-only-text-left {
    text-align: left !important;
  }

  .small-only-text-right {
    text-align: right !important;
  }

  .small-only-text-center {
    text-align: center !important;
  }

  .small-only-text-justify {
    text-align: justify !important;
  }
}
@media only screen {
  .small-text-left {
    text-align: left !important;
  }

  .small-text-right {
    text-align: right !important;
  }

  .small-text-center {
    text-align: center !important;
  }

  .small-text-justify {
    text-align: justify !important;
  }
}
@media print, only screen and (min-width:420px) and (max-width:599px) {
  .medium-only-text-left {
    text-align: left !important;
  }

  .medium-only-text-right {
    text-align: right !important;
  }

  .medium-only-text-center {
    text-align: center !important;
  }

  .medium-only-text-justify {
    text-align: justify !important;
  }
}
@media print, only screen and (min-width:420px) {
  .medium-text-left {
    text-align: left !important;
  }

  .medium-text-right {
    text-align: right !important;
  }

  .medium-text-center {
    text-align: center !important;
  }

  .medium-text-justify {
    text-align: justify !important;
  }
}
@media print, only screen and (min-width:600px) and (max-width:959px) {
  .large-only-text-left {
    text-align: left !important;
  }

  .large-only-text-right {
    text-align: right !important;
  }

  .large-only-text-center {
    text-align: center !important;
  }

  .large-only-text-justify {
    text-align: justify !important;
  }
}
@media print, only screen and (min-width:600px) {
  .large-text-left {
    text-align: left !important;
  }

  .large-text-right {
    text-align: right !important;
  }

  .large-text-center {
    text-align: center !important;
  }

  .large-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:960px) and (max-width:1499px) {
  .xlarge-only-text-left {
    text-align: left !important;
  }

  .xlarge-only-text-right {
    text-align: right !important;
  }

  .xlarge-only-text-center {
    text-align: center !important;
  }

  .xlarge-only-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:960px) {
  .xlarge-text-left {
    text-align: left !important;
  }

  .xlarge-text-right {
    text-align: right !important;
  }

  .xlarge-text-center {
    text-align: center !important;
  }

  .xlarge-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:1500px) and (max-width:99999999px) {
  .xxlarge-only-text-left {
    text-align: left !important;
  }

  .xxlarge-only-text-right {
    text-align: right !important;
  }

  .xxlarge-only-text-center {
    text-align: center !important;
  }

  .xxlarge-only-text-justify {
    text-align: justify !important;
  }
}
@media only screen and (min-width:1500px) {
  .xxlarge-text-left {
    text-align: left !important;
  }

  .xxlarge-text-right {
    text-align: right !important;
  }

  .xxlarge-text-center {
    text-align: center !important;
  }

  .xxlarge-text-justify {
    text-align: justify !important;
  }
}
/* Typography resets */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

/* Default Link Styles */
a {
  color: #cd9834;
  line-height: inherit;
  text-decoration: none;
}
a:hover, a:focus {
  color: #b1832c;
}
a img {
  border: none;
}

/* Default paragraph styles */
p {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility;
}
p.lead {
  font-size: 1.21875rem;
  line-height: 1.6;
}
p aside {
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.35;
}

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
  color: #222222;
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  text-rendering: optimizeLegibility;
}
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  color: #6f6f6f;
  font-size: 60%;
  line-height: 0;
}

h1 {
  font-size: 2.125rem;
}

h2 {
  font-size: 1.6875rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

.subheader {
  line-height: 1.4;
  color: #6f6f6f;
  font-weight: 400;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

hr {
  border: solid #DDDDDD;
  border-width: 1px 0 0;
  clear: both;
  height: 0;
  margin: 1.25rem 0 1.1875rem;
}

/* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: 700;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

code {
  background-color: #f8f8f8;
  border-color: #dfdfdf;
  border-style: solid;
  border-width: 1px;
  color: #333333;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 400;
  padding: 0.125rem 0.3125rem 0.0625rem;
}

/* Lists */
ul,
ol,
dl {
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.6;
  list-style-position: outside;
  margin-bottom: 1.25rem;
}

ul {
  margin-left: 1.1rem;
}

/* Unordered Lists */
ul li ul,
ul li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}
ul.square li ul, ul.circle li ul, ul.disc li ul {
  list-style: inherit;
}
ul.square {
  list-style-type: square;
  margin-left: 1.1rem;
}
ul.circle {
  list-style-type: circle;
  margin-left: 1.1rem;
}
ul.disc {
  list-style-type: disc;
  margin-left: 1.1rem;
}

/* Ordered Lists */
ol {
  margin-left: 1.4rem;
}
ol li ul,
ol li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

.no-bullet {
  list-style-type: none;
  margin-left: 0;
}
.no-bullet li ul,
.no-bullet li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
  list-style: none;
}

/* Definition Lists */
dl dt {
  margin-bottom: 0.3rem;
  font-weight: 700;
}
dl dd {
  margin-bottom: 0.75rem;
}

/* Abbreviations */
abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #262626;
  cursor: help;
}

abbr {
  text-transform: none;
}
abbr[title] {
  border-bottom: 1px dotted #DDDDDD;
}

/* Blockquotes */
blockquote {
  margin: 0 0 1.25rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #DDDDDD;
}
blockquote cite {
  display: block;
  font-size: 0.8125rem;
  color: #555555;
}
blockquote cite:before {
  content: "— ";
}
blockquote cite a,
blockquote cite a:visited {
  color: #555555;
}

blockquote,
blockquote p {
  line-height: 1.6;
  color: #6f6f6f;
}

/* Microformats */
.vcard {
  display: inline-block;
  margin: 0 0 1.25rem 0;
  border: 1px solid #DDDDDD;
  padding: 0.625rem 0.75rem;
}
.vcard li {
  margin: 0;
  display: block;
}
.vcard .fn {
  font-weight: 700;
  font-size: 0.9375rem;
}

.vevent .summary {
  font-weight: 700;
}
.vevent abbr {
  cursor: default;
  text-decoration: none;
  font-weight: 700;
  border: none;
  padding: 0 0.0625rem;
}

@media print, only screen and (min-width:420px) {
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.4;
  }

  h1 {
    font-size: 2.75rem;
  }

  h2 {
    font-size: 2.3125rem;
  }

  h3 {
    font-size: 1.6875rem;
  }

  h4 {
    font-size: 1.4375rem;
  }

  h5 {
    font-size: 1.125rem;
  }

  h6 {
    font-size: 1rem;
  }
}
/* Flex Mixin */
/* Flex Mixin */
.off-canvas-wrap {
  -webkit-backface-visibility: hidden;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.off-canvas-wrap.move-right, .off-canvas-wrap.move-left, .off-canvas-wrap.move-bottom, .off-canvas-wrap.move-top {
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
}

.inner-wrap {
  position: relative;
  width: 100%;
  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: -moz-transform 500ms ease;
  -ms-transition: -ms-transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease;
}
.inner-wrap:before, .inner-wrap:after {
  content: " ";
  display: table;
}
.inner-wrap:after {
  clear: both;
}

.tab-bar {
  -webkit-backface-visibility: hidden;
  background: #333333;
  color: #ffffff;
  height: 2.8125rem;
  line-height: 2.8125rem;
  position: relative;
}
.tab-bar h1, .tab-bar h2, .tab-bar h3, .tab-bar h4, .tab-bar h5, .tab-bar h6 {
  color: #ffffff;
  font-weight: 700;
  line-height: 2.8125rem;
  margin: 0;
}
.tab-bar h1, .tab-bar h2, .tab-bar h3, .tab-bar h4 {
  font-size: 1.125rem;
}

.left-small {
  height: 2.8125rem;
  position: absolute;
  top: 0;
  width: 2.8125rem;
  border-right: solid 1px #1a1a1a;
  left: 0;
}

.right-small {
  height: 2.8125rem;
  position: absolute;
  top: 0;
  width: 2.8125rem;
  border-left: solid 1px #1a1a1a;
  right: 0;
}

.tab-bar-section {
  height: 2.8125rem;
  padding: 0 0.625rem;
  position: absolute;
  text-align: center;
  top: 0;
}
.tab-bar-section.left {
  text-align: left;
}
.tab-bar-section.right {
  text-align: right;
}
.tab-bar-section.left {
  left: 0;
  right: 2.8125rem;
}
.tab-bar-section.right {
  left: 2.8125rem;
  right: 0;
}
.tab-bar-section.middle {
  left: 2.8125rem;
  right: 2.8125rem;
}

.tab-bar .menu-icon {
  color: #ffffff;
  display: block;
  height: 2.8125rem;
  padding: 0;
  position: relative;
  text-indent: 2.1875rem;
  transform: translate3d(0, 0, 0);
  width: 2.8125rem;
}
.tab-bar .menu-icon span::after {
  content: "";
  display: block;
  height: 0;
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  left: 0.90625rem;
  box-shadow: 0 0 0 1px #ffffff, 0 7px 0 1px #ffffff, 0 14px 0 1px #ffffff;
  width: 1rem;
}
.tab-bar .menu-icon span:hover:after {
  box-shadow: 0 0 0 1px #b3b3b3, 0 7px 0 1px #b3b3b3, 0 14px 0 1px #b3b3b3;
}

.left-off-canvas-menu {
  -webkit-backface-visibility: hidden;
  background: #333333;
  bottom: 0;
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  transition: transform 500ms ease 0s;
  width: 15.625rem;
  z-index: 1001;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
  -o-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  left: 0;
  top: 0;
}
.left-off-canvas-menu * {
  -webkit-backface-visibility: hidden;
}

.right-off-canvas-menu {
  -webkit-backface-visibility: hidden;
  background: #333333;
  bottom: 0;
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  transition: transform 500ms ease 0s;
  width: 15.625rem;
  z-index: 1001;
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  right: 0;
  top: 0;
}
.right-off-canvas-menu * {
  -webkit-backface-visibility: hidden;
}

.top-off-canvas-menu {
  -webkit-backface-visibility: hidden;
  background: #333333;
  bottom: 0;
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  transition: transform 500ms ease 0s;
  width: 15.625rem;
  z-index: 1001;
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  -ms-transform: translate(0, -100%);
  -o-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  top: 0;
  width: 100%;
  height: 18.75rem;
}
.top-off-canvas-menu * {
  -webkit-backface-visibility: hidden;
}

.bottom-off-canvas-menu {
  -webkit-backface-visibility: hidden;
  background: #333333;
  bottom: 0;
  box-sizing: content-box;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  transition: transform 500ms ease 0s;
  width: 15.625rem;
  z-index: 1001;
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -ms-transform: translate(0, 100%);
  -o-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  bottom: 0;
  width: 100%;
  height: 18.75rem;
}
.bottom-off-canvas-menu * {
  -webkit-backface-visibility: hidden;
}

ul.off-canvas-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul.off-canvas-list li label {
  background: #444444;
  border-bottom: none;
  border-top: 1px solid #5e5e5e;
  color: #999999;
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0;
  padding: 0.3rem 0.9375rem;
  text-transform: uppercase;
}
ul.off-canvas-list li a {
  border-bottom: 1px solid #262626;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  padding: 0.6666666667rem;
  transition: background 300ms ease;
}
ul.off-canvas-list li a:hover {
  background: #242424;
}
ul.off-canvas-list li a:active {
  background: #242424;
}

.move-right > .inner-wrap {
  -webkit-transform: translate3d(15.625rem, 0, 0);
  -moz-transform: translate3d(15.625rem, 0, 0);
  -ms-transform: translate(15.625rem, 0);
  -o-transform: translate3d(15.625rem, 0, 0);
  transform: translate3d(15.625rem, 0, 0);
}
.move-right .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 300ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
@media print, only screen and (min-width:420px) {
  .move-right .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.move-left > .inner-wrap {
  -webkit-transform: translate3d(-15.625rem, 0, 0);
  -moz-transform: translate3d(-15.625rem, 0, 0);
  -ms-transform: translate(-15.625rem, 0);
  -o-transform: translate3d(-15.625rem, 0, 0);
  transform: translate3d(-15.625rem, 0, 0);
}
.move-left .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 300ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
@media print, only screen and (min-width:420px) {
  .move-left .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.move-top > .inner-wrap {
  -webkit-transform: translate3d(0, -18.75rem, 0);
  -moz-transform: translate3d(0, -18.75rem, 0);
  -ms-transform: translate(0, -18.75rem);
  -o-transform: translate3d(0, -18.75rem, 0);
  transform: translate3d(0, -18.75rem, 0);
}
.move-top .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 300ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
@media print, only screen and (min-width:420px) {
  .move-top .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.move-bottom > .inner-wrap {
  -webkit-transform: translate3d(0, 18.75rem, 0);
  -moz-transform: translate3d(0, 18.75rem, 0);
  -ms-transform: translate(0, 18.75rem);
  -o-transform: translate3d(0, 18.75rem, 0);
  transform: translate3d(0, 18.75rem, 0);
}
.move-bottom .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 300ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
@media print, only screen and (min-width:420px) {
  .move-bottom .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.offcanvas-overlap .left-off-canvas-menu, .offcanvas-overlap .right-off-canvas-menu,
.offcanvas-overlap .top-off-canvas-menu, .offcanvas-overlap .bottom-off-canvas-menu {
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
  z-index: 1003;
}
.offcanvas-overlap .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 300ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
@media print, only screen and (min-width:420px) {
  .offcanvas-overlap .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.offcanvas-overlap-left .right-off-canvas-menu {
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
  z-index: 1003;
}
.offcanvas-overlap-left .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 300ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
@media print, only screen and (min-width:420px) {
  .offcanvas-overlap-left .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.offcanvas-overlap-right .left-off-canvas-menu {
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
  z-index: 1003;
}
.offcanvas-overlap-right .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 300ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
@media print, only screen and (min-width:420px) {
  .offcanvas-overlap-right .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.offcanvas-overlap-top .bottom-off-canvas-menu {
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
  z-index: 1003;
}
.offcanvas-overlap-top .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 300ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
@media print, only screen and (min-width:420px) {
  .offcanvas-overlap-top .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.offcanvas-overlap-bottom .top-off-canvas-menu {
  -ms-transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
  z-index: 1003;
}
.offcanvas-overlap-bottom .exit-off-canvas {
  -webkit-backface-visibility: hidden;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.5), 4px 0 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: background 300ms ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.2);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
@media print, only screen and (min-width:420px) {
  .offcanvas-overlap-bottom .exit-off-canvas:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.no-csstransforms .left-off-canvas-menu {
  left: -15.625rem;
}
.no-csstransforms .right-off-canvas-menu {
  right: -15.625rem;
}
.no-csstransforms .top-off-canvas-menu {
  top: -18.75rem;
}
.no-csstransforms .bottom-off-canvas-menu {
  bottom: -18.75rem;
}
.no-csstransforms .move-left > .inner-wrap {
  right: 15.625rem;
}
.no-csstransforms .move-right > .inner-wrap {
  left: 15.625rem;
}
.no-csstransforms .move-top > .inner-wrap {
  right: 18.75rem;
}
.no-csstransforms .move-bottom > .inner-wrap {
  left: 18.75rem;
}

.left-submenu {
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  background: #333333;
  bottom: 0;
  box-sizing: content-box;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 15.625rem;
  height: 18.75rem;
  z-index: 1002;
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate(-100%, 0);
  -o-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  left: 0;
  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: -moz-transform 500ms ease;
  -ms-transition: -ms-transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease;
}
.left-submenu * {
  -webkit-backface-visibility: hidden;
}
.left-submenu .back > a {
  background: #444;
  border-bottom: none;
  border-top: 1px solid #5e5e5e;
  color: #999999;
  font-weight: 700;
  padding: 0.3rem 0.9375rem;
  text-transform: uppercase;
  margin: 0;
}
.left-submenu .back > a:hover {
  background: #303030;
  border-bottom: none;
  border-top: 1px solid #5e5e5e;
}
.left-submenu .back > a:before {
  content: "«";
  margin-right: 0.5rem;
  display: inline;
}
.left-submenu.move-right, .left-submenu.offcanvas-overlap-right, .left-submenu.offcanvas-overlap {
  -webkit-transform: translate3d(0%, 0, 0);
  -moz-transform: translate3d(0%, 0, 0);
  -ms-transform: translate(0%, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.right-submenu {
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  background: #333333;
  bottom: 0;
  box-sizing: content-box;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 15.625rem;
  height: 18.75rem;
  z-index: 1002;
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate(100%, 0);
  -o-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  right: 0;
  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: -moz-transform 500ms ease;
  -ms-transition: -ms-transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease;
}
.right-submenu * {
  -webkit-backface-visibility: hidden;
}
.right-submenu .back > a {
  background: #444;
  border-bottom: none;
  border-top: 1px solid #5e5e5e;
  color: #999999;
  font-weight: 700;
  padding: 0.3rem 0.9375rem;
  text-transform: uppercase;
  margin: 0;
}
.right-submenu .back > a:hover {
  background: #303030;
  border-bottom: none;
  border-top: 1px solid #5e5e5e;
}
.right-submenu .back > a:after {
  content: "»";
  margin-left: 0.5rem;
  display: inline;
}
.right-submenu.move-left, .right-submenu.offcanvas-overlap-left, .right-submenu.offcanvas-overlap {
  -webkit-transform: translate3d(0%, 0, 0);
  -moz-transform: translate3d(0%, 0, 0);
  -ms-transform: translate(0%, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.top-submenu {
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  background: #333333;
  bottom: 0;
  box-sizing: content-box;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 15.625rem;
  height: 18.75rem;
  z-index: 1002;
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  -ms-transform: translate(0, -100%);
  -o-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  top: 0;
  width: 100%;
  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: -moz-transform 500ms ease;
  -ms-transition: -ms-transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease;
}
.top-submenu * {
  -webkit-backface-visibility: hidden;
}
.top-submenu .back > a {
  background: #444;
  border-bottom: none;
  border-top: 1px solid #5e5e5e;
  color: #999999;
  font-weight: 700;
  padding: 0.3rem 0.9375rem;
  text-transform: uppercase;
  margin: 0;
}
.top-submenu .back > a:hover {
  background: #303030;
  border-bottom: none;
  border-top: 1px solid #5e5e5e;
}
.top-submenu.move-bottom, .top-submenu.offcanvas-overlap-bottom, .top-submenu.offcanvas-overlap {
  -webkit-transform: translate3d(0, 0%, 0);
  -moz-transform: translate3d(0, 0%, 0);
  -ms-transform: translate(0, 0%);
  -o-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}

.bottom-submenu {
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  background: #333333;
  bottom: 0;
  box-sizing: content-box;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 15.625rem;
  height: 18.75rem;
  z-index: 1002;
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -ms-transform: translate(0, 100%);
  -o-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  bottom: 0;
  width: 100%;
  -webkit-transition: -webkit-transform 500ms ease;
  -moz-transition: -moz-transform 500ms ease;
  -ms-transition: -ms-transform 500ms ease;
  -o-transition: -o-transform 500ms ease;
  transition: transform 500ms ease;
}
.bottom-submenu * {
  -webkit-backface-visibility: hidden;
}
.bottom-submenu .back > a {
  background: #444;
  border-bottom: none;
  border-top: 1px solid #5e5e5e;
  color: #999999;
  font-weight: 700;
  padding: 0.3rem 0.9375rem;
  text-transform: uppercase;
  margin: 0;
}
.bottom-submenu .back > a:hover {
  background: #303030;
  border-bottom: none;
  border-top: 1px solid #5e5e5e;
}
.bottom-submenu.move-top, .bottom-submenu.offcanvas-overlap-top, .bottom-submenu.offcanvas-overlap {
  -webkit-transform: translate3d(0, 0%, 0);
  -moz-transform: translate3d(0, 0%, 0);
  -ms-transform: translate(0, 0%);
  -o-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
}

.left-off-canvas-menu ul.off-canvas-list li.has-submenu > a:after {
  content: "»";
  margin-left: 0.5rem;
  display: inline;
}

.right-off-canvas-menu ul.off-canvas-list li.has-submenu > a:before {
  content: "«";
  margin-right: 0.5rem;
  display: inline;
}

/* Global.scss - For Global Application Styles and Documentation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes qFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes slide-right {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
@keyframes slide-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}
@keyframes prev {
  from {
    transform: translateX(0);
  }
  17% {
    transform: translateX(-3px);
  }
  36% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes next {
  from {
    transform: translateX(0);
  }
  17% {
    transform: translateX(3px);
  }
  36% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.25, 1.25, 1.25);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.q-sticky.bottom .sticky-child, .position-bottom-left, .position-bottom-right {
  bottom: 0;
  top: auto;
}

.q-legal-notification .q-minimize-text, .position-top-left, .position-bottom-left {
  left: 0;
  right: auto;
}

.q-legal-notification .q-content-container .silent-consent, .position-top-right, .position-bottom-right {
  left: auto;
  right: 0;
}

.q-legal-notification .q-minimize-text, .q-legal-notification .q-content-container .silent-consent, .q-sticky.top .sticky-child, .position-top-right, .position-top-left {
  bottom: auto;
  top: 0;
}

/**
 * Shorthand mixin for absolute positioning
 *
 * @param {Length} $vertical [top] - Vertical position
 * @param {Length} $horizontal [null] - Horizontal position
 */
/**
 * Vertically align content properly
 */
/*Primary Nav Link border, e.g bottom*/
/* Draw an arrow pointing straight up, down, left and right */
/* Draw a triangle pointing straight up, down, left and right */
/* Required for use within @media */
/* $medium-up etc variables are not available in _variables.scss
   hence for now, we are declaring a map as a means to acess
   the media variables dynamically.
*/
.q-responsive-image .q-round-image-responsive, .q-round-image {
  border-radius: 50%;
  margin: 0;
  padding: 0;
}

.q-nav-primary .q-loader {
  animation: rotate-360 0.75s infinite linear;
  border: 0.625rem solid rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  height: 5.625rem;
  margin: 0 auto;
  transform: translateZ(0);
  width: 5.625rem;
}
.q-nav-primary .q-loader::before {
  border-bottom: 0.625rem solid transparent;
  border-left: 0.625rem solid transparent;
  border-radius: 100% !important;
  border-right: 0.625rem solid transparent;
  border-top: 0.625rem solid #cd9834;
  box-sizing: content-box;
  content: "";
  display: block;
  height: 100% !important;
  left: -0.625rem;
  position: absolute;
  top: -0.625rem;
  width: 100% !important;
}

.q-multimedia-scroller [class*=q-video-caption],
adv-grid > adv-scrl [class*=q-video-caption] {
  display: none !important;
}

.no-animation {
  transition-duration: 0s !important;
}

.no-touch .q-button.q-list-item.q-tertiary-nav-button, .q-tertiary-nav-button.q-button.q-list-item {
  border: 0;
  border-left: 3px solid transparent;
  border-top: 1px solid #b3b3b3;
  color: #262626;
  width: 100%;
}
.q-tertiary-nav-button.q-button.q-list-item:hover, .q-tertiary-nav-button.q-button.q-list-item:focus {
  border-left-color: #cd9834;
}
.active.q-tertiary-nav-button.q-button.q-list-item {
  border-left-color: #cd9834;
  color: #262626;
}
.no-touch .q-button.q-list-item.q-tertiary-nav-button span, .q-tertiary-nav-button.q-button.q-list-item span {
  color: #262626;
}

dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}

dialog:not([open]) {
  display: none;
}

dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}

html {
  /* This fixes the grey highlight effect seen on elements when 'tapping' on
  	mobile device */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* For some Androids */
  font-size: 17px;
  height: 100%;
  min-height: 100%;
}
@media print, only screen and (min-width:600px) {
  html {
    font-size: 20px;
  }
}
@media only screen and (min-width:960px) {
  html {
    font-size: 23px;
  }
}

body {
  -ms-overflow-x: hidden;
  height: 100%;
}

p {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin-bottom: 0;
}

ul,
ol,
dl {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin-bottom: inherit;
}

.q-page-container {
  border-left: 1px solid #262626;
  border-right: 1px solid #262626;
  min-height: 100vh;
  position: relative;
  z-index: 0;
}

.grid-decoration {
  border-top: 0.5rem solid #cd9834;
}

.column.grid-column-alignment-left,
.columns.grid-column-alignment-left {
  text-align: left;
}
.column.grid-column-alignment-center,
.columns.grid-column-alignment-center {
  text-align: center;
}
.column.grid-column-alignment-right,
.columns.grid-column-alignment-right {
  text-align: right;
}

.q-nbsp::before {
  content: " ";
}

/* Makes first grid builder have a left and right margin */
.q-gridbuilder {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  /* Disabled horizontal padding with gutters on */
  /* Disabled horizontal padding with gutters off */
}
.q-gridbuilder.gridfullwidth,
.q-gridbuilder .q-gridbuilder {
  /* Makes a full bleed grid, and makes any nest grid builder not have left/right margin */
  padding-left: 0;
  padding-right: 0;
}
.q-gridbuilder .q-indent-grid-content {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}
.q-gridbuilder .gmit-gf-request-type {
  padding: 0 0 0.5rem;
}
.q-gridbuilder.grid-padding-only-horizontal > .columns {
  padding-bottom: 0;
  padding-top: 0;
}
.q-gridbuilder.grid-padding-only-vertical:not(.collapse) > .columns {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}
.q-gridbuilder.grid-padding-only-vertical.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.grid-padding-small > .columns,
.grid-padding-small > .column {
  padding: 1.25rem calc(1.25rem + 1.25rem);
  padding: 1.25rem calc(1.25rem + 2.5vw);
}

.grid-padding-medium > .columns,
.grid-padding-medium > .column {
  padding: 2.5rem calc(2.5rem + 1.25rem);
  padding: 2.5rem calc(2.5rem + 2.5vw);
}

.grid-padding-large > .columns,
.grid-padding-large > .column {
  padding: 5rem calc(5rem + 1.25rem);
  padding: 5rem calc(5rem + 2.5vw);
}

.collapse.grid-padding-small > .columns,
.collapse.grid-padding-small > .column {
  padding: 1.25rem;
}

.collapse.grid-padding-medium > .columns,
.collapse.grid-padding-medium > .column {
  padding: 2.5rem;
}

.collapse.grid-padding-large > .columns,
.collapse.grid-padding-large > .column {
  padding: 5rem;
}

.row.gridspacing-small,
.row.collapse.gridspacing-small {
  margin-bottom: 1.25rem;
}

.row.gridspacing-medium,
.row.collapse.gridspacing-medium {
  margin-bottom: 2.5rem;
}

.row.gridspacing-large,
.row.collapse.gridspacing-large {
  margin-bottom: 5rem;
}

.row.gridspacing-xlarge,
.row.collapse.gridspacing-xlarge {
  margin-bottom: 7.5rem;
}

.row {
  /* Fix for flex grid in Safari */
}
.row.gridfullwidth {
  max-width: 156.25rem;
}
.row.gridfullwidth .row {
  max-width: 156.25rem;
}
.row.grid-bg-transparent {
  background-color: transparent;
}
.row.grid-bg-color-two {
  background-color: #f2f2f2;
}
.row.grid-bg-color-three {
  background-color: #e6e6e6;
}
.row.grid-bg-color-four {
  background-color: #262626;
}
.row.grid-bg-color-five {
  background-color: #1a1a1a;
}
.row.grid-bg-color-six {
  background-color: #ffffff;
}
.row::before, .row.row::after {
  width: 0;
}

/* Alignment styles */
.q-left {
  float: left;
}

.q-right {
  float: right;
}

.q-center {
  margin: 0 auto;
}

/* Sticky Styles */
.q-sticky .sticky-child {
  z-index: 55;
}
.q-columns-half {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

.q-columns-quarter {
  padding-left: 1.25vw;
  padding-right: 1.25vw;
}

.q-no-link {
  text-decoration: none;
}

.q-block {
  display: block;
}

.q-inline {
  display: inline;
}

picture img {
  width: 100%;
}

body[data-image-lazy-load-enabled=true] .q-responsive-image-container:not([data-enable-image-height-control=true]) picture {
  display: inline-block;
  height: auto;
  width: 100%;
}
body[data-image-lazy-load-enabled=true] .q-mod-responsive-image[data-hq].q-animate-fade-in,
body[data-image-lazy-load-enabled=true] .q-mod-vehicle-tile img[data-hq].q-animate-fade-in,
body[data-image-lazy-load-enabled=true] .q-gallery-item img[data-hq].q-animate-fade-in,
body[data-image-lazy-load-enabled=true] .gallery-preview .gallery-item .responsive-image[data-hq].q-animate-fade-in,
body[data-image-lazy-load-enabled=true] .q-mod-static-image[data-hq].q-animate-fade-in {
  animation-duration: 1s;
}
body[data-image-lazy-load-enabled=true] .slick-slide picture {
  text-align: center;
}
body[data-image-lazy-load-enabled=true] .slick-slide img {
  display: inline-block;
}

* {
  -webkit-font-smoothing: antialiased;
}

@media only screen and (min-width:1500px) {
  .q-sticky.top .sticky-child, .q-sticky.bottom .sticky-child {
    left: 50%;
    margin-left: -749.5px;
    width: 1499px;
  }
}

.q-scroll-disabled {
  max-height: 100vh;
  overflow: hidden;
}

.body-scroll-disabled {
  overflow: hidden;
}

.body-scroll-enabled {
  overflow: auto;
}

.q-slide-left {
  animation-duration: 0.25s;
  animation-name: slide-left;
  animation-timing-function: ease;
}

.q-slide-right {
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-name: slide-right;
  animation-timing-function: ease;
}

/**
Prevent sub and sup elements from affecting the line height in
all browsers.
*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

@media only screen and (min-width:960px) {
  .q-grid-row-new-desktop {
    clear: left;
  }
}
@media print, only screen and (min-width:600px) and (max-width:959px), print, only screen and (min-width:420px) and (max-width:599px) {
  .q-grid-row-new-tablet {
    clear: left;
  }
}
@media only screen and (max-width: 419px) {
  .q-grid-row-new-mobile {
    clear: left;
  }
}
.q-skip-nav {
  left: -62.5rem;
  position: absolute;
}
.q-skip-nav:focus {
  left: 0;
}

.q-disclosure-data-content,
.q-print-reference,
.q-print-sequence-data,
.q-disclosure-print-number {
  display: none;
}

.q-margin-top {
  margin-top: 1rem;
}

.ui-autocomplete {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  border-top: 0;
  cursor: pointer;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  list-style: none;
  width: 0;
}
.ui-autocomplete li {
  padding-left: 0.5rem;
}

adv-grid.grid-sm-fh,
adv-grid.grid-sm-fh > adv-scrl > adv-slides,
.gb-fh {
  height: 100%;
}

div#embedded-messaging button#embeddedMessagingConversationButton {
  margin: 0;
  padding: 0;
}

.q-text-color-gold {
  color: #cd9834;
}

.q-text-color-white {
  color: #ffffff;
}

.q-text-ltr {
  direction: ltr;
  display: inline-block;
}

@media only screen and (max-width: 419px) {
  .row.gridspacing-xlarge,
.row.collapse.gridspacing-xlarge {
    margin-bottom: 5rem;
  }
}

.no-touch .q-static-image .q-img-hover,
.no-touch .q-responsive-image.q-img-hover {
  display: block;
  overflow: hidden;
  position: relative;
}
.no-touch .q-static-image .q-img-hover img,
.no-touch .q-responsive-image.q-img-hover img {
  transition: 0.5s ease;
}
.no-touch .q-static-image .q-img-hover img:hover,
.no-touch .q-responsive-image.q-img-hover img:hover {
  cursor: pointer;
  transform: translateZ(0) scale(1.06);
}

@media only screen and (min-width:960px) {
  html {
    font-size: 20px;
  }
}
@media only screen and (min-width:1500px) {
  html {
    font-size: 23px;
  }
}

/********
 * NOTE: These variables and mixins MUST be in this file so that this file can be used
 *       on its own for use in AEM dialog.
 ********/
/********
 * NOTE: These variables and mixins MUST be in this file so that this file can be used
 *       on its own for use in AEM dialog.
 ********/
.no-touch .q-button.q-list-item.q-tertiary-nav-button, .q-tertiary-nav-button.q-button.q-list-item {
  padding: 1.875rem 1.75rem;
}

.q-sticky.top .sticky-child, .q-sticky.bottom .sticky-child {
  left: 0;
  position: fixed;
  right: 0;
  transform: translateZ(0);
}

/* Flex Mixin */
/*************************
 * For backwards compatibility of previously authored grids, the key and value pair
 * should not be changed. New values will have the key out of order if the values
 * are ordered from smallest to largest.
 *************************/
.icon-search::before, .icon-checkbox::before, .icon-img-inactive::before, .icon-img-active::before, .icon-alt::before, .icon-plus:hover::before, .icon-plus::before, .icon-minus:hover::before, .icon-minus::before {
  content: "";
}

.icon-minus::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/minus-dark.svg") no-repeat center;
  height: 2rem;
  width: 2rem;
}
.icon-minus:hover::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/minus.svg") no-repeat center;
  height: 2rem;
  width: 2rem;
}

.icon-plus::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/plus-dark.svg") no-repeat center;
  height: 2rem;
  width: 2rem;
}
.icon-plus:hover::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/plusHover.svg") no-repeat center;
  height: 2rem;
  width: 2rem;
}

.icon-alt::before {
  background: no-repeat center;
  height: 1rem;
  width: 1rem;
}

.icon-img-active::before {
  background: no-repeat center;
  height: 1rem;
  width: 1rem;
}

.icon-img-inactive::before {
  background: no-repeat center;
  height: 1rem;
  width: 1rem;
}

.icon-checkbox::before {
  background: no-repeat center;
  height: 1rem;
  width: 1rem;
}

.icon-search::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/assets/navigation-icons/nav_search-icon.svg") no-repeat center;
  height: 2rem;
  width: 2rem;
}

.icon-social-media-email {
  height: 1.375rem;
  width: 1.375rem;
}
.icon-social-media-email::after {
  content: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/assets/social-icons/social-email-color.svg");
}

.icon-social-media-facebook {
  height: 1.375rem;
  width: 1.375rem;
}
.icon-social-media-facebook::after {
  content: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/assets/social-icons/social-facebook-color.svg");
}

.icon-social-media-instagram {
  height: 1.375rem;
  width: 1.375rem;
}
.icon-social-media-pinterest {
  height: 1.375rem;
  width: 1.375rem;
}
.icon-social-media-pinterest::after {
  content: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/assets/social-icons/social-pinterest-color.svg");
}

.icon-social-media-twitter {
  height: 1.375rem;
  width: 1.375rem;
}
.icon-social-media-twitter::after {
  content: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/assets/social-icons/social-twitter-color.svg");
}

.icon-social-media-youtube {
  height: 1.375rem;
  width: 1.375rem;
}
h1 > .icon,
h2 > .icon,
h3 > .icon,
h4 > .icon,
h5 > .icon,
h6 > .icon {
  margin-right: 0.5rem;
  vertical-align: middle;
}

h1 > .icon {
  font-size: 2.125rem;
  line-height: 2.125rem;
}

h2 > .icon {
  font-size: 1.6875rem;
  line-height: 1.6875rem;
}

h3 > .icon {
  font-size: 1.375rem;
  line-height: 1.375rem;
}

h4 > .icon {
  font-size: 1.125rem;
  line-height: 1.125rem;
}

h5 > .icon {
  font-size: 1.125rem;
  line-height: 1.125rem;
}

h6 > .icon {
  font-size: 1rem;
  line-height: 1rem;
}

@media print, only screen and (min-width:420px) {
  h1 > .icon {
    font-size: 2.75rem;
    line-height: 2.75rem;
  }

  h2 > .icon {
    font-size: 2.3125rem;
    line-height: 2.3125rem;
  }

  h3 > .icon {
    font-size: 1.6875rem;
    line-height: 1.6875rem;
  }

  h4 > .icon {
    font-size: 1.4375rem;
    line-height: 1.4375rem;
  }

  h5 > .icon {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  h6 > .icon {
    font-size: 1rem;
    line-height: 1rem;
  }
}
/* inject:patterns */
body[data-image-lazy-load-enabled=true] .q-mod-responsive-image[data-hq].q-animate-fade-in,
body[data-image-lazy-load-enabled=true] .q-mod-vehicle-tile img[data-hq].q-animate-fade-in,
body[data-image-lazy-load-enabled=true] .q-gallery-item img[data-hq].q-animate-fade-in,
body[data-image-lazy-load-enabled=true] .gallery-preview .gallery-item .responsive-image[data-hq].q-animate-fade-in,
body[data-image-lazy-load-enabled=true] .q-mod-static-image[data-hq].q-animate-fade-in {
  animation-fill-mode: forwards;
  animation-name: qFadeIn;
}

.q-animate {
  visibility: hidden;
}

* .q-invert .show-disclosure {
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
}
* .q-invert .show-disclosure:hover {
  color: #e6ac39;
}
* .q-invert .show-disclosure.active {
  color: inherit;
}
* .show-disclosure {
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
}
* .show-disclosure:hover {
  color: #cd9834;
}
* .show-disclosure.active {
  color: inherit;
}

.q-headline .show-disclosure {
  text-decoration: none;
}
.q-headline .show-disclosure .q-disclosure-print-hide {
  text-decoration: underline;
}
.q-headline .q-invert .show-disclosure:hover {
  color: #e6ac39;
}

.q-colorizer .q-colorizer-text sup,
.q-colorizer .q-colorizer-text sub {
  text-decoration: underline;
}
.q-colorizer .q-colorizer-text .show-disclosure {
  text-decoration: none;
}
.q-colorizer .q-colorizer-text .show-disclosure sup,
.q-colorizer .q-colorizer-text .show-disclosure sub {
  text-decoration: underline;
}

footer {
  background: #f2f2f2;
  padding-bottom: 2.5rem;
  padding-top: 2.5rem;
}
footer .q-button.q-inline-icon {
  border-color: #b3b3b3;
  color: #262626;
}
footer .q-button.q-link {
  color: #808080;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
  padding: 0;
  text-align: left;
}
footer .q-button .hoverable-container > img {
  height: 1.5rem;
}
footer .q-button.q-icon-only {
  display: block;
  float: left;
  height: 1.5rem;
  margin-right: 0.625rem;
  overflow: hidden;
  padding: 0 0.25rem 0 0;
  width: auto;
}
footer img {
  height: 1.5rem;
  vertical-align: middle;
}
footer .q-static-image {
  text-align: right;
}
footer .q-expander .q-button.q-link, footer .q-expander .q-button.q-invert {
  line-height: 1.25rem;
}
footer .q-expander .q-button.q-link:active, footer .q-expander .q-button.q-link:hover, footer .q-expander .q-button.q-invert:active, footer .q-expander .q-button.q-invert:hover {
  color: #262626;
}
footer .q-expander > div .q-expander-button {
  background-color: #f2f2f2;
  border-bottom: 1px solid #b3b3b3;
  border-top: 1px solid #b3b3b3;
}
footer .q-expander > div .q-expander-button:hover {
  border-bottom: 1px solid #b3b3b3;
  border-top: 1px solid #b3b3b3;
}
footer .q-expander > div .q-expander-button .q-headline-text.q-descriptive1 {
  color: #262626;
}
footer .q-expander.active > div > .q-expander-button, footer .q-expander.open > div > .q-expander-button {
  background: none;
  border: 0;
}
@media only screen and (max-width: 419px) {
  footer {
    padding-top: 0;
  }
  footer .q-expander {
    margin-left: -0.0625rem;
    margin-left: -2.5vw;
    margin-right: -0.0625rem;
    margin-right: -2.5vw;
  }
  footer .q-expander.q-disabled-med, footer .q-expander.q-disabled-lg, footer .q-expander.q-disabled-xl {
    margin-left: -5vw;
    margin-right: -5vw;
  }
  footer .q-expander .q-link {
    border-bottom: 0.0625rem solid #e6e6e6;
    border-top: 0.0625rem solid #e6e6e6;
    color: #262626;
    margin: -0.0625rem 0.3125rem 0;
    padding: 0.9375rem 0;
    width: calc(100% - .625rem);
  }
  footer .q-expander .q-link:hover {
    color: #cd9834;
  }
  footer .q-expander > div .q-expander-button {
    border-left: 0;
    border-right: 0;
    padding-left: 5vw;
  }
  footer .q-expander.active > div .q-expander-content, footer .q-expander.open > div .q-expander-content {
    border-bottom: 0;
  }
  footer .q-expander.active > div .q-expander-button, footer .q-expander.open > div .q-expander-button {
    background: #f2f2f2;
    border-bottom: 1px solid #b3b3b3;
    border-top: 1px solid #b3b3b3;
  }
  footer .q-expander:hover > div > .q-expander-button {
    background-color: #ffffff;
  }
  footer gb-content-well {
    background: #ffffff;
    padding: 1.875rem 0.625rem;
  }
}
@media print, only screen and (min-width:600px) {
  footer .q-button.q-icon-only {
    margin-right: 0.1875rem;
  }
}

.gb-dl-filter-modal {
  background-color: #ffffff;
  margin: 1rem 0 0 1rem;
  max-width: 27.13rem;
  padding: 1.25rem;
  z-index: 999;
}
.gb-dl-filter-modal .gb-brand-container {
  display: flex;
  padding: 0 0 1.4rem;
}
.gb-dl-filter-modal .gb-brand-container .gb-brand {
  border: 1px solid #000000;
  cursor: pointer;
  font-size: 0.63rem;
  margin-right: 0.9375rem;
  text-align: center;
  width: 100%;
}
.gb-dl-filter-modal .gb-dl-h2 {
  font-size: 100%;
  font-weight: 900;
}
.gb-dl-filter-modal .gb-dl-filter-back {
  color: #000000;
  font-family: inherit;
  font-weight: 900;
}
.gb-dl-filter-modal li {
  grid-column: 1;
  list-style: none;
}
.gb-dl-filter-modal .db-dl-filter-modal-apply-button {
  border: 0;
  color: #ffffff;
  width: 100%;
}
.gb-dl-filter-modal .db-dl-filter-modal-apply-button:hover {
  color: #ffffff;
}
.gb-dl-filter-modal .gb-dl-filter-modal-apply-button-container {
  border-top: 1px solid #cccccc;
  display: flex;
  grid-column: 1;
  padding-top: 0.75rem;
  width: 100%;
}
.gb-dl-filter-modal .gb-dl-filter-modal-return {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
}
.gb-dl-filter-modal .gb-checkbox {
  display: flex;
}
.gb-dl-filter-modal .gb-checkbox input {
  appearance: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  cursor: pointer;
  display: inline-block;
  flex: 0 0 1.5rem;
  float: left;
  height: 1.5rem;
  margin-right: 1rem;
  position: relative;
  width: 1.5rem;
}
.gb-dl-filter-modal .gb-checkbox input:checked::after {
  background-image: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/checkmark.svg");
  left: 0.25rem;
  position: relative;
  top: 0.25rem;
}

.none-margin,
.margin-none {
  margin-bottom: 0 !important;
}

.small-margin,
.margin-small {
  margin-bottom: 1.25rem !important;
}

.medium-margin,
.margin-medium {
  margin-bottom: 2.5rem !important;
}

.large-margin,
.margin-large {
  margin-bottom: 5rem !important;
}

.xlarge-margin,
.margin-xlarge {
  margin-bottom: 5rem !important;
}
@media print, only screen and (min-width:420px) {
  .xlarge-margin,
.margin-xlarge {
    margin-bottom: 7.5rem !important;
  }
}

nav a {
  text-decoration: none;
}

/* Foundation Top Bar */
.top-bar {
  overflow: hidden;
}
.top-bar .toggle-topbar.menu-icon a span::after {
  box-shadow: 0 0 0 0.125rem #000000, 0 0.4375rem 0 0.125rem #000000, 0 0.875rem 0 0.125rem #000000;
  width: 1.375rem;
}

.top-bar-section {
  padding: 0;
}
.top-bar-section ul li > a {
  display: inline;
  font-family: Arial, sans-serif;
  font-size: 1.125rem;
}
.top-bar-section .first-level li > a {
  font-weight: 700;
}
.top-bar-section .utility li > a {
  font-weight: normal;
}

/* Foundation Off-Canvas */
.left-off-canvas-menu {
  overflow-y: hidden;
  /* ul {
  	&.off-canvas-list {
  		li {
  			&.has-submenu > a {
  				@include icon(arrow-right, after) {
  					float: right;
  					margin-top: .25rem;
  				}
  			}
  		}
  	}
  } */
}

.left-submenu .back > a {
  padding: 0.6666666667rem;
}

.right-small {
  width: auto;
}

.off-canvas-list {
  font-size: 1.25rem;
  text-transform: uppercase;
}
.off-canvas-list a {
  text-decoration: none;
}
.off-canvas-list form {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.off-canvas-list form input {
  color: #333333;
  font-weight: 700;
  line-height: 1rem;
  padding: 0.75rem 0 0.75rem 0.9375rem;
  width: 100%;
}
.off-canvas-list form input,
.off-canvas-list form .input-group-btn {
  background-color: #ffffff;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  display: table-cell;
  margin: 0;
}
.off-canvas-list form .input-group-btn {
  width: 2.5rem;
}
.off-canvas-list form .button-search {
  background-color: transparent;
  border: medium none;
  box-shadow: none;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
}
.off-canvas-list form .button-search .icon {
  color: #333333;
}
.off-canvas-list label {
  cursor: default;
}
.off-canvas-list .button {
  background-color: #333333;
}
.off-canvas-list.has-search > .has-submenu > .left-submenu {
  top: 4.875rem;
}
.off-canvas-list .left-submenu .title {
  background: #ffffff;
  border-bottom: 0.0625rem solid #cccccc;
  border-top: 0.1875rem solid #f7d900;
  color: #333333;
  font-size: 1.125rem;
  margin: 0;
  padding: 0.5rem 1rem;
}
.off-canvas-list.shopping-tools i {
  vertical-align: inherit;
}

.q-navigation-link-list {
  font-family: Arial, sans-serif;
  position: relative;
}

@media print, only screen and (min-width:600px) {
  .q-navigation-link-list .q-navigation-bar {
    background-color: transparent;
    position: relative;
  }
  .q-navigation-link-list .q-navigation-bar .q-scroller-content .q-scroller-list .q-scroller-content-item .q-label {
    font-size: 1.125rem;
  }
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-number, .q-bold1 {
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 0.625rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-number, .q-bold1 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-number, .q-bold1 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-number a, .q-bold1 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-number a:visited, .q-bold1 a:visited {
  color: #262626;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-number a:hover, .q-bold1 a:hover, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-number a:active, .q-bold1 a:active {
  color: #cd9834;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-number, .q-invert.q-bold1 {
  color: #ffffff;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-number a, .q-invert.q-bold1 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-number a:visited, .q-invert.q-bold1 a:visited {
  color: #ffffff;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-number a:hover, .q-invert.q-bold1 a:hover, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-number a:active, .q-invert.q-bold1 a:active {
  color: #e6ac39;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label, .q-tab-nav .q-change-view-header .q-dropdown .q-category-title, .q-bold2 {
  font-size: 1.333rem;
  line-height: 1.6rem;
  margin-bottom: 0.275rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label, .q-tab-nav .q-change-view-header .q-dropdown .q-category-title, .q-bold2 {
    font-size: 1.333rem;
    line-height: 1.6rem;
    margin-bottom: 0.275rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label, .q-tab-nav .q-change-view-header .q-dropdown .q-category-title, .q-bold2 {
    font-size: 1.333rem;
    line-height: 1.6rem;
    margin-bottom: 0.275rem;
    padding-top: 0rem;
  }
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label a, .q-tab-nav .q-change-view-header .q-dropdown .q-category-title a, .q-bold2 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label a:visited, .q-tab-nav .q-change-view-header .q-dropdown .q-category-title a:visited, .q-bold2 a:visited {
  color: #262626;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label a:hover, .q-tab-nav .q-change-view-header .q-dropdown .q-category-title a:hover, .q-bold2 a:hover, .q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label a:active, .q-tab-nav .q-change-view-header .q-dropdown .q-category-title a:active, .q-bold2 a:active {
  color: #cd9834;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-invert.q-button-label, .q-tab-nav .q-change-view-header .q-dropdown .q-invert.q-category-title, .q-invert.q-bold2 {
  color: #ffffff;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-invert.q-button-label a, .q-tab-nav .q-change-view-header .q-dropdown .q-invert.q-category-title a, .q-invert.q-bold2 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-invert.q-button-label a:visited, .q-tab-nav .q-change-view-header .q-dropdown .q-invert.q-category-title a:visited, .q-invert.q-bold2 a:visited {
  color: #ffffff;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-invert.q-button-label a:hover, .q-tab-nav .q-change-view-header .q-dropdown .q-invert.q-category-title a:hover, .q-invert.q-bold2 a:hover, .q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-invert.q-button-label a:active, .q-tab-nav .q-change-view-header .q-dropdown .q-invert.q-category-title a:active, .q-invert.q-bold2 a:active {
  color: #e6ac39;
}
.q-print-header .q-print-header-container .title, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-unit, .q-sticky-notification .q-content-container .q-title .q-title-label, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-title, .q-gallery-layer .q-gallery-thumbnails .q-gallery-headline, .q-headline .q-scroller-indicator-text, .q-descriptive1 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.375rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 400;
  letter-spacing: 0.15rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-print-header .q-print-header-container .title, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-unit, .q-sticky-notification .q-content-container .q-title .q-title-label, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-title, .q-gallery-layer .q-gallery-thumbnails .q-gallery-headline, .q-headline .q-scroller-indicator-text, .q-descriptive1 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-print-header .q-print-header-container .title, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-unit, .q-sticky-notification .q-content-container .q-title .q-title-label, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-title, .q-gallery-layer .q-gallery-thumbnails .q-gallery-headline, .q-headline .q-scroller-indicator-text, .q-descriptive1 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
.q-print-header .q-print-header-container .title a, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-unit a, .q-sticky-notification .q-content-container .q-title .q-title-label a, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-title a, .q-gallery-layer .q-gallery-thumbnails .q-gallery-headline a, .q-headline .q-scroller-indicator-text a, .q-descriptive1 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-print-header .q-print-header-container .title a:visited, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-unit a:visited, .q-sticky-notification .q-content-container .q-title .q-title-label a:visited, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-title a:visited, .q-gallery-layer .q-gallery-thumbnails .q-gallery-headline a:visited, .q-headline .q-scroller-indicator-text a:visited, .q-descriptive1 a:visited {
  color: #262626;
}
.q-print-header .q-print-header-container .title a:hover, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-unit a:hover, .q-sticky-notification .q-content-container .q-title .q-title-label a:hover, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-title a:hover, .q-gallery-layer .q-gallery-thumbnails .q-gallery-headline a:hover, .q-headline .q-scroller-indicator-text a:hover, .q-descriptive1 a:hover, .q-print-header .q-print-header-container .title a:active, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-unit a:active, .q-sticky-notification .q-content-container .q-title .q-title-label a:active, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-title a:active, .q-gallery-layer .q-gallery-thumbnails .q-gallery-headline a:active, .q-headline .q-scroller-indicator-text a:active, .q-descriptive1 a:active {
  color: #cd9834;
}
.q-print-header .q-print-header-container .q-invert.title, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-unit, .q-sticky-notification .q-content-container .q-title .q-invert.q-title-label, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-title, .q-gallery-layer .q-gallery-thumbnails .q-invert.q-gallery-headline, .q-headline .q-invert.q-scroller-indicator-text, .q-invert.q-descriptive1 {
  color: #ffffff;
}
.q-print-header .q-print-header-container .q-invert.title a, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-unit a, .q-sticky-notification .q-content-container .q-title .q-invert.q-title-label a, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-title a, .q-gallery-layer .q-gallery-thumbnails .q-invert.q-gallery-headline a, .q-headline .q-invert.q-scroller-indicator-text a, .q-invert.q-descriptive1 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-print-header .q-print-header-container .q-invert.title a:visited, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-unit a:visited, .q-sticky-notification .q-content-container .q-title .q-invert.q-title-label a:visited, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-title a:visited, .q-gallery-layer .q-gallery-thumbnails .q-invert.q-gallery-headline a:visited, .q-headline .q-invert.q-scroller-indicator-text a:visited, .q-invert.q-descriptive1 a:visited {
  color: #ffffff;
}
.q-print-header .q-print-header-container .q-invert.title a:hover, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-unit a:hover, .q-sticky-notification .q-content-container .q-title .q-invert.q-title-label a:hover, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-title a:hover, .q-gallery-layer .q-gallery-thumbnails .q-invert.q-gallery-headline a:hover, .q-headline .q-invert.q-scroller-indicator-text a:hover, .q-invert.q-descriptive1 a:hover, .q-print-header .q-print-header-container .q-invert.title a:active, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-invert.q-unit a:active, .q-sticky-notification .q-content-container .q-title .q-invert.q-title-label a:active, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-title a:active, .q-gallery-layer .q-gallery-thumbnails .q-invert.q-gallery-headline a:active, .q-headline .q-invert.q-scroller-indicator-text a:active, .q-invert.q-descriptive1 a:active {
  color: #e6ac39;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-title, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-year, .q-tab-nav .q-360-colorizer .q-colorizer-category, .q-legal-notification .q-content-container .q-content.q-silent-consent, .q-legal-notification .q-content-container .q-title .q-title-label, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-year, .q-vehicle-information-row .q-vehicle-info-text-price .q-from-label, .q-descriptive2 {
  font-size: 0.8rem;
  line-height: 1.333rem;
  margin-bottom: 0.542rem;
  padding-top: 0rem;
  color: #6e6e6e;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 400;
  letter-spacing: 0.15rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-title, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-year, .q-tab-nav .q-360-colorizer .q-colorizer-category, .q-legal-notification .q-content-container .q-content.q-silent-consent, .q-legal-notification .q-content-container .q-title .q-title-label, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-year, .q-vehicle-information-row .q-vehicle-info-text-price .q-from-label, .q-descriptive2 {
    font-size: 0.8rem;
    line-height: 1.333rem;
    margin-bottom: 0.542rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-title, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-year, .q-tab-nav .q-360-colorizer .q-colorizer-category, .q-legal-notification .q-content-container .q-content.q-silent-consent, .q-legal-notification .q-content-container .q-title .q-title-label, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-year, .q-vehicle-information-row .q-vehicle-info-text-price .q-from-label, .q-descriptive2 {
    font-size: 0.8rem;
    line-height: 1.333rem;
    margin-bottom: 0.542rem;
    padding-top: 0rem;
  }
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-title a, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-year a, .q-tab-nav .q-360-colorizer .q-colorizer-category a, .q-legal-notification .q-content-container .q-content.q-silent-consent a, .q-legal-notification .q-content-container .q-title .q-title-label a, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-year a, .q-vehicle-information-row .q-vehicle-info-text-price .q-from-label a, .q-descriptive2 a {
  color: #6e6e6e;
  font-weight: inherit;
  text-decoration: none;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-title a:visited, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-year a:visited, .q-tab-nav .q-360-colorizer .q-colorizer-category a:visited, .q-legal-notification .q-content-container .q-content.q-silent-consent a:visited, .q-legal-notification .q-content-container .q-title .q-title-label a:visited, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-year a:visited, .q-vehicle-information-row .q-vehicle-info-text-price .q-from-label a:visited, .q-descriptive2 a:visited {
  color: #262626;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-title a:hover, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-year a:hover, .q-tab-nav .q-360-colorizer .q-colorizer-category a:hover, .q-legal-notification .q-content-container .q-content.q-silent-consent a:hover, .q-legal-notification .q-content-container .q-title .q-title-label a:hover, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-year a:hover, .q-vehicle-information-row .q-vehicle-info-text-price .q-from-label a:hover, .q-descriptive2 a:hover, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-title a:active, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-year a:active, .q-tab-nav .q-360-colorizer .q-colorizer-category a:active, .q-legal-notification .q-content-container .q-content.q-silent-consent a:active, .q-legal-notification .q-content-container .q-title .q-title-label a:active, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-year a:active, .q-vehicle-information-row .q-vehicle-info-text-price .q-from-label a:active, .q-descriptive2 a:active {
  color: #cd9834;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-invert.q-title, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-year, .q-tab-nav .q-360-colorizer .q-invert.q-colorizer-category, .q-legal-notification .q-content-container .q-invert.q-content.q-silent-consent, .q-legal-notification .q-content-container .q-title .q-invert.q-title-label, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-year, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-from-label, .q-invert.q-descriptive2 {
  color: #ffffff;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-invert.q-title a, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-year a, .q-tab-nav .q-360-colorizer .q-invert.q-colorizer-category a, .q-legal-notification .q-content-container .q-invert.q-content.q-silent-consent a, .q-legal-notification .q-content-container .q-title .q-invert.q-title-label a, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-year a, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-from-label a, .q-invert.q-descriptive2 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-invert.q-title a:visited, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-year a:visited, .q-tab-nav .q-360-colorizer .q-invert.q-colorizer-category a:visited, .q-legal-notification .q-content-container .q-invert.q-content.q-silent-consent a:visited, .q-legal-notification .q-content-container .q-title .q-invert.q-title-label a:visited, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-year a:visited, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-from-label a:visited, .q-invert.q-descriptive2 a:visited {
  color: #ffffff;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-invert.q-title a:hover, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-year a:hover, .q-tab-nav .q-360-colorizer .q-invert.q-colorizer-category a:hover, .q-legal-notification .q-content-container .q-invert.q-content.q-silent-consent a:hover, .q-legal-notification .q-content-container .q-title .q-invert.q-title-label a:hover, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-year a:hover, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-from-label a:hover, .q-invert.q-descriptive2 a:hover, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-invert.q-title a:active, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-year a:active, .q-tab-nav .q-360-colorizer .q-invert.q-colorizer-category a:active, .q-legal-notification .q-content-container .q-invert.q-content.q-silent-consent a:active, .q-legal-notification .q-content-container .q-title .q-invert.q-title-label a:active, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-invert.q-vehicle-info-row-year a:active, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-from-label a:active, .q-invert.q-descriptive2 a:active {
  color: #e6ac39;
}
.q-descriptive3 {
  font-size: 0.7rem;
  line-height: 1rem;
  margin-bottom: 0.25rem;
  padding-top: 0rem;
  color: #6e6e6e;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 400;
  letter-spacing: 0.15rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-descriptive3 {
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-descriptive3 {
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
.q-descriptive3 a {
  color: #6e6e6e;
  font-weight: inherit;
  text-decoration: none;
}
.q-descriptive3 a:visited {
  color: #262626;
}
.q-descriptive3 a:hover, .q-descriptive3 a:active {
  color: #cd9834;
}
.q-invert.q-descriptive3 {
  color: #ffffff;
}
.q-invert.q-descriptive3 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-invert.q-descriptive3 a:visited {
  color: #ffffff;
}
.q-invert.q-descriptive3 a:hover, .q-invert.q-descriptive3 a:active {
  color: #e6ac39;
}
@media print, only screen and (min-width:37.5em) {
  .q-descriptive3 {
    letter-spacing: 0.15rem;
  }
}
@media only screen and (min-width:60em) {
  .q-descriptive3 {
    letter-spacing: 0.15rem;
  }
}

.q-display1 {
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 0.125rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-display1 {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 0.125rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-display1 {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 0.125rem;
    padding-top: 0rem;
  }
}
.q-display1 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-display1 a:visited {
  color: #262626;
}
.q-display1 a:hover, .q-display1 a:active {
  color: #cd9834;
}
.q-invert.q-display1 {
  color: #ffffff;
}
.q-invert.q-display1 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-invert.q-display1 a:visited {
  color: #ffffff;
}
.q-invert.q-display1 a:hover, .q-invert.q-display1 a:active {
  color: #e6ac39;
}
.q-heavy.q-display1 {
  font-weight: 900;
}
@media print, only screen and (min-width:37.5em) {
  .q-display1 {
    letter-spacing: 0.1rem;
  }
}
@media only screen and (min-width:60em) {
  .q-display1 {
    letter-spacing: 0.1rem;
  }
}

.q-display2 {
  font-size: 2.25rem;
  line-height: 2.25rem;
  margin-bottom: 0.25rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.15rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-display2 {
    font-size: 2.25rem;
    line-height: 2.25rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-display2 {
    font-size: 2.25rem;
    line-height: 2.25rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
.q-display2 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-display2 a:visited {
  color: #262626;
}
.q-display2 a:hover, .q-display2 a:active {
  color: #cd9834;
}
.q-invert.q-display2 {
  color: #ffffff;
}
.q-invert.q-display2 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-invert.q-display2 a:visited {
  color: #ffffff;
}
.q-invert.q-display2 a:hover, .q-invert.q-display2 a:active {
  color: #e6ac39;
}
.q-heavy.q-display2 {
  font-weight: 900;
}
@media print, only screen and (min-width:37.5em) {
  .q-display2 {
    letter-spacing: 0.15rem;
  }
}
@media only screen and (min-width:60em) {
  .q-display2 {
    letter-spacing: 0.15rem;
  }
}

.q-trailering-towing-search-results .q-main-title, .q-display3 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 0.375rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.15rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-trailering-towing-search-results .q-main-title, .q-display3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-trailering-towing-search-results .q-main-title, .q-display3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
.q-trailering-towing-search-results .q-main-title a, .q-display3 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-trailering-towing-search-results .q-main-title a:visited, .q-display3 a:visited {
  color: #262626;
}
.q-trailering-towing-search-results .q-main-title a:hover, .q-display3 a:hover, .q-trailering-towing-search-results .q-main-title a:active, .q-display3 a:active {
  color: #cd9834;
}
.q-trailering-towing-search-results .q-invert.q-main-title, .q-invert.q-display3 {
  color: #ffffff;
}
.q-trailering-towing-search-results .q-invert.q-main-title a, .q-invert.q-display3 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-trailering-towing-search-results .q-invert.q-main-title a:visited, .q-invert.q-display3 a:visited {
  color: #ffffff;
}
.q-trailering-towing-search-results .q-invert.q-main-title a:hover, .q-invert.q-display3 a:hover, .q-trailering-towing-search-results .q-invert.q-main-title a:active, .q-invert.q-display3 a:active {
  color: #e6ac39;
}
.q-trailering-towing-search-results .q-heavy.q-main-title, .q-heavy.q-display3 {
  font-weight: 900;
}
@media print, only screen and (min-width:37.5em) {
  .q-trailering-towing-search-results .q-main-title, .q-display3 {
    letter-spacing: 0.15rem;
  }
}
@media only screen and (min-width:60em) {
  .q-trailering-towing-search-results .q-main-title, .q-display3 {
    letter-spacing: 0.15rem;
  }
}

.q-display4 {
  font-size: 4rem;
  line-height: 3rem;
  margin-bottom: 0.125rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-display4 {
    font-size: 6rem;
    line-height: 5rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-display4 {
    font-size: 6rem;
    line-height: 5rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
.q-display4 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-display4 a:visited {
  color: #262626;
}
.q-display4 a:hover, .q-display4 a:active {
  color: #cd9834;
}
.q-invert.q-display4 {
  color: #ffffff;
}
.q-invert.q-display4 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-invert.q-display4 a:visited {
  color: #ffffff;
}
.q-invert.q-display4 a:hover, .q-invert.q-display4 a:active {
  color: #e6ac39;
}
.q-heavy.q-display4 {
  font-weight: 800;
}

.q-gallery-layer .q-gallery-header .q-dropdown .q-category-title, .q-headline1 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 400;
  letter-spacing: 0.04rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-gallery-layer .q-gallery-header .q-dropdown .q-category-title, .q-headline1 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-gallery-layer .q-gallery-header .q-dropdown .q-category-title, .q-headline1 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
    padding-top: 0rem;
  }
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-category-title a, .q-headline1 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-category-title a:visited, .q-headline1 a:visited {
  color: #262626;
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-category-title a:hover, .q-headline1 a:hover, .q-gallery-layer .q-gallery-header .q-dropdown .q-category-title a:active, .q-headline1 a:active {
  color: #cd9834;
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-invert.q-category-title, .q-invert.q-headline1 {
  color: #ffffff;
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-invert.q-category-title a, .q-invert.q-headline1 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-invert.q-category-title a:visited, .q-invert.q-headline1 a:visited {
  color: #ffffff;
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-invert.q-category-title a:hover, .q-invert.q-headline1 a:hover, .q-gallery-layer .q-gallery-header .q-dropdown .q-invert.q-category-title a:active, .q-invert.q-headline1 a:active {
  color: #e6ac39;
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-heavy.q-category-title, .q-heavy.q-headline1 {
  font-weight: 900;
}

.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim, .q-nav-primary .q-nav-dealername-wrapper, .q-headline2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0.375rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 400;
  letter-spacing: 0.04rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim, .q-nav-primary .q-nav-dealername-wrapper, .q-headline2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim, .q-nav-primary .q-nav-dealername-wrapper, .q-headline2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim a, .q-nav-primary .q-nav-dealername-wrapper a, .q-headline2 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim a:visited, .q-nav-primary .q-nav-dealername-wrapper a:visited, .q-headline2 a:visited {
  color: #262626;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim a:hover, .q-nav-primary .q-nav-dealername-wrapper a:hover, .q-headline2 a:hover, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim a:active, .q-nav-primary .q-nav-dealername-wrapper a:active, .q-headline2 a:active {
  color: #cd9834;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-nameplate-trim, .q-nav-primary .q-invert.q-nav-dealername-wrapper, .q-invert.q-headline2 {
  color: #ffffff;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-nameplate-trim a, .q-nav-primary .q-invert.q-nav-dealername-wrapper a, .q-invert.q-headline2 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-nameplate-trim a:visited, .q-nav-primary .q-invert.q-nav-dealername-wrapper a:visited, .q-invert.q-headline2 a:visited {
  color: #ffffff;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-nameplate-trim a:hover, .q-nav-primary .q-invert.q-nav-dealername-wrapper a:hover, .q-invert.q-headline2 a:hover, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-nameplate-trim a:active, .q-nav-primary .q-invert.q-nav-dealername-wrapper a:active, .q-invert.q-headline2 a:active {
  color: #e6ac39;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-heavy.q-nameplate-trim, .q-nav-primary .q-heavy.q-nav-dealername-wrapper, .q-heavy.q-headline2 {
  font-weight: 900;
}
@media print, only screen and (min-width:37.5em) {
  .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim, .q-nav-primary .q-nav-dealername-wrapper, .q-headline2 {
    letter-spacing: 0.04rem;
  }
}
@media only screen and (min-width:60em) {
  .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim, .q-nav-primary .q-nav-dealername-wrapper, .q-headline2 {
    letter-spacing: 0.04rem;
  }
}

.q-headline3 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.375rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 400;
  letter-spacing: 0.04rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-headline3 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-headline3 {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
.q-headline3 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-headline3 a:visited {
  color: #262626;
}
.q-headline3 a:hover, .q-headline3 a:active {
  color: #cd9834;
}
.q-invert.q-headline3 {
  color: #ffffff;
}
.q-invert.q-headline3 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-invert.q-headline3 a:visited {
  color: #ffffff;
}
.q-invert.q-headline3 a:hover, .q-invert.q-headline3 a:active {
  color: #e6ac39;
}
.q-heavy.q-headline3 {
  font-weight: 900;
}

.q-headline4 {
  font-size: 1rem;
  line-height: 1.625rem;
  margin-bottom: 0.25rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.071rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-headline4 {
    font-size: 1rem;
    line-height: 1.625rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-headline4 {
    font-size: 1rem;
    line-height: 1.625rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
.q-headline4 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-headline4 a:visited {
  color: #262626;
}
.q-headline4 a:hover, .q-headline4 a:active {
  color: #cd9834;
}
.q-invert.q-headline4 {
  color: #ffffff;
}
.q-invert.q-headline4 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-invert.q-headline4 a:visited {
  color: #ffffff;
}
.q-invert.q-headline4 a:hover, .q-invert.q-headline4 a:active {
  color: #e6ac39;
}
.q-heavy.q-headline4 {
  font-weight: 900;
}

.q-headline5 {
  font-size: 2.125rem;
  line-height: 3rem;
  margin-bottom: 0.125rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.04375rem;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-headline5 {
    font-size: 2.125rem;
    line-height: 3rem;
    margin-bottom: 0.125rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-headline5 {
    font-size: 2.125rem;
    line-height: 3rem;
    margin-bottom: 0.125rem;
    padding-top: 0rem;
  }
}
.q-headline5 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-headline5 a:visited {
  color: #262626;
}
.q-headline5 a:hover, .q-headline5 a:active {
  color: #cd9834;
}
.q-invert.q-headline5 {
  color: #ffffff;
}
.q-invert.q-headline5 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-invert.q-headline5 a:visited {
  color: #ffffff;
}
.q-invert.q-headline5 a:hover, .q-invert.q-headline5 a:active {
  color: #e6ac39;
}
.q-heavy.q-headline5 {
  font-weight: 900;
}

.q-headline6 {
  font-size: 1.75rem;
  line-height: 2.5rem;
  margin-bottom: 0.625rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.214rem;
  margin: 0;
  padding: 0;
  background-color: transparent;
  text-align: inherit;
}
@media print, only screen and (min-width:600px) {
  .q-headline6 {
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-headline6 {
    font-size: 1.75rem;
    line-height: 2.5rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
.q-headline6 a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-headline6 a:visited {
  color: #262626;
}
.q-headline6 a:hover, .q-headline6 a:active {
  color: #cd9834;
}
.q-invert.q-headline6 {
  color: #ffffff;
}
.q-invert.q-headline6 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-invert.q-headline6 a:visited {
  color: #ffffff;
}
.q-invert.q-headline6 a:hover, .q-invert.q-headline6 a:active {
  color: #e6ac39;
}
.q-heavy.q-headline6 {
  font-weight: 900;
}

.q-heavy {
  font-style: normal;
  font-weight: 900;
}

.q-body3, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text, .q-legal-notification .q-content-container .q-content.q-required-consent, .q-vehicle-information-row .q-vehicle-body-description, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price, .q-disclaimer-body, .q-alt-price, .q-body2, .q-body1 {
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
}
.q-body3 a, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description a, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs a, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text a, .q-legal-notification .q-content-container .q-content.q-required-consent a, .q-vehicle-information-row .q-vehicle-body-description a, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price a, .q-disclaimer-body a, .q-alt-price a, .q-body2 a, .q-body1 a {
  color: inherit;
  font-weight: 500;
  text-decoration: underline;
}
.q-body3 a:visited, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description a:visited, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs a:visited, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text a:visited, .q-legal-notification .q-content-container .q-content.q-required-consent a:visited, .q-vehicle-information-row .q-vehicle-body-description a:visited, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price a:visited, .q-disclaimer-body a:visited, .q-alt-price a:visited, .q-body2 a:visited, .q-body1 a:visited {
  color: #262626;
}
.q-body3 a:hover, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description a:hover, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs a:hover, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text a:hover, .q-legal-notification .q-content-container .q-content.q-required-consent a:hover, .q-vehicle-information-row .q-vehicle-body-description a:hover, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price a:hover, .q-disclaimer-body a:hover, .q-alt-price a:hover, .q-body2 a:hover, .q-body1 a:hover, .q-body3 a:active, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description a:active, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs a:active, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text a:active, .q-legal-notification .q-content-container .q-content.q-required-consent a:active, .q-vehicle-information-row .q-vehicle-body-description a:active, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price a:active, .q-disclaimer-body a:active, .q-alt-price a:active, .q-body2 a:active, .q-body1 a:active {
  color: #cd9834;
}
.q-body3 ul li, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description ul li, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs ul li, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text ul li, .q-legal-notification .q-content-container .q-content.q-required-consent ul li, .q-vehicle-information-row .q-vehicle-body-description ul li, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price ul li, .q-disclaimer-body ul li, .q-alt-price ul li, .q-body2 ul li, .q-body1 ul li,
.q-body3 ol li,
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-description ol li,
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs ol li,
.q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text ol li,
.q-legal-notification .q-content-container .q-content.q-required-consent ol li,
.q-vehicle-information-row .q-vehicle-body-description ol li,
.q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price ol li,
.q-disclaimer-body ol li,
.q-alt-price ol li,
.q-body2 ol li,
.q-body1 ol li {
  margin-top: 0;
}
.q-invert.q-body3, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-invert.q-description, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-specs, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-invert.q-tooltip-text, .q-legal-notification .q-content-container .q-invert.q-content.q-required-consent, .q-vehicle-information-row .q-invert.q-vehicle-body-description, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-vehicle-info-as-shown-price, .q-invert.q-disclaimer-body, .q-invert.q-alt-price, .q-invert.q-body2, .q-invert.q-body1 {
  color: #ffffff;
}
.q-invert.q-body3 a, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-invert.q-description a, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-specs a, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-invert.q-tooltip-text a, .q-legal-notification .q-content-container .q-invert.q-content.q-required-consent a, .q-vehicle-information-row .q-invert.q-vehicle-body-description a, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-vehicle-info-as-shown-price a, .q-invert.q-disclaimer-body a, .q-invert.q-alt-price a, .q-invert.q-body2 a, .q-invert.q-body1 a {
  color: inherit;
  font-weight: inherit;
  text-decoration: underline;
}
.q-invert.q-body3 a:visited, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-invert.q-description a:visited, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-specs a:visited, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-invert.q-tooltip-text a:visited, .q-legal-notification .q-content-container .q-invert.q-content.q-required-consent a:visited, .q-vehicle-information-row .q-invert.q-vehicle-body-description a:visited, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-vehicle-info-as-shown-price a:visited, .q-invert.q-disclaimer-body a:visited, .q-invert.q-alt-price a:visited, .q-invert.q-body2 a:visited, .q-invert.q-body1 a:visited {
  color: #ffffff;
}
.q-invert.q-body3 a:hover, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-invert.q-description a:hover, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-specs a:hover, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-invert.q-tooltip-text a:hover, .q-legal-notification .q-content-container .q-invert.q-content.q-required-consent a:hover, .q-vehicle-information-row .q-invert.q-vehicle-body-description a:hover, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-vehicle-info-as-shown-price a:hover, .q-invert.q-disclaimer-body a:hover, .q-invert.q-alt-price a:hover, .q-invert.q-body2 a:hover, .q-invert.q-body1 a:hover, .q-invert.q-body3 a:active, .q-trailering-towing-vehicle-capability-tile .q-content-section .q-invert.q-description a:active, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-invert.q-specs a:active, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-invert.q-tooltip-text a:active, .q-legal-notification .q-content-container .q-invert.q-content.q-required-consent a:active, .q-vehicle-information-row .q-invert.q-vehicle-body-description a:active, .q-vehicle-information-row .q-vehicle-info-text-price .q-invert.q-vehicle-info-as-shown-price a:active, .q-invert.q-disclaimer-body a:active, .q-invert.q-alt-price a:active, .q-invert.q-body2 a:active, .q-invert.q-body1 a:active {
  color: #e6ac39;
}

.q-body1 {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin-bottom: 0.375rem;
  padding-top: 0rem;
  color: #262626;
}
@media print, only screen and (min-width:600px) {
  .q-body1 {
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-body1 {
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
    padding-top: 0rem;
  }
}
.q-body1 ul,
.q-body1 ol {
  margin: 0 0 0 1.25rem;
}

.q-trailering-towing-vehicle-capability-tile .q-content-section .q-description, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text, .q-legal-notification .q-content-container .q-content.q-required-consent, .q-vehicle-information-row .q-vehicle-body-description, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price, .q-disclaimer-body, .q-alt-price, .q-body2 {
  font-size: 0.8rem;
  line-height: 1.25rem;
  margin-bottom: 0.625rem;
  padding-top: 0rem;
  color: #808080;
}
@media print, only screen and (min-width:600px) {
  .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text, .q-legal-notification .q-content-container .q-content.q-required-consent, .q-vehicle-information-row .q-vehicle-body-description, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price, .q-disclaimer-body, .q-alt-price, .q-body2 {
    font-size: 0.8rem;
    line-height: 1.25rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text, .q-legal-notification .q-content-container .q-content.q-required-consent, .q-vehicle-information-row .q-vehicle-body-description, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price, .q-disclaimer-body, .q-alt-price, .q-body2 {
    font-size: 0.8rem;
    line-height: 1.25rem;
    margin-bottom: 0.625rem;
    padding-top: 0rem;
  }
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-description ul, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs ul, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text ul, .q-legal-notification .q-content-container .q-content.q-required-consent ul, .q-vehicle-information-row .q-vehicle-body-description ul, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price ul, .q-disclaimer-body ul, .q-alt-price ul, .q-body2 ul,
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-description ol,
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs ol,
.q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text ol,
.q-legal-notification .q-content-container .q-content.q-required-consent ol,
.q-vehicle-information-row .q-vehicle-body-description ol,
.q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price ol,
.q-disclaimer-body ol,
.q-alt-price ol,
.q-body2 ol {
  margin: 0 0 0 1.25rem;
}

.q-caps {
  text-transform: uppercase;
}

.q-invert.q-label2, .q-invert.q-label1 {
  color: #ffffff;
}

.q-label1 {
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.q-label2 {
  color: #808080;
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
}

.q-invert {
  color: #ffffff;
}

@media only screen and (min-width:60em) {
  .q-headline .q-scroller-indicator-text {
    color: #ffffff;
  }
}

.q-price {
  white-space: nowrap;
}

@media only screen and (max-width: 26.1875em) {
  .hide-for-small {
    display: none !important;
  }
}
@media print, only screen and (min-width:26.25em) and (max-width:37.4375em) {
  .hide-for-medium {
    display: none !important;
  }
}
@media print, only screen and (min-width:37.5em) and (max-width:59.9375em) {
  .hide-for-large {
    display: none !important;
  }
}
@media only screen and (min-width:60em) and (max-width:74.9375em) {
  .hide-for-xlarge {
    display: none !important;
  }
}
@media only screen and (min-width:75em) and (max-width:6249999.9375em) {
  .hide-for-xxlarge {
    display: none !important;
  }
}
@media only screen {
  .hide-for-small-up {
    display: none !important;
  }
}
@media print, only screen and (min-width:26.25em) {
  .hide-for-medium-up {
    display: none !important;
  }
}
@media print, only screen and (min-width:37.5em) {
  .hide-for-large-up {
    display: none !important;
  }
}
@media only screen and (min-width:60em) {
  .hide-for-xlarge-up {
    display: none !important;
  }
}
@media only screen and (min-width:75em) {
  .hide-for-xxlarge-up {
    display: none !important;
  }
}
@media print, only screen and (max-width:26.1875em) {
  .hide-for-small-down {
    display: none !important;
  }
}
@media print, only screen and (max-width:37.4375em) {
  .hide-for-medium-down {
    display: none !important;
  }
}
@media print, only screen and (max-width:59.9375em) {
  .hide-for-large-down {
    display: none !important;
  }
}
@media print, only screen and (max-width:74.9375em) {
  .hide-for-xlarge-down {
    display: none !important;
  }
}
@media print, only screen and (max-width:6249999.9375em) {
  .hide-for-xxlarge-down {
    display: none !important;
  }
}
@media print, only screen and (min-width:26.25em) and (max-width:37.4375em) {
  .show-for-small-only {
    display: none !important;
  }
}
@media print, only screen and (min-width:37.5em) and (max-width:59.9375em) {
  .show-for-small-only {
    display: none !important;
  }
}
@media only screen and (min-width:60em) and (max-width:74.9375em) {
  .show-for-small-only {
    display: none !important;
  }
}
@media only screen and (min-width:75em) and (max-width:6249999.9375em) {
  .show-for-small-only {
    display: none !important;
  }
}
@media only screen and (max-width: 26.1875em) {
  .show-for-medium-only {
    display: none !important;
  }
}
@media print, only screen and (min-width:37.5em) and (max-width:59.9375em) {
  .show-for-medium-only {
    display: none !important;
  }
}
@media only screen and (min-width:60em) and (max-width:74.9375em) {
  .show-for-medium-only {
    display: none !important;
  }
}
@media only screen and (min-width:75em) and (max-width:6249999.9375em) {
  .show-for-medium-only {
    display: none !important;
  }
}
@media only screen and (max-width: 26.1875em) {
  .show-for-large-only {
    display: none !important;
  }
}
@media print, only screen and (min-width:26.25em) and (max-width:37.4375em) {
  .show-for-large-only {
    display: none !important;
  }
}
@media only screen and (min-width:60em) and (max-width:74.9375em) {
  .show-for-large-only {
    display: none !important;
  }
}
@media only screen and (min-width:75em) and (max-width:6249999.9375em) {
  .show-for-large-only {
    display: none !important;
  }
}
@media only screen and (max-width: 26.1875em) {
  .show-for-xlarge-only {
    display: none !important;
  }
}
@media print, only screen and (min-width:26.25em) and (max-width:37.4375em) {
  .show-for-xlarge-only {
    display: none !important;
  }
}
@media print, only screen and (min-width:37.5em) and (max-width:59.9375em) {
  .show-for-xlarge-only {
    display: none !important;
  }
}
@media only screen and (min-width:75em) and (max-width:6249999.9375em) {
  .show-for-xlarge-only {
    display: none !important;
  }
}
@media only screen and (max-width: 26.1875em) {
  .show-for-xxlarge-only {
    display: none !important;
  }
}
@media print, only screen and (min-width:26.25em) and (max-width:37.4375em) {
  .show-for-xxlarge-only {
    display: none !important;
  }
}
@media print, only screen and (min-width:37.5em) and (max-width:59.9375em) {
  .show-for-xxlarge-only {
    display: none !important;
  }
}
@media only screen and (min-width:60em) and (max-width:74.9375em) {
  .show-for-xxlarge-only {
    display: none !important;
  }
}
@media print, only screen and (max-width:26.1875em) {
  .show-for-medium-up {
    display: none !important;
  }
}
@media print, only screen and (max-width:37.4375em) {
  .show-for-large-up {
    display: none !important;
  }
}
@media print, only screen and (max-width:59.9375em) {
  .show-for-xlarge-up {
    display: none !important;
  }
}
@media print, only screen and (min-width:37.5em) {
  .show-for-medium-down {
    display: none !important;
  }
}
@media only screen and (min-width:60em) {
  .show-for-large-down {
    display: none !important;
  }
}
@media only screen and (min-width:75em) {
  .show-for-xlarge-down {
    display: none !important;
  }
}
@font-face {
  font-display: fallback;
  font-family: "Louis";
  font-style: italic;
  font-weight: 700;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-bolditalic-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-bolditalic-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Louis";
  font-style: normal;
  font-weight: 700;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-bold-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-bold-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Louis";
  font-style: italic;
  font-weight: 900;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-heavyitalic-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-heavyitalic-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Louis";
  font-style: normal;
  font-weight: 900;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-heavy-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-heavy-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Louis";
  font-style: italic;
  font-weight: 400;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-italic-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-italic-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Louis";
  font-style: normal;
  font-weight: 400;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-regular-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/louis-regular-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-bolditalic-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-bolditalic-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-bold-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-bold-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-italic-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-italic-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-lightitalic-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-lightitalic-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-light-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-light-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-mediumitalic-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-mediumitalic-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-medium-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-medium-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-regular-webfont.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/roboto-regular-webfont.woff") format("woff");
}
@font-face {
  font-display: fallback;
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 700;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NotoSansKR-Bold.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NotoSansKR-Bold.woff") format("woff");
  unicode-range: U+AC00-D7A3, U+1100-11FF, U+3130-318F, U+A960-A97F, U+D7B0-D7FF;
}
@font-face {
  font-display: fallback;
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 500;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NotoSansKR-Medium.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NotoSansKR-Medium.woff") format("woff");
  unicode-range: U+AC00-D7A3, U+1100-11FF, U+3130-318F, U+A960-A97F, U+D7B0-D7FF;
}
@font-face {
  font-display: fallback;
  font-family: "NotoSansKR";
  font-style: normal;
  font-weight: 400;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NotoSansKR-Regular.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NotoSansKR-Regular.woff") format("woff");
  unicode-range: U+AC00-D7A3, U+1100-11FF, U+3130-318F, U+A960-A97F, U+D7B0-D7FF;
}
@font-face {
  font-display: fallback;
  font-family: "NanumGothic";
  font-style: normal;
  font-weight: 700;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NanumGothic-Bold.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NanumGothic-Bold.woff") format("woff");
  unicode-range: U+AC00-D7A3, U+1100-11FF, U+3130-318F, U+A960-A97F, U+D7B0-D7FF;
}
@font-face {
  font-display: fallback;
  font-family: "NanumGothic";
  font-style: normal;
  font-weight: 400;
  src: url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NanumGothic-Regular.woff2") format("woff2"), url("/etc.clientlibs/clientlibs/chevrolet/resources/fonts/NanumGothic-Regular.woff") format("woff");
  unicode-range: U+AC00-D7A3, U+1100-11FF, U+3130-318F, U+A960-A97F, U+D7B0-D7FF;
}
footer .q-expander {
  margin: 0;
}
footer .q-expander .q-button.q-link:active, footer .q-expander .q-button.q-link:focus {
  color: #808080;
}
.no-touch footer .q-button.q-link:focus {
  color: #808080;
}
.no-touch footer .q-button.q-link:focus:hover {
  color: #262626;
}
footer .image-svg-container .q-rectangle-image-responsive {
  width: 100%;
}

.q-trailering-towing-vehicle-capability-tile .q-content-section .q-description, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text, .q-legal-notification .q-content-container .q-content.q-required-consent, .q-vehicle-information-row .q-vehicle-body-description, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price, .q-disclaimer-body, .q-alt-price, .q-body2 {
  color: #6e6e6e;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-description a, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs a, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text a, .q-legal-notification .q-content-container .q-content.q-required-consent a, .q-vehicle-information-row .q-vehicle-body-description a, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price a, .q-disclaimer-body a, .q-alt-price a, .q-body2 a {
  font-weight: 700;
}
@media only screen and (max-width: 26.1875em) {
  .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text, .q-legal-notification .q-content-container .q-content.q-required-consent, .q-vehicle-information-row .q-vehicle-body-description, .q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price, .q-disclaimer-body, .q-alt-price, .q-body2 {
    color: #262626;
  }
}

.q-headline1,
.q-headline2,
.q-headline3,
.q-descriptive1,
.q-descriptive2 {
  margin-bottom: 0;
  margin-top: 0;
}

.q-descriptive-section {
  font-size: 0.866rem;
  line-height: 1.333rem;
  margin-bottom: 0.542rem;
  padding-top: 0rem;
  color: #6e6e6e;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: inherit;
  font-weight: 700;
  letter-spacing: 0.3rem;
  margin: 0;
  padding: 0;
  margin-bottom: 0;
}
@media print, only screen and (min-width:600px) {
  .q-descriptive-section {
    font-size: 0.866rem;
    line-height: 1.333rem;
    margin-bottom: 0.542rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-descriptive-section {
    font-size: 0.866rem;
    line-height: 1.333rem;
    margin-bottom: 0.542rem;
    padding-top: 0rem;
  }
}
.q-descriptive-section a {
  color: #6e6e6e;
  font-weight: inherit;
  text-decoration: none;
}
.q-descriptive-section a:visited {
  color: #262626;
}
.q-descriptive-section a:hover, .q-descriptive-section a:active {
  color: #cd9834;
}
.q-descriptive-section.q-invert {
  color: #ffffff;
}
.q-descriptive-section.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-descriptive-section.q-invert a:visited {
  color: #ffffff;
}
.q-descriptive-section.q-invert a:hover, .q-descriptive-section.q-invert a:active {
  color: #e6ac39;
}
.q-retail-body {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.25rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-retail-body {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-retail-body {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 0.25rem;
    padding-top: 0rem;
  }
}
.q-retail-body a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-body a:visited {
  color: #262626;
}
.q-retail-body a:hover, .q-retail-body a:active {
  color: #cd9834;
}
.q-retail-body.q-invert {
  color: #ffffff;
}
.q-retail-body.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-body.q-invert a:visited {
  color: #ffffff;
}
.q-retail-body.q-invert a:hover, .q-retail-body.q-invert a:active {
  color: #e6ac39;
}
.q-retail-label {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 0.575rem;
  padding-top: 0rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-retail-label {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.575rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-retail-label {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 0.575rem;
    padding-top: 0rem;
  }
}
.q-retail-label a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-label a:visited {
  color: #262626;
}
.q-retail-label a:hover, .q-retail-label a:active {
  color: #cd9834;
}
.q-retail-label.q-invert {
  color: #ffffff;
}
.q-retail-label.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-label.q-invert a:visited {
  color: #ffffff;
}
.q-retail-label.q-invert a:hover, .q-retail-label.q-invert a:active {
  color: #e6ac39;
}
.q-retail-numeral {
  font-size: 1.75rem;
  line-height: 1.75rem;
  margin-bottom: 0.125rem;
  padding-top: 0rem;
  color: #cd9834;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
@media print, only screen and (min-width:600px) {
  .q-retail-numeral {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin-bottom: 0.125rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-retail-numeral {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin-bottom: 0.125rem;
    padding-top: 0rem;
  }
}
.q-retail-numeral a {
  color: #cd9834;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-numeral a:visited {
  color: #262626;
}
.q-retail-numeral a:hover, .q-retail-numeral a:active {
  color: #cd9834;
}
.q-retail-numeral.q-invert {
  color: #ffffff;
}
.q-retail-numeral.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-retail-numeral.q-invert a:visited {
  color: #ffffff;
}
.q-retail-numeral.q-invert a:hover, .q-retail-numeral.q-invert a:active {
  color: #e6ac39;
}
.q-body3 {
  font-size: 0.8rem;
  line-height: 1rem;
  margin-bottom: 0.25rem;
  padding-top: 0rem;
  color: #6e6e6e;
}
@media print, only screen and (min-width:600px) {
  .q-body3 {
    font-size: 0.7rem;
    line-height: 0.75rem;
    margin-bottom: 0.5rem;
    padding-top: 0rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-body3 {
    font-size: 0.7rem;
    line-height: 0.75rem;
    margin-bottom: 0.5rem;
    padding-top: 0rem;
  }
}
@media only screen {
  .q-body3 {
    margin-bottom: 1rem;
  }
}
.q-body3 ul,
.q-body3 ol {
  margin: 0 0 0 1.25rem;
}

.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-title, .q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-year, .q-tab-nav .q-360-colorizer .q-colorizer-category, .q-legal-notification .q-content-container .q-content.q-silent-consent, .q-legal-notification .q-content-container .q-title .q-title-label, .q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-year, .q-vehicle-information-row .q-vehicle-info-text-price .q-from-label, .q-descriptive2 {
  margin-bottom: 0 !important;
}

.q-alt-price {
  color: #808080;
  line-height: 1.25rem;
  margin-bottom: 0;
}
@media only screen and (min-width:60em) {
  .q-alt-price {
    font-size: 0.875rem;
  }
}

/* endinject */
/* inject:components */
.q-button {
  transition: background;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  background-color: transparent;
  border-style: solid;
  border-width: 2px;
  color: #262626;
  display: block;
  font: 700 0.706rem Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  letter-spacing: normal;
  line-height: 2;
  margin-bottom: 0;
  padding: 0.824rem 0;
  text-align: center;
  width: 100%;
}

.q-button.q-link, .q-button.q-icon-only {
  border: 0;
  display: inline-block;
  text-align: inherit;
  width: auto;
}
.gb-target-zone-button.q-button.q-link, .gb-target-zone-button.q-button.q-icon-only {
  background-color: transparent;
}

.q-button-large.q-button.q-list-item {
  padding-bottom: 1.75rem;
  padding-top: 1.75rem;
}

.q-button.q-list-item {
  border: 0;
  border-left: 4px solid transparent;
  text-align: inherit;
}
.q-button-padding-none.q-button.q-list-item {
  padding-left: 0;
  padding-right: 0;
}
.q-button-padding.q-button.q-list-item {
  padding-left: 1.625rem;
  padding-right: 1.875rem;
}

.q-invert.q-button.q-secondary-button {
  border-color: #737373;
  color: #ffffff;
}

.q-button.q-primary-button {
  border-color: #b3842d;
}
.gb-target-zone-button-active.q-button.q-primary-button {
  background-color: #b3842d;
  color: #262626;
}
.q-invert.gb-target-zone-button-active.q-button.q-primary-button {
  background-color: #b3842d;
  color: #ffffff;
}

.no-touch .q-button.q-primary-button:active, .no-touch .q-button.q-primary-button:hover, .no-touch .q-button.q-primary-button:focus, .no-touch .gb-target-zone-button-active.q-button.q-primary-button {
  background-color: #b3842d;
  background-color: #b3842d;
  border-color: #b3842d;
  color: #262626;
}
.no-touch .q-invert.q-button.q-primary-button:active, .no-touch .q-invert.q-button.q-primary-button:hover, .no-touch .q-invert.q-button.q-primary-button:focus {
  background-color: #b3842d;
  color: #ffffff;
}

.q-button.q-secondary-button {
  border-color: #8c8c8c;
}
.gb-target-zone-button-active.q-button.q-secondary-button {
  background-color: #8c8c8c;
  border-color: #8c8c8c;
  color: #262626;
}
.q-invert.gb-target-zone-button-active.q-button.q-secondary-button {
  background-color: #737373;
  border-color: #737373;
  color: #ffffff;
}

.no-touch .q-button.q-secondary-button:active, .no-touch .q-button.q-secondary-button:hover, .no-touch .q-button.q-secondary-button:focus, .no-touch .gb-target-zone-button-active.q-button.q-secondary-button {
  background-color: #8c8c8c;
  background-color: #737373;
  border-color: #737373;
}
.no-touch .q-invert.q-button.q-secondary-button:active, .no-touch .q-invert.q-button.q-secondary-button:hover, .no-touch .q-invert.q-button.q-secondary-button:focus, .no-touch .q-invert.gb-target-zone-button-active.q-button.q-secondary-button {
  background-color: #737373;
  border-color: #737373;
  color: #ffffff;
}

.q-button.q-inline-icon-quaternary, .q-button.q-quaternary-button {
  background-color: #ffffff;
  border: 0;
  border-radius: 8px;
  color: #262626;
  padding-inline: 1rem;
}
.gb-target-zone-button-active.q-button.q-inline-icon-quaternary, .gb-target-zone-button-active.q-button.q-quaternary-button {
  background-color: #262626;
  color: #ffffff;
}
.gb-target-zone-button-active.q-button.q-inline-icon-quaternary .icon-normal, .gb-target-zone-button-active.q-button.q-quaternary-button .icon-normal {
  display: none;
}
.gb-target-zone-button-active.q-button.q-inline-icon-quaternary .icon-hover, .gb-target-zone-button-active.q-button.q-quaternary-button .icon-hover {
  display: inline;
}
.gb-target-zone-button-active.q-button.q-inline-icon-quaternary img:only-child, .gb-target-zone-button-active.q-button.q-quaternary-button img:only-child {
  display: inline;
}
.gb-target-zone-button-active.q-button.q-inline-icon-quaternary::after, .gb-target-zone-button-active.q-button.q-quaternary-button::after {
  background-image: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/chevron-white.svg");
  background-position: center right;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
}
.gb-target-zone-button-active.q-button.q-inline-icon-quaternary::after, .gb-target-zone-button-active.q-button.q-quaternary-button::after {
  right: 1rem;
}
.q-button.q-inline-icon-quaternary .move-text, .q-button.q-quaternary-button .move-text {
  padding-inline-end: 1rem;
}

.no-touch .q-button.q-inline-icon-quaternary:active, .no-touch .q-button.q-quaternary-button:active, .no-touch .q-button.q-inline-icon-quaternary:hover, .no-touch .q-button.q-quaternary-button:hover, .no-touch .q-button.q-inline-icon-quaternary:focus, .no-touch .q-button.q-quaternary-button:focus, .no-touch .gb-target-zone-button-active.q-button.q-inline-icon-quaternary, .no-touch .gb-target-zone-button-active.q-button.q-quaternary-button {
  background-color: #ffffff;
  background-color: #262626;
  border-color: #262626;
  color: #ffffff;
}

.q-list-item-primary.q-button.q-list-item, .q-list-item-icon-primary.q-button.q-list-item {
  background-color: transparent;
  border-bottom: solid 1px #b3b3b3;
  color: #262626;
  line-height: 1.375rem;
  text-align: left;
}

.no-touch .q-button.q-list-item.q-list-item-primary:active, .no-touch .q-button.q-list-item.q-list-item-icon-primary:active, .no-touch .q-button.q-list-item.q-list-item-primary:hover, .no-touch .q-button.q-list-item.q-list-item-icon-primary:hover {
  background-color: transparent;
  border-left-color: #cd9834;
}

.q-list-item-secondary.q-button.q-list-item, .q-list-item-icon-secondary.q-button.q-list-item {
  background-color: transparent;
  border-bottom: solid 1px #b3b3b3;
  color: #262626;
  line-height: 1.375rem;
  text-align: left;
}
.q-list-item-tertiary.q-button.q-list-item, .q-list-item-icon-tertiary.q-button.q-list-item {
  background-color: transparent;
  border-bottom: solid 1px #b3b3b3;
  color: #b3b3b3;
  line-height: 1.375rem;
  text-align: left;
  border-left: 4px solid transparent;
}
.q-list-item-icon-secondary.q-button.q-list-item {
  color: #808080;
}
.q-button {
  cursor: pointer;
}
.q-button:active div, .q-button:focus div {
  left: 0;
  position: relative;
  top: 0;
}
.q-button.q-link {
  font-size: 0.7rem;
  letter-spacing: normal;
  line-height: 1.375rem;
  padding: 10px 0;
}
.q-button.q-link:hover, .q-button.q-link:active, .q-button.q-link:focus {
  background-color: transparent;
}
.q-button.q-link.gb-target-zone-button-active {
  color: #947029;
}
.q-button.q-link.q-invert {
  color: #ffffff;
}
.q-button.q-link.q-invert.gb-target-zone-button-active {
  color: #b3842d;
}
.q-button.q-icon-stacked, .q-button.q-inline-icon {
  border-color: #cd9834;
}
.q-button.q-icon-stacked-secondary, .q-button.q-inline-icon-secondary {
  border-color: #b3b3b3;
  color: #262626;
}
.q-button.q-icon-stacked-secondary.q-invert, .q-button.q-inline-icon-secondary.q-invert {
  border-color: #b3b3b3;
  color: #ffffff;
}
.q-button.q-inline-icon-quaternary {
  text-align: start;
}
.q-button.q-inline-icon-quaternary .q-shift-icon .move-icon {
  flex: 0 0 auto;
  margin-inline-end: 1rem;
}
.q-button .q-svg-icon-xsmall {
  height: 0.625rem;
  width: 0.625rem;
  margin: auto;
}
.q-button .q-svg-icon-small {
  height: 1.25rem;
  width: 1.25rem;
  margin: auto;
}
.q-button .q-svg-icon-medium {
  height: 1.875rem;
  width: 1.875rem;
  margin: auto;
}
.q-button .q-svg-icon-large {
  height: 2.5rem;
  width: 2.5rem;
  margin: auto;
}
.q-button .q-svg-icon-xlarge {
  height: 3.75rem;
  width: 3.75rem;
  margin: auto;
}
.q-button .q-svg-icon-xxlarge {
  height: 5.938rem;
  width: 5.938rem;
  margin: auto;
}
.q-button.q-icon-stacked .q-svg-icon, .q-button.q-icon-stacked-secondary .q-svg-icon {
  height: 1.304rem;
  width: 1.304rem;
  margin-bottom: 0.625rem;
}
@media print, only screen and (min-width:37.5em) {
  .q-button.q-icon-stacked .q-svg-icon, .q-button.q-icon-stacked-secondary .q-svg-icon {
    height: 1.304rem;
    width: 1.304rem;
  }
}
.q-button.q-icon-stacked .icon-font, .q-button.q-icon-stacked-secondary .icon-font {
  font-size: 1.304rem;
  margin-bottom: 0.625rem;
}
@media print, only screen and (min-width:37.5em) {
  .q-button.q-icon-stacked .icon-font, .q-button.q-icon-stacked-secondary .icon-font {
    font-size: 1.304rem;
  }
}
.q-button .icon-font {
  font-size: 1.176rem;
}
.q-button .q-svg-icon {
  height: 1.176rem;
  width: 1.176rem;
}
@media print, only screen and (min-width:37.5em) {
  .q-button .q-svg-icon {
    height: 1.304rem;
    width: 1.304rem;
  }
}
.q-button .q-svg-icon svg {
  height: 100%;
  width: 100%;
}
.q-button .q-shift-icon {
  align-items: center;
  display: inline-flex;
}
.q-button .q-shift-icon.q-icon-right .move-text {
  margin-right: 1.25rem;
  order: 1;
}
.q-button .q-shift-icon.q-icon-right .move-icon {
  order: 2;
}
.q-button .q-shift-icon.q-icon-left .move-text {
  order: 2;
}
.q-button .q-shift-icon.q-icon-left .move-icon {
  margin-right: 1.25rem;
  order: 1;
}
.q-button.q-invert {
  color: #ffffff;
}
.q-button.q-invert svg {
  height: 100%;
  width: 100%;
}
.q-button.hoverable-icon .hoverable-container .icon-normal {
  display: inline;
}
.q-button.hoverable-icon .hoverable-container .icon-hover {
  display: none;
}
.q-button.hoverable-icon .hoverable-container .icon-active {
  display: none;
}
.q-button.hoverable-icon:active .hoverable-container .icon-normal, .q-button.hoverable-icon:hover .hoverable-container .icon-normal, .q-button.hoverable-icon:focus .hoverable-container .icon-normal {
  display: none;
}
.q-button.hoverable-icon:active .hoverable-container .icon-hover, .q-button.hoverable-icon:hover .hoverable-container .icon-hover, .q-button.hoverable-icon:focus .hoverable-container .icon-hover {
  display: inline;
}
.q-button.hoverable-icon:active .hoverable-container .icon-active, .q-button.hoverable-icon:hover .hoverable-container .icon-active, .q-button.hoverable-icon:focus .hoverable-container .icon-active {
  display: none;
}
.q-button.hoverable-icon.gb-target-zone-button-active .hoverable-container .icon-normal {
  display: none;
}
.q-button.hoverable-icon.gb-target-zone-button-active .hoverable-container .icon-hover {
  display: none;
}
.q-button.hoverable-icon.gb-target-zone-button-active .hoverable-container .icon-active {
  display: inline;
}
.columns > .q-button.q-link, .columns > .q-button.q-icon-only {
  display: block;
}
.q-button.gb-target-zone-button-active {
  color: #262626;
}

.no-touch .q-button:active, .no-touch .q-button:hover, .no-touch .q-button:focus {
  color: #262626;
}
.no-touch .q-button.q-link:active, .no-touch .q-button.q-link:hover, .no-touch .q-button.q-link:focus, .no-touch .q-button.q-icon-only:active, .no-touch .q-button.q-icon-only:hover, .no-touch .q-button.q-icon-only:focus {
  color: #947029;
}
.no-touch .q-button.q-link.q-invert:active, .no-touch .q-button.q-link.q-invert:hover, .no-touch .q-button.q-link.q-invert:focus, .no-touch .q-button.q-icon-only.q-invert:active, .no-touch .q-button.q-icon-only.q-invert:hover, .no-touch .q-button.q-icon-only.q-invert:focus {
  color: #b3842d;
}
.no-touch .q-button.q-icon-stacked:active, .no-touch .q-button.q-icon-stacked:hover, .no-touch .q-button.q-icon-stacked:focus, .no-touch .q-button.q-icon-stacked.gb-target-zone-button-active, .no-touch .q-button.q-inline-icon:active, .no-touch .q-button.q-inline-icon:hover, .no-touch .q-button.q-inline-icon:focus, .no-touch .q-button.q-inline-icon.gb-target-zone-button-active {
  box-shadow: inset 0 0 0 2px #cd9834;
}
.no-touch .q-button.q-icon-stacked.q-invert:active, .no-touch .q-button.q-icon-stacked.q-invert:hover, .no-touch .q-button.q-icon-stacked.q-invert:focus, .no-touch .q-button.q-inline-icon.q-invert:active, .no-touch .q-button.q-inline-icon.q-invert:hover, .no-touch .q-button.q-inline-icon.q-invert:focus {
  color: #ffffff;
}
.no-touch .q-button.q-icon-stacked-secondary:active, .no-touch .q-button.q-icon-stacked-secondary:hover, .no-touch .q-button.q-icon-stacked-secondary:focus, .no-touch .q-button.q-icon-stacked-secondary.gb-target-zone-button-active, .no-touch .q-button.q-inline-icon-secondary:active, .no-touch .q-button.q-inline-icon-secondary:hover, .no-touch .q-button.q-inline-icon-secondary:focus, .no-touch .q-button.q-inline-icon-secondary.gb-target-zone-button-active {
  box-shadow: inset 0 0 0 2px #b3b3b3;
}
.no-touch .q-button.q-icon-stacked-secondary.q-invert:active, .no-touch .q-button.q-icon-stacked-secondary.q-invert:hover, .no-touch .q-button.q-icon-stacked-secondary.q-invert:focus, .no-touch .q-button.q-inline-icon-secondary.q-invert:active, .no-touch .q-button.q-inline-icon-secondary.q-invert:hover, .no-touch .q-button.q-inline-icon-secondary.q-invert:focus {
  background-color: transparent;
  border-color: #b3b3b3;
  color: #ffffff;
}
.no-touch .q-button.q-list-item.q-button-padding:active, .no-touch .q-button.q-list-item.q-button-padding:hover {
  transition: border-color;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  border-left-color: #cd9834;
}
.no-touch .q-button.q-list-item.q-list-item-secondary:hover, .no-touch .q-button.q-list-item.q-list-item-icon-secondary:hover {
  background-color: transparent;
  border-left-color: #cd9834;
}
.no-touch .q-button.q-list-item.q-list-item-tertiary, .no-touch .q-button.q-list-item.q-list-item-icon-tertiary {
  transition: border-color;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.no-touch .q-button.q-list-item.q-list-item-tertiary:active, .no-touch .q-button.q-list-item.q-list-item-tertiary:focus, .no-touch .q-button.q-list-item.q-list-item-tertiary:hover, .no-touch .q-button.q-list-item.q-list-item-icon-tertiary:active, .no-touch .q-button.q-list-item.q-list-item-icon-tertiary:focus, .no-touch .q-button.q-list-item.q-list-item-icon-tertiary:hover {
  border-left-color: #cd9834;
  color: #262626;
}
.q-button {
  transition: background;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  background-color: transparent;
  border-style: solid;
  border-width: 2px;
  color: #262626;
  display: block;
  font: 700 0.706rem Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  letter-spacing: normal;
  line-height: 2;
  margin-bottom: 0;
  padding: 0.824rem 0;
  text-align: center;
  width: 100%;
}

.q-button.q-link, .q-button.q-icon-only {
  border: 0;
  display: inline-block;
  text-align: inherit;
  width: auto;
}
.gb-target-zone-button.q-button.q-link, .gb-target-zone-button.q-button.q-icon-only {
  background-color: transparent;
}

.q-button-large.q-button.q-list-item {
  padding-bottom: 1.75rem;
  padding-top: 1.75rem;
}

.q-button.q-list-item {
  border: 0;
  border-left: 4px solid transparent;
  text-align: inherit;
}
.q-button-padding-none.q-button.q-list-item {
  padding-left: 0;
  padding-right: 0;
}
.q-button-padding.q-button.q-list-item {
  padding-left: 1.625rem;
  padding-right: 1.875rem;
}

.q-invert.q-button.q-secondary-button {
  border-color: #737373;
  color: #ffffff;
}

.q-button.q-primary-button {
  border-color: #b3842d;
}
.gb-target-zone-button-active.q-button.q-primary-button {
  background-color: #b3842d;
  color: #262626;
}
.q-invert.gb-target-zone-button-active.q-button.q-primary-button {
  background-color: #b3842d;
  color: #ffffff;
}

.no-touch .q-button.q-primary-button:active, .no-touch .q-button.q-primary-button:hover, .no-touch .q-button.q-primary-button:focus, .no-touch .gb-target-zone-button-active.q-button.q-primary-button {
  background-color: #b3842d;
  background-color: #b3842d;
  border-color: #b3842d;
  color: #262626;
}
.no-touch .q-invert.q-button.q-primary-button:active, .no-touch .q-invert.q-button.q-primary-button:hover, .no-touch .q-invert.q-button.q-primary-button:focus {
  background-color: #b3842d;
  color: #ffffff;
}

.q-button.q-secondary-button {
  border-color: #8c8c8c;
}
.gb-target-zone-button-active.q-button.q-secondary-button {
  background-color: #8c8c8c;
  border-color: #8c8c8c;
  color: #262626;
}
.q-invert.gb-target-zone-button-active.q-button.q-secondary-button {
  background-color: #737373;
  border-color: #737373;
  color: #ffffff;
}

.no-touch .q-button.q-secondary-button:active, .no-touch .q-button.q-secondary-button:hover, .no-touch .q-button.q-secondary-button:focus, .no-touch .gb-target-zone-button-active.q-button.q-secondary-button {
  background-color: #8c8c8c;
  background-color: #737373;
  border-color: #737373;
}
.no-touch .q-invert.q-button.q-secondary-button:active, .no-touch .q-invert.q-button.q-secondary-button:hover, .no-touch .q-invert.q-button.q-secondary-button:focus, .no-touch .q-invert.gb-target-zone-button-active.q-button.q-secondary-button {
  background-color: #737373;
  border-color: #737373;
  color: #ffffff;
}

.q-button.q-quaternary-button, .q-button.q-inline-icon-quaternary {
  background-color: #ffffff;
  border: 0;
  border-radius: 8px;
  color: #262626;
  padding-inline: 1rem;
}
.gb-target-zone-button-active.q-button.q-quaternary-button, .gb-target-zone-button-active.q-button.q-inline-icon-quaternary {
  background-color: #262626;
  color: #ffffff;
}
.gb-target-zone-button-active.q-button.q-quaternary-button .icon-normal, .gb-target-zone-button-active.q-button.q-inline-icon-quaternary .icon-normal {
  display: none;
}
.gb-target-zone-button-active.q-button.q-quaternary-button .icon-hover, .gb-target-zone-button-active.q-button.q-inline-icon-quaternary .icon-hover {
  display: inline;
}
.gb-target-zone-button-active.q-button.q-quaternary-button img:only-child, .gb-target-zone-button-active.q-button.q-inline-icon-quaternary img:only-child {
  display: inline;
}
.gb-target-zone-button-active.q-button.q-quaternary-button::after, .gb-target-zone-button-active.q-button.q-inline-icon-quaternary::after {
  background-image: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/chevron-white.svg");
  background-position: center right;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
}
.gb-target-zone-button-active.q-button.q-quaternary-button::after, .gb-target-zone-button-active.q-button.q-inline-icon-quaternary::after {
  right: 1rem;
}
.q-button.q-quaternary-button .move-text, .q-button.q-inline-icon-quaternary .move-text {
  padding-inline-end: 1rem;
}

.no-touch .q-button.q-quaternary-button:active, .no-touch .q-button.q-inline-icon-quaternary:active, .no-touch .q-button.q-quaternary-button:hover, .no-touch .q-button.q-inline-icon-quaternary:hover, .no-touch .q-button.q-quaternary-button:focus, .no-touch .q-button.q-inline-icon-quaternary:focus, .no-touch .gb-target-zone-button-active.q-button.q-quaternary-button, .no-touch .gb-target-zone-button-active.q-button.q-inline-icon-quaternary {
  background-color: #ffffff;
  background-color: #262626;
  border-color: #262626;
  color: #ffffff;
}

.q-list-item-primary.q-button.q-list-item, .q-list-item-icon-primary.q-button.q-list-item {
  background-color: transparent;
  border-bottom: solid 1px #b3b3b3;
  color: #262626;
  line-height: 1.375rem;
  text-align: left;
}

.no-touch .q-button.q-list-item.q-list-item-primary:active, .no-touch .q-button.q-list-item.q-list-item-icon-primary:active, .no-touch .q-button.q-list-item.q-list-item-primary:hover, .no-touch .q-button.q-list-item.q-list-item-icon-primary:hover {
  background-color: transparent;
  border-left-color: #cd9834;
}

.q-list-item-secondary.q-button.q-list-item, .q-list-item-icon-secondary.q-button.q-list-item {
  background-color: transparent;
  border-bottom: solid 1px #b3b3b3;
  color: #262626;
  line-height: 1.375rem;
  text-align: left;
}
.q-list-item-tertiary.q-button.q-list-item, .q-list-item-icon-tertiary.q-button.q-list-item {
  background-color: transparent;
  border-bottom: solid 1px #b3b3b3;
  color: #b3b3b3;
  line-height: 1.375rem;
  text-align: left;
  border-left: 4px solid transparent;
}
.q-list-item-icon-secondary.q-button.q-list-item {
  color: #808080;
}
.q-overlay.q-content-overlay-gradient-generic-type-on-top-white .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-top-white .q-responsive-image-container::before, .q-overlay.q-content-overlay-gradient-generic-type-on-top-cool .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-top-cool .q-responsive-image-container::before, .q-overlay.q-content-overlay-gradient-generic-type-on-top-warm .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-top-warm .q-responsive-image-container::before, .q-overlay.q-content-overlay-gradient-generic-type-on-top-neutral .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-top-neutral .q-responsive-image-container::before, .q-overlay.q-content-overlay-gradient-generic-type-on-bottom-white .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-white .q-responsive-image-container::before, .q-overlay.q-content-overlay-gradient-generic-type-on-bottom-cool .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-cool .q-responsive-image-container::before, .q-overlay.q-content-overlay-gradient-generic-type-on-bottom-warm .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-warm .q-responsive-image-container::before, .q-overlay.q-content-overlay-gradient-generic-type-on-bottom-neutral .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-neutral .q-responsive-image-container::before {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.q-content-overlay-container {
  position: relative;
}

.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-neutral .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-neutral .q-responsive-image-container::before {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 50%);
}
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-warm .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-warm .q-responsive-image-container::before {
  background-image: linear-gradient(to top, rgba(172, 134, 103, 0.3), rgba(172, 134, 103, 0) 50%);
}
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-cool .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-cool .q-responsive-image-container::before {
  background-image: linear-gradient(to top, rgba(78, 92, 132, 0.3), rgba(78, 92, 132, 0) 50%);
}
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-white .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-bottom-white .q-responsive-image-container::before {
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 50%);
}
.q-overlay.q-content-overlay-gradient-generic-type-on-top-neutral .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-top-neutral .q-responsive-image-container::before {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 50%);
}
.q-overlay.q-content-overlay-gradient-generic-type-on-top-warm .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-top-warm .q-responsive-image-container::before {
  background-image: linear-gradient(to bottom, rgba(172, 134, 103, 0.3), rgba(172, 134, 103, 0) 50%);
}
.q-overlay.q-content-overlay-gradient-generic-type-on-top-cool .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-top-cool .q-responsive-image-container::before {
  background-image: linear-gradient(to bottom, rgba(78, 92, 132, 0.3), rgba(78, 92, 132, 0) 50%);
}
.q-overlay.q-content-overlay-gradient-generic-type-on-top-white .q-static-image-container::before,
.q-overlay.q-content-overlay-gradient-generic-type-on-top-white .q-responsive-image-container::before {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 50%);
}

.q-content-overlay {
  /* Adds padding to first grid nested within content overlay */
}
.q-content-overlay > .q-gridbuilder {
  padding-right: 1.25rem;
  padding-right: 2.5vw;
  padding-left: 1.25rem;
  padding-left: 2.5vw;
}
.q-content-overlay.q-layer,
.q-content-overlay .q-layer {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.q-content-overlay.q-layer .q-layer-width,
.q-content-overlay .q-layer .q-layer-width {
  width: 100%;
}
.q-content-overlay.q-layer .q-layer-height,
.q-content-overlay .q-layer .q-layer-height {
  height: 100%;
}
.q-content-overlay .content-overlay-bg-color-one {
  background-color: rgba(26, 26, 26, 0.8);
}
.q-content-overlay .content-overlay-bg-color-two {
  background-color: rgba(77, 77, 77, 0.8);
}
.q-content-overlay .content-overlay-bg-color-three {
  background-color: rgba(230, 230, 230, 0.8);
}
.q-content-overlay .content-overlay-bg-color-four {
  background-color: rgba(242, 242, 242, 0.8);
}

@media only screen and (max-width: 26.1875em) {
  .q-overlay-below-xs {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
  .q-overlay-below-xs .q-content-overlay.q-layer,
.q-overlay-below-xs .q-content-overlay .q-layer {
    position: relative;
  }

  .q-content-overlay.q-left-xs, .q-content-overlay.q-right-xs, .q-content-overlay.q-top-xs {
    top: 0;
    width: 100%;
  }
  .q-content-overlay.q-left-xs .q-fifty-percent-width-xsmall {
    height: 100%;
    width: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xs .q-fifty-percent-width-xsmall {
    float: right;
  }
  .q-content-overlay.q-left-xs .q-fifty-eight-percent-width-xsmall {
    height: 100%;
    width: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xs .q-fifty-eight-percent-width-xsmall {
    float: right;
  }
  .q-content-overlay.q-left-xs .q-forty-two-percent-width-xsmall {
    height: 100%;
    width: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xs .q-forty-two-percent-width-xsmall {
    float: right;
  }
  .q-content-overlay.q-left-xs .q-hundred-percent-width-xsmall {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xs .q-hundred-percent-width-xsmall {
    float: right;
  }
  .q-content-overlay.q-left-xs .q-seventy-five-percent-width-xsmall {
    height: 100%;
    width: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xs .q-seventy-five-percent-width-xsmall {
    float: right;
  }
  .q-content-overlay.q-left-xs .q-sixty-six-percent-width-xsmall {
    height: 100%;
    width: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xs .q-sixty-six-percent-width-xsmall {
    float: right;
  }
  .q-content-overlay.q-left-xs .q-thirty-three-percent-width-xsmall {
    height: 100%;
    width: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xs .q-thirty-three-percent-width-xsmall {
    float: right;
  }
  .q-content-overlay.q-left-xs .q-twenty-five-percent-width-xsmall {
    height: 100%;
    width: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xs .q-twenty-five-percent-width-xsmall {
    float: right;
  }
  .q-content-overlay.q-right-xs .q-fifty-percent-width-xsmall {
    height: 100%;
    width: 50%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xs .q-fifty-percent-width-xsmall {
    margin-left: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xs .q-fifty-percent-width-xsmall {
    float: left;
  }
  .q-content-overlay.q-right-xs .q-fifty-eight-percent-width-xsmall {
    height: 100%;
    width: 58%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xs .q-fifty-eight-percent-width-xsmall {
    margin-left: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xs .q-fifty-eight-percent-width-xsmall {
    float: left;
  }
  .q-content-overlay.q-right-xs .q-forty-two-percent-width-xsmall {
    height: 100%;
    width: 42%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xs .q-forty-two-percent-width-xsmall {
    margin-left: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xs .q-forty-two-percent-width-xsmall {
    float: left;
  }
  .q-content-overlay.q-right-xs .q-hundred-percent-width-xsmall {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xs .q-hundred-percent-width-xsmall {
    float: right;
  }
  .q-content-overlay.q-right-xs .q-seventy-five-percent-width-xsmall {
    height: 100%;
    width: 75%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xs .q-seventy-five-percent-width-xsmall {
    margin-left: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xs .q-seventy-five-percent-width-xsmall {
    float: left;
  }
  .q-content-overlay.q-right-xs .q-sixty-six-percent-width-xsmall {
    height: 100%;
    width: 66.66%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xs .q-sixty-six-percent-width-xsmall {
    margin-left: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xs .q-sixty-six-percent-width-xsmall {
    float: left;
  }
  .q-content-overlay.q-right-xs .q-thirty-three-percent-width-xsmall {
    height: 100%;
    width: 33.33%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xs .q-thirty-three-percent-width-xsmall {
    margin-left: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xs .q-thirty-three-percent-width-xsmall {
    float: left;
  }
  .q-content-overlay.q-right-xs .q-twenty-five-percent-width-xsmall {
    height: 100%;
    width: 25%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xs .q-twenty-five-percent-width-xsmall {
    margin-left: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xs .q-twenty-five-percent-width-xsmall {
    float: left;
  }
  .q-content-overlay.q-bottom-xs > div {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .q-content-overlay.q-above-xs {
    display: flex;
    position: relative;
  }
}
@media print, only screen and (min-width:26.25em) and (max-width:37.4375em) {
  .q-overlay-below-s {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
  .q-overlay-below-s .q-content-overlay.q-layer,
.q-overlay-below-s .q-content-overlay .q-layer {
    position: relative;
  }

  .q-content-overlay.q-left-s, .q-content-overlay.q-right-s, .q-content-overlay.q-top-s {
    top: 0;
    width: 100%;
  }
  .q-content-overlay.q-left-s .q-fifty-percent-width-small {
    height: 100%;
    width: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-left-s .q-fifty-percent-width-small {
    float: right;
  }
  .q-content-overlay.q-left-s .q-fifty-eight-percent-width-small {
    height: 100%;
    width: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-left-s .q-fifty-eight-percent-width-small {
    float: right;
  }
  .q-content-overlay.q-left-s .q-forty-two-percent-width-small {
    height: 100%;
    width: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-left-s .q-forty-two-percent-width-small {
    float: right;
  }
  .q-content-overlay.q-left-s .q-hundred-percent-width-small {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-left-s .q-hundred-percent-width-small {
    float: right;
  }
  .q-content-overlay.q-left-s .q-seventy-five-percent-width-small {
    height: 100%;
    width: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-left-s .q-seventy-five-percent-width-small {
    float: right;
  }
  .q-content-overlay.q-left-s .q-sixty-six-percent-width-small {
    height: 100%;
    width: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-left-s .q-sixty-six-percent-width-small {
    float: right;
  }
  .q-content-overlay.q-left-s .q-thirty-three-percent-width-small {
    height: 100%;
    width: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-left-s .q-thirty-three-percent-width-small {
    float: right;
  }
  .q-content-overlay.q-left-s .q-twenty-five-percent-width-small {
    height: 100%;
    width: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-left-s .q-twenty-five-percent-width-small {
    float: right;
  }
  .q-content-overlay.q-right-s .q-fifty-percent-width-small {
    height: 100%;
    width: 50%;
  }
  html[dir=ltr] .q-content-overlay.q-right-s .q-fifty-percent-width-small {
    margin-left: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-right-s .q-fifty-percent-width-small {
    float: left;
  }
  .q-content-overlay.q-right-s .q-fifty-eight-percent-width-small {
    height: 100%;
    width: 58%;
  }
  html[dir=ltr] .q-content-overlay.q-right-s .q-fifty-eight-percent-width-small {
    margin-left: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-right-s .q-fifty-eight-percent-width-small {
    float: left;
  }
  .q-content-overlay.q-right-s .q-forty-two-percent-width-small {
    height: 100%;
    width: 42%;
  }
  html[dir=ltr] .q-content-overlay.q-right-s .q-forty-two-percent-width-small {
    margin-left: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-right-s .q-forty-two-percent-width-small {
    float: left;
  }
  .q-content-overlay.q-right-s .q-hundred-percent-width-small {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-right-s .q-hundred-percent-width-small {
    float: right;
  }
  .q-content-overlay.q-right-s .q-seventy-five-percent-width-small {
    height: 100%;
    width: 75%;
  }
  html[dir=ltr] .q-content-overlay.q-right-s .q-seventy-five-percent-width-small {
    margin-left: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-right-s .q-seventy-five-percent-width-small {
    float: left;
  }
  .q-content-overlay.q-right-s .q-sixty-six-percent-width-small {
    height: 100%;
    width: 66.66%;
  }
  html[dir=ltr] .q-content-overlay.q-right-s .q-sixty-six-percent-width-small {
    margin-left: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-right-s .q-sixty-six-percent-width-small {
    float: left;
  }
  .q-content-overlay.q-right-s .q-thirty-three-percent-width-small {
    height: 100%;
    width: 33.33%;
  }
  html[dir=ltr] .q-content-overlay.q-right-s .q-thirty-three-percent-width-small {
    margin-left: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-right-s .q-thirty-three-percent-width-small {
    float: left;
  }
  .q-content-overlay.q-right-s .q-twenty-five-percent-width-small {
    height: 100%;
    width: 25%;
  }
  html[dir=ltr] .q-content-overlay.q-right-s .q-twenty-five-percent-width-small {
    margin-left: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-right-s .q-twenty-five-percent-width-small {
    float: left;
  }
  .q-content-overlay.q-bottom-s > div {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .q-content-overlay.q-above-s {
    display: flex;
    position: relative;
  }
}
@media print, only screen and (min-width:37.5em) and (max-width:59.9375em) {
  .q-overlay-below-m {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
  }
  .q-overlay-below-m .q-content-overlay.q-layer,
.q-overlay-below-m .q-content-overlay .q-layer {
    position: relative;
  }

  .q-content-overlay.q-left-m, .q-content-overlay.q-right-m, .q-content-overlay.q-top-m {
    top: 0;
    width: 100%;
  }
  .q-content-overlay.q-left-m .q-fifty-percent-width-medium {
    height: 100%;
    width: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-left-m .q-fifty-percent-width-medium {
    float: right;
  }
  .q-content-overlay.q-left-m .q-fifty-eight-percent-width-medium {
    height: 100%;
    width: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-left-m .q-fifty-eight-percent-width-medium {
    float: right;
  }
  .q-content-overlay.q-left-m .q-forty-two-percent-width-medium {
    height: 100%;
    width: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-left-m .q-forty-two-percent-width-medium {
    float: right;
  }
  .q-content-overlay.q-left-m .q-hundred-percent-width-medium {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-left-m .q-hundred-percent-width-medium {
    float: right;
  }
  .q-content-overlay.q-left-m .q-seventy-five-percent-width-medium {
    height: 100%;
    width: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-left-m .q-seventy-five-percent-width-medium {
    float: right;
  }
  .q-content-overlay.q-left-m .q-sixty-six-percent-width-medium {
    height: 100%;
    width: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-left-m .q-sixty-six-percent-width-medium {
    float: right;
  }
  .q-content-overlay.q-left-m .q-thirty-three-percent-width-medium {
    height: 100%;
    width: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-left-m .q-thirty-three-percent-width-medium {
    float: right;
  }
  .q-content-overlay.q-left-m .q-twenty-five-percent-width-medium {
    height: 100%;
    width: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-left-m .q-twenty-five-percent-width-medium {
    float: right;
  }
  .q-content-overlay.q-right-m .q-fifty-percent-width-medium {
    height: 100%;
    width: 50%;
  }
  html[dir=ltr] .q-content-overlay.q-right-m .q-fifty-percent-width-medium {
    margin-left: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-right-m .q-fifty-percent-width-medium {
    float: left;
  }
  .q-content-overlay.q-right-m .q-fifty-eight-percent-width-medium {
    height: 100%;
    width: 58%;
  }
  html[dir=ltr] .q-content-overlay.q-right-m .q-fifty-eight-percent-width-medium {
    margin-left: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-right-m .q-fifty-eight-percent-width-medium {
    float: left;
  }
  .q-content-overlay.q-right-m .q-forty-two-percent-width-medium {
    height: 100%;
    width: 42%;
  }
  html[dir=ltr] .q-content-overlay.q-right-m .q-forty-two-percent-width-medium {
    margin-left: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-right-m .q-forty-two-percent-width-medium {
    float: left;
  }
  .q-content-overlay.q-right-m .q-hundred-percent-width-medium {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-right-m .q-hundred-percent-width-medium {
    float: right;
  }
  .q-content-overlay.q-right-m .q-seventy-five-percent-width-medium {
    height: 100%;
    width: 75%;
  }
  html[dir=ltr] .q-content-overlay.q-right-m .q-seventy-five-percent-width-medium {
    margin-left: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-right-m .q-seventy-five-percent-width-medium {
    float: left;
  }
  .q-content-overlay.q-right-m .q-sixty-six-percent-width-medium {
    height: 100%;
    width: 66.66%;
  }
  html[dir=ltr] .q-content-overlay.q-right-m .q-sixty-six-percent-width-medium {
    margin-left: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-right-m .q-sixty-six-percent-width-medium {
    float: left;
  }
  .q-content-overlay.q-right-m .q-thirty-three-percent-width-medium {
    height: 100%;
    width: 33.33%;
  }
  html[dir=ltr] .q-content-overlay.q-right-m .q-thirty-three-percent-width-medium {
    margin-left: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-right-m .q-thirty-three-percent-width-medium {
    float: left;
  }
  .q-content-overlay.q-right-m .q-twenty-five-percent-width-medium {
    height: 100%;
    width: 25%;
  }
  html[dir=ltr] .q-content-overlay.q-right-m .q-twenty-five-percent-width-medium {
    margin-left: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-right-m .q-twenty-five-percent-width-medium {
    float: left;
  }
  .q-content-overlay.q-bottom-m > div {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .q-content-overlay.q-above-m {
    display: flex;
    position: relative;
  }
}
@media only screen and (min-width:60em) and (max-width:93.6875em) {
  .q-content-overlay.q-left-l, .q-content-overlay.q-right-l, .q-content-overlay.q-top-l {
    top: 0;
    width: 100%;
  }
  .q-content-overlay.q-left-l .q-fifty-percent-width-large {
    height: 100%;
    width: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-left-l .q-fifty-percent-width-large {
    float: right;
  }
  .q-content-overlay.q-left-l .q-fifty-eight-percent-width-large {
    height: 100%;
    width: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-left-l .q-fifty-eight-percent-width-large {
    float: right;
  }
  .q-content-overlay.q-left-l .q-forty-two-percent-width-large {
    height: 100%;
    width: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-left-l .q-forty-two-percent-width-large {
    float: right;
  }
  .q-content-overlay.q-left-l .q-hundred-percent-width-large {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-left-l .q-hundred-percent-width-large {
    float: right;
  }
  .q-content-overlay.q-left-l .q-seventy-five-percent-width-large {
    height: 100%;
    width: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-left-l .q-seventy-five-percent-width-large {
    float: right;
  }
  .q-content-overlay.q-left-l .q-sixty-six-percent-width-large {
    height: 100%;
    width: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-left-l .q-sixty-six-percent-width-large {
    float: right;
  }
  .q-content-overlay.q-left-l .q-thirty-three-percent-width-large {
    height: 100%;
    width: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-left-l .q-thirty-three-percent-width-large {
    float: right;
  }
  .q-content-overlay.q-left-l .q-twenty-five-percent-width-large {
    height: 100%;
    width: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-left-l .q-twenty-five-percent-width-large {
    float: right;
  }
  .q-content-overlay.q-right-l .q-fifty-percent-width-large {
    height: 100%;
    width: 50%;
  }
  html[dir=ltr] .q-content-overlay.q-right-l .q-fifty-percent-width-large {
    margin-left: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-right-l .q-fifty-percent-width-large {
    float: left;
  }
  .q-content-overlay.q-right-l .q-fifty-eight-percent-width-large {
    height: 100%;
    width: 58%;
  }
  html[dir=ltr] .q-content-overlay.q-right-l .q-fifty-eight-percent-width-large {
    margin-left: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-right-l .q-fifty-eight-percent-width-large {
    float: left;
  }
  .q-content-overlay.q-right-l .q-forty-two-percent-width-large {
    height: 100%;
    width: 42%;
  }
  html[dir=ltr] .q-content-overlay.q-right-l .q-forty-two-percent-width-large {
    margin-left: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-right-l .q-forty-two-percent-width-large {
    float: left;
  }
  .q-content-overlay.q-right-l .q-hundred-percent-width-large {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-right-l .q-hundred-percent-width-large {
    float: right;
  }
  .q-content-overlay.q-right-l .q-seventy-five-percent-width-large {
    height: 100%;
    width: 75%;
  }
  html[dir=ltr] .q-content-overlay.q-right-l .q-seventy-five-percent-width-large {
    margin-left: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-right-l .q-seventy-five-percent-width-large {
    float: left;
  }
  .q-content-overlay.q-right-l .q-sixty-six-percent-width-large {
    height: 100%;
    width: 66.66%;
  }
  html[dir=ltr] .q-content-overlay.q-right-l .q-sixty-six-percent-width-large {
    margin-left: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-right-l .q-sixty-six-percent-width-large {
    float: left;
  }
  .q-content-overlay.q-right-l .q-thirty-three-percent-width-large {
    height: 100%;
    width: 33.33%;
  }
  html[dir=ltr] .q-content-overlay.q-right-l .q-thirty-three-percent-width-large {
    margin-left: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-right-l .q-thirty-three-percent-width-large {
    float: left;
  }
  .q-content-overlay.q-right-l .q-twenty-five-percent-width-large {
    height: 100%;
    width: 25%;
  }
  html[dir=ltr] .q-content-overlay.q-right-l .q-twenty-five-percent-width-large {
    margin-left: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-right-l .q-twenty-five-percent-width-large {
    float: left;
  }
  .q-content-overlay.q-bottom-l > div {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .q-content-overlay.q-above-l {
    display: flex;
    position: relative;
  }
}
@media only screen and (min-width:93.75em) {
  .q-content-overlay.q-left-xl, .q-content-overlay.q-right-xl, .q-content-overlay.q-top-xl {
    top: 0;
    width: 100%;
  }
  .q-content-overlay.q-left-xl .q-fifty-percent-width-xlarge {
    height: 100%;
    width: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xl .q-fifty-percent-width-xlarge {
    float: right;
  }
  .q-content-overlay.q-left-xl .q-fifty-eight-percent-width-xlarge {
    height: 100%;
    width: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xl .q-fifty-eight-percent-width-xlarge {
    float: right;
  }
  .q-content-overlay.q-left-xl .q-forty-two-percent-width-xlarge {
    height: 100%;
    width: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xl .q-forty-two-percent-width-xlarge {
    float: right;
  }
  .q-content-overlay.q-left-xl .q-hundred-percent-width-xlarge {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xl .q-hundred-percent-width-xlarge {
    float: right;
  }
  .q-content-overlay.q-left-xl .q-seventy-five-percent-width-xlarge {
    height: 100%;
    width: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xl .q-seventy-five-percent-width-xlarge {
    float: right;
  }
  .q-content-overlay.q-left-xl .q-sixty-six-percent-width-xlarge {
    height: 100%;
    width: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xl .q-sixty-six-percent-width-xlarge {
    float: right;
  }
  .q-content-overlay.q-left-xl .q-thirty-three-percent-width-xlarge {
    height: 100%;
    width: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xl .q-thirty-three-percent-width-xlarge {
    float: right;
  }
  .q-content-overlay.q-left-xl .q-twenty-five-percent-width-xlarge {
    height: 100%;
    width: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-left-xl .q-twenty-five-percent-width-xlarge {
    float: right;
  }
  .q-content-overlay.q-right-xl .q-fifty-percent-width-xlarge {
    height: 100%;
    width: 50%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xl .q-fifty-percent-width-xlarge {
    margin-left: 50%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xl .q-fifty-percent-width-xlarge {
    float: left;
  }
  .q-content-overlay.q-right-xl .q-fifty-eight-percent-width-xlarge {
    height: 100%;
    width: 58%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xl .q-fifty-eight-percent-width-xlarge {
    margin-left: 42%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xl .q-fifty-eight-percent-width-xlarge {
    float: left;
  }
  .q-content-overlay.q-right-xl .q-forty-two-percent-width-xlarge {
    height: 100%;
    width: 42%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xl .q-forty-two-percent-width-xlarge {
    margin-left: 58%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xl .q-forty-two-percent-width-xlarge {
    float: left;
  }
  .q-content-overlay.q-right-xl .q-hundred-percent-width-xlarge {
    height: 100%;
    width: 100%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xl .q-hundred-percent-width-xlarge {
    float: right;
  }
  .q-content-overlay.q-right-xl .q-seventy-five-percent-width-xlarge {
    height: 100%;
    width: 75%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xl .q-seventy-five-percent-width-xlarge {
    margin-left: 25%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xl .q-seventy-five-percent-width-xlarge {
    float: left;
  }
  .q-content-overlay.q-right-xl .q-sixty-six-percent-width-xlarge {
    height: 100%;
    width: 66.66%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xl .q-sixty-six-percent-width-xlarge {
    margin-left: 33.33%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xl .q-sixty-six-percent-width-xlarge {
    float: left;
  }
  .q-content-overlay.q-right-xl .q-thirty-three-percent-width-xlarge {
    height: 100%;
    width: 33.33%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xl .q-thirty-three-percent-width-xlarge {
    margin-left: 66.66%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xl .q-thirty-three-percent-width-xlarge {
    float: left;
  }
  .q-content-overlay.q-right-xl .q-twenty-five-percent-width-xlarge {
    height: 100%;
    width: 25%;
  }
  html[dir=ltr] .q-content-overlay.q-right-xl .q-twenty-five-percent-width-xlarge {
    margin-left: 75%;
  }
  html[dir=rtl] .q-content-overlay.q-right-xl .q-twenty-five-percent-width-xlarge {
    float: left;
  }
  .q-content-overlay.q-bottom-xl > div {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
  .q-content-overlay.q-above-xl {
    display: flex;
    position: relative;
  }
}
.q-disclosure-page-container .q-desclaimer-page-headline {
  background-color: #cd9834;
  margin: 0;
  margin-top: 1.25rem;
  padding: 1.25rem;
}

.q-disclosure-modal-toast .q-disclosure-container {
  background-color: #000000;
  /* Fallback color */
  color: #ffffff;
  display: none;
  /* Hidden by default */
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-size: 0.875rem;
  opacity: 0.9;
}
.q-disclosure-modal-toast .q-disclosure-modal-container {
  height: 100%;
  /* Full height */
  left: 0;
  overflow: auto;
  /* Enable scroll if needed */
  position: fixed;
  /* Stay in place */
  top: 0;
  width: 100%;
  /* Full width */
  z-index: 1200;
  /* Sit on top */
}
.q-disclosure-modal-toast .q-modal-content {
  height: 100%;
  margin: 0;
  opacity: 0.8;
  overflow-y: auto;
  padding: 2.5rem 4.688rem 0.9375rem 0.875rem;
  width: 100%;
}
.q-disclosure-modal-toast .q-modal-content h3 {
  margin-bottom: 1.25rem;
}
.q-disclosure-modal-toast .q-modal-content h3 a {
  font-weight: 400;
}
.q-disclosure-modal-toast .q-modal-content p {
  margin: 0;
  padding: 0;
}
.q-disclosure-modal-toast .q-modal-content a {
  outline-style: none;
  text-decoration: underline;
}
.q-disclosure-modal-toast .q-modal-content .q-close-disclosure {
  right: 1.875rem;
  top: 1.75rem;
}
.q-disclosure-modal-toast .q-close-disclosure {
  font-size: 1.25rem;
  height: 1.25rem;
  line-height: inherit;
  opacity: 1;
  outline-style: none;
  padding: 0;
  position: absolute;
  right: 1.25rem;
  text-align: center;
  top: 1.875rem;
  width: 1.375rem;
}
.q-disclosure-modal-toast .q-close-disclosure .q-modal-close-icon {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/close.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 1.25rem;
  padding-left: 1.25rem;
  width: 1.25rem;
  vertical-align: baseline;
}
.q-disclosure-modal-toast .q-close-disclosure img:hover, .q-disclosure-modal-toast .q-close-disclosure img:focus {
  transition: opacity;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  cursor: pointer;
  text-decoration: none;
}
.q-disclosure-modal-toast .q-close-disclosure:focus {
  opacity: 0.8;
}
.q-disclosure-modal-toast .q-disclosure-toast-container {
  bottom: 0;
  padding: 0.875rem 0.875rem;
  position: fixed;
  width: 100%;
  z-index: 1200;
  /* Sit on top of Gallery Layer*/
}
.q-disclosure-modal-toast .q-disclosure-toast-container .q-toast-content {
  float: left;
  margin-bottom: 1.25rem;
  width: 80%;
}
.q-disclosure-modal-toast .q-disclosure-toast-container .q-toast-content p {
  margin: 0.3125rem 0;
}
.q-disclosure-modal-toast .q-disclosure-toast-container .q-toast-content .q-close-disclosure {
  top: 1.25rem;
}

@media print, only screen and (min-width:37.5em) {
  .q-disclosure-modal-toast .q-modal-content {
    padding: 1.875rem 11.25rem 3.75rem 3.75rem;
  }
  .q-disclosure-modal-toast .q-close-disclosure {
    font-size: 1.25rem;
    height: 1.88rem;
    line-height: 1.1;
    right: 3.875rem;
    top: 1.25rem;
    width: 1.88rem;
  }
}
@media only screen and (min-width:60em) {
  .q-disclosure-modal-toast .q-disclosure-toast-container .q-toast-content,
.q-disclosure-modal-toast .gmwc-modal-wrapper .q-toast-content {
    width: 90%;
  }
  .q-disclosure-modal-toast .q-modal-content {
    padding: 2.5rem 11.25rem 3.75rem 8.75rem;
  }
  .q-disclosure-modal-toast .q-modal-content .q-close-disclosure {
    right: 4.375rem;
    top: 1.875rem;
  }
}
@media only screen and (min-width:93.75em) {
  .q-disclosure-modal-toast .q-modal-content .q-close-disclosure {
    line-height: 1.1;
  }
}
.q-expander {
  margin-bottom: -0.0625rem;
}
.q-expander .q-expander-button::before {
  height: 1.875rem;
  width: 1.875rem;
}
.q-expander > .q-expander-button, .q-expander > div > .q-expander-button {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  padding: 0.875rem 2.8235rem 0.875rem 1.875rem;
  position: relative;
  text-align: left;
}
.q-expander > .q-expander-button::before, .q-expander > div > .q-expander-button::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/plus.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.q-expander > .q-expander-button:hover, .q-expander > div > .q-expander-button:hover {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  cursor: pointer;
}
.q-expander > .q-expander-button:hover::before, .q-expander > div > .q-expander-button:hover::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/plusHover.svg");
  background-size: 100% 100%;
}
.q-expander > .q-expander-button .q-button-text, .q-expander > div > .q-expander-button .q-button-text {
  color: #262626;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 0.75rem;
  font-weight: 900;
  letter-spacing: normal;
  line-height: 2;
  margin: 0;
}
.q-expander > .q-expander-button .q-headline-text, .q-expander > div > .q-expander-button .q-headline-text {
  margin-left: 2.813rem;
}
.q-expander > .q-expander-button.hide-icon::before, .q-expander > div > .q-expander-button.hide-icon::before {
  content: none !important;
}
.q-expander > .q-expander-button.hide-icon .q-headline-text, .q-expander > div > .q-expander-button.hide-icon .q-headline-text {
  margin-left: 0;
}
.q-expander > .q-expander-content, .q-expander > div > .q-expander-content {
  transition: height;
  transition-duration: 0.01s;
  transition-timing-function: linear;
  height: 0;
  overflow: hidden;
}
.q-expander:active:focus {
  outline: none;
}
.q-expander.active > .q-expander-content, .q-expander.active > div > .q-expander-content, .q-expander.open > .q-expander-content, .q-expander.open > div > .q-expander-content {
  transition: height;
  transition-duration: 0.01s;
  transition-timing-function: linear;
  height: auto;
}
.q-expander.q-show-border > .q-expander-button, .q-expander.q-show-border > div > .q-expander-button {
  border-bottom: 0;
  border-top: 0;
}
.q-expander.q-show-border.active > .q-expander-button, .q-expander.q-show-border.active > div > .q-expander-button, .q-expander.q-show-border.open > .q-expander-button, .q-expander.q-show-border.open > div > .q-expander-button {
  border-bottom: 1px solid #b3b3b3;
}
.q-expander.q-show-border.active > .q-expander-button.q-button-b, .q-expander.q-show-border.active > div > .q-expander-button.q-button-b, .q-expander.q-show-border.open > .q-expander-button.q-button-b, .q-expander.q-show-border.open > div > .q-expander-button.q-button-b {
  border-bottom: 0;
  border-top: 1px solid #b3b3b3;
}
.q-expander .grid-padding-small,
.q-expander .grid-padding-medium, .grid-padding-large .q-expander {
  margin-left: 0;
  margin-right: 0;
}
.q-expander.q-button-active > .q-expander-button, .q-expander.q-button-active > div > .q-expander-button {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
}
.q-expander.q-button-active > .q-expander-button::before, .q-expander.q-button-active > div > .q-expander-button::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/minus.svg");
  background-size: 100% 100%;
}
@media print, only screen and (max-width:37.4375em) {
  .collapse .q-expander {
    margin-left: -0.0625rem;
    margin-left: -2.5vw;
    margin-right: -0.0625rem;
    margin-right: -2.5vw;
  }
}
@media print, only screen and (max-width:37.4375em) {
  .q-expander .grid-padding-small.q-show-border,
.q-expander .grid-padding-small > .q-expander-button, .q-expander .grid-padding-small > div > .q-expander-button,
.q-expander .grid-padding-medium.q-show-border,
.q-expander .grid-padding-medium > .q-expander-button,
.q-expander .grid-padding-medium > div > .q-expander-button, .grid-padding-large .q-expander.q-show-border,
.grid-padding-large .q-expander > .q-expander-button, .grid-padding-large .q-expander > div > .q-expander-button {
    border-left: 1px solid #b3b3b3;
    border-right: 1px solid #b3b3b3;
  }
}
@media print, only screen and (min-width:26.25em) {
  .q-expander.q-show-border > .q-expander-button, .q-expander.q-show-border > div > .q-expander-button {
    border: 1px solid #b3b3b3;
  }
}
@media print, only screen and (max-width:59.9375em) {
  .q-expander .q-expander-button::before {
    height: 1.875rem;
    width: 1.875rem;
  }
  .q-expander.active > .q-expander-button, .q-expander.active > div > .q-expander-button {
    border-left: 0;
    border-right: 0;
  }
  .q-expander > .q-expander-button, .q-expander > div > .q-expander-button {
    border-left: 0;
    border-right: 0;
  }
  .q-expander > .q-expander-button:hover, .q-expander > div > .q-expander-button:hover {
    border-left: 0;
    border-right: 0;
  }
  .q-expander > .q-expander-button::before, .q-expander > div > .q-expander-button::before {
    right: 1.25rem;
  }
  .q-expander > .q-expander-button .q-headline-text, .q-expander > div > .q-expander-button .q-headline-text {
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (max-width: 419px) {
  .q-expander.q-disabled-xs > .q-expander-content,
.q-expander.q-disabled-xs > div > .q-expander-content {
    display: inline;
  }
  .q-expander.q-disabled-xs > .q-expander-button,
.q-expander.q-disabled-xs > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-expander.q-disabled-xs > .q-expander-button::before,
.q-expander.q-disabled-xs > div > .q-expander-button::before {
    content: none !important;
  }
  .q-expander.q-disabled-xs > .q-expander-button:hover,
.q-expander.q-disabled-xs > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-expander.q-disabled-xs > .q-expander-button .q-headline-text,
.q-expander.q-disabled-xs > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-expander.q-disabled-xs > .q-expander-button [class=" q-headline-text"],
.q-expander.q-disabled-xs > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-expander.q-disabled-xs.open > .q-expander-button,
.q-expander.q-disabled-xs.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
}
@media print, only screen and (min-width:420px) and (max-width:599px) {
  .q-expander.q-disabled-sm > .q-expander-content,
.q-expander.q-disabled-sm > div > .q-expander-content {
    display: inline;
  }
  .q-expander.q-disabled-sm > .q-expander-button,
.q-expander.q-disabled-sm > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-expander.q-disabled-sm > .q-expander-button::before,
.q-expander.q-disabled-sm > div > .q-expander-button::before {
    content: none !important;
  }
  .q-expander.q-disabled-sm > .q-expander-button:hover,
.q-expander.q-disabled-sm > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-expander.q-disabled-sm > .q-expander-button .q-headline-text,
.q-expander.q-disabled-sm > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-expander.q-disabled-sm > .q-expander-button [class=" q-headline-text"],
.q-expander.q-disabled-sm > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-expander.q-disabled-sm.open > .q-expander-button,
.q-expander.q-disabled-sm.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
}
@media print, only screen and (min-width:600px) and (max-width:959px) {
  .q-expander.q-disabled-med > .q-expander-content,
.q-expander.q-disabled-med > div > .q-expander-content {
    display: inline;
  }
  .q-expander.q-disabled-med > .q-expander-button,
.q-expander.q-disabled-med > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-expander.q-disabled-med > .q-expander-button::before,
.q-expander.q-disabled-med > div > .q-expander-button::before {
    content: none !important;
  }
  .q-expander.q-disabled-med > .q-expander-button:hover,
.q-expander.q-disabled-med > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-expander.q-disabled-med > .q-expander-button .q-headline-text,
.q-expander.q-disabled-med > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-expander.q-disabled-med > .q-expander-button [class=" q-headline-text"],
.q-expander.q-disabled-med > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-expander.q-disabled-med.open > .q-expander-button,
.q-expander.q-disabled-med.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
}
@media only screen and (min-width:960px) and (max-width:1499px) {
  .q-expander.q-disabled-lg > .q-expander-content,
.q-expander.q-disabled-lg > div > .q-expander-content {
    display: inline;
  }
  .q-expander.q-disabled-lg > .q-expander-button,
.q-expander.q-disabled-lg > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-expander.q-disabled-lg > .q-expander-button::before,
.q-expander.q-disabled-lg > div > .q-expander-button::before {
    content: none !important;
  }
  .q-expander.q-disabled-lg > .q-expander-button:hover,
.q-expander.q-disabled-lg > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-expander.q-disabled-lg > .q-expander-button .q-headline-text,
.q-expander.q-disabled-lg > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-expander.q-disabled-lg > .q-expander-button [class=" q-headline-text"],
.q-expander.q-disabled-lg > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-expander.q-disabled-lg.open > .q-expander-button,
.q-expander.q-disabled-lg.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
}
@media only screen and (min-width:1500px) and (max-width:99999999px) {
  .q-expander.q-disabled-xl > .q-expander-content,
.q-expander.q-disabled-xl > div > .q-expander-content {
    display: inline;
  }
  .q-expander.q-disabled-xl > .q-expander-button,
.q-expander.q-disabled-xl > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-expander.q-disabled-xl > .q-expander-button::before,
.q-expander.q-disabled-xl > div > .q-expander-button::before {
    content: none !important;
  }
  .q-expander.q-disabled-xl > .q-expander-button:hover,
.q-expander.q-disabled-xl > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-expander.q-disabled-xl > .q-expander-button .q-headline-text,
.q-expander.q-disabled-xl > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-expander.q-disabled-xl > .q-expander-button [class=" q-headline-text"],
.q-expander.q-disabled-xl > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-expander.q-disabled-xl.open > .q-expander-button,
.q-expander.q-disabled-xl.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
}

.q-headline .q-headline-icon {
  display: inline-block;
  vertical-align: top;
}
.q-headline .q-headline-icon.icon-left {
  margin-inline-end: 0.5em;
}
.q-headline .q-headline-icon.icon-right {
  margin-inline-start: 0.5em;
}
.q-headline .q-headline-icon img {
  height: 1.25em;
  width: auto;
}
.q-headline .q-headline-icon img:nth-child(2) {
  display: none;
}
.q-headline .q-headline-icon a:hover img:nth-child(1) {
  display: none;
}
.q-headline .q-headline-icon a:hover img:nth-child(2) {
  display: inline-block;
}
.q-headline .q-headline-icon .q-headline-icon-text {
  display: inline-block;
  width: calc(100% - 2.312rem);
}
.q-headline .q-headline-icon-block {
  display: inline-block;
}
.q-headline .q-headline-icon-block.icon-left {
  float: left;
  height: 1.875rem;
  margin-inline-end: 0.5em;
  width: 1.875rem;
}
.q-headline .q-headline-icon-block.icon-right {
  float: right;
  height: 1.875rem;
  margin-inline-start: 0.5em;
  width: 1.875rem;
}
.q-headline .q-headline-icon-block img {
  height: 1.875rem;
  width: 1.875rem;
}
@media print, only screen and (min-width:37.5em) {
  .q-headline .q-headline-icon-block.icon-left {
    height: 2.5rem;
    width: 2.5rem;
  }
  .q-headline .q-headline-icon-block.icon-right {
    height: 2.5rem;
    width: 2.5rem;
  }
  .q-headline .q-headline-icon-block img {
    height: 2.5rem;
    width: 2.5rem;
  }
  .q-headline .q-headline-icon-block .q-headline-icon-text {
    display: inline-block;
    width: calc(100% - 2.938rem);
  }
}
@media only screen and (min-width:93.75em) {
  .q-headline .q-headline-icon-block .q-headline-icon-text {
    width: calc(100% - 3.125rem);
  }
}

.q-legal-notification {
  transition: max-height;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  background-color: #000000;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 1199;
}
.q-legal-notification .float-left {
  float: left;
}
.q-legal-notification.q-minimize .q-content-container {
  visibility: hidden;
}
.q-legal-notification.q-minimize .q-minimize-text {
  visibility: visible;
}
.q-legal-notification.q-disclaimer-none {
  display: none;
}
.q-legal-notification .q-disclaimer-none {
  display: none;
}
.q-legal-notification .q-content-container .q-button-container {
  float: right;
}
.q-legal-notification .q-content-container .silent-consent {
  position: absolute;
}
.q-legal-notification .q-content-container .q-close-icon {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/close.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 1.25rem;
  padding-left: 1.25rem;
  width: 1.25rem;
  pointer-events: none;
  vertical-align: baseline;
}
.q-legal-notification .q-content-container .q-title .q-close-button .icon-minus {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/minus-white.svg") no-repeat center;
  float: right;
  height: 1.25rem;
  margin-top: 0.2rem;
  width: 1.25rem;
}
.q-legal-notification .q-content-container .q-title .q-minimize-text .icon-plus {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/plus-white.svg") no-repeat center;
  float: right;
  height: 1.25rem;
  margin-top: 0.2rem;
  width: 1.25rem;
}
.q-legal-notification .q-close-button {
  cursor: pointer;
}
.q-legal-notification .q-minimize-text {
  cursor: pointer;
}
@media print, only screen and (min-width:26.25em) {
  .q-legal-notification .float-left {
    margin-bottom: 0;
  }
  .q-legal-notification .q-content-container .q-close-icon {
    height: 1.88rem;
    width: 1.88rem;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-legal-notification .q-content-container .q-title .q-title-label {
    display: inline-block;
  }
}
@media only screen and (min-width:60em) {
  .q-legal-notification .q-content-container .q-title .q-close-button .icon-minus {
    float: left;
  }
  .q-legal-notification .q-content-container .q-title .q-minimize-text .icon-plus {
    float: left;
  }
}

q-progress-bar .q-pb-label {
  color: #4b4b4b;
  font-size: 1rem;
}
q-progress-bar progress {
  appearance: none;
  background-color: #d7d7d7;
  border-radius: 15px;
  height: 8px;
  margin-bottom: 2.4rem;
  margin-top: 0.4rem;
  width: 100%;
}
q-progress-bar progress::-webkit-progress-bar {
  background-color: #d7d7d7;
  border-radius: 15px;
  height: 8px;
}
q-progress-bar progress::-webkit-progress-value {
  background: #0079bf;
  border-radius: 15px;
  height: 8px;
}
q-progress-bar progress::-moz-progress-bar {
  background: #0079bf;
  border-radius: 15px;
  height: 8px;
}

.q-rep-scroller {
  position: relative;
}
.q-rep-scroller .slick-arrow {
  align-items: center;
  border: 0;
  display: flex !important;
  justify-content: center;
  outline: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
.q-rep-scroller .slick-arrow::before {
  content: "";
  display: block;
}
.q-rep-scroller .slick-arrow.slick-prev {
  left: 0;
}
.q-rep-scroller .slick-arrow.slick-prev::before {
  transform: rotate(180deg);
}
.q-rep-scroller .slick-arrow.slick-next {
  right: 0;
}
.q-rep-scroller .slick-arrow.slick-disabled {
  display: none !important;
}
.q-rep-scroller .slick-arrow ~ .slick-dots {
  display: flex;
}
.q-rep-scroller .slick-slide {
  display: flex;
}
.q-rep-scroller .slick-slide > * {
  max-width: 100%;
}
.q-rep-scroller .slick-slide img {
  min-height: 1px;
}
.q-rep-scroller .slick-track {
  display: flex;
  margin: 0 auto;
}
.q-rep-scroller .slick-dots {
  align-items: center;
  display: none;
  justify-content: center;
  left: 0;
  list-style: none;
  margin: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.q-rep-scroller .slick-dots li button {
  border: 0;
  margin: 0;
  padding: 0;
}
.q-rep-scroller.show-dots .slick-dots li {
  cursor: pointer;
}
.q-rep-scroller.show-dots .slick-dots li button {
  border-radius: 50%;
  display: block;
}
.q-rep-scroller.show-numbers .slick-dots {
  counter-reset: num-dots;
  pointer-events: none;
}
.q-rep-scroller.show-numbers .slick-dots li {
  counter-increment: num-dots 1;
}
.q-rep-scroller.show-numbers .slick-dots li button {
  display: none;
}
.q-rep-scroller.show-numbers .slick-dots li button:focus {
  outline: 0;
}
.q-rep-scroller.show-numbers .slick-dots li.slick-active button {
  display: inline-block;
}
.q-rep-scroller.show-numbers .slick-dots::after {
  content: "/" counter(num-dots);
}

.q-rte-launch-gallery {
  cursor: pointer;
  text-decoration: underline;
}
.q-rte-launch-gallery:hover {
  color: inherit;
}

.q-rte-launch-modal,
.q-mod-rte_modal {
  cursor: pointer;
  text-decoration: underline;
}
.q-rte-launch-modal:hover,
.q-mod-rte_modal:hover {
  color: #cd9834;
}

.q-invert .q-rte-launch-modal:hover {
  color: #e6ac39;
}

.q-separator hr {
  margin: 0 auto;
}
.q-separator hr.separator-primary {
  border-color: #b3b3b3;
}
.q-separator hr.separator-secondary {
  border-color: #808080;
}
.q-separator hr.separator-tertiary {
  border-color: #262626;
}
.q-separator hr.invisible {
  visibility: hidden;
}

.q-separator-horizontal,
.q-separator-vertical,
.q-separator-combined {
  overflow: hidden;
  position: relative;
  text-align: center;
}
.q-separator-horizontal span,
.q-separator-vertical span,
.q-separator-combined span {
  color: #808080;
  display: inline-block;
  position: relative;
}
.q-separator-horizontal span::before, .q-separator-horizontal span::after,
.q-separator-vertical span::before,
.q-separator-vertical span::after,
.q-separator-combined span::before,
.q-separator-combined span::after {
  border-top: 1px solid #808080;
  content: "";
  position: absolute;
}

.q-separator-horizontal span::before, .q-separator-horizontal span::after {
  height: 0.0625rem;
  top: 50%;
  width: 624.9375rem;
}
.q-separator-horizontal span::before {
  margin-right: 0.3125rem;
  right: 100%;
}
.q-separator-horizontal span::after {
  left: 100%;
  margin-left: 0.3125rem;
}

.q-separator-vertical span {
  top: 50%;
  transform: translateY(-50%);
}
.q-separator-vertical span::before, .q-separator-vertical span::after {
  border-left: 1px solid #808080;
  height: 624.9375rem;
  left: 50%;
}
.q-separator-vertical span::before {
  bottom: 100%;
  margin-bottom: 0.3125rem;
}
.q-separator-vertical span::after {
  margin-top: 0.3125rem;
  top: 100%;
}

@media print, only screen and (max-width:59.9375em) {
  .q-separator-combined span::before, .q-separator-combined span::after {
    height: 0.0625rem;
    top: 50%;
    width: 624.9375rem;
  }
  .q-separator-combined span::before {
    margin-right: 0.3125rem;
    right: 100%;
  }
  .q-separator-combined span::after {
    left: 100%;
    margin-left: 0.3125rem;
  }
}
@media only screen and (min-width:60em) {
  .q-separator-combined span {
    top: 50%;
    transform: translateY(-50%);
  }
  .q-separator-combined span::before, .q-separator-combined span::after {
    border-left: 1px solid #808080;
    height: 624.9375rem;
    left: 50%;
  }
  .q-separator-combined span::before {
    bottom: 100%;
    margin-bottom: 0.3125rem;
  }
  .q-separator-combined span::after {
    margin-top: 0.3125rem;
    top: 100%;
  }
}
.q-sticky-notification {
  transition: height;
  transition-duration: 0.75s;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  background-color: #000000;
  bottom: 0;
  height: 0;
  max-height: 100%;
  overflow: auto;
  position: fixed;
  width: 100%;
  z-index: 150;
}
.q-sticky-notification .float-left {
  float: left;
}
.q-sticky-notification .q-content-container .q-close-button {
  cursor: pointer;
  inset-block-end: auto;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: auto;
  position: absolute;
  z-index: 15;
}
.q-sticky-notification .q-content-container .q-close-button .q-close-icon {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/close.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 1.88rem;
  padding-left: 1.88rem;
  width: 1.88rem;
  vertical-align: baseline;
}
@media print, only screen and (min-width:26.25em) {
  .q-sticky-notification .float-left {
    margin-bottom: 0;
  }
}

.q-vertical-spacing .is-vertical {
  bottom: 0;
  line-height: 0;
  padding: 0;
  vertical-align: text-bottom;
}

.q-wrapper {
  display: block;
}

.q-wrapper.q-rte-launch-modal {
  text-decoration: none;
}

.q-gallery-layer {
  background-color: #ffffff;
}
.q-gallery-layer .edit-mms {
  display: none;
}
.q-gallery-layer .q-filmstrip .slick-list,
.q-gallery-layer .q-filmstrip .slick-slide {
  margin: 0;
}
.q-gallery-layer .q-filmstrip .ui-control {
  border: 0;
}
.q-gallery-layer .q-multimedia-scroller .slick-prev,
.q-gallery-layer .q-multimedia-scroller .slick-next {
  height: 5rem;
  width: 2.352rem;
}
.q-gallery-layer .q-multimedia-scroller .slick-prev::before,
.q-gallery-layer .q-multimedia-scroller .slick-next::before {
  background-repeat: no-repeat;
  background-size: contain;
  height: 2.64rem;
  width: 1.35rem;
}
.q-gallery-layer .q-gallery-container {
  height: 100%;
  height: calc(100vh - 54px);
  overflow-y: scroll;
  position: relative;
  top: 3.813rem;
}
.q-gallery-layer .q-gallery-container .q-gallery-full-scroller {
  position: relative;
  top: 0;
  z-index: 999;
}
.q-gallery-layer .q-gallery-container .q-gallery-full-scroller .slick-list {
  height: 100%;
  overflow: hidden;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller {
  clear: both;
  display: inline-block;
  float: left;
  width: 100%;
  z-index: 999;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-separator hr {
  display: none;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller.fixed-position {
  position: fixed;
  z-index: 999;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item gb-video-brightcove .q-autosize,
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item gb-video-brightcove .q-ps-autosize,
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item .q-video .q-autosize,
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item .q-video .q-ps-autosize {
  padding: 0;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item gb-video-youtube .gb-autosize {
  padding: 0;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item .q-layer,
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item .q-responsive-image-disclosure {
  visibility: hidden;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item.slick-active .q-layer {
  visibility: visible;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item.slick-active .q-responsive-image-disclosure {
  height: 100%;
  position: absolute;
  top: 0;
  visibility: visible;
  z-index: 3;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item.slick-active .q-responsive-image-disclosure .q-responsive-image-disclosure-text {
  bottom: 0;
  color: #cd9834;
  padding: 0 0 1.563rem;
  position: absolute;
  right: calc(1.875rem + 17px);
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item .video-info-container,
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item .q-video-info-container,
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item .gb-video-info-container {
  display: none;
}
.q-gallery-layer .q-gallery-container .q-slider .slick-list {
  height: auto;
  top: 0;
}
.q-gallery-layer .q-gallery-container .q-slider .q-video .q-autosize {
  background: #000000;
  background-size: cover;
}
.q-gallery-layer .q-gallery-container .q-slider .q-video .q-autosize.q-has-play-button:not(.has-posterframe) .q-video-player {
  display: block;
}
.q-gallery-layer .q-gallery-container .q-slider .q-video.has-posterframe::after, .q-gallery-layer .q-gallery-container .q-slider .q-video.has-modal::after {
  top: 50%;
}
.q-gallery-layer .q-gallery-container .q-slider gb-brightcove-video.q-start-button-position-override::after {
  top: 50%;
}
.q-gallery-layer .q-gallery-container .q-slider gb-brightcove-video .q-content-overlay .q-layer {
  height: 100%;
}
.q-gallery-layer .q-gallery-container .q-slider .q-video-caption {
  display: none;
}
.q-gallery-layer .q-gallery-container .q-gallery-full-scroller .small-margin {
  margin: 0;
}
.q-gallery-layer .q-gallery-container .q-gallery-thumbnail-scroller .q-gallery-thumbnail-scroller-item .small-margin {
  margin: 0;
}
.q-gallery-layer .q-gallery-container.q-gallery-layer-edit {
  height: 100%;
}
.q-gallery-layer .q-gallery-container .q-layer .q-layer-width {
  position: absolute;
}
.q-gallery-layer .q-gallery-container .q-layer .q-layer-width .q-text {
  font-weight: normal;
}
.q-gallery-layer .q-gallery-container .q-slider-item.slick-active .q-layer-width {
  background-color: #e6e6e6;
}
.q-gallery-layer .q-gallery-header {
  background-color: #ffffff;
  height: auto;
  max-width: 120rem;
  position: absolute;
  top: 0;
  z-index: 9999;
}
.q-gallery-layer .q-gallery-header .q-gallery-header-nav {
  display: inline-block;
  float: left;
}
.q-gallery-layer .q-gallery-header .q-gallery-header-nav.disabled .q-dropdown:hover {
  cursor: default;
}
.q-gallery-layer .q-gallery-header .q-dropdown {
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0.3125rem 0.3125rem 0.3125rem 5%;
  text-align: left;
  width: auto;
}
.q-gallery-layer .q-gallery-header .q-dropdown.open .svg-icon .q-gallery-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 1.25rem;
  padding-left: 1.25rem;
  width: 1.25rem;
  margin-bottom: 0;
  transform: rotate(0deg);
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-category-title {
  display: inline-block;
  font-size: 1.4rem;
}
.q-gallery-layer .q-gallery-header .q-dropdown .svg-icon {
  display: inline-block;
  float: right;
  line-height: 0;
  margin-top: 0.625rem;
}
.q-gallery-layer .q-gallery-header .q-dropdown .svg-icon.hide {
  display: none;
}
.q-gallery-layer .q-gallery-header .q-dropdown .svg-icon .q-gallery-carat-container {
  height: 2rem;
  width: 2rem;
}
.q-gallery-layer .q-gallery-header .q-dropdown .svg-icon .q-gallery-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 1.25rem;
  padding-left: 1.25rem;
  width: 1.25rem;
  margin-bottom: 0;
}
.q-gallery-layer .q-gallery-header .q-gallery-header-close {
  margin: 1.125rem 5%;
  position: absolute;
  right: 0;
  z-index: 999;
}
.q-gallery-layer .q-gallery-header .q-gallery-header-close .q-gallery-close-icon {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_close-icon_v01.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 1.88rem;
  padding-left: 1.88rem;
  width: 1.88rem;
}
.q-gallery-layer .q-gallery-header .q-gallery-header-close .close-reveal-modal {
  color: #cd9834;
  line-height: 0;
  padding: 0.625rem;
  position: relative;
  right: 0;
  top: 0;
}
.q-gallery-layer .q-gallery-header.q-gallery-header-edit {
  position: initial;
}
.q-gallery-layer .q-gallery-header-dropdown {
  background: #ffffff;
  background-color: transparent;
  margin: 0;
  max-height: 100%;
  max-height: calc(100vh - 54px);
  max-width: 15.44rem;
  overflow: hidden;
  position: absolute;
  top: 3.813rem;
  visibility: visible;
  z-index: 9999;
}
.q-gallery-layer .q-gallery-header-dropdown .q-gallery-dropdown-item.active {
  background-color: #ffffff;
  border-left: 3px solid #cd9834;
}
.q-gallery-layer .q-gallery-header-dropdown .q-gallery-dropdown-item .q-button-label {
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1.25rem;
}
.q-gallery-layer .q-gallery-header-dropdown.q-dropdown-padding {
  padding-bottom: 3.375rem;
}
.q-gallery-layer .q-gallery-header-dropdown.q-overflow-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
.q-gallery-layer .q-multimedia-teaser {
  margin-bottom: 0;
}
.q-gallery-layer .q-multimedia-scroller .q-slider-counter {
  bottom: 0;
  color: #ffffff;
  position: absolute;
  right: 0;
  text-align: center;
  width: 3.75rem;
  z-index: 5;
}
.q-gallery-layer .q-multimedia-scroller .q-caption-scroller.q-multimedia-teaser .q-teaser-content-container {
  background: transparent;
  display: block !important;
}
.q-gallery-layer .q-multimedia-scroller .q-caption-scroller.q-multimedia-teaser .q-teaser-content-container .q-gallery-title {
  font-size: 0.875rem;
  padding: 0;
}
.q-gallery-layer .q-gallery-thumbnail-scroller {
  background: #ffffff;
  bottom: 0;
  display: none;
  margin-top: 0;
  max-width: 120rem;
  visibility: hidden;
  z-index: 999;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip {
  min-height: auto;
  overflow: hidden;
  padding: 1rem 2.188rem;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-caption-scroller.q-multimedia-teaser .q-teaser-content-container {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-prev,
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-next {
  background-color: #ffffff;
  border: 0;
  font-size: 0;
  height: 100%;
  margin: 0;
  opacity: 1;
  padding: 1.25rem 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.938rem;
  z-index: 100;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-prev::before,
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-next::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/slick-arrow-grey.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 1.938rem;
  left: 0;
  margin: auto;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.125rem;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-prev .icon,
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-next .icon {
  display: none;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-prev {
  left: 0;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-prev::before {
  transform: rotate(180deg);
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-next {
  right: 0;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slick-current {
  position: relative;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slick-current::after {
  border: 3px solid #cd9834;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item {
  position: relative;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item:active::after, .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item.active::after {
  border: 3px solid #cd9834;
  bottom: 0;
  content: " ";
  cursor: pointer;
  height: 100%;
  position: absolute;
  width: 100%;
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item .q-gallery-video-icon {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/video_play_btn.svg") no-repeat;
  background-size: contain;
  border: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0.5);
  width: 50%;
}
.q-gallery-layer .q-gallery-thumbnails {
  clear: both;
  display: block;
  float: left;
  padding: 1rem 0.625rem 9rem;
  visibility: visible;
  width: 100%;
  z-index: 0;
}
.q-gallery-layer .q-gallery-thumbnails .q-gallery-headline {
  clear: both;
  float: left;
  text-transform: uppercase;
  width: 100%;
}
.q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 2rem;
}
.q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item {
  display: block;
  float: left;
  height: auto;
  list-style: none;
  margin: 0 7% 1.1765rem 0;
  padding: 0;
  position: relative;
  width: 28.5%;
}
.q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item img {
  display: block;
  float: left;
}
.q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item:active .q-gallery-thumbnail-item:first-child, .q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item.active .q-gallery-thumbnail-item:first-child {
  cursor: pointer;
  float: left;
  outline: 3px solid #cd9834;
  outline-offset: -3px;
  width: 100%;
}
.q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item .q-gallery-video-icon {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/video_play_btn.svg");
  background-size: contain;
  border: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0.5);
  width: 50%;
}
.q-gallery-layer .q-thumbnail-container {
  padding: 0;
  padding-left: 0.5rem;
}
.q-gallery-layer .mms-text,
.q-gallery-layer .gallerylayer-text {
  display: none;
}
.q-gallery-layer .q-content-bar {
  display: none;
}
.q-gallery-layer .q-hide-util {
  overflow: hidden;
}
@media print, only screen and (max-width:37.4375em) {
  .q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block {
    justify-content: flex-end;
  }
}
@media only screen and (orientation: landscape) {
  .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item .q-gallery-video-icon {
    transform: scale(0.5);
    width: 50%;
  }
}
@media print, only screen and (min-width:26.25em) {
  .q-gallery-layer .q-gallery-thumbnails {
    padding-bottom: 9rem;
  }
  .q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item {
    margin: 0 4.6% 1.5rem 0;
    width: 21.5%;
  }
  .q-gallery-layer .q-gallery-header-dropdown {
    top: 3.813rem;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-gallery-layer .q-multimedia-scroller .slick-next,
.q-gallery-layer .q-multimedia-scroller .slick-prev {
    height: 7.5rem;
    width: 4rem;
  }
  .q-gallery-layer .q-multimedia-scroller .slick-next::before,
.q-gallery-layer .q-multimedia-scroller .slick-prev::before {
    height: 3.8rem;
    width: 2rem;
  }
  .q-gallery-layer .q-gallery-container {
    height: calc(100vh - 64px);
    top: 3.813rem;
  }
  .q-gallery-layer .q-gallery-container .q-slider .slick-list {
    height: 100%;
    overflow: hidden;
  }
  .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item {
    float: left;
  }
  .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item.q-slider-item-low-count {
    /* When > 6 items in thumbnail scroller this ensures correct aspect ratio. */
    /* !important is needed in order to override style attribute set by Slick Slider. */
    width: 16.666% !important;
  }
  .q-gallery-layer .q-gallery-header {
    position: fixed;
  }
  .q-gallery-layer .q-gallery-header .q-dropdown {
    border: 0;
    padding: 0.3125rem 0.3125rem 0.3125rem 1.875rem;
  }
  .q-gallery-layer .q-gallery-header .q-gallery-header-close {
    margin: 0.875rem 0 0 0;
    right: 5%;
  }
  .q-gallery-layer .q-gallery-header .q-gallery-header-close .q-gallery-close-icon {
    background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_close-icon_v01.svg") no-repeat;
    background-size: contain;
    box-sizing: border-box;
    height: 1.88rem;
    padding-left: 1.88rem;
    width: 1.88rem;
  }
  .q-gallery-layer .q-gallery-header-dropdown {
    max-height: calc(100vh - 64px);
    top: 3.813rem;
  }
}
@media print, only screen and (min-width:37.5em) and (max-width:59.9375em) {
  .q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item .q-gallery-video-icon {
    transform: translateZ(0) scale(0.5);
  }
  .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item .q-gallery-video-icon {
    transform: scale(0.5);
    width: 50%;
  }
}
@media only screen and (min-width:60em) {
  .q-gallery-layer {
    margin: 0 auto;
    max-width: 120rem;
  }
  .q-gallery-layer .q-multimedia-scroller .slick-next,
.q-gallery-layer .q-multimedia-scroller .slick-prev {
    height: 6.521rem;
    width: 3.478rem;
  }
  .q-gallery-layer .q-multimedia-scroller .slick-next::before,
.q-gallery-layer .q-multimedia-scroller .slick-prev::before {
    height: 3.3rem;
    width: 1.74rem;
  }
  .q-gallery-layer .q-gallery-container {
    background-color: #ffffff;
    height: calc(100vh - 74px);
    overflow-y: hidden;
    top: 3.813rem;
  }
  .q-gallery-layer .q-gallery-container .q-slider-item.slick-active .q-layer-width {
    bottom: 0;
    position: absolute;
    width: 100vw;
    z-index: 999;
  }
  .q-gallery-layer .q-gallery-header {
    position: fixed;
  }
  .q-gallery-layer .q-gallery-header .q-dropdown {
    border: 0;
    padding: 0.3125rem 0.3125rem 0.3125rem 1.875rem;
  }
  .q-gallery-layer .q-gallery-header .q-dropdown .q-category-title {
    font-size: 1.4rem;
    min-width: 10.94rem;
  }
  .q-gallery-layer .q-gallery-header .q-gallery-header-close {
    margin: 0.875rem 0 0 0;
    right: 2.5rem;
  }
  .q-gallery-layer .q-gallery-header-dropdown {
    max-height: calc(100vh - 74px);
    max-width: 20.5rem;
    top: 3.813rem;
  }
  .q-gallery-layer .q-gallery-header-dropdown.q-dropdown-padding {
    padding-bottom: 4.625rem;
  }
  .q-gallery-layer .q-gallery-thumbnail-scroller {
    display: inherit;
    visibility: visible;
  }
  .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item {
    float: left;
  }
  .q-gallery-layer .q-gallery-thumbnails {
    display: none;
    padding-bottom: 9.625rem;
  }
  .q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item .q-gallery-video-icon {
    transform: translateZ(0) scale(1);
  }
}
.no-touch .q-gallery-layer .q-multimedia-scroller .q-slider:hover .slick-arrow {
  opacity: 0.4;
}
.no-touch .q-gallery-layer .q-multimedia-scroller .q-slider:hover .slick-arrow:hover {
  opacity: 0.7;
}
.no-touch .q-gallery-layer .q-multimedia-scroller .q-slider .slick-arrow:focus {
  opacity: 0.7;
}
.no-touch .q-gallery-layer .q-gallery-header .q-dropdown:hover, .no-touch .q-gallery-layer .q-gallery-header .q-dropdown.focus {
  color: #ffffff;
}
.no-touch .q-gallery-layer .q-gallery-header .q-dropdown:hover .svg-icon .q-gallery-carat, .no-touch .q-gallery-layer .q-gallery-header .q-dropdown.focus .svg-icon .q-gallery-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 1.25rem;
  padding-left: 1.25rem;
  width: 1.25rem;
  margin-bottom: 0;
}
.no-touch .q-gallery-layer .q-gallery-header .q-dropdown.open .svg-icon .q-gallery-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 1.25rem;
  padding-left: 1.25rem;
  width: 1.25rem;
}
.no-touch .q-gallery-layer .q-gallery-header-dropdown .q-gallery-dropdown-item:hover {
  background-color: #ffffff;
}
.no-touch .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-prev:hover,
.no-touch .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-next:hover {
  opacity: 1;
}
.no-touch .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-prev:hover::before,
.no-touch .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-next:hover::before {
  opacity: 1;
}
.no-touch .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item:hover::after {
  border: 3px solid #cd9834;
  bottom: 0;
  content: " ";
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}
.no-touch .q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item:hover .q-gallery-thumbnail-item:first-child {
  cursor: pointer;
  float: left;
  outline: 3px solid #cd9834;
  outline-offset: -3px;
  width: 100%;
}
.no-touch .q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block .q-slider-item .q-gallery-video-icon:hover {
  cursor: pointer;
}
.touch .q-gallery-layer button.slick-arrow.gallery-image-arrow {
  opacity: 0.4 !important;
}
.touch .q-gallery-layer .q-multimedia-scroller.fixed-position {
  overflow: hidden;
}
.touch .q-gallery-layer .q-multimedia-scroller.fixed-position .slick-arrow {
  display: none !important;
}

.gallery-reveal-modal {
  border: 0;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
}
.gallery-reveal-modal .q-modal-header {
  display: none;
}
.gallery-reveal-modal.open {
  background-color: #ffffff;
}
.gallery-reveal-modal.open .q-modal-content {
  left: 0;
  margin: 0;
  top: 0;
  width: 100%;
}

.q-hidden {
  visibility: hidden;
}

.q-visible {
  visibility: visible;
}

.q-gallery-preview {
  width: 100%;
}
.q-gallery-preview .row {
  display: flex;
  flex-wrap: wrap;
  float: left;
  max-width: 100% !important;
  width: 100% !important;
}
.q-gallery-preview .row.collapse {
  max-width: 100% !important;
  width: 100% !important;
}
.q-gallery-preview .row.collapse::before, .q-gallery-preview .row.collapse::after {
  display: flex !important;
}
.q-gallery-preview .row.collapse > .columns {
  display: flex;
  flex: 0 0 50%;
}
.q-gallery-preview .q-gallery-item-container {
  display: flex;
  flex: 0 0 100%;
  height: 100%;
}
.q-gallery-preview .columns {
  padding: 0;
}
.q-gallery-preview > .row {
  max-width: 100%;
}
.q-gallery-preview .q-gallery-item {
  background-color: #1a1a1a;
  display: flex;
  float: left;
  width: 100%;
}
.q-gallery-preview .q-gallery-item .small-margin,
.q-gallery-preview .q-gallery-item .medium-margin,
.q-gallery-preview .q-gallery-item .large-margin {
  margin-bottom: 0;
}
.q-gallery-preview .q-gallery-item .q-responsive-image {
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: 0.5s ease;
  width: 100%;
}
.q-gallery-preview .q-gallery-item .q-responsive-image img {
  transition: 0.5s ease;
}
.q-gallery-preview .q-gallery-item .q-responsive-image .q-gallery-video-icon {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/video_play_btn.svg") no-repeat;
  background-size: contain;
  border: 0;
  bottom: 0;
  height: 5rem;
  left: 0;
  margin: auto;
  opacity: 0.75;
  position: absolute;
  right: 0;
  top: 0;
  width: 5rem;
}
.q-gallery-preview .q-gallery-item .q-responsive-content {
  align-items: center;
  color: #ffffff;
  display: flex;
  text-align: center;
  width: 100%;
}
.q-gallery-preview .q-gallery-item .q-responsive-content img {
  margin-bottom: 10px;
}
.q-gallery-preview .q-gallery-item .q-responsive-content .svg-icon-gallery {
  width: 100%;
}
.q-gallery-preview .q-gallery-item .q-responsive-content .svg-icon-gallery,
.q-gallery-preview .q-gallery-item .q-responsive-content .q-gallery-label {
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0;
  padding: 0.1875rem 0 0.3125rem;
  text-align: center;
  text-transform: none;
}
.q-gallery-preview .q-gallery-item .q-responsive-content .q-gallery-count {
  color: #cd9834;
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  display: none;
}
.q-gallery-preview .q-gallery-item .q-responsive-content-table {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.q-gallery-preview .q-gallery-item .q-responsive-content-table img {
  width: 2.5rem;
}
.q-gallery-preview .row > .columns:last-child {
  float: left;
}
.q-gallery-preview .row > .columns:last-child .q-gallery-item {
  background-color: #1a1a1a;
}
@media print, only screen and (min-width:26.25em) {
  .q-gallery-preview .q-gallery-item .q-responsive-content .q-responsive-content-table .svg-icon-gallery,
.q-gallery-preview .q-gallery-item .q-responsive-content .q-responsive-content-table .q-gallery-label {
    font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.6875rem;
    padding: 0.1875rem 0 0.3125rem;
  }
  .q-gallery-preview .q-gallery-item .q-responsive-content:hover .q-gallery-label {
    border-bottom: 3px solid #cd9834;
    margin-bottom: 0.5rem;
    padding: 0.1875rem 0 0.3125rem;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-gallery-preview.focus .q-gallery-item .q-responsive-image {
    transition: initial;
    transition-duration: 0s;
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  .q-gallery-preview .q-gallery-item:focus .q-responsive-image img {
    cursor: pointer;
    transform: translateZ(0) scale(1.06);
  }
  .q-gallery-preview .q-gallery-item:focus .q-responsive-content .q-gallery-label {
    border-bottom: 3px solid #cd9834;
    margin-bottom: 0.5rem;
    padding: 0.1875rem 0 0.3125rem;
  }
  .q-gallery-preview .q-gallery-item .q-responsive-content .q-gallery-count {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  .q-gallery-preview .q-gallery-item .q-responsive-content .q-responsive-content-table .svg-icon-gallery,
.q-gallery-preview .q-gallery-item .q-responsive-content .q-responsive-content-table .q-gallery-label {
    font-size: 1.125rem;
  }
}
@media only screen and (min-width:60em) {
  .q-gallery-preview .row.collapse > .columns {
    flex: 0 0 33.33%;
  }
  .q-gallery-preview .q-gallery-item .q-responsive-image .q-gallery-video-icon {
    height: 5rem;
    width: 5rem;
  }
}

.q-body-gallery-preview {
  inset-block-start: 0;
  inset-inline-start: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.no-touch .q-gallery-preview .q-responsive-image:hover img {
  cursor: pointer;
  transform: translateZ(0) scale(1.06);
}
.no-touch .q-gallery-preview .q-responsive-image:hover .q-gallery-video-icon {
  opacity: 1;
  transform: translateZ(0) scale(1);
}
.no-touch .q-gallery-preview .q-responsive-content:hover {
  background-color: #000000;
  cursor: pointer;
}
.no-touch .q-gallery-preview .q-responsive-content:hover .q-gallery-label {
  border-bottom: 3px solid #cd9834;
  margin-bottom: -0.125rem;
  padding-bottom: 0.3125rem;
}
.no-touch .q-gallery-preview:hover .q-responsive-image {
  transition: initial;
  transition-duration: 0s;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
@media print, only screen and (min-width:26.25em) {
  .no-touch .q-gallery-preview .q-gallery-item .q-responsive-content:hover .q-gallery-label {
    border-bottom: 3px solid #cd9834;
    margin-bottom: 0.5rem;
    padding: 0.1875rem 0 0.3125rem;
  }
}

html[dir=rtl] .q-gallery-preview .row,
html[dir=rtl] .q-gallery-preview .q-gallery-item {
  float: right;
}
html[dir=rtl] .q-gallery-preview .row .q-responsive-content .q-responsive-content-table .q-gallery-count,
html[dir=rtl] .q-gallery-preview .row .q-responsive-content .q-responsive-content-table .q-gallery-label,
html[dir=rtl] .q-gallery-preview .q-gallery-item .q-responsive-content .q-responsive-content-table .q-gallery-count,
html[dir=rtl] .q-gallery-preview .q-gallery-item .q-responsive-content .q-responsive-content-table .q-gallery-label {
  letter-spacing: 0;
}
html[dir=rtl] .q-gallery-preview > .columns:last-child {
  float: right;
}

.q-image-svg .q-rectangle-image-responsive {
  width: auto;
}
.q-image-svg .q-round-image {
  width: auto;
}

.q-multimedia-scroller,
adv-grid > adv-scrl {
  position: relative;
  /* Arrows */
  /* Custom styles for slider elements */
  /* Arrows */
  /* Keep this class below .slick-next and .slick-prev */
}
.q-multimedia-scroller .q-with-caption .slider-pagination,
.q-multimedia-scroller .q-slider-tabs .slider-pagination,
adv-grid > adv-scrl .q-with-caption .slider-pagination,
adv-grid > adv-scrl .q-slider-tabs .slider-pagination {
  top: auto;
}
.q-multimedia-scroller .q-with-caption .slider-pagination,
adv-grid > adv-scrl .q-with-caption .slider-pagination {
  bottom: 0;
  position: absolute;
}
.q-multimedia-scroller .q-caption-scroller .q-teaser-content-container,
adv-grid > adv-scrl .q-caption-scroller .q-teaser-content-container {
  background-color: #f7d900;
}
.q-multimedia-scroller .q-caption-scroller .q-button,
adv-grid > adv-scrl .q-caption-scroller .q-button {
  display: none;
  font-weight: 700;
  margin-top: 0;
}
@media print, only screen and (min-width:26.25em) {
  .q-multimedia-scroller .q-caption-scroller .q-button,
adv-grid > adv-scrl .q-caption-scroller .q-button {
    display: block;
  }
}
.q-multimedia-scroller .slick-arrow,
.q-multimedia-scroller .scrl-btn,
adv-grid > adv-scrl .slick-arrow,
adv-grid > adv-scrl .scrl-btn {
  transition: opacity;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  background-color: rgba(0, 0, 0, 0.2);
}
.q-multimedia-scroller .slick-arrow.slick-disabled,
.q-multimedia-scroller .scrl-btn.slick-disabled,
adv-grid > adv-scrl .slick-arrow.slick-disabled,
adv-grid > adv-scrl .scrl-btn.slick-disabled {
  display: none !important;
}
.q-multimedia-scroller .q-link-icon-inline,
adv-grid > adv-scrl .q-link-icon-inline {
  transition: opacity;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  opacity: 0;
}
.q-multimedia-scroller.q-slide-complete .q-link-icon-inline,
adv-grid > adv-scrl.q-slide-complete .q-link-icon-inline {
  opacity: 1;
}
.q-multimedia-scroller.q-circle-arrow,
adv-grid > adv-scrl.q-circle-arrow {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/circleArrow.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 2rem;
  padding-left: 2rem;
  width: 2rem;
}
.q-multimedia-scroller .q-svg-icon,
adv-grid > adv-scrl .q-svg-icon {
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.q-multimedia-scroller .q-svg-icon-large,
adv-grid > adv-scrl .q-svg-icon-large {
  height: 2rem;
  width: 2rem;
}
.q-multimedia-scroller .q-indicator-numbers,
adv-grid > adv-scrl .q-indicator-numbers {
  color: #ffffff;
  left: 3.75rem;
  position: relative;
  text-rendering: geometricPrecision;
  top: 0.625rem;
  vertical-align: middle;
  z-index: 1;
}
.q-multimedia-scroller .q-dots-to-numbers-numbers,
adv-grid > adv-scrl .q-dots-to-numbers-numbers {
  display: none;
}
.q-multimedia-scroller .q-dots-to-numbers-dots,
adv-grid > adv-scrl .q-dots-to-numbers-dots {
  display: inline;
}
.q-multimedia-scroller .q-content-title,
adv-grid > adv-scrl .q-content-title {
  font-family: Louis, Tahoma, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.00125rem;
  line-height: 1.875rem;
}
.q-multimedia-scroller .q-indicator-separator,
adv-grid > adv-scrl .q-indicator-separator {
  display: none;
}
.q-multimedia-scroller .touch-arrow,
adv-grid > adv-scrl .touch-arrow {
  display: none;
}
.q-multimedia-scroller .q-content-bar,
adv-grid > adv-scrl .q-content-bar {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.q-multimedia-scroller .q-content-bar.q-dots-below,
adv-grid > adv-scrl .q-content-bar.q-dots-below {
  position: relative;
}
.q-multimedia-scroller .q-content-bar.q-dots-below .slick-indicator-container,
adv-grid > adv-scrl .q-content-bar.q-dots-below .slick-indicator-container {
  padding: 0 0.625rem;
}
.q-multimedia-scroller .q-content-bar.q-content-below .q-link-label,
adv-grid > adv-scrl .q-content-bar.q-content-below .q-link-label {
  color: #000000;
}
.q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below,
adv-grid > adv-scrl .q-content-bar.q-content-below.q-dots-below {
  margin-top: 0;
}
.q-multimedia-scroller .q-content-bar.q-dots-above.q-content-below,
adv-grid > adv-scrl .q-content-bar.q-dots-above.q-content-below {
  position: relative;
}
.q-multimedia-scroller .q-content-bar.q-content-above .q-scroller-overlay,
adv-grid > adv-scrl .q-content-bar.q-content-above .q-scroller-overlay {
  z-index: 2;
}
.q-multimedia-scroller .q-semi-opaque-bar,
adv-grid > adv-scrl .q-semi-opaque-bar {
  background-color: #000000;
  display: block;
  height: 3.75rem;
  margin-top: -3.75rem;
  opacity: 0.5;
  z-index: 101;
}
.q-multimedia-scroller .q-semi-opaque-bar.q-dots-below,
adv-grid > adv-scrl .q-semi-opaque-bar.q-dots-below {
  margin-bottom: 3.75rem;
  margin-top: -7.5rem;
}
.q-multimedia-scroller .q-semi-opaque-bar.q-content-below,
adv-grid > adv-scrl .q-semi-opaque-bar.q-content-below {
  background-color: transparent;
  margin-top: 0.25rem;
}
.q-multimedia-scroller .q-semi-opaque-bar.q-content-below.q-dots-below,
adv-grid > adv-scrl .q-semi-opaque-bar.q-content-below.q-dots-below {
  margin-bottom: 0;
  margin-top: -3.75rem;
}
.q-multimedia-scroller .q-slider,
adv-grid > adv-scrl .q-slider {
  min-height: 11rem;
  overflow: hidden;
}
.q-multimedia-scroller .q-slider .q-slider-item,
adv-grid > adv-scrl .q-slider .q-slider-item {
  height: auto;
}
.q-multimedia-scroller .q-slider .q-slider-item:not(:first-child),
adv-grid > adv-scrl .q-slider .q-slider-item:not(:first-child) {
  opacity: 0;
}
.q-multimedia-scroller .q-slider .q-slider-item.slick-cloned .q-mod-responsive-image,
adv-grid > adv-scrl .q-slider .q-slider-item.slick-cloned .q-mod-responsive-image {
  visibility: visible !important;
}
.q-multimedia-scroller .q-slider .q-slider-item > .q-slide-link-container,
adv-grid > adv-scrl .q-slider .q-slider-item > .q-slide-link-container {
  display: block;
}
.q-multimedia-scroller .q-slider .slick-track,
adv-grid > adv-scrl .q-slider .slick-track {
  height: 100%;
}
.q-multimedia-scroller .q-slider .slick-track .q-slider-item:not(:first-child),
adv-grid > adv-scrl .q-slider .slick-track .q-slider-item:not(:first-child) {
  opacity: 1;
}
.q-multimedia-scroller .q-slider.q-slider-hover-arrow .slider-control-prev,
adv-grid > adv-scrl .q-slider.q-slider-hover-arrow .slider-control-prev {
  left: -62.5rem;
}
.q-multimedia-scroller .q-slider.q-slider-hover-arrow .q-slider-control-next,
adv-grid > adv-scrl .q-slider.q-slider-hover-arrow .q-slider-control-next {
  right: -62.5rem;
}
.q-multimedia-scroller .q-slider.q-slider-hover-arrow:hover .q-slider-control-prev,
adv-grid > adv-scrl .q-slider.q-slider-hover-arrow:hover .q-slider-control-prev {
  left: 0;
}
.q-multimedia-scroller .q-slider.q-slider-hover-arrow:hover .q-slider-control-next,
adv-grid > adv-scrl .q-slider.q-slider-hover-arrow:hover .q-slider-control-next {
  right: 0;
}
.q-multimedia-scroller .q-slider .slick-slide,
adv-grid > adv-scrl .q-slider .slick-slide {
  margin: 0;
}
.q-multimedia-scroller .q-slider .slick-list,
adv-grid > adv-scrl .q-slider .slick-list {
  height: 100%;
}
.q-multimedia-scroller .touch .q-slider.slider-hover-arrow .slider-control-prev,
adv-grid > adv-scrl .touch .q-slider.slider-hover-arrow .slider-control-prev {
  left: 0;
}
.q-multimedia-scroller .touch .q-slider.slider-hover-arrow .slider-control-next,
adv-grid > adv-scrl .touch .q-slider.slider-hover-arrow .slider-control-next {
  right: 0;
}
.q-multimedia-scroller .slick-arrow,
.q-multimedia-scroller .scrl-btn,
adv-grid > adv-scrl .slick-arrow,
adv-grid > adv-scrl .scrl-btn {
  transition: opacity;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  background-color: rgba(0, 0, 0, 0.2);
  border: transparent;
  color: transparent;
  cursor: pointer;
  font-size: 0;
  height: 6.875rem;
  line-height: 0;
  margin: 0;
  margin-top: -5.313rem;
  opacity: 0;
  outline: none;
  padding: 1.063rem 0;
  position: absolute;
  top: 50%;
  width: 3.125rem;
  z-index: 100;
}
.q-multimedia-scroller .slick-arrow.slick-disabled,
.q-multimedia-scroller .scrl-btn.slick-disabled,
adv-grid > adv-scrl .slick-arrow.slick-disabled,
adv-grid > adv-scrl .scrl-btn.slick-disabled {
  display: none;
  opacity: 1 !important;
}
.q-multimedia-scroller .slick-arrow.slick-disabled::before,
.q-multimedia-scroller .scrl-btn.slick-disabled::before,
adv-grid > adv-scrl .slick-arrow.slick-disabled::before,
adv-grid > adv-scrl .scrl-btn.slick-disabled::before {
  opacity: 0.25;
}
.q-multimedia-scroller .slick-arrow::before,
.q-multimedia-scroller .scrl-btn::before,
adv-grid > adv-scrl .slick-arrow::before,
adv-grid > adv-scrl .scrl-btn::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/slick-arrow.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 3.75rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(1);
  width: 1.875rem;
}
.q-multimedia-scroller .slick-arrow.invert-arrow::before,
.q-multimedia-scroller .scrl-btn.invert-arrow::before,
adv-grid > adv-scrl .slick-arrow.invert-arrow::before,
adv-grid > adv-scrl .scrl-btn.invert-arrow::before {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/slick-arrow-invert.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  filter: FlipH;
  height: 3.75rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(-1);
  width: 1.875rem;
}
.q-multimedia-scroller .slick-prev,
.q-multimedia-scroller .scrl-prev,
adv-grid > adv-scrl .slick-prev,
adv-grid > adv-scrl .scrl-prev {
  left: 0;
}
.q-multimedia-scroller .slick-prev::before,
.q-multimedia-scroller .scrl-prev::before,
adv-grid > adv-scrl .slick-prev::before,
adv-grid > adv-scrl .scrl-prev::before {
  /* flip right arrow to face left */
  filter: FlipH;
  transform: scaleX(-1);
}
.q-multimedia-scroller .slick-prev.invert-arrow::before,
.q-multimedia-scroller .scrl-prev.invert-arrow::before,
adv-grid > adv-scrl .slick-prev.invert-arrow::before,
adv-grid > adv-scrl .scrl-prev.invert-arrow::before {
  transform: scaleX(1);
}
.q-multimedia-scroller .slick-next,
.q-multimedia-scroller .scrl-next,
adv-grid > adv-scrl .slick-next,
adv-grid > adv-scrl .scrl-next {
  right: 0;
}
.q-multimedia-scroller .slick-slide,
adv-grid > adv-scrl .slick-slide {
  height: 100%;
}
.q-multimedia-scroller .slick-slide .q-center-transform,
adv-grid > adv-scrl .slick-slide .q-center-transform {
  display: inline-block;
}
.q-multimedia-scroller .slick-indicator-container,
adv-grid > adv-scrl .slick-indicator-container {
  bottom: 0;
  height: 3.75rem;
  margin: 0;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}
.q-multimedia-scroller .slick-dots,
adv-grid > adv-scrl .slick-dots {
  margin-left: 3.75rem;
  margin-top: 1.4rem;
  position: absolute;
  z-index: 1;
}
.q-multimedia-scroller .slick-dots li,
adv-grid > adv-scrl .slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 1.18rem;
  margin: 0 0.41rem;
  position: relative;
  width: 1.18rem;
}
.q-multimedia-scroller .slick-dots li button,
adv-grid > adv-scrl .slick-dots li button {
  background: 0;
  background-color: #b3b3b3;
  border-color: transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  bottom: 0;
  color: transparent;
  display: block;
  height: 0.94rem;
  left: 0;
  margin: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 0.94rem;
  /* for Chrome: no blue box on click */
}
.q-multimedia-scroller .slick-dots li button:focus,
adv-grid > adv-scrl .slick-dots li button:focus {
  outline: 0;
}
.q-multimedia-scroller .slick-dots li.slick-active button,
adv-grid > adv-scrl .slick-dots li.slick-active button {
  background-color: transparent;
  border-color: #b3b3b3;
  height: 1.18rem;
  width: 1.18rem;
}
.q-multimedia-scroller .slick-dots li:first-child,
adv-grid > adv-scrl .slick-dots li:first-child {
  margin-left: 0;
}
.q-multimedia-scroller .slick-dots li:last-child,
adv-grid > adv-scrl .slick-dots li:last-child {
  margin-right: 0;
}
.q-multimedia-scroller .slick-dots.q-dots-align-center,
adv-grid > adv-scrl .slick-dots.q-dots-align-center {
  position: relative;
  text-align: center;
}
.q-multimedia-scroller .slick-dots.q-dots-align-left,
adv-grid > adv-scrl .slick-dots.q-dots-align-left {
  position: relative;
  text-align: left;
}
.q-multimedia-scroller .slick-dots.q-dots-align-right,
adv-grid > adv-scrl .slick-dots.q-dots-align-right {
  position: relative;
  text-align: right;
}
.q-multimedia-scroller .scroller-item,
adv-grid > adv-scrl .scroller-item {
  position: relative;
}
.q-multimedia-scroller .scroller-background-image,
adv-grid > adv-scrl .scroller-background-image {
  height: auto;
  width: 100%;
}
.q-multimedia-scroller .q-scroller-overlay,
adv-grid > adv-scrl .q-scroller-overlay {
  display: block;
  position: absolute;
  visibility: visible;
  z-index: 100;
}
.q-multimedia-scroller .q-scroller-overlay.q-content-bar-align-left,
adv-grid > adv-scrl .q-scroller-overlay.q-content-bar-align-left {
  right: auto;
  text-align: left;
}
.q-multimedia-scroller .q-scroller-overlay.q-content-bar-align-center,
adv-grid > adv-scrl .q-scroller-overlay.q-content-bar-align-center {
  left: 50%;
  right: auto;
  text-align: center;
  transform: translate(-50%);
}
.q-multimedia-scroller .q-scroller-overlay.q-content-bar-align-right,
adv-grid > adv-scrl .q-scroller-overlay.q-content-bar-align-right {
  text-align: right;
}
.q-multimedia-scroller .q-scroller-overlay-header,
adv-grid > adv-scrl .q-scroller-overlay-header {
  padding: 1rem;
  top: 0;
}
.q-multimedia-scroller .q-scroller-overlay-more,
adv-grid > adv-scrl .q-scroller-overlay-more {
  bottom: 0.9rem;
  right: 3.75rem;
  z-index: 55;
}
.q-multimedia-scroller .q-scroller-overlay-more .q-link-label,
adv-grid > adv-scrl .q-scroller-overlay-more .q-link-label {
  color: #ffffff;
}
.q-multimedia-scroller .q-link-icon-inline .q-link-label,
adv-grid > adv-scrl .q-link-icon-inline .q-link-label {
  display: inline-block;
  margin: 0 0.125rem 0 0;
  text-rendering: geometricPrecision;
  vertical-align: middle;
}
.q-multimedia-scroller .q-link-icon-inline .q-link-icon,
adv-grid > adv-scrl .q-link-icon-inline .q-link-icon {
  vertical-align: middle;
}
.q-multimedia-scroller .q-scroller-item,
adv-grid > adv-scrl .q-scroller-item {
  z-index: -1;
}
.q-multimedia-scroller .q-scroller-item .slick-current,
adv-grid > adv-scrl .q-scroller-item .slick-current {
  z-index: 1;
}
.q-multimedia-scroller .slick-arrow-no-content-bar,
adv-grid > adv-scrl .slick-arrow-no-content-bar {
  margin-top: -3.438rem;
}

adv-grid > adv-scrl > .scrl-btn {
  align-self: center;
  margin: 0 !important;
  top: auto !important;
}

@media print, only screen and (min-width:37.5em) {
  .q-multimedia-scroller .slick-dots.q-dots-align-left {
    margin-left: 3.75rem;
  }
  .q-multimedia-scroller .slick-dots.q-dots-align-right {
    margin-left: -3.75rem;
    margin-right: 3.75rem;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay-more {
    background-color: #ffffff;
    border-bottom: 1px solid #e6e6e6;
    bottom: auto;
    display: block;
    overflow: hidden;
    padding: 1.25rem 0.625rem;
    position: relative;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay-more.q-content-align-right {
    right: 0;
  }
  .q-multimedia-scroller .q-content-bar.q-dots-above .slick-indicator-container {
    padding: 0 0.625rem;
    position: absolute;
    top: -3.75rem;
  }
  .q-multimedia-scroller .q-scroller-overlay.q-content-align-right {
    margin-right: 3.75rem;
  }
  .q-multimedia-scroller .q-scroller-overlay.q-content-bar-align-left {
    margin-left: 0;
    padding: 0 0.625rem;
  }
  .q-multimedia-scroller .q-scroller-overlay.q-content-bar-align-left .q-link-icon-inline {
    margin-left: 3.75rem;
  }
  .q-multimedia-scroller .q-link-icon-inline:hover .q-link-label {
    color: #cd9834;
  }
}
@media only screen and (min-width:60em) {
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay-more {
    padding: 1.25rem 3.75rem;
  }
}
@media print, only screen and (max-width:59.9375em) {
  .q-multimedia-scroller .q-indicator-numbers {
    color: #262626;
    left: 0;
    width: 100%;
  }
  .q-multimedia-scroller .q-dots-to-numbers-numbers {
    display: inline;
  }
  .q-multimedia-scroller .q-dots-to-numbers-dots {
    /* !important needed to override element style attribute */
    display: none !important;
  }
  .q-multimedia-scroller .q-content-bar {
    position: relative;
  }
  .q-multimedia-scroller .q-content-bar.q-dots-above .slick-indicator-container {
    padding: 0 0.625rem;
    position: absolute;
    top: -3.75rem;
  }
  .q-multimedia-scroller .q-content-bar.q-dots-above.q-content-above .slick-indicator-container {
    top: 0;
  }
  .q-multimedia-scroller .q-content-bar.q-content-above {
    position: relative;
  }
  .q-multimedia-scroller .q-content-bar.q-content-above .q-scroller-overlay {
    background-color: transparent;
    border-bottom: transparent;
    bottom: 0.625rem;
    height: auto;
    padding: 0 0.625rem;
    position: absolute;
  }
  .q-multimedia-scroller .q-content-bar.q-content-above .q-scroller-overlay.q-content-bar-align-right {
    right: 0;
  }
  .q-multimedia-scroller .q-content-bar.q-content-above .q-scroller-overlay .q-link-label {
    color: #ffffff;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay-more {
    background-color: #ffffff;
    border-bottom: 1px solid #e6e6e6;
    bottom: auto;
    display: block;
    overflow: hidden;
    padding-top: 1rem;
    position: relative;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay-more .q-link-label {
    color: #262626;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .slick-list {
    overflow: visible;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .slick-dots {
    margin-top: -0.625rem;
    opacity: 1;
    padding: 0;
    top: 50%;
    width: 100%;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .slick-dots li button {
    border-color: transparent;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .slick-dots li.slick-active button {
    background-color: transparent;
    border-color: #b3b3b3;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .slick-indicator-container {
    background-color: #ffffff;
    opacity: 1;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .slick-indicator-container.q-single-slide {
    display: none;
  }
  .q-multimedia-scroller .q-semi-opaque-bar {
    display: none;
  }
  .q-multimedia-scroller .q-semi-opaque-bar.q-content-above {
    display: block;
  }
  .q-multimedia-scroller .q-scroller-overlay-more {
    background-color: #ffffff;
    border-bottom: 1px solid #e6e6e6;
    bottom: auto;
    display: block;
    height: auto;
    overflow: hidden;
    padding-top: 1rem;
    position: relative;
    right: auto;
    text-align: right;
  }
  .q-multimedia-scroller .slick-indicator-container {
    height: 3.75rem;
  }
  .q-multimedia-scroller .q-scroller-overlay-more .q-link-label {
    color: #262626;
  }
  .q-multimedia-scroller .q-scroller-overlay {
    padding: 1.25rem 0.625rem;
  }
  .q-multimedia-scroller .slick-list {
    overflow: visible;
  }
  .q-multimedia-scroller .slick-dots {
    background-color: #ffffff;
    margin: 0;
    margin-top: -0.625rem;
    opacity: 1;
    padding: 0;
    position: relative;
    text-align: center;
    top: 50%;
    width: 100%;
  }
  .q-multimedia-scroller .slick-dots li button {
    border-color: transparent;
  }
  .q-multimedia-scroller .slick-dots li.slick-active button {
    background-color: transparent;
    border-color: #b3b3b3;
  }
  .q-multimedia-scroller .slick-indicator-container {
    background-color: #ffffff;
    opacity: 1;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .q-multimedia-scroller .slick-indicator-container.q-single-slide {
    display: none;
  }
  .q-multimedia-scroller .grid-bg-grey-very-light .slick-dots,
.q-multimedia-scroller .grid-bg-grey-very-light .slick-indicator-container,
.q-multimedia-scroller .grid-bg-grey-light .slick-dots,
.q-multimedia-scroller .grid-bg-grey-light .slick-indicator-container,
.q-multimedia-scroller .grid-bg-grey-dark .slick-dots,
.q-multimedia-scroller .grid-bg-grey-dark .slick-indicator-container,
.q-multimedia-scroller .grid-bg-grey-very-dark .slick-dots,
.q-multimedia-scroller .grid-bg-grey-very-dark .slick-indicator-container {
    background-color: transparent;
  }
  .q-multimedia-scroller .slick-arrow,
.q-multimedia-scroller .scrl-btn {
    margin-top: -3.438rem;
  }
}
.q-multimedia-scroller,
adv-grid {
  /* Authorable properties - Arrows */
}
.no-touch .q-multimedia-scroller,
.no-touch adv-grid {
  /* Arrows */
}
.no-touch .q-multimedia-scroller:hover .slick-arrow,
.no-touch .q-multimedia-scroller:hover .scrl-btn,
.no-touch adv-grid:hover .slick-arrow,
.no-touch adv-grid:hover .scrl-btn {
  opacity: 0.5;
}
.no-touch .q-multimedia-scroller .slick-arrow:hover, .no-touch .q-multimedia-scroller .slick-arrow.focus,
.no-touch .q-multimedia-scroller .scrl-btn:hover,
.no-touch .q-multimedia-scroller .scrl-btn.focus,
.no-touch adv-grid .slick-arrow:hover,
.no-touch adv-grid .slick-arrow.focus,
.no-touch adv-grid .scrl-btn:hover,
.no-touch adv-grid .scrl-btn.focus {
  opacity: 1;
}
.q-multimedia-scroller .slick-arrow.fixed-arrow,
.q-multimedia-scroller .scrl-btn.fixed-arrow,
adv-grid .slick-arrow.fixed-arrow,
adv-grid .scrl-btn.fixed-arrow {
  opacity: 0.5;
}
.q-multimedia-scroller .slick-arrow.fixed-arrow.focus,
.q-multimedia-scroller .scrl-btn.fixed-arrow.focus,
adv-grid .slick-arrow.fixed-arrow.focus,
adv-grid .scrl-btn.fixed-arrow.focus {
  opacity: 1;
}
.q-multimedia-scroller .q-semi-opaque-bar.inside-image,
adv-grid .q-semi-opaque-bar.inside-image {
  margin-top: -3.75rem;
}
.q-multimedia-scroller .q-semi-opaque-bar.outside-image,
adv-grid .q-semi-opaque-bar.outside-image {
  margin-bottom: -3.75rem;
}
.q-multimedia-scroller .q-semi-opaque-bar.fixed-arrow-touch,
adv-grid .q-semi-opaque-bar.fixed-arrow-touch {
  opacity: 0.5;
}
.q-multimedia-scroller .q-semi-opaque-bar.fixed-arrow-touch:hover, .q-multimedia-scroller .q-semi-opaque-bar.fixed-arrow-touch.focus,
adv-grid .q-semi-opaque-bar.fixed-arrow-touch:hover,
adv-grid .q-semi-opaque-bar.fixed-arrow-touch.focus {
  opacity: 1;
}
.q-multimedia-scroller .q-semi-opaque-bar.no-arrow-touch,
adv-grid .q-semi-opaque-bar.no-arrow-touch {
  opacity: 0;
}
.q-multimedia-scroller .q-semi-opaque-bar.no-arrow-touch:hover, .q-multimedia-scroller .q-semi-opaque-bar.no-arrow-touch.focus,
adv-grid .q-semi-opaque-bar.no-arrow-touch:hover,
adv-grid .q-semi-opaque-bar.no-arrow-touch.focus {
  opacity: 0;
}

.q-responsive-image {
  position: relative;
  width: 100%;
}
.q-responsive-image .q-responsive-image-container {
  overflow: hidden;
}
.q-responsive-image .q-rectangle-image-responsive {
  height: auto;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
.q-responsive-image .q-rectangle-image-responsive.q-center-transform {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.q-responsive-image .q-rectangle-image-responsive.q-full-height {
  height: 100%;
  max-width: none;
  width: auto;
}
.q-responsive-image .q-rectangle-image-responsive.q-full-height.q-center-transform {
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.q-responsive-image .q-fadeout {
  background-color: #000000;
  opacity: 0.4;
}
.q-responsive-image .q-space {
  flex-shrink: 0;
  position: relative;
}
.q-responsive-image .q-index {
  z-index: -1;
}
.q-responsive-image .q-responsive-image-disclosure {
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.q-responsive-image .q-responsive-image-disclosure .q-responsive-image-disclosure-text {
  position: absolute;
}
.q-responsive-image .q-responsive-image-disclosure .q-responsive-image-disclosure-text.q-responsive-image-disclosure-text-left {
  bottom: 1.56rem;
  left: 1.88rem;
  padding-right: 1.88rem;
  text-align: left;
}
.q-responsive-image .q-responsive-image-disclosure .q-responsive-image-disclosure-text.q-responsive-image-disclosure-text-center {
  bottom: 1.56rem;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;
}
.q-responsive-image .q-responsive-image-disclosure .q-responsive-image-disclosure-text.q-responsive-image-disclosure-text-right {
  bottom: 1.56rem;
  padding-left: 1.88rem;
  right: 1.88rem;
  text-align: right;
}

@media only screen and (min-width:60em) {
  .q-multimedia-scroller [data-show-contentbar=true] .q-responsive-image .q-responsive-image-disclosure .q-responsive-image-disclosure-text {
    padding-bottom: 3.75rem;
  }
  .q-multimedia-scroller [data-show-contentbar=false] .q-responsive-image .q-responsive-image-disclosure .q-responsive-image-disclosure-text {
    padding-bottom: 2.5rem;
  }
}
.slide .q-slide-separator {
  border: solid #999999;
  border-width: 1px 0 0;
  margin: 2.813rem 0 2.188rem;
  text-align: center;
}
.slide .q-slide-separator::after {
  background-color: #ffffff;
  color: #cccccc;
  content: "End Slide Component";
  font-family: inherit;
  padding: 0.625rem;
  position: relative;
  top: -0.875rem;
}

.q-static-image {
  position: relative;
  width: fit-content;
}
.q-static-image .q-rectangle-image {
  border-radius: 0;
  margin: 0;
  max-width: none;
  padding: 0;
}
.q-static-image .q-round-image {
  max-width: none;
}
.q-static-image .fadeout {
  background-color: #000000;
  opacity: 0.4;
}

.q-nav-primary ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.q-nav-primary .q-top-bar {
  background: #f2f2f2;
  border-bottom: 0.0625rem solid #b3b3b3;
  padding: 0.25rem;
  position: relative;
  top: 0;
  transition: top 0.25s ease;
  z-index: 120;
}
.q-nav-primary .q-top-bar-section {
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 156.25rem;
  padding: 0.625rem;
  position: relative;
}
.q-nav-primary .q-top-bar-section .q-nav-logo {
  display: flex;
  flex: none;
  max-width: none;
  padding: 0 0.469rem;
  width: 5.625rem;
}
.q-nav-primary .q-top-bar-section .q-nav-logo .q-nav-logo-image {
  height: 100%;
  width: 100%;
}
.q-nav-primary .q-top-bar-section .q-nav-list-container {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 0;
}
.q-nav-primary .q-top-bar-section .q-nav-list-container .q-content-nav {
  border-left: none;
  border-right: none;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 0;
  padding: 0.25rem;
}
.q-nav-primary .q-top-bar-section .q-nav-list {
  display: flex;
}
.q-nav-primary .q-top-bar-section .q-nav-list-item {
  padding: 0 0.294rem;
  position: relative;
}
.q-nav-primary .q-top-bar-section .q-nav-list-item.flyout-opened .q-nav-primary-link {
  color: #262626;
}
.q-nav-primary .q-top-bar-section .q-nav-list-item.q-nav-more-menu-item.q-menu-open .q-nav-primary-link {
  color: #262626;
}
.q-nav-primary .q-top-bar-section .q-nav-primary-link {
  color: #808080;
  display: block;
  line-height: 1.375;
  padding: 0;
  text-align: center;
}
.q-nav-primary .q-top-bar-section .q-nav-primary-link:hover {
  color: #262626;
}
.q-nav-primary .q-top-bar-section .q-nav-primary-link:active, .q-nav-primary .q-top-bar-section .q-nav-primary-link:focus {
  color: #808080;
}
.q-nav-primary .q-top-bar-section .q-nav-primary-link-label {
  display: inline-block;
  font-size: 0.9rem;
  letter-spacing: 0;
  max-width: 8.75rem;
  position: relative;
}
.q-nav-primary .q-top-bar-section .q-icon,
.q-nav-primary .q-top-bar-section .q-icon-svg {
  display: block;
  height: 0.875rem;
  margin: 0.625rem auto;
  text-align: center;
}
.q-nav-primary .q-top-bar-section .q-icon::before,
.q-nav-primary .q-top-bar-section .q-icon-svg::before {
  font-size: 0.875rem;
}
.q-nav-primary .q-top-bar-section .q-icon-more {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/more.svg") no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  width: 100%;
}
.q-nav-primary .q-nav-collapsed {
  background: #ffffff;
  display: none;
  max-height: 100vh;
  max-height: calc(100vh - 4.688rem);
  overflow: auto;
  position: fixed;
  width: 100%;
  z-index: 120;
}
.q-nav-primary .q-nav-collapsed .q-nav-collapsed-link {
  display: inline-block;
  position: relative;
}
.q-nav-primary .q-nav-collapsed .q-icon,
.q-nav-primary .q-nav-collapsed .q-icon-svg {
  display: inline-block;
  height: 1.25rem;
  margin-right: 0.625rem;
  width: auto;
}
.q-nav-primary .q-nav-collapsed .q-icon::before,
.q-nav-primary .q-nav-collapsed .q-icon-svg::before {
  font-size: 1.25rem;
}
.q-nav-primary .q-nav-overlay {
  background-color: #1a1a1a;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.75;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 120;
}
.q-nav-primary .nav-flyout {
  -webkit-overflow-scrolling: touch;
  background: #ffffff;
  box-shadow: 0 0.5rem 0.3125rem -0.125rem rgba(50, 50, 50, 0.3);
  display: none;
  left: 0;
  max-width: 156.25rem;
  overflow-y: scroll !important;
  position: fixed;
  width: 100%;
  z-index: 120;
}
.q-nav-primary .nav-flyout.stack-for-small .q-button-back {
  display: block;
}
.q-nav-primary .q-vehicle-selector .q-button-back,
.q-nav-primary .q-vehicle-selector .q-vehicle-selector-subtitle {
  display: block;
}
.q-nav-primary .q-vehicle-selector .q-button-back {
  background: #ffffff;
  position: fixed;
  transform: translateZ(0);
  z-index: 120;
}
.q-nav-primary .q-button-back {
  display: none;
  position: relative;
}
.q-nav-primary .q-button-back .q-back-button-label {
  margin-left: 2rem;
  padding-left: 1.125rem;
}
.q-nav-primary .q-sticky.top .sticky-child {
  left: 0;
  margin-left: 0;
  width: 100%;
}
@media print, only screen and (max-width:59.9375em) {
  .q-nav-primary .q-vehicle-selector {
    background: #ffffff;
    display: none;
    left: -100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 120;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-nav-primary .q-top-bar {
    padding: 0.25rem;
  }
  .q-nav-primary .q-top-bar-section .q-icon,
.q-nav-primary .q-top-bar-section .q-icon-svg {
    height: 1.25rem;
  }
  .q-nav-primary .q-top-bar-section .q-icon::before,
.q-nav-primary .q-top-bar-section .q-icon-svg::before {
    font-size: 1.25rem;
  }
  .q-nav-primary .q-top-bar-section .q-nav-list-container .q-content-nav {
    border-left: none;
    border-right: none;
    justify-content: flex-end;
    padding: 0.25rem;
  }
  .q-nav-primary .q-top-bar-section .q-nav-list-item {
    margin: 0;
  }
  .q-nav-primary .nav-flyout.stack-for-small .q-button-back {
    display: none;
  }
}
@media print, only screen and (min-width:37.5em) and (max-width:59.9375em) {
  .q-nav-primary .nav-flyout.stack-for-medium .q-button-back {
    display: block;
  }
}
@media only screen and (min-width:60em) {
  .q-nav-primary .q-button-back {
    display: none;
  }
  .q-nav-primary .q-top-bar {
    padding: 0.25rem;
  }
  .q-nav-primary .q-top-bar-section .q-nav-list-container {
    justify-content: space-between;
  }
  .q-nav-primary .q-top-bar-section .q-nav-list-container .q-content-nav {
    justify-content: flex-start;
    margin: 0;
    padding: 0.25rem;
  }
  .q-nav-primary .q-top-bar-section .q-nav-primary-link-label {
    font-size: 0.9rem;
  }
  .q-nav-primary .q-top-bar-section .q-nav-list-item {
    padding: 0 0.783rem;
  }
  .q-nav-primary .q-top-bar-section .q-icon,
.q-nav-primary .q-top-bar-section .q-icon-svg {
    display: inline-block;
    margin: 0;
  }
  .q-nav-primary .q-nav-collapsed {
    display: none !important;
  }
  .q-nav-primary .nav-flyout {
    left: 50%;
    transform: translateX(-50%);
  }
  .q-nav-primary .q-vehicle-selector .q-nav-vehicle-selector-content {
    padding-top: 0 !important;
  }
  .q-nav-primary .q-vehicle-selector .q-button-back,
.q-nav-primary .q-vehicle-selector .q-vehicle-selector-subtitle {
    display: none !important;
  }
}
@media only screen and (min-width:93.75em) {
  .q-nav-primary .q-top-bar-section .q-nav-primary-link-label {
    font-size: 0.9rem;
  }
}

.q-nav-anchor {
  background-color: #f2f2f2;
  border-bottom: 0.0625rem solid #e6e6e6;
  border-top: 0.0625rem solid #e6e6e6;
  display: block;
  position: relative;
  width: 100%;
  z-index: 110;
}
.q-nav-anchor .sticky-child {
  transition: top 0.25s ease;
}
.q-nav-anchor .q-sticky.top .sticky-child {
  background-color: #f2f2f2;
  border-bottom: 0.0625rem solid #e6e6e6;
  border-top: 0.0625rem solid #e6e6e6;
  left: unset;
  margin-left: unset;
  width: 100%;
  z-index: 110;
}
.q-nav-anchor .q-overlay {
  background-color: #1a1a1a;
  height: 100%;
  left: 0;
  opacity: 0.75;
  position: fixed;
  top: 0;
  width: 100%;
}
.q-nav-anchor .q-nav-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 156.25rem;
  min-height: 4.375rem;
  position: relative;
}
.q-nav-anchor .q-nav-container .hide-cta-for-specific-breakpoint-up {
  display: flex;
  flex-direction: column;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container {
  background-color: #f2f2f2;
  display: inline-flex;
  flex-grow: 1;
  height: 100%;
  justify-content: flex-end;
  z-index: 2;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list {
  align-items: center;
  background-color: #f2f2f2;
  display: inline-flex;
  height: 100%;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li {
  margin: 0 1.563rem 0 0;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li:not(.q-cta-list-item) {
  font-family: Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li:not(.q-cta-list-item) a {
  color: #808080;
  padding-bottom: 1.125rem;
  padding-top: 1.25rem;
  position: relative;
  text-align: center;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li:not(.q-cta-list-item) a:hover {
  color: #262626;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li:not(.q-cta-list-item) a:hover::after {
  left: 0;
  width: 100%;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li:not(.q-cta-list-item) a:focus::after {
  left: 0;
  width: 100%;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li:not(.q-cta-list-item) a::after {
  border-bottom: 0.1875rem solid #cd9834;
  bottom: 0;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  right: 0;
  width: 0;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li:not(.q-cta-list-item) .q-sibling-nav-active::after {
  left: 0;
  width: 100%;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list .q-cta-list-item button {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list .show-cta-for-specific-breakpoint-up {
  display: none;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list span {
  font-size: 0.9rem;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button {
  font-size: 1.333rem;
  line-height: 1.6rem;
  color: #262626;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  align-items: center;
  cursor: default;
  display: inline-flex;
  flex-shrink: 0;
  height: 100%;
  justify-content: space-between;
  outline: inherit;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 2;
}
@media print, only screen and (min-width:600px) {
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button {
    font-size: 1.333rem;
    line-height: 1.6rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button {
    font-size: 1.333rem;
    line-height: 1.6rem;
  }
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button a:visited {
  color: #262626;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button a:hover, .q-nav-anchor .q-nav-container > .q-secondary-nav-button a:active {
  color: #cd9834;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-invert {
  color: #ffffff;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-invert a:visited {
  color: #ffffff;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-invert a:hover, .q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-invert a:active {
  color: #e6ac39;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-nav-button-with-year-toggle {
  width: 255px;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button span {
  position: relative;
}
.q-nav-anchor .q-nav-container .q-dropdown-box {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: auto;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.q-nav-anchor .q-nav-container .q-dropdown-box .q-list-item {
  font-size: 0.9375rem;
}
.q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-big, .q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-small {
  overflow-y: auto;
}
.q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-big {
  max-height: calc(100vh - 5.294rem);
}
.q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-small {
  max-height: calc(100vh - 9.41rem);
}
.q-nav-anchor .q-nav-container .q-dropdown-box .q-sibling-medium {
  background-color: #f2f2f2;
}
.q-nav-anchor .q-nav-container .q-dropdown-list {
  background-color: #ffffff;
  width: 100%;
}
.q-nav-anchor .q-nav-container .q-dropdown-list li:not(.q-cta-list-item):first-child a {
  border-top: 0.0625rem solid #b3b3b3;
}
.q-nav-anchor .q-nav-container .q-anchor-cta-list li {
  padding: 4%;
}
.q-nav-anchor .q-nav-container .q-section-header-subtitle {
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #262626;
  font-family: Louis, Tahoma, sans-serif;
  font-style: inherit;
  font-weight: 100;
  letter-spacing: 0.09em;
  margin: 0;
  padding: 0;
  background-color: #262626;
  padding: 3%;
  text-align: left;
}
@media print, only screen and (min-width:600px) {
  .q-nav-anchor .q-nav-container .q-section-header-subtitle {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
@media only screen and (min-width:1500px) {
  .q-nav-anchor .q-nav-container .q-section-header-subtitle {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
.q-nav-anchor .q-nav-container .q-section-header-subtitle a {
  color: #262626;
  font-weight: inherit;
  text-decoration: none;
}
.q-nav-anchor .q-nav-container .q-section-header-subtitle a:visited {
  color: #262626;
}
.q-nav-anchor .q-nav-container .q-section-header-subtitle a:hover, .q-nav-anchor .q-nav-container .q-section-header-subtitle a:active {
  color: #cd9834;
}
.q-nav-anchor .q-nav-container .q-section-header-subtitle.q-invert {
  color: #ffffff;
}
.q-nav-anchor .q-nav-container .q-section-header-subtitle.q-invert a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.q-nav-anchor .q-nav-container .q-section-header-subtitle.q-invert a:visited {
  color: #ffffff;
}
.q-nav-anchor .q-nav-container .q-section-header-subtitle.q-invert a:hover, .q-nav-anchor .q-nav-container .q-section-header-subtitle.q-invert a:active {
  color: #e6ac39;
}
.q-nav-anchor .q-nav-container .q-section-header-subtitle.q-invert {
  color: #ffffff;
}
.q-nav-anchor .q-nav-container .q-suggested-models-container {
  background-color: #ffffff;
}
.q-nav-anchor ul {
  margin-bottom: 0;
  margin-left: 0;
}
.q-nav-anchor .q-button.q-cta-button {
  border-color: #cd9834;
}
.q-nav-anchor .q-button.q-cta-button:active, .q-nav-anchor .q-button.q-cta-button:hover, .q-nav-anchor .q-button.q-cta-button:focus, .q-nav-anchor .q-button.q-cta-button.gb-target-zone-button-active {
  background-color: #cd9834;
}
.q-nav-anchor .q-year-toggle {
  display: inline-flex;
}
.q-nav-anchor .q-year-toggle .q-year-toggle-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
@media print, only screen and (min-width:26.25em) {
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button {
    letter-spacing: 0;
    width: 100%;
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box {
    width: 100%;
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-big {
    max-height: calc(100vh - 5.294rem);
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-small {
    max-height: calc(100vh - 9.41rem);
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button {
    letter-spacing: 0;
    width: 320px;
  }
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-nav-button-with-year-toggle {
    width: 255px;
  }
  .q-nav-anchor .q-nav-container .hide-cta-for-specific-breakpoint-up {
    display: none;
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box {
    width: 400px;
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-big {
    max-height: calc(100vh - 4.5rem);
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-small {
    max-height: calc(100vh - 8rem);
  }
  .q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list .show-cta-for-specific-breakpoint-up {
    display: block;
  }
}
@media only screen and (min-width:60em) {
  .q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list span {
    font-size: 0.9rem;
  }
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button {
    letter-spacing: 0;
    width: 320px;
  }
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-nav-button-with-year-toggle {
    width: 255px;
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box {
    width: 400px;
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-big {
    max-height: calc(100vh - 4.5rem);
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-small {
    max-height: calc(100vh - 8rem);
  }
}
@media only screen and (min-width:93.75em) {
  .q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list span {
    font-size: 0.9rem;
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-big {
    max-height: calc(100vh - 3.91rem);
  }
  .q-nav-anchor .q-nav-container .q-dropdown-box.q-overlay-scroll-small {
    max-height: calc(100vh - 6.96rem);
  }
}

.q-nav-segment {
  width: 100%;
}
.q-nav-segment .q-content-container {
  background-color: #ffffff;
  border-bottom: none;
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
}
.q-nav-segment .q-content-item {
  background-color: #ffffff;
  border-bottom: 1px solid #b3b3b3;
  flex: 0 0 100%;
  font: 700 0.75rem Roboto, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  text-align: left;
}
.q-nav-segment .q-content-item a {
  color: #262626;
  display: block;
  padding: 1rem 1.875rem;
  position: relative;
}
.q-nav-segment .q-content-item a:hover {
  background-color: #ffffff;
}
.q-nav-segment .q-content-item a.js-no-outline:focus {
  background-color: unset;
  outline: none;
}
.q-nav-segment.q-seg-nav-sticky {
  top: 0;
  z-index: 2;
}
@media print, only screen and (min-width:37.5em) {
  .q-nav-segment .q-content-item a {
    padding: 1.375rem 1.875rem;
  }
}
@media only screen and (min-width:60em) {
  .q-nav-segment.q-seg-nav-sticky {
    position: absolute;
  }
  .q-nav-segment .q-content-container {
    border-bottom: none;
    padding: 0;
  }
  .q-nav-segment .q-content-container .q-content-item {
    border-bottom: 1px solid #b3b3b3;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .q-nav-segment .q-content-container .q-content-item a {
    color: #808080;
    height: 100%;
    padding: 0;
  }
  .q-nav-segment .q-content-container .q-content-item a:focus {
    background-color: #ffffff;
  }
  .q-nav-segment .q-content-container .q-content-item a span {
    display: inline-block;
    position: relative;
  }
  .q-nav-segment .q-content-container .q-content-item a.js-no-outline:focus {
    background-color: unset;
    outline: none;
  }
}

.grid-padding-small .q-tab-nav,
.grid-padding-medium .q-tab-nav,
.grid-padding-large .q-tab-nav {
  margin-left: 0;
  margin-right: 0;
}
.grid-padding-small .q-tab-nav .q-tabs-content,
.grid-padding-medium .q-tab-nav .q-tabs-content,
.grid-padding-large .q-tab-nav .q-tabs-content {
  border: 1px solid #b3b3b3;
  border-width: 0 1px 1px 1px;
}

.q-tab-nav .q-tabs-content {
  border: 1px solid #b3b3b3;
  border-width: 0 1px 1px 1px;
  width: 100%;
}
.q-tab-nav .q-tabs-content::before, .q-tab-nav .q-tabs-content::after {
  content: " ";
  display: table;
}
.q-tab-nav .q-tabs-content::after {
  clear: both;
}
.q-tab-nav .q-tabs-content > .content {
  width: 100%;
}
.q-tab-nav .q-floating-tab {
  width: 100%;
}
.q-tab-nav .q-floating-tab .q-tabs {
  display: none;
  margin-left: 0;
  width: 100%;
}
.q-tab-nav .q-floating-tab .q-tabs::before, .q-tab-nav .q-floating-tab .q-tabs::after {
  content: none;
}
.q-tab-nav .q-floating-tab .q-tab-title {
  border: 1px solid #b3b3b3;
  border-width: 1px 1px 1px 0;
  display: flex;
  flex: 1;
}
.q-tab-nav .q-floating-tab .q-tab-title:first-child {
  border-left: 1px solid #b3b3b3;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button {
  align-items: center;
  background-color: #f2f2f2;
  border: 0;
  display: flex;
  padding: 0 1.25rem;
  position: relative;
  width: 100%;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button.q-icon-stacked .q-button {
  display: block;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button.q-icon-only {
  width: 100%;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button.q-icon-stacked .q-svg-icon, .q-tab-nav .q-floating-tab .q-tab-title .q-tab-button.q-icon-only .q-svg-icon, .q-tab-nav .q-floating-tab .q-tab-title .q-tab-button.q-inline-icon .q-svg-icon {
  height: 3.125rem;
  width: 3.125rem;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button .q-button {
  border: 4px solid transparent;
  color: #808080;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  margin: 0 auto;
  padding: 1.09375rem 0;
  position: relative;
  text-transform: none;
  width: auto;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button .q-button::before {
  transition: width 0.25s ease, left 0.25s ease;
  border-bottom: 3px solid #cd9834;
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  width: 0;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button .q-button .q-move-text {
  border-bottom: 0;
  flex: 1 1 auto;
}
.q-tab-nav .q-floating-tab .q-tab-title.active {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  border-width: 1px 1px 0 0;
}
.q-tab-nav .q-floating-tab .q-tab-title.active:first-child {
  border-left: 1px solid #b3b3b3;
}
.q-tab-nav .q-floating-tab .q-tab-title.active .q-tab-button {
  background-color: transparent;
}
.q-tab-nav .q-floating-tab .q-tab-title.active .q-tab-button .q-button::before {
  transition: width 0.25s ease, left 0.25s ease;
  left: 0;
  width: 100%;
}
.q-tab-nav .q-floating-tab .q-tab-title.active .q-tab-button .q-button {
  color: #808080;
}
.q-tab-nav .q-floating-tab .q-tab-title.active .q-tab-button .q-button .q-move-text {
  border-bottom: 0;
}
.q-tab-nav .q-floating-tab .q-tab-title.active .q-tab-button:focus {
  background-color: #ffffff;
}
.q-tab-nav .q-floating-tab .q-tab-title.active .icon-hover {
  display: inline;
}
.q-tab-nav .q-floating-tab .q-tab-title.active .icon-normal {
  display: none;
}
.q-tab-nav .q-floating-tab.q-swap-tabs .q-tabs {
  display: flex;
}
.q-tab-nav .q-floating-tab.q-swap-tabs .q-tabs .q-tab-title .q-tab-button .q-button {
  border: 0;
}
.q-tab-nav .q-floating-tab.q-swap-tabs .q-tabs.active {
  border-bottom: 0;
}
.q-tab-nav .q-floating-tab.q-large-impact-tabs {
  align-items: center;
  border-width: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.q-tab-nav .q-floating-tab.q-large-impact-tabs::-webkit-scrollbar {
  display: none;
}
.q-tab-nav .q-floating-tab.q-large-impact-tabs .q-tabs {
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: auto;
}
.q-tab-nav .q-floating-tab.q-large-impact-tabs .q-tabs .q-tab-title {
  border: 0;
  display: flex;
  justify-content: space-around;
  margin: auto;
}
.q-tab-nav .q-floating-tab.q-large-impact-tabs .q-tabs .q-tab-title .q-tab-button .q-button {
  border: 0;
}
.q-tab-nav .q-floating-tab.q-large-impact-invert-tabs {
  align-items: center;
  border-width: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.q-tab-nav .q-floating-tab.q-large-impact-invert-tabs::-webkit-scrollbar {
  display: none;
}
.q-tab-nav .q-floating-tab.q-large-impact-invert-tabs .q-tabs {
  background-color: #000000;
  display: flex;
  justify-content: space-around;
  margin: auto;
  width: auto;
}
.q-tab-nav .q-floating-tab.q-large-impact-invert-tabs .q-tabs .q-tab-title {
  background-color: #000000;
  border: 0;
  display: flex;
  justify-content: space-around;
  margin: auto;
}
.q-tab-nav .q-floating-tab.q-large-impact-invert-tabs .q-tabs .q-tab-title .q-tab-button {
  background-color: #000000;
}
.q-tab-nav .q-floating-tab.q-large-impact-invert-tabs .q-tabs .q-tab-title .q-tab-button .q-button {
  background-color: #000000;
  border: 0;
}
.q-tab-nav .q-floating-tab.hide {
  border-top: 1px solid #b3b3b3;
}
.q-tab-nav .q-floating-tab.hide .q-tabs {
  display: none;
}
.q-tab-nav .q-tabs-content {
  margin-bottom: 0;
}
.q-tab-nav .q-tabs-content .q-expander-content {
  padding-top: 0;
}
.q-tab-nav .q-tabs-content .q-expander-content > .q-gridbuilder {
  margin: 0;
}
.q-tab-nav .q-tabs-content > .q-content {
  padding: 0;
}
.q-tab-nav .q-tabs-content > .q-content.active.active-override {
  visibility: visible;
  z-index: 1;
}
.q-tab-nav .q-tabs-content > .q-content.active-override {
  display: inline-block;
  float: left;
  position: relative;
  visibility: hidden;
  z-index: 0;
}
.q-tab-nav .q-expander {
  margin-left: auto;
  margin-right: auto;
}
.q-tab-nav .q-expander .q-expander-button div {
  color: #1a1a1a;
  font-size: 0.75rem;
  font-weight: 700;
}
.q-tab-nav .q-expander .q-expander-button.disable:hover div {
  color: #262626;
}
.q-tab-nav .q-expander .q-expander-button .expander-icon {
  margin-right: 1.25rem;
}
.q-tab-nav .q-expander .q-expander-button .tab-icon {
  display: inline-block;
  width: 1rem;
}
@media print, only screen and (max-width:37.4375em) {
  .q-tab-nav .q-expander .q-expander-button {
    border-left: 0;
    border-right: 0;
  }
}
.q-tab-nav .q-expander .q-expander-content {
  overflow-x: hidden;
}
.q-tab-nav .q-expander.active {
  margin-bottom: 0;
}
.q-tab-nav .q-expander.q-disable {
  margin-left: auto;
  margin-right: auto;
}
.q-tab-nav .q-expander.q-disable .q-expander-button {
  border: 1px solid #b3b3b3;
  cursor: default;
  padding: 1.125rem 1.875rem;
  text-align: center;
}
.q-tab-nav .q-expander.q-disable .q-expander-button div {
  color: #808080;
}
.q-tab-nav .q-expander.q-disable .q-expander-button::before {
  content: none;
}
.q-tab-nav.q-swap-tab .q-expander-button {
  border: 0;
  font: 700 0.706rem Roboto, Helvetica, NotoSansKR, NanumGothic, sans-serif;
}
.q-tab-nav.q-swap-tab .q-expander-button::before {
  content: none;
}
.q-tab-nav.q-swap-tab .q-expander-button .q-button-text {
  color: #1a1a1a;
}
.q-tab-nav.q-swap-tab .q-expander-button .tab-icon {
  display: none;
}
.q-tab-nav.q-tabnav-360colorizer > div > .tabs-content > .q-content:not(.active) .left-arrow,
.q-tab-nav.q-tabnav-360colorizer > div > .tabs-content > .q-content:not(.active) .right-arrow {
  display: none !important;
}
.q-tab-nav.q-tabnav-360colorizer > div > .tabs-content > .q-content:not(.active) .q-expander:not(.q-360colorizer).active .left-arrow,
.q-tab-nav.q-tabnav-360colorizer > div > .tabs-content > .q-content:not(.active) .q-expander:not(.q-360colorizer).active .right-arrow {
  display: initial !important;
}
.q-tab-nav.q-tabnav-360colorizer > div > .tabs-content > .q-content .q-360colorizer > div > .tabs-content .q-content:not(.active) .left-arrow,
.q-tab-nav.q-tabnav-360colorizer > div > .tabs-content > .q-content .q-360colorizer > div > .tabs-content .q-content:not(.active) .right-arrow {
  display: none !important;
}
.q-tab-nav.q-360colorizer .q-mod-video-brightcove .q-posterframe {
  display: none !important;
}
@media only screen and (max-width: 419px) {
  .q-tab-nav.q-disabled-xs > .q-expander-content,
.q-tab-nav.q-disabled-xs > div > .q-expander-content {
    display: inline;
  }
  .q-tab-nav.q-disabled-xs > .q-expander-button,
.q-tab-nav.q-disabled-xs > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-tab-nav.q-disabled-xs > .q-expander-button::before,
.q-tab-nav.q-disabled-xs > div > .q-expander-button::before {
    content: none !important;
  }
  .q-tab-nav.q-disabled-xs > .q-expander-button:hover,
.q-tab-nav.q-disabled-xs > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-tab-nav.q-disabled-xs > .q-expander-button .q-headline-text,
.q-tab-nav.q-disabled-xs > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-tab-nav.q-disabled-xs > .q-expander-button [class=" q-headline-text"],
.q-tab-nav.q-disabled-xs > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-tab-nav.q-disabled-xs.open > .q-expander-button,
.q-tab-nav.q-disabled-xs.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
  .q-tab-nav.q-floating-xs .content:not(.active) {
    left: 0;
  }
  .q-tab-nav.q-floating-xs .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-xs .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-floating-xs .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-xs.q-two-floating-tabs .q-floating-tab {
    display: none;
  }
  .q-tab-nav.q-floating-xs.q-two-floating-tabs .q-tabs-content > .q-content.active-override {
    visibility: visible;
    visibility: visible;
    visibility: visible;
  }
  .q-tab-nav.q-floating-xs.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-xs.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button {
    border: 1px solid #b3b3b3;
    display: block;
    pointer-events: none;
    text-align: center;
  }
  .q-tab-nav.q-floating-xs.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button::before {
    display: none;
  }
  .q-tab-nav.q-floating-xs.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button .q-headline-text {
    margin: auto;
  }
  .q-tab-nav.q-floating-xs.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button .q-headline-text .tab-icon {
    display: block;
    margin: auto;
    width: 2rem;
  }
  .q-tab-nav.q-floating-xs.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-xs.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-content {
    display: block;
    height: auto;
  }
  .q-tab-nav.q-floating-xs .q-tabs {
    display: flex;
    flex-direction: row;
  }
  .q-tab-nav.q-floating-xs .q-360colorizer {
    display: block !important;
  }
  .q-tab-nav.q-expander-xs .q-tabs-content {
    border: 0;
  }
  .q-tab-nav.q-expander-xs .q-tabs-content > .q-content.active-override {
    visibility: visible;
  }
  .q-tab-nav.q-expander-xs .q-large-impact-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-xs.q-large-impact .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-xs .q-large-impact-invert-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-xs.q-large-impact-invert .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-xs .content {
    float: none;
    padding: 0;
  }
  .q-tab-nav.q-expander-xs .q-disable {
    border: 0;
    margin-left: 2.5rem;
    margin-left: 5vw;
    margin-right: 2.5rem;
    margin-right: 5vw;
  }
  .q-tab-nav.q-expander-xs .q-disable:first-of-type {
    margin-bottom: 1.25rem;
  }
  .q-tab-nav.q-expander-xs .q-disable .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-expander-xs .q-disable .q-expander-button {
    background-color: #ffffff;
    border: 1px solid #b3b3b3;
    margin-bottom: 0;
  }
  .q-tab-nav.q-expander-xs .q-disable .q-expander-button .q-button-text {
    border-bottom: 0;
    color: #808080;
    font-size: 0.75rem;
    line-height: 2;
  }
  .q-tab-nav.q-expander-xs .q-disable .q-expander-button .q-headline-text {
    margin: 0;
  }
  .q-tab-nav.q-expander-xs .q-expander.q-disable .q-expander-content {
    border-bottom: 0;
    height: auto;
  }
  .q-tab-nav.q-swap-xs .q-swap-tabs .q-tab-button .q-svg-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-xs .q-swap-tabs .q-tab-button .q-move-text {
    display: none;
  }
  .q-tab-nav.q-swap-xs .q-swap-tabs .q-tab-button .q-shift-icon .q-move-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-xs .q-content.active .q-expander .q-expander-content {
    cursor: default;
    display: block;
    height: auto;
  }
  .q-tab-nav.q-swap-xs .q-content.active .q-expander .q-expander-button {
    display: block;
  }
  .q-tab-nav.q-swap-xs .q-content.active .q-expander .q-expander-button .q-headline-text {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .q-tab-nav.q-swap-xs .q-content .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-swap-xs .q-content .q-expander .q-expander-button {
    background: #ffffff;
    border: #ffffff;
    cursor: default;
    display: none;
  }
  .q-tab-nav.q-tab-xs .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-tab-xs .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-tab-xs .q-expander.active .q-expander-content {
    display: block;
  }
}
@media print, only screen and (min-width:420px) and (max-width:599px) {
  .q-tab-nav.q-disabled-sm > .q-expander-content,
.q-tab-nav.q-disabled-sm > div > .q-expander-content {
    display: inline;
  }
  .q-tab-nav.q-disabled-sm > .q-expander-button,
.q-tab-nav.q-disabled-sm > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-tab-nav.q-disabled-sm > .q-expander-button::before,
.q-tab-nav.q-disabled-sm > div > .q-expander-button::before {
    content: none !important;
  }
  .q-tab-nav.q-disabled-sm > .q-expander-button:hover,
.q-tab-nav.q-disabled-sm > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-tab-nav.q-disabled-sm > .q-expander-button .q-headline-text,
.q-tab-nav.q-disabled-sm > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-tab-nav.q-disabled-sm > .q-expander-button [class=" q-headline-text"],
.q-tab-nav.q-disabled-sm > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-tab-nav.q-disabled-sm.open > .q-expander-button,
.q-tab-nav.q-disabled-sm.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
  .q-tab-nav.q-floating-sm .content:not(.active) {
    left: 0;
  }
  .q-tab-nav.q-floating-sm .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-sm .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-floating-sm .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-sm.q-two-floating-tabs .q-floating-tab {
    display: none;
  }
  .q-tab-nav.q-floating-sm.q-two-floating-tabs .q-tabs-content > .q-content.active-override {
    visibility: visible;
    visibility: visible;
    visibility: visible;
  }
  .q-tab-nav.q-floating-sm.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-sm.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button {
    border: 1px solid #b3b3b3;
    display: block;
    pointer-events: none;
    text-align: center;
  }
  .q-tab-nav.q-floating-sm.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button::before {
    display: none;
  }
  .q-tab-nav.q-floating-sm.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button .q-headline-text {
    margin: auto;
  }
  .q-tab-nav.q-floating-sm.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button .q-headline-text .tab-icon {
    display: block;
    margin: auto;
    width: 2rem;
  }
  .q-tab-nav.q-floating-sm.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-sm.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-content {
    display: block;
    height: auto;
  }
  .q-tab-nav.q-floating-sm .q-tabs {
    display: flex;
    flex-direction: row;
  }
  .q-tab-nav.q-floating-sm .q-360colorizer {
    display: block !important;
  }
  .q-tab-nav.q-expander-sm .q-tabs-content {
    border: 0;
  }
  .q-tab-nav.q-expander-sm .q-tabs-content > .q-content.active-override {
    visibility: visible;
  }
  .q-tab-nav.q-expander-sm .q-large-impact-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-sm.q-large-impact .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-sm .q-large-impact-invert-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-sm.q-large-impact-invert .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-sm .content {
    float: none;
    padding: 0;
  }
  .q-tab-nav.q-expander-sm .q-disable {
    border: 0;
    margin-left: 2.5rem;
    margin-left: 5vw;
    margin-right: 2.5rem;
    margin-right: 5vw;
  }
  .q-tab-nav.q-expander-sm .q-disable:first-of-type {
    margin-bottom: 1.25rem;
  }
  .q-tab-nav.q-expander-sm .q-disable .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-expander-sm .q-disable .q-expander-button {
    background-color: #ffffff;
    border: 1px solid #b3b3b3;
    margin-bottom: 0;
  }
  .q-tab-nav.q-expander-sm .q-disable .q-expander-button .q-button-text {
    border-bottom: 0;
    color: #808080;
    font-size: 0.75rem;
    line-height: 2;
  }
  .q-tab-nav.q-expander-sm .q-disable .q-expander-button .q-headline-text {
    margin: 0;
  }
  .q-tab-nav.q-expander-sm .q-expander.q-disable .q-expander-content {
    border-bottom: 0;
    height: auto;
  }
  .q-tab-nav.q-swap-sm .q-swap-tabs .q-tab-button .q-svg-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-sm .q-swap-tabs .q-tab-button .q-move-text {
    display: none;
  }
  .q-tab-nav.q-swap-sm .q-swap-tabs .q-tab-button .q-shift-icon .q-move-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-sm .q-content.active .q-expander .q-expander-content {
    cursor: default;
    display: block;
    height: auto;
  }
  .q-tab-nav.q-swap-sm .q-content.active .q-expander .q-expander-button {
    display: block;
  }
  .q-tab-nav.q-swap-sm .q-content.active .q-expander .q-expander-button .q-headline-text {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .q-tab-nav.q-swap-sm .q-content .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-swap-sm .q-content .q-expander .q-expander-button {
    background: #ffffff;
    border: #ffffff;
    cursor: default;
    display: none;
  }
  .q-tab-nav.q-tab-sm .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-tab-sm .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-tab-sm .q-expander.active .q-expander-content {
    display: block;
  }
}
@media print, only screen and (min-width:600px) and (max-width:959px) {
  .q-tab-nav.q-disabled-med > .q-expander-content,
.q-tab-nav.q-disabled-med > div > .q-expander-content {
    display: inline;
  }
  .q-tab-nav.q-disabled-med > .q-expander-button,
.q-tab-nav.q-disabled-med > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-tab-nav.q-disabled-med > .q-expander-button::before,
.q-tab-nav.q-disabled-med > div > .q-expander-button::before {
    content: none !important;
  }
  .q-tab-nav.q-disabled-med > .q-expander-button:hover,
.q-tab-nav.q-disabled-med > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-tab-nav.q-disabled-med > .q-expander-button .q-headline-text,
.q-tab-nav.q-disabled-med > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-tab-nav.q-disabled-med > .q-expander-button [class=" q-headline-text"],
.q-tab-nav.q-disabled-med > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-tab-nav.q-disabled-med.open > .q-expander-button,
.q-tab-nav.q-disabled-med.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
  .q-tab-nav.q-floating-med .content:not(.active) {
    left: 0;
  }
  .q-tab-nav.q-floating-med .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-med .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-floating-med .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-med.q-two-floating-tabs .q-floating-tab {
    display: none;
  }
  .q-tab-nav.q-floating-med.q-two-floating-tabs .q-tabs-content > .q-content.active-override {
    visibility: visible;
    visibility: visible;
    visibility: visible;
  }
  .q-tab-nav.q-floating-med.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-med.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button {
    border: 1px solid #b3b3b3;
    display: block;
    pointer-events: none;
    text-align: center;
  }
  .q-tab-nav.q-floating-med.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button::before {
    display: none;
  }
  .q-tab-nav.q-floating-med.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button .q-headline-text {
    margin: auto;
  }
  .q-tab-nav.q-floating-med.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button .q-headline-text .tab-icon {
    display: block;
    margin: auto;
    width: 2rem;
  }
  .q-tab-nav.q-floating-med.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-med.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-content {
    display: block;
    height: auto;
  }
  .q-tab-nav.q-floating-med .q-tabs {
    display: flex;
    flex-direction: row;
  }
  .q-tab-nav.q-floating-med .q-360colorizer {
    display: block !important;
  }
  .q-tab-nav.q-expander-med .q-tabs-content {
    border: 0;
  }
  .q-tab-nav.q-expander-med .q-tabs-content > .q-content.active-override {
    visibility: visible;
  }
  .q-tab-nav.q-expander-med .q-large-impact-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-med.q-large-impact .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-med .q-large-impact-invert-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-med.q-large-impact-invert .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-med .content {
    float: none;
    padding: 0;
  }
  .q-tab-nav.q-expander-med .q-disable {
    border: 0;
    margin-left: 2.5rem;
    margin-left: 5vw;
    margin-right: 2.5rem;
    margin-right: 5vw;
  }
  .q-tab-nav.q-expander-med .q-disable:first-of-type {
    margin-bottom: 1.25rem;
  }
  .q-tab-nav.q-expander-med .q-disable .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-expander-med .q-disable .q-expander-button {
    background-color: #ffffff;
    border: 1px solid #b3b3b3;
    margin-bottom: 0;
  }
  .q-tab-nav.q-expander-med .q-disable .q-expander-button .q-button-text {
    border-bottom: 0;
    color: #808080;
    font-size: 0.75rem;
    line-height: 2;
  }
  .q-tab-nav.q-expander-med .q-disable .q-expander-button .q-headline-text {
    margin: 0;
  }
  .q-tab-nav.q-expander-med .q-expander.q-disable .q-expander-content {
    border-bottom: 0;
    height: auto;
  }
  .q-tab-nav.q-swap-med .q-swap-tabs .q-tab-button .q-svg-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-med .q-swap-tabs .q-tab-button .q-move-text {
    display: none;
  }
  .q-tab-nav.q-swap-med .q-swap-tabs .q-tab-button .q-shift-icon .q-move-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-med .q-content.active .q-expander .q-expander-content {
    cursor: default;
    display: block;
    height: auto;
  }
  .q-tab-nav.q-swap-med .q-content.active .q-expander .q-expander-button {
    display: block;
  }
  .q-tab-nav.q-swap-med .q-content.active .q-expander .q-expander-button .q-headline-text {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .q-tab-nav.q-swap-med .q-content .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-swap-med .q-content .q-expander .q-expander-button {
    background: #ffffff;
    border: #ffffff;
    cursor: default;
    display: none;
  }
  .q-tab-nav.q-tab-med .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-tab-med .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-tab-med .q-expander.active .q-expander-content {
    display: block;
  }
}
@media only screen and (min-width:960px) and (max-width:1499px) {
  .q-tab-nav.q-disabled-lg > .q-expander-content,
.q-tab-nav.q-disabled-lg > div > .q-expander-content {
    display: inline;
  }
  .q-tab-nav.q-disabled-lg > .q-expander-button,
.q-tab-nav.q-disabled-lg > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-tab-nav.q-disabled-lg > .q-expander-button::before,
.q-tab-nav.q-disabled-lg > div > .q-expander-button::before {
    content: none !important;
  }
  .q-tab-nav.q-disabled-lg > .q-expander-button:hover,
.q-tab-nav.q-disabled-lg > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-tab-nav.q-disabled-lg > .q-expander-button .q-headline-text,
.q-tab-nav.q-disabled-lg > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-tab-nav.q-disabled-lg > .q-expander-button [class=" q-headline-text"],
.q-tab-nav.q-disabled-lg > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-tab-nav.q-disabled-lg.open > .q-expander-button,
.q-tab-nav.q-disabled-lg.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
  .q-tab-nav.q-floating-lg .content:not(.active) {
    left: 0;
    position: absolute;
  }
  .q-tab-nav.q-floating-lg .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-lg .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-floating-lg .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-lg.q-two-floating-tabs .q-tabs-content > .q-content.active-override {
    visibility: visible;
    visibility: visible;
    visibility: visible;
  }
  .q-tab-nav.q-floating-lg.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-lg.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-floating-lg.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-lg .q-tabs {
    display: flex;
    flex-direction: row;
  }
  .q-tab-nav.q-floating-lg .q-360colorizer {
    display: block !important;
  }
  .q-tab-nav.q-expander-lg .q-tabs-content {
    border: 0;
  }
  .q-tab-nav.q-expander-lg .q-tabs-content > .q-content.active-override {
    visibility: visible;
  }
  .q-tab-nav.q-expander-lg .q-large-impact-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-lg.q-large-impact .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-lg .q-large-impact-invert-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-lg.q-large-impact-invert .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-lg .content {
    float: none;
    padding: 0;
  }
  .q-tab-nav.q-expander-lg .q-disable {
    border: 0;
    margin-left: 2.5rem;
    margin-left: 5vw;
    margin-right: 2.5rem;
    margin-right: 5vw;
  }
  .q-tab-nav.q-expander-lg .q-disable:first-of-type {
    margin-bottom: 1.25rem;
  }
  .q-tab-nav.q-expander-lg .q-disable .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-expander-lg .q-disable .q-expander-button {
    background-color: #ffffff;
    border: 1px solid #b3b3b3;
    margin-bottom: 0;
  }
  .q-tab-nav.q-expander-lg .q-disable .q-expander-button .q-button-text {
    border-bottom: 0;
    color: #808080;
    font-size: 0.75rem;
    line-height: 2;
  }
  .q-tab-nav.q-expander-lg .q-disable .q-expander-button .q-headline-text {
    margin: 0;
  }
  .q-tab-nav.q-expander-lg .q-expander.q-disable .q-expander-content {
    border-bottom: 0;
    height: auto;
  }
  .q-tab-nav.q-swap-lg .q-swap-tabs .q-tab-button .q-svg-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-lg .q-swap-tabs .q-tab-button .q-move-text {
    display: none;
  }
  .q-tab-nav.q-swap-lg .q-swap-tabs .q-tab-button .q-shift-icon .q-move-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-lg .q-content.active .q-expander .q-expander-content {
    cursor: default;
    display: block;
    height: auto;
  }
  .q-tab-nav.q-swap-lg .q-content.active .q-expander .q-expander-button {
    display: block;
  }
  .q-tab-nav.q-swap-lg .q-content.active .q-expander .q-expander-button .q-headline-text {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .q-tab-nav.q-swap-lg .q-content .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-swap-lg .q-content .q-expander .q-expander-button {
    background: #ffffff;
    border: #ffffff;
    cursor: default;
    display: none;
  }
  .q-tab-nav.q-tab-lg .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-tab-lg .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-tab-lg .q-expander.active .q-expander-content {
    display: block;
  }
}
@media only screen and (min-width:1500px) and (max-width:99999999px) {
  .q-tab-nav.q-disabled-xl > .q-expander-content,
.q-tab-nav.q-disabled-xl > div > .q-expander-content {
    display: inline;
  }
  .q-tab-nav.q-disabled-xl > .q-expander-button,
.q-tab-nav.q-disabled-xl > div > .q-expander-button {
    background: none;
    border: 0;
    cursor: default;
    padding: 0 0 1.25rem;
  }
  .q-tab-nav.q-disabled-xl > .q-expander-button::before,
.q-tab-nav.q-disabled-xl > div > .q-expander-button::before {
    content: none !important;
  }
  .q-tab-nav.q-disabled-xl > .q-expander-button:hover,
.q-tab-nav.q-disabled-xl > div > .q-expander-button:hover {
    background: none;
    border: 0;
    cursor: default;
  }
  .q-tab-nav.q-disabled-xl > .q-expander-button .q-headline-text,
.q-tab-nav.q-disabled-xl > div > .q-expander-button .q-headline-text {
    margin-left: 0;
  }
  .q-tab-nav.q-disabled-xl > .q-expander-button [class=" q-headline-text"],
.q-tab-nav.q-disabled-xl > div > .q-expander-button [class=" q-headline-text"] {
    color: #262626;
  }
  .q-tab-nav.q-disabled-xl.open > .q-expander-button,
.q-tab-nav.q-disabled-xl.open > div > .q-expander-button {
    background: none;
    border: 0;
  }
  .q-tab-nav.q-floating-xl .content:not(.active) {
    left: 0;
    position: absolute;
  }
  .q-tab-nav.q-floating-xl .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-xl .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-floating-xl .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-xl.q-two-floating-tabs .q-tabs-content > .q-content.active-override {
    visibility: visible;
    visibility: visible;
    visibility: visible;
  }
  .q-tab-nav.q-floating-xl.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-floating-xl.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-floating-xl.q-two-floating-tabs .q-tabs-content > .q-content.active-override .q-expander.active .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-floating-xl .q-tabs {
    display: flex;
    flex-direction: row;
  }
  .q-tab-nav.q-floating-xl .q-360colorizer {
    display: block !important;
  }
  .q-tab-nav.q-expander-xl .q-tabs-content {
    border: 0;
  }
  .q-tab-nav.q-expander-xl .q-tabs-content > .q-content.active-override {
    visibility: visible;
  }
  .q-tab-nav.q-expander-xl .q-large-impact-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-xl.q-large-impact .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-xl .q-large-impact-invert-tabs {
    display: none;
  }
  .q-tab-nav.q-expander-xl.q-large-impact-invert .q-expander.active .q-expander-content {
    border-bottom: 1px solid #b3b3b3;
  }
  .q-tab-nav.q-expander-xl .content {
    float: none;
    padding: 0;
  }
  .q-tab-nav.q-expander-xl .q-disable {
    border: 0;
    margin-left: 2.5rem;
    margin-left: 5vw;
    margin-right: 2.5rem;
    margin-right: 5vw;
  }
  .q-tab-nav.q-expander-xl .q-disable:first-of-type {
    margin-bottom: 1.25rem;
  }
  .q-tab-nav.q-expander-xl .q-disable .q-expander-content {
    display: block;
  }
  .q-tab-nav.q-expander-xl .q-disable .q-expander-button {
    background-color: #ffffff;
    border: 1px solid #b3b3b3;
    margin-bottom: 0;
  }
  .q-tab-nav.q-expander-xl .q-disable .q-expander-button .q-button-text {
    border-bottom: 0;
    color: #808080;
    font-size: 0.75rem;
    line-height: 2;
  }
  .q-tab-nav.q-expander-xl .q-disable .q-expander-button .q-headline-text {
    margin: 0;
  }
  .q-tab-nav.q-expander-xl .q-expander.q-disable .q-expander-content {
    border-bottom: 0;
    height: auto;
  }
  .q-tab-nav.q-swap-xl .q-swap-tabs .q-tab-button .q-svg-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-xl .q-swap-tabs .q-tab-button .q-move-text {
    display: none;
  }
  .q-tab-nav.q-swap-xl .q-swap-tabs .q-tab-button .q-shift-icon .q-move-icon {
    margin: 0;
  }
  .q-tab-nav.q-swap-xl .q-content.active .q-expander .q-expander-content {
    cursor: default;
    display: block;
    height: auto;
  }
  .q-tab-nav.q-swap-xl .q-content.active .q-expander .q-expander-button {
    display: block;
  }
  .q-tab-nav.q-swap-xl .q-content.active .q-expander .q-expander-button .q-headline-text {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .q-tab-nav.q-swap-xl .q-content .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-swap-xl .q-content .q-expander .q-expander-button {
    background: #ffffff;
    border: #ffffff;
    cursor: default;
    display: none;
  }
  .q-tab-nav.q-tab-xl .q-expander {
    margin-bottom: 0;
  }
  .q-tab-nav.q-tab-xl .q-expander .q-expander-button {
    display: none;
  }
  .q-tab-nav.q-tab-xl .q-expander.active .q-expander-content {
    display: block;
  }
}
.q-tab-nav .q-360-colorizer {
  padding: 1rem;
}
.q-tab-nav .q-360-colorizer .q-colorizer-tabs {
  justify-content: left;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title {
  margin: 0;
  padding: 0;
}
.q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title.active .q-colorizer-chip .q-colorizer-checkmark {
  display: block;
}
.q-tab-nav .q-360-colorizer .q-colorizer-category {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
}
.q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
}
.q-tab-nav .q-360-colorizer .q-bottom-headline {
  display: none;
}
.q-tab-nav .q-360-colorizer .q-bottom-headline.active {
  display: block;
}
.q-tab-nav .q-360-colorizer .q-bottom-headline .row {
  padding: 0;
}
@media print, only screen and (min-width:37.5em) {
  .q-tab-nav .q-360-colorizer .q-colorizer-category {
    display: flex;
    flex-flow: row;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .tab-title .q-colorizer-panel.tooltip-leftmost .q-tooltip-text {
    left: 0;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .tab-title .q-colorizer-panel.tooltip-leftmost .q-tooltip-text::before, .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .tab-title .q-colorizer-panel.tooltip-leftmost .q-tooltip-text::after {
    left: 15%;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name:nth-child(2) {
    border-left: 2px solid #808080;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 1rem;
  }
}
@media only screen and (min-width:60em) {
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.no-touch .q-tab-title:hover, .no-touch .q-tab-title.focus {
  background-color: #ffffff;
}
.no-touch .q-tab-title .q-tab-button:hover, .no-touch .q-tab-title .q-tab-button:focus {
  background-color: transparent;
  box-shadow: none;
}
.no-touch .q-tab-title .q-tab-button:hover .q-button, .no-touch .q-tab-title .q-tab-button:focus .q-button {
  color: #262626;
}
.no-touch .q-tab-title .q-tab-button:hover .q-button .q-move-text, .no-touch .q-tab-title .q-tab-button:focus .q-button .q-move-text {
  border-bottom: 0;
}
.no-touch .q-tab-title.active .q-tab-button:hover {
  background-color: transparent;
}
.no-touch .q-tab-title.active .q-tab-button:hover .q-button .q-move-text {
  border-bottom: 0;
}

.q-vehicle-information-row {
  padding: 0;
  width: 100%;
}
.q-vehicle-information-row .q-vehicle-info-text-price {
  padding-bottom: 0;
  text-align: left;
  width: 100%;
  color: #808080;
}
.q-vehicle-information-row .q-vehicle-info-text-price .q-from-label {
  color: #808080;
}
.q-vehicle-information-row .q-vehicle-info-text-price .q-from-label p {
  color: inherit;
  display: inline;
  font-size: inherit;
}
.q-vehicle-information-row .q-vehicle-info-text-price .q-from-label {
  color: #808080;
  display: inline-block;
}
.q-vehicle-information-row .q-vehicle-info-text-price .q-from-label p {
  color: inherit;
  display: inline;
  text-transform: none;
}
.q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price {
  color: #808080;
  line-height: 1.25rem;
  margin-bottom: 0;
}
.q-vehicle-information-row .q-vehicle-info-text-price .q-vehicle-info-as-shown-price p {
  color: inherit;
  display: inline;
  font-size: inherit;
}
.q-vehicle-information-row .q-vehicle-image {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  width: 50%;
  float: left;
}
.q-vehicle-information-row .q-vehicle-year-title-msrp {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  width: 50%;
  float: left;
}
.q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-year {
  color: #808080;
  margin-bottom: 0;
}
.q-vehicle-information-row .q-vehicle-year-title-msrp .q-vehicle-info-row-title {
  margin-bottom: 0;
  padding-top: 0.125rem;
  text-transform: uppercase;
}
.q-vehicle-information-row .q-vehicle-body-description {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  width: 100%;
  float: left;
}
.q-vehicle-information-row .q-vir-first-button {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  width: 50%;
  float: left;
  float: left;
  margin: 0.625rem 0;
}
.q-vehicle-information-row .q-vir-second-button {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  width: 50%;
  float: left;
  float: left;
  margin: 0.625rem 0;
}
@media print, only screen and (min-width:37.5em) {
  .q-vehicle-information-row .q-vehicle-info-image-container {
    padding-left: 0;
    padding-right: 0;
  }
  .q-vehicle-information-row .q-descriptive2 {
    margin-bottom: 0;
  }
  .q-vehicle-information-row .q-vehicle-description {
    margin-top: 1.25rem;
  }
  .q-vehicle-information-row .q-vehicle-image {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 50%;
    float: left;
  }
  .q-vehicle-information-row .q-vehicle-year-title-msrp {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 50%;
    float: left;
  }
  .q-vehicle-information-row .q-vehicle-body-description {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 66.6666666667%;
    float: left;
    margin-top: 1.25rem;
  }
  .q-vehicle-information-row .q-vir-first-button {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 33.3333333333%;
    float: left;
    margin-top: 1.25rem;
  }
  .q-vehicle-information-row .q-vir-second-button {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 33.3333333333%;
    float: left;
    margin-right: 0;
    margin-top: 0;
  }
}
@media only screen and (min-width:60em) {
  .q-vehicle-information-row .q-vehicle-body-description {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 41.6666666667%;
    float: left;
    padding-left: 3.75rem;
  }
  .q-vehicle-information-row .q-vehicle-image {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 25%;
    float: left;
  }
  .q-vehicle-information-row .q-vehicle-year-title-msrp {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 16.6666666667%;
    float: left;
  }
  .q-vehicle-information-row .q-vir-first-button {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 16.6666666667%;
    float: left;
    margin-bottom: 0.75rem;
  }
  .q-vehicle-information-row .q-vir-second-button {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    width: 16.6666666667%;
    float: left;
    margin-bottom: 0.75rem;
  }
}

.q-vehicle-selector {
  flex-flow: row wrap;
  overflow: hidden;
  position: relative;
}
.q-vehicle-selector .q-button-back,
.q-vehicle-selector .q-vehicle-selector-subtitle {
  display: none;
}
.q-vehicle-selector .q-vehicle-selector-subtitle {
  background: #262626;
  color: #ffffff;
  font-family: Louis, Tahoma, NotoSansKR, NanumGothic, sans-serif;
  font-size: 0.75rem;
  font-weight: 100;
  line-height: 1.25rem;
  padding: 3%;
  text-transform: uppercase;
}
.q-vehicle-selector .q-vehicle-selector-row {
  display: flex;
  flex-flow: row wrap;
}
.q-vehicle-selector .q-vehicle-selector-row .q-vehicle-selector-item {
  display: none;
  padding: 0;
}
.q-vehicle-selector .q-vehicle-selector-row .q-vehicle-selector-item .q-vehicle-information-tile-wyear-secondarynav {
  display: flex;
}
.q-vehicle-selector .q-vehicle-selector-row .q-vehicle-selector-item .q-vehicle-information-tile-wyear-secondarynav .q-vehicle-info-text {
  white-space: normal;
}
@media only screen and (min-width:60em) {
  .q-vehicle-selector .q-vehicle-selector-row .q-vehicle-selector-item {
    padding: 1.25rem;
  }
}

.columns > .q-button.q-link, .columns > .q-button.q-icon-only {
  display: block;
}

@media print, only screen and (min-width:37.5em) {
  .q-button.q-primary-button {
    border-width: 3px;
    font-size: 0.7rem;
    padding-block: 0.8rem;
  }
  .q-button.q-secondary-button {
    border-width: 3px;
  }
}
@media only screen and (min-width:60em) {
  .q-button.q-primary-button {
    padding-block: 0.783rem;
  }
}
@media only screen and (min-width:93.75em) {
  .q-button.q-primary-button {
    font-size: 0.696rem;
  }
}
.q-disclosure-modal-toast .q-disclosure-toast-container .q-toast-content {
  margin-bottom: 0;
}

@media print, only screen and (min-width:26.25em) {
  .q-disclosure-modal-toast .q-close-disclosure .q-modal-close-icon {
    height: 1.88rem;
    width: 1.88rem;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-disclosure-modal-toast .q-disclosure-toast-container {
    padding: 1.25rem 4.875rem;
  }
}
@media only screen and (min-width:60em) {
  .q-expander > .q-expander-button, .q-expander > div > .q-expander-button {
    padding-right: 1.875rem;
  }
}

.q-headline .q-headline-icon .q-headline-icon {
  transform: translateY(-0.25rem);
}
.q-headline .q-headline-icon.text-right {
  display: block;
}

.q-legal-notification .float-left {
  margin-bottom: 1rem;
}
.q-legal-notification .float-left:last-child {
  margin-bottom: 0;
}
.q-legal-notification .q-content-container .q-title .q-title-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0;
  margin-right: 1.25rem;
}
.q-legal-notification .q-close-button {
  font-size: 1.5rem;
  line-height: 1.25rem;
}
.q-legal-notification .q-content-container .q-title,
.q-legal-notification .q-content-container .q-content {
  color: #ffffff;
}
.q-legal-notification .q-content-container .q-title {
  padding: 1.125rem 1.25rem;
}
.q-legal-notification .q-content-container .q-title .q-title-label {
  color: #ffffff;
}
.q-legal-notification .q-content-container .q-content {
  padding: 1.25rem;
}
.q-legal-notification .q-content-container .q-content.q-silent-consent {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0;
  padding: 1.25rem;
}
.q-legal-notification .q-content-container .q-content.q-silent-consent a {
  color: #ffffff;
  text-decoration: underline;
}
.q-legal-notification .q-content-container .q-content.q-silent-consent a:hover {
  color: #cd9834;
  text-decoration: underline;
}
.q-legal-notification .q-content-container .q-content.q-required-consent {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0;
  padding: 1.25rem;
}
.q-legal-notification .q-content-container .q-content.q-required-consent a {
  color: #ffffff;
}
.q-legal-notification .q-content-container .q-content.q-required-consent a:hover {
  color: #cd9834;
}
.q-legal-notification .q-content-container .q-button-container {
  margin-bottom: 1.25rem;
}
.q-legal-notification .q-content-container .q-button-container .q-button {
  margin: 0 auto;
  max-width: 93.6875em;
  color: #ffffff;
  line-height: 1.25rem;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  width: 85%;
}
.q-legal-notification .q-content-container .silent-consent {
  padding: 1rem;
}
.q-legal-notification .q-minimize-text {
  text-decoration: none;
  width: 100%;
}
@media print, only screen and (min-width:26.25em) {
  .q-legal-notification .float-left {
    margin-bottom: 0;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-legal-notification .q-content-container .q-title .q-title-label {
    margin: 0 1.25rem;
  }
  .q-legal-notification .q-content-container .q-title .icon {
    float: none;
  }
  .q-legal-notification .q-content-container .q-button-container .q-button {
    padding-bottom: 1.0625rem;
    padding-top: 1.0625rem;
  }
}

q-progress-bar .q-pb-label {
  font-size: 0.6rem;
}

.q-sticky-notification.q-min-height {
  min-height: 3.3125rem;
}
.q-sticky-notification .q-content-container .q-title .q-title-label {
  font-size: 0.9375rem;
  letter-spacing: 0.085rem;
  line-height: 1.25rem;
  margin-bottom: 0;
  margin-right: 1.88rem;
}
.q-sticky-notification .q-close-button {
  font-size: 1.5rem;
  padding: 0.625rem;
}
.q-sticky-notification .q-content-container .q-title,
.q-sticky-notification .q-content-container .q-content {
  color: #ffffff;
}
.q-sticky-notification .q-content-container .q-title {
  padding: 1.125rem 1.25rem;
}
.q-sticky-notification .q-content-container .q-title .q-title-label {
  color: #ffffff;
}
@media print, only screen and (min-width:26.25em) {
  .q-sticky-notification .q-content-container .q-title .q-title-label {
    font-size: 1.25rem;
    letter-spacing: 0.1125rem;
    line-height: 1.875rem;
    margin-right: 0;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-sticky-notification .q-content-container .q-title .q-title-label {
    margin: 0 1.25rem;
  }
}
@media only screen and (min-width:60em) {
  .q-sticky-notification .q-content-container .q-title .q-title-label {
    font-size: 1.5625rem;
    letter-spacing: 0.140625rem;
    line-height: 2.5rem;
  }
}

.wrapper-underline-hover-xs::after {
  background-color: #cd9834;
  content: "";
  display: block;
  height: 3px;
  transform: scaleX(0);
  transition: transform 250ms linear;
  width: 100%;
}
.wrapper-underline-hover-xs:hover::after {
  transform: scaleX(1);
}

.wrapper-list-hover-xs {
  border-left: 3px solid transparent;
  transition: cubic-bezier(0, 1, 0.5, 1) 0.25s;
}
.wrapper-list-hover-xs:hover {
  border-color: #cd9834;
}

@media print, only screen and (min-width:37.5em) {
  .wrapper-underline-hover-m {
    border: 0;
  }
  .wrapper-underline-hover-m::after {
    background-color: #cd9834;
    content: "";
    display: block;
    height: 3px;
    transform: scaleX(0);
    transition: transform 250ms linear;
    width: 100%;
  }
  .wrapper-underline-hover-m:hover::after {
    transform: scaleX(1);
  }

  .wrapper-list-hover-m {
    border-left: 3px solid transparent;
    transition: cubic-bezier(0, 1, 0.5, 1) 0.25s;
  }
  .wrapper-list-hover-m::after {
    content: none;
  }
  .wrapper-list-hover-m:hover {
    border-color: #cd9834;
  }

  .wrapper-no-hover-m {
    background: none;
    border: 0;
    transition: none;
  }
  .wrapper-no-hover-m::after {
    content: none;
  }
}
.q-gallery-layer .q-gallery-header .q-dropdown {
  border-bottom: 1px solid #b3b3b3;
  border-right: 1px solid #b3b3b3;
  height: 3.813rem;
  min-width: 15.47rem;
}
.q-gallery-layer .q-gallery-header .q-dropdown:hover .svg-icon {
  background-color: #262626;
}
.q-gallery-layer .q-gallery-header .q-dropdown:hover .svg-icon .q-gallery-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 0.75rem;
  padding-left: 4.375rem;
  width: 4.375rem;
}
.q-gallery-layer .q-gallery-header .q-dropdown.open .svg-icon {
  background-color: #262626;
}
.q-gallery-layer .q-gallery-header .q-dropdown.open .svg-icon .q-gallery-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 0.75rem;
  padding-left: 4.375rem;
  width: 4.375rem;
  transform: translateX(-1.75rem) rotate(180deg);
}
.q-gallery-layer .q-gallery-header .q-dropdown .q-category-title {
  display: inline-block;
  font-size: 1.40625rem;
  margin: 0.625rem 0.9375rem 0 0;
  padding: 0;
}
.q-gallery-layer .q-gallery-header .q-dropdown .svg-icon {
  background-color: #808080;
  height: 3.125rem;
  margin: 0;
  transition: background 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  width: 4.375rem;
}
.q-gallery-layer .q-gallery-header .q-dropdown .svg-icon .q-gallery-carat {
  height: 0.75rem;
  position: absolute;
  top: calc(50% - 6px);
  transform: translateX(1.75rem);
  width: 4.375rem;
}
.q-gallery-layer .q-gallery-header .q-gallery-header-close .q-gallery-close-icon {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_close-icon_v01.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 1.25rem;
  padding-left: 1.313rem;
  width: 1.313rem;
}
.q-gallery-layer .q-gallery-header-dropdown .q-gallery-dropdown-item {
  background-color: #ffffff;
  border-bottom: 1px solid #b3b3b3;
  content: " ";
  float: left;
  font-weight: 700;
  padding: 1.75rem 3.5rem;
  width: 100%;
}
.q-gallery-layer .q-gallery-header-dropdown .q-gallery-dropdown-item .q-button-label {
  color: #262626;
  font-size: 0.71rem;
}
.q-gallery-layer .q-gallery-container .q-gallery-thumbnails .q-gallery-headline {
  font-size: 0.9375rem;
  letter-spacing: 0.09em;
  line-height: 1.875rem;
  margin-bottom: 0;
}
.q-gallery-layer .q-gallery-container .q-gallery-thumbnails .q-thumbnail-block .q-slider-item {
  margin: 0 7% 1.1765rem 0;
  width: 28.5%;
}
.q-gallery-layer .q-gallery-container .q-gallery-thumbnails .q-thumbnail-block .q-slider-item:nth-child(3n) {
  margin-right: 0;
}
.q-gallery-layer .q-gallery-container .q-layer .q-layer-width .q-text {
  color: #262626;
  font-size: 0.875rem;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .slick-arrow {
  background-color: #000000;
  opacity: 0;
  transform: translateY(-50%);
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .slick-prev,
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .slick-next {
  margin-top: inherit;
}
.q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item.slick-active .q-responsive-image-disclosure .q-responsive-image-disclosure-text {
  padding: 0 0 0.3125rem;
  right: calc(.625rem + 17px);
}
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-prev::before,
.q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .slick-next::before {
  margin-top: -50%;
  top: 50%;
}
@media print, only screen and (min-width:26.25em) {
  .q-gallery-layer .q-gallery-container .q-multimedia-scroller .q-slider-item.slick-active .q-responsive-image-disclosure .q-responsive-image-disclosure-text {
    padding: 0 0 1.563rem;
    right: calc(1.875rem + 17px);
  }
}
@media print, only screen and (max-width:59.9375em) {
  .q-gallery-layer .q-gallery-thumbnails .q-thumbnail-block {
    justify-content: start;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-gallery-layer .q-gallery-container .q-gallery-thumbnails .q-gallery-headline {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  .q-gallery-layer .q-gallery-container .q-gallery-thumbnails .q-thumbnail-block .q-slider-item {
    margin: 0 4.6% 1.5rem 0;
    width: 21.5%;
  }
  .q-gallery-layer .q-gallery-container .q-gallery-thumbnails .q-thumbnail-block .q-slider-item:nth-child(3n) {
    margin-right: 4.6%;
  }
  .q-gallery-layer .q-gallery-container .q-gallery-thumbnails .q-thumbnail-block .q-slider-item:nth-child(4n) {
    margin-right: 0;
  }
  .q-gallery-layer .q-gallery-container .q-multimedia-scroller .slick-arrow {
    transform: translateY(-50%);
  }
  .q-gallery-layer .q-gallery-header .q-dropdown {
    height: 3.813rem;
  }
  .q-gallery-layer .q-gallery-header .q-dropdown .q-category-title {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 2.5rem;
    margin: 0.3125rem 1.438rem 0 0;
  }
  .q-gallery-layer .q-gallery-header .q-gallery-header-close .q-gallery-close-icon {
    background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_close-icon_v01.svg") no-repeat;
    background-size: contain;
    box-sizing: border-box;
    height: 1.88rem;
    padding-left: 1.88rem;
    width: 1.88rem;
  }
  .q-gallery-layer .q-gallery-header-dropdown .q-gallery-dropdown-item {
    background-color: #ffffff;
    border-bottom: 1px solid #b3b3b3;
    content: " ";
    float: left;
  }
  .q-gallery-layer .q-gallery-header-dropdown .q-gallery-dropdown-item .q-button-label {
    color: #262626;
    font-size: 0.6rem;
  }
}
@media only screen and (min-width:60em) {
  .q-gallery-layer {
    max-width: none;
  }
  .q-gallery-layer .q-dropdown {
    height: 3.813rem;
  }
  .q-gallery-layer .q-gallery-container .q-layer .q-layer-width .q-text {
    color: #6e6e6e;
    font-size: 0.7rem;
  }
  .q-gallery-layer .q-gallery-container .q-slider {
    height: inherit;
  }
  .q-gallery-layer .q-gallery-header {
    max-width: none;
  }
  .q-gallery-layer .row {
    max-width: none;
  }
  .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item {
    margin: 1.5rem 0.75rem;
  }
}
@media only screen and (min-width:93.75em) {
  .q-gallery-layer .q-gallery-thumbnail-scroller .q-filmstrip .q-slider-item {
    margin: 1.304rem 0.652rem;
  }
}
@media only screen and (min-width:156.25em) {
  .q-gallery-layer .q-gallery-header-dropdown .q-gallery-dropdown-item .q-button-label {
    font-size: 0.783rem;
  }
}
.no-touch .q-gallery-layer .q-gallery-header .q-dropdown:hover .svg-icon, .no-touch .q-gallery-layer .q-gallery-header .q-dropdown.focus .svg-icon {
  background-color: #262626;
}
.no-touch .q-gallery-layer .q-gallery-header .q-dropdown:hover .svg-icon .q-gallery-carat, .no-touch .q-gallery-layer .q-gallery-header .q-dropdown.focus .svg-icon .q-gallery-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 0.75rem;
  padding-left: 4.375rem;
  width: 4.375rem;
}
.no-touch .q-gallery-layer .q-gallery-header .q-dropdown.open .svg-icon {
  background-color: #262626;
}
.no-touch .q-gallery-layer .q-gallery-header .q-dropdown.open .svg-icon .q-gallery-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 0.75rem;
  padding-left: 4.375rem;
  width: 4.375rem;
  transform: translateX(-1.75rem) rotate(180deg);
}
.no-touch .q-gallery-layer .q-gallery-header-dropdown .q-gallery-dropdown-item:hover {
  background-color: #ffffff;
  border-left: 3px solid #cd9834;
}
.no-touch .q-gallery-layer .q-multimedia-scroller:hover .slick-arrow {
  background-color: #000000;
  opacity: 0.4;
}
.no-touch .q-gallery-layer .q-multimedia-scroller:hover .slick-arrow:hover {
  opacity: 0.7;
}

.q-multimedia-scroller .q-content-title,
adv-grid > adv-scrl .q-content-title {
  letter-spacing: 0.04rem;
  line-height: 1.5;
}
.q-multimedia-scroller .q-link-icon-inline .q-link-label,
adv-grid > adv-scrl .q-link-icon-inline .q-link-label {
  margin-right: 4px;
}
.q-multimedia-scroller .slick-arrow,
.q-multimedia-scroller .scrl-btn,
adv-grid > adv-scrl .slick-arrow,
adv-grid > adv-scrl .scrl-btn {
  opacity: 0;
}
.q-multimedia-scroller .slick-arrow.invert-arrow,
.q-multimedia-scroller .scrl-btn.invert-arrow,
adv-grid > adv-scrl .slick-arrow.invert-arrow,
adv-grid > adv-scrl .scrl-btn.invert-arrow {
  background-color: #ffffff;
}
.q-multimedia-scroller .slick-arrow.invert-arrow::before,
.q-multimedia-scroller .scrl-btn.invert-arrow::before,
adv-grid > adv-scrl .slick-arrow.invert-arrow::before,
adv-grid > adv-scrl .scrl-btn.invert-arrow::before {
  transform: scaleX(1);
}
.q-multimedia-scroller .slick-arrow.slick-prev.invert-arrow::before,
.q-multimedia-scroller .scrl-btn.slick-prev.invert-arrow::before,
adv-grid > adv-scrl .slick-arrow.slick-prev.invert-arrow::before,
adv-grid > adv-scrl .scrl-btn.slick-prev.invert-arrow::before {
  transform: scaleX(-1);
}
.q-multimedia-scroller .q-content-bar .slick-dots,
adv-grid > adv-scrl .q-content-bar .slick-dots {
  padding: 0 0.63rem;
}
.q-multimedia-scroller .q-content-bar .slick-dots.q-dots-to-numbers-dots,
adv-grid > adv-scrl .q-content-bar .slick-dots.q-dots-to-numbers-dots {
  display: block;
}
@media print, only screen and (max-width:59.9375em) {
  .q-multimedia-scroller .q-indicator-separator,
adv-grid > adv-scrl .q-indicator-separator {
    display: block;
  }
  .q-multimedia-scroller .slick-dots,
adv-grid > adv-scrl .slick-dots {
    background-color: transparent;
  }
  .q-multimedia-scroller .slick-indicator-container,
adv-grid > adv-scrl .slick-indicator-container {
    background-color: transparent;
  }
}
@media print, only screen and (max-width:59.9375em) {
  .q-multimedia-scroller .q-link-icon-inline:hover .q-link-label,
adv-grid > adv-scrl .q-link-icon-inline:hover .q-link-label {
    color: #262626;
  }
}
@media print, only screen and (min-width:26.25em) and (max-width:37.4375em) {
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay,
adv-grid > adv-scrl .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay {
    padding-top: 1rem;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .slick-dots.q-dots-align-center,
adv-grid > adv-scrl .q-content-bar.q-content-below.q-dots-below .slick-dots.q-dots-align-center {
    margin-top: 1.25rem;
  }
  .q-multimedia-scroller .q-content-title,
adv-grid > adv-scrl .q-content-title {
    font-size: 1.176rem;
    letter-spacing: 0.047rem;
  }
  .q-multimedia-scroller .q-headline,
adv-grid > adv-scrl .q-headline {
    padding: 0.625rem;
  }
}
@media only screen and (max-width: 26.1875em) {
  .q-multimedia-scroller .q-content-title,
adv-grid > adv-scrl .q-content-title {
    font-size: 0.9rem;
  }
  .q-multimedia-scroller .q-headline,
adv-grid > adv-scrl .q-headline {
    padding: 0.625rem;
  }
}
@media print, only screen and (max-width:26.1875em) {
  .q-multimedia-scroller .q-content-title,
adv-grid > adv-scrl .q-content-title {
    line-height: 1.667;
  }
}
@media print, only screen and (min-width:37.5em) and (max-width:59.9375em) {
  .q-multimedia-scroller .q-content-bar .q-scroller-overlay.q-content-bar-align-left,
adv-grid > adv-scrl .q-content-bar .q-scroller-overlay.q-content-bar-align-left {
    padding-left: 1rem;
  }
  .q-multimedia-scroller .q-content-bar .q-scroller-overlay.q-content-bar-align-left .q-link-icon-inline,
adv-grid > adv-scrl .q-content-bar .q-scroller-overlay.q-content-bar-align-left .q-link-icon-inline {
    margin-left: 0;
  }
  .q-multimedia-scroller .q-content-bar .q-scroller-overlay.q-content-bar-align-right,
adv-grid > adv-scrl .q-content-bar .q-scroller-overlay.q-content-bar-align-right {
    padding-right: 1rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-dots.q-dots-align-left,
adv-grid > adv-scrl .q-content-bar .slick-dots.q-dots-align-left {
    margin-left: 0.38rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-dots.q-dots-align-right,
adv-grid > adv-scrl .q-content-bar .slick-dots.q-dots-align-right {
    margin-left: -0.38rem;
    margin-right: 0.38rem;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay,
adv-grid > adv-scrl .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay {
    background-color: transparent;
    border: 0;
    position: absolute;
    z-index: 2;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay.q-content-bar-align-right,
adv-grid > adv-scrl .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay.q-content-bar-align-right {
    right: 0;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .slick-indicator-container,
adv-grid > adv-scrl .q-content-bar.q-content-below.q-dots-below .slick-indicator-container {
    border-bottom: 1px solid #e6e6e6;
    height: 4rem;
  }
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .slick-indicator-container .slick-dots.q-dots-align-right,
adv-grid > adv-scrl .q-content-bar.q-content-below.q-dots-below .slick-indicator-container .slick-dots.q-dots-align-right {
    right: 4.25rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-dots li,
adv-grid > adv-scrl .q-content-bar .slick-dots li {
    height: 1rem;
    margin: 0 0.35rem;
    width: 1rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-dots li button,
adv-grid > adv-scrl .q-content-bar .slick-dots li button {
    height: 0.8rem;
    width: 0.8rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-dots li.slick-active button,
adv-grid > adv-scrl .q-content-bar .slick-dots li.slick-active button {
    height: 1rem;
    width: 1rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-dots li:first-child,
adv-grid > adv-scrl .q-content-bar .slick-dots li:first-child {
    margin-left: 0;
  }
  .q-multimedia-scroller .q-content-bar .slick-dots li:last-child,
adv-grid > adv-scrl .q-content-bar .slick-dots li:last-child {
    margin-right: 0;
  }
  .q-multimedia-scroller .q-content-title,
adv-grid > adv-scrl .q-content-title {
    font-size: 1rem;
  }
}
@media only screen and (min-width:60em) {
  .q-multimedia-scroller .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay-more,
adv-grid > adv-scrl .q-content-bar.q-content-below.q-dots-below .q-scroller-overlay-more {
    padding-top: 1rem;
  }
  .q-multimedia-scroller .q-content-bar.q-dots-below .slick-indicator-container .slick-dots.q-dots-align-center, .q-multimedia-scroller .q-content-bar.q-dots-above .slick-indicator-container .slick-dots.q-dots-align-center,
adv-grid > adv-scrl .q-content-bar.q-dots-below .slick-indicator-container .slick-dots.q-dots-align-center,
adv-grid > adv-scrl .q-content-bar.q-dots-above .slick-indicator-container .slick-dots.q-dots-align-center {
    margin-left: 0;
    margin-top: 4.25rem;
  }
  .q-multimedia-scroller .q-content-bar .q-scroller-overlay.q-content-bar-align-left,
adv-grid > adv-scrl .q-content-bar .q-scroller-overlay.q-content-bar-align-left {
    padding: 0 0.63rem;
  }
  .q-multimedia-scroller .q-content-bar .q-scroller-overlay.q-content-bar-align-left .q-link-icon-inline,
adv-grid > adv-scrl .q-content-bar .q-scroller-overlay.q-content-bar-align-left .q-link-icon-inline {
    margin-left: 2.38rem;
  }
  .q-multimedia-scroller .q-content-bar .q-scroller-overlay.q-content-bar-align-right,
adv-grid > adv-scrl .q-content-bar .q-scroller-overlay.q-content-bar-align-right {
    bottom: 0;
    padding: 0;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .q-multimedia-scroller .q-content-bar .slick-dots.q-dots-align-left,
adv-grid > adv-scrl .q-content-bar .slick-dots.q-dots-align-left {
    margin-left: 2.38rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-dots.q-dots-align-right,
adv-grid > adv-scrl .q-content-bar .slick-dots.q-dots-align-right {
    margin-left: -2.38rem;
    margin-right: 2.38rem;
  }
}
@media only screen and (min-width:93.75em) {
  .q-multimedia-scroller .q-content-bar .q-scroller-overlay.q-content-bar-align-left .q-link-icon-inline,
adv-grid > adv-scrl .q-content-bar .q-scroller-overlay.q-content-bar-align-left .q-link-icon-inline {
    margin-left: 0;
  }
  .q-multimedia-scroller .q-content-bar .q-scroller-overlay.q-content-bar-align-right,
adv-grid > adv-scrl .q-content-bar .q-scroller-overlay.q-content-bar-align-right {
    right: 2.61rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-indicator-container .slick-dots,
adv-grid > adv-scrl .q-content-bar .slick-indicator-container .slick-dots {
    padding: 0 0.54rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-indicator-container .slick-dots li,
adv-grid > adv-scrl .q-content-bar .slick-indicator-container .slick-dots li {
    height: 0.87rem;
    margin: 0 0.3rem;
    width: 0.87rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-indicator-container .slick-dots li button,
adv-grid > adv-scrl .q-content-bar .slick-indicator-container .slick-dots li button {
    height: 0.7rem;
    width: 0.7rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-indicator-container .slick-dots li.slick-active button,
adv-grid > adv-scrl .q-content-bar .slick-indicator-container .slick-dots li.slick-active button {
    height: 0.87rem;
    width: 0.87rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-indicator-container .slick-dots li:first-child,
adv-grid > adv-scrl .q-content-bar .slick-indicator-container .slick-dots li:first-child {
    margin-left: 0;
  }
  .q-multimedia-scroller .q-content-bar .slick-indicator-container .slick-dots li:last-child,
adv-grid > adv-scrl .q-content-bar .slick-indicator-container .slick-dots li:last-child {
    margin-right: 0;
  }
  .q-multimedia-scroller .q-content-bar .slick-indicator-container .slick-dots.q-dots-align-left,
adv-grid > adv-scrl .q-content-bar .slick-indicator-container .slick-dots.q-dots-align-left {
    margin-left: 2.07rem;
  }
  .q-multimedia-scroller .q-content-bar .slick-indicator-container .slick-dots.q-dots-align-right,
adv-grid > adv-scrl .q-content-bar .slick-indicator-container .slick-dots.q-dots-align-right {
    margin-left: -2.07rem;
    margin-right: 2.07rem;
  }
}
.no-touch .q-multimedia-scroller:hover,
.no-touch adv-grid > adv-scrl:hover {
  /* Arrows */
}
.no-touch .q-multimedia-scroller:hover .slick-arrow,
.no-touch .q-multimedia-scroller:hover .scrl-btn,
.no-touch adv-grid > adv-scrl:hover .slick-arrow,
.no-touch adv-grid > adv-scrl:hover .scrl-btn {
  background-color: #000000;
  opacity: 0.4;
}
.no-touch .q-multimedia-scroller:hover .slick-arrow.invert-arrow,
.no-touch .q-multimedia-scroller:hover .scrl-btn.invert-arrow,
.no-touch adv-grid > adv-scrl:hover .slick-arrow.invert-arrow,
.no-touch adv-grid > adv-scrl:hover .scrl-btn.invert-arrow {
  background-color: #ffffff;
}
.no-touch .q-multimedia-scroller:hover .slick-arrow:hover,
.no-touch .q-multimedia-scroller:hover .scrl-btn:hover,
.no-touch adv-grid > adv-scrl:hover .slick-arrow:hover,
.no-touch adv-grid > adv-scrl:hover .scrl-btn:hover {
  opacity: 0.7;
}

.q-responsive-image .q-responsive-image-disclosure .q-responsive-image-disclosure-text, .q-responsive-image .q-responsive-image-disclosure .q-rte-disclosure {
  color: #e6e6e6;
}

/* Extend solar nav_primary for Chevrolet-specific styles */
.q-nav-primary .q-nav-collapsed .has-flyout .q-nav-collapsed-link::after {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  content: "";
  position: absolute;
  border-bottom: 0.3125rem solid transparent;
  border-left: 0.4375rem solid #808080;
  border-top: 0.3125rem solid transparent;
  position: absolute;
  right: 1.875rem;
}
.q-nav-primary .q-nav-collapsed .has-flyout .q-nav-collapsed-link:hover::after {
  border: 0;
  content: "";
  position: absolute;
  border-bottom: 0.3125rem solid transparent;
  border-left: 0.4375rem solid #262626;
  border-top: 0.3125rem solid transparent;
}
.q-nav-primary .q-button-back::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  content: "";
  position: absolute;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.4375rem solid #b3b3b3;
  border-top: 0.3125rem solid transparent;
  left: 1.875rem;
}
.q-nav-primary .q-button-back:hover {
  border-left-color: transparent !important;
}
.q-nav-primary .q-top-bar-section {
  flex-wrap: wrap;
  max-width: none;
}
.q-nav-primary .nav-flyout .flyout-nav-content .flyout-content .q-text {
  margin-bottom: 0;
}
.q-nav-primary .q-nav-logo {
  order: 1;
}
.q-nav-primary .q-nav-list-container {
  order: 3;
}
.q-nav-primary .q-nav-dealername-wrapper {
  margin: 0;
  order: 4;
  width: 100%;
}
.q-nav-primary .q-top-bar {
  padding: 0;
}
.q-nav-primary .q-eyebrow-on .q-top-bar-section {
  padding: 0.5rem 0.95rem;
}
.q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-logo {
  padding: 0;
  width: 5.88rem;
}
.q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-primary-link {
  color: #6e6e6e;
  line-height: 0.87;
}
.q-nav-primary .q-eyebrow-on .q-eyebrow-nav {
  padding-left: 0.95rem;
  padding-right: 0.95rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.q-nav-primary .q-eyebrow-on .q-eyebrow-nav .q-eyebrow-list {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.q-nav-primary .q-eyebrow-on .q-eyebrow-nav .q-eyebrow-list .q-nav-list-item .q-nav-primary-link {
  display: flex;
  justify-content: flex-end;
  line-height: 0.87;
  padding: 0.43rem 1rem 0.5rem 0;
}
.q-nav-primary .q-eyebrow-on .q-eyebrow-nav .q-eyebrow-list .q-nav-list-item .q-nav-primary-link .q-icon-svg {
  bottom: 0.07rem;
  height: 0.75rem;
  padding: 0 0.22rem 0 0;
  position: relative;
}
.q-nav-primary .q-eyebrow-on .q-eyebrow-nav .q-eyebrow-list .q-nav-list-item .q-nav-primary-link .q-nav-primary-link-label {
  color: #6e6e6e;
  font-size: 0.6rem;
  font-weight: 400;
  margin: 0;
  width: auto;
}
.q-nav-primary .q-eyebrow-on .q-eyebrow-nav .q-eyebrow-list .q-nav-list-item .q-nav-primary-link:hover .q-icon-svg {
  filter: invert(10%) sepia(0%) saturate(208%) hue-rotate(146deg) brightness(98%) contrast(87%);
}
.q-nav-primary .q-eyebrow-on .q-eyebrow-nav .q-eyebrow-list .q-nav-list-item .q-nav-primary-link:hover .q-nav-primary-link-label {
  color: #262626;
}
@media print, only screen and (max-width:26.1875em) {
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list-item .q-nav-primary-link-label {
    font-size: 0.7rem;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-logo {
    width: 5rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list-item .q-nav-primary-link-label {
    font-size: 0.8rem;
  }
}
@media only screen and (min-width:60em) {
  .q-nav-primary .q-eyebrow-on .q-top-bar-section {
    padding: 0.5rem 0.625rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-logo {
    width: 6.5rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container {
    padding: 0.5rem 0 0;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list {
    padding: 0.25rem 1.25rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list.q-utility-nav {
    padding-right: 0;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list .q-nav-list-item .q-icon-svg {
    padding-right: 0.475rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list .q-nav-list-item .q-nav-primary-link-label {
    font-size: 0.8rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list .q-nav-list-item.has-flyout .q-nav-primary-link-label::before {
    border: 0;
    content: "";
    position: absolute;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-top: 0.4rem solid #808080;
    right: -0.75rem;
    top: 0.2rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list .q-nav-list-item.flyout-opened .q-nav-primary-link-label::before {
    border: 0;
    content: "";
    position: absolute;
    border-bottom: 0.4rem solid #cd9834;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
  }
}
@media only screen and (min-width:156.25em) {
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-logo {
    width: 5.65rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container {
    padding: 0.7rem 0 0.2rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list {
    padding: 0.25rem 0.75rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list.q-utility-nav {
    padding-right: 0.15rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list .q-nav-list-item .q-icon-svg {
    padding-right: 0.32rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list .q-nav-list-item.has-flyout .q-nav-primary-link-label::before {
    border: 0;
    content: "";
    position: absolute;
    border-left: 0.305rem solid transparent;
    border-right: 0.305rem solid transparent;
    border-top: 0.435rem solid #808080;
    right: -0.85rem;
    top: 0.25rem;
  }
  .q-nav-primary .q-eyebrow-on .q-top-bar-section .q-nav-list-container .q-nav-list .q-nav-list-item.flyout-opened .q-nav-primary-link-label::before {
    border: 0;
    content: "";
    position: absolute;
    border-bottom: 0.435rem solid #cd9834;
    border-left: 0.305rem solid transparent;
    border-right: 0.305rem solid transparent;
  }
  .q-nav-primary .q-eyebrow-on .q-eyebrow-nav .q-eyebrow-list .q-nav-list-item .q-nav-primary-link {
    padding: 0.43rem 1rem 0.5rem 0;
  }
}
@media only screen and (min-width:60em) {
  .q-nav-primary .q-top-bar-section .q-nav-list-item.has-flyout .q-nav-primary-link-label::before {
    border: 0;
    content: "";
    position: absolute;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-top: 0.375rem solid #808080;
    bottom: 0.375rem;
    right: -0.75rem;
  }
  .q-nav-primary .q-top-bar-section .q-nav-list-item.flyout-opened .q-nav-primary-link-label::before {
    border: 0;
    content: "";
    position: absolute;
    border-bottom: 0.375rem solid #cd9834;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
  }
  .q-nav-primary .q-nav-vehicle-selector {
    padding: 1.25rem;
  }
  .q-nav-primary .q-nav-dealername-wrapper {
    font-size: 1.4rem;
    max-width: 37%;
    order: 2;
    padding: 0 0.5rem 0 0.2rem;
    width: auto;
  }
}
@media only screen and (min-width:93.75em) {
  .q-nav-primary .q-nav-dealername-wrapper {
    max-width: 50%;
  }
}

/* extend solar nav_secondary for brand-specific styles */
.q-nav-anchor .q-nav-container {
  min-height: 3rem;
}
.q-nav-anchor .q-nav-container .q-year-toggle {
  margin: 0 0 0 0.75rem;
  order: 2;
}
.q-nav-anchor .q-nav-container .q-year-toggle .q-year-toggle-list-item {
  color: #808080;
  line-height: 1.8rem;
}
.q-nav-anchor .q-nav-container .q-year-toggle .q-year-toggle-list-item a {
  color: inherit;
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.075rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  text-align: center;
}
.q-nav-anchor .q-nav-container .q-year-toggle .q-year-toggle-list-item.focus, .q-nav-anchor .q-nav-container .q-year-toggle .q-year-toggle-list-item:hover {
  background-color: #cd9834;
  color: #000000;
}
.q-nav-anchor .q-nav-container .q-year-toggle .q-year-toggle-list-item.active {
  background-color: #808080;
  color: #ffffff;
}
.q-nav-anchor .q-nav-container .q-year-toggle .q-year-toggle-list-item.active:hover {
  background-color: #808080;
  color: #ffffff;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container {
  order: 3;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li:not(.q-cta-list-item) a::after {
  transition: width 0.25s ease, left 0.25s ease;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button {
  order: 1;
  padding: 0.29rem 0.29rem 0.29rem 1.76rem;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-dropdown-has-content {
  background-color: #ffffff;
  border-right: 0.0625rem solid #e6e6e6;
  cursor: pointer;
  min-height: 3.75rem;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-dropdown-has-content:hover::after, .q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-dropdown-has-content:focus::after {
  transition: width 0.25s ease, left 0.25s ease;
  background-color: #262626;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-dropdown-has-content::after {
  background: #808080 url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat center;
  background-size: 0.94rem 0.71rem;
  content: "";
  height: 2.94rem;
  position: absolute;
  right: 0.3125rem;
  width: 4.12rem;
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-dropdown-has-content.q-dropdown-open::after {
  background-color: #262626;
  transform: rotateX(180deg);
}
.q-nav-anchor .q-nav-container > .q-secondary-nav-button .q-dropdown-arrow {
  max-width: 75%;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-active-anchor {
  border-left-color: #cd9834;
}
.q-nav-anchor .q-nav-container .q-sibling-nav-container .q-sibling-nav-list li:not(.q-cta-list-item) a::after {
  transition: width 0.25s ease, left 0.25s ease;
}
@media print, only screen and (min-width:37.5em) {
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button {
    padding: 0.25rem 0.25rem 0.25rem 1.5rem;
  }
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-dropdown-has-content::after {
    background-size: 0.8rem 0.6rem;
    height: 2.5rem;
    width: 3.5rem;
  }
}
@media only screen and (min-width:93.75em) {
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button {
    padding: 0.22rem 0.22rem 0.22rem 1.3rem;
  }
  .q-nav-anchor .q-nav-container > .q-secondary-nav-button.q-dropdown-has-content::after {
    background-size: 0.7rem 0.52rem;
    height: 2.17rem;
    width: 3.04rem;
  }
}

/* extend solar nav_segment for brand-specific styles */
.q-nav-segment .q-content-item a::after {
  border-bottom: 0.3125rem solid transparent;
  border-left: 0.4375rem solid #b3b3b3;
  border-top: 0.3125rem solid transparent;
  content: "";
  position: absolute;
  right: 1.875rem;
  top: 50%;
  transform: translateY(-50%);
}
.q-nav-segment .q-content-item a::before {
  background: transparent;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0.1875rem;
}
.q-nav-segment .q-content-item a:hover::before {
  background: #cd9834;
}
.q-nav-segment .q-content-item a:hover::after {
  border-left: 0.4375rem solid #cd9834;
}
@media only screen and (min-width:60em) {
  .q-nav-segment .q-content-item {
    background: #f2f2f2;
    border-bottom: 0.0625rem solid #b3b3b3;
    border-left: 0.0625rem solid #b3b3b3;
    height: 3.375rem;
  }
  .q-nav-segment .q-content-item:hover {
    background-color: #ffffff;
  }
  .q-nav-segment .q-content-item:hover .q-no-link {
    color: #262626;
  }
  .q-nav-segment .q-content-item.active {
    background-color: #ffffff;
    border-bottom: 0;
  }
  .q-nav-segment .q-content-item:not(:last-of-type) {
    border-right: 0;
  }
  .q-nav-segment .q-content-item:first-of-type {
    border-left: 0;
  }
  .q-nav-segment .q-content-item a {
    line-height: 3.375rem;
  }
  .q-nav-segment .q-content-item a span::before {
    transition: width 0.25s ease, left 0.25s ease;
    background: #cd9834;
    bottom: -0.0625rem;
    content: "";
    height: 0.1875rem;
    left: 50%;
    position: absolute;
    width: 0;
  }
  .q-nav-segment .q-content-item a.active span::before {
    transition: width 0.25s ease, left 0.25s ease;
    left: 0;
    width: 100%;
  }
  .q-nav-segment .q-content-item a::before, .q-nav-segment .q-content-item a::after {
    content: none;
  }
}

.q-tab-nav .q-floating-tab.q-swap-tabs {
  border: 1px solid #b3b3b3;
}
.q-tab-nav .q-floating-tab.q-swap-tabs .q-tabs .q-tab-title {
  border: 0;
}
.q-tab-nav .q-floating-tab.q-swap-tabs .q-tabs .q-tab-title .q-tab-button {
  background: #ffffff;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button.q-icon-stacked {
  padding: 0.3125rem 0.3125rem 0;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button.q-icon-stacked .q-button .q-move-text {
  margin-top: 0.625rem;
}
.q-tab-nav .q-floating-tab .q-tab-title .q-tab-button.q-icon-only {
  padding: 0 0.3125rem;
}
.no-touch .q-tab-nav .q-floating-tab .q-tab-title.active .q-tab-button:hover .q-button {
  color: #262626;
}
.q-tab-nav .q-tabs-content.colorizer-tabs-content {
  border: 0;
}
.q-tab-nav .q-tabs-content.colorizer-tabs-content .brightcove-player-video,
.q-tab-nav .q-tabs-content.colorizer-tabs-content video-js {
  background: transparent;
}
.q-tab-nav .q-tabs-content.colorizer-tabs-content .brightcove-player-video .video-js,
.q-tab-nav .q-tabs-content.colorizer-tabs-content video-js .video-js {
  background: transparent;
}
.q-tab-nav .q-tabs-content.colorizer-tabs-content .brightcove-player-video .video-js .vjs-tech,
.q-tab-nav .q-tabs-content.colorizer-tabs-content video-js .video-js .vjs-tech {
  background: transparent !important;
}
.q-tab-nav .q-360-colorizer .q-colorizer-category {
  align-items: center;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
}
.q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .q-colorizer-chip {
  border: 2px solid transparent;
  display: flex;
  margin: 0.31rem 0 0;
}
.q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .q-colorizer-chip:hover {
  border-color: #b3b3b3;
}
.q-tab-nav .q-360-colorizer .q-colorizer-tabs {
  justify-content: center;
}
.q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-colorizer-panel:hover .q-tooltip-text {
  display: none;
}
.q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title.active .q-colorizer-chip {
  margin: 0.31rem 0 0;
}
.q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text {
  background-color: #000000;
  border: hidden;
  color: #ffffff;
  text-transform: capitalize;
  z-index: 2;
}
.q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text::after, .q-tab-nav .q-360-colorizer .q-colorizer-tabs .tab-title .q-tooltip-text::before {
  border-top: 10px solid #000000;
}
@media print, only screen and (min-width:600px) {
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .tab-title.active .q-colorizer-chip {
    height: 2.18rem;
    width: 2.18rem;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .tab-title.active .q-colorizer-chip:hover {
    height: 2.18rem;
    width: 2.18rem;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .q-colorizer-chip {
    height: 2.18rem;
    width: 2.18rem;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .q-colorizer-chip:hover {
    height: 2.18rem;
    width: 2.18rem;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .q-colorizer-chip .q-colorizer-select {
    height: 1.5rem;
    width: 1.5rem;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-tab-nav .q-360-colorizer .q-colorizer-tabs {
    justify-content: left;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category {
    align-items: inherit;
    text-align: inherit;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-colorizer-panel .tab-title:hover .q-tooltip-text {
    transform: translate(-50%, 1.25rem);
    visibility: visible;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-colorizer-panel.tooltip-leftmost .tab-title .q-tooltip-text {
    transform: translate(-0.75rem, 1.25rem);
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name:nth-child(2) {
    border-left: 0;
  }
  .q-tab-nav .q-360-colorizer .q-bottom-headline .columns {
    padding: 0;
  }
}
@media only screen and (min-width:60em) {
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .q-colorizer-panel .tab-title:hover .q-tooltip-text {
    display: block;
    padding-top: 0.4rem;
  }
}
@media only screen and (min-width:93.75em) {
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .tab-title.active .q-colorizer-chip {
    height: 1.75rem;
    width: 1.75rem;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .tab-title.active .q-colorizer-chip:hover {
    height: 1.75rem;
    width: 1.75rem;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .q-colorizer-chip {
    height: 1.75rem;
    width: 1.75rem;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .q-colorizer-chip:hover {
    height: 1.75rem;
    width: 1.75rem;
  }
  .q-tab-nav .q-360-colorizer .q-colorizer-category .q-category-name .q-colorizer-chip .q-colorizer-select {
    height: 1.305rem;
    width: 1.305rem;
  }
}
.q-tab-nav.q-tabnav-360colorizer .q-tabnav-360colorizer-row {
  border-bottom: 1px solid #e6e6e6;
  border-width: 0 1px 1px;
  display: none;
  justify-content: left;
  width: 100%;
}
.q-tab-nav.q-tabnav-360colorizer .q-tabnav-360colorizer-row .q-tab-title {
  background: none;
  border: 0;
  display: block;
  flex: unset;
}
.q-tab-nav.q-tabnav-360colorizer .q-tabnav-360colorizer-row .q-tab-title.active {
  border-left: 0;
}
.q-tab-nav.q-tabnav-360colorizer .q-tabnav-360colorizer-row .q-tab-title.active .q-tab-button .q-button::before {
  border-bottom: 5px solid #cd9834;
  bottom: 0;
  height: 0.76rem;
}
.q-tab-nav.q-tabnav-360colorizer .q-tabnav-360colorizer-row .q-tab-title .q-tab-button {
  background: none;
  border: 0;
  color: #000000;
  padding: 0;
}
.q-tab-nav.q-tabnav-360colorizer .q-tabnav-360colorizer-row .q-tab-title .q-tab-button .q-button {
  border: 0;
  color: #000000;
  line-height: 1.76rem;
  margin: 0 0.8rem 0 0;
  padding: 0.5rem 0;
}
.q-tab-nav.q-tabnav-360colorizer .q-tabnav-360colorizer-row .q-tab-title .q-tab-button .q-button .q-move-text {
  border: 0;
  color: #000000;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1.17rem;
}
.q-tab-nav.q-tabnav-360colorizer .q-tabs-content {
  border: 0;
}
@media print, only screen and (min-width:26.25em) {
  .q-tab-nav.q-tabnav-360colorizer .q-tabnav-360colorizer-row .q-tab-title .q-tab-button .q-button .q-move-text {
    font-size: 0.6rem;
  }
}
.q-tab-nav .q-change-view-header {
  background-color: #ffffff;
  height: 3.1765rem;
  margin: 0.5rem auto;
  max-width: 17.65rem;
  padding-right: 1rem;
  position: relative;
  top: 0;
  z-index: 110;
}
.q-tab-nav .q-change-view-header .q-change-view-header-nav {
  display: inline-block;
  float: right;
}
.q-tab-nav .q-change-view-header .q-change-view-header-nav.disabled .q-dropdown:hover {
  cursor: default;
}
.q-tab-nav .q-change-view-header .q-dropdown {
  border: 0;
  border: 1px solid #b3b3b3;
  cursor: pointer;
  display: inline-block;
  height: 3.1765rem;
  min-width: 14.69rem;
  padding: 0.2647rem 0.3529rem 0.2647rem 0.6471rem;
  text-align: left;
  width: 17.6471rem;
}
.q-tab-nav .q-change-view-header .q-dropdown:hover .svg-icon {
  background-color: #262626;
}
.q-tab-nav .q-change-view-header .q-dropdown:hover .svg-icon .q-change-view-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 0.4706rem;
  padding-left: 3.375rem;
  width: 3.375rem;
  background-position: calc(100% - 1rem) center, 100% 0;
  margin-top: 1.0588rem;
}
.q-tab-nav .q-change-view-header .q-dropdown.open .svg-icon {
  background-color: #262626;
}
.q-tab-nav .q-change-view-header .q-dropdown.open .svg-icon .q-change-view-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 0.4706rem;
  padding-left: 3.375rem;
  width: 3.375rem;
  margin-top: 1.0588rem;
  transform: translateX(-1rem) rotate(180deg);
}
.q-tab-nav .q-change-view-header .q-dropdown .q-category-title {
  display: inline-block;
  font-size: 1.0588rem;
  line-height: 1.7647rem;
  margin: 0.5rem 0 0;
  padding: 0;
}
.q-tab-nav .q-change-view-header .q-dropdown .svg-icon {
  background-color: #808080;
  display: inline-block;
  float: right;
  height: 2.5883rem;
  line-height: 0;
  margin: 0;
  transition: background 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  width: 4.1177rem;
}
.q-tab-nav .q-change-view-header .q-dropdown .svg-icon.hide {
  display: none;
}
.q-tab-nav .q-change-view-header .q-dropdown .svg-icon .q-change-view-carat-container {
  height: 2rem;
  padding-left: 3.375rem;
  width: 2rem;
}
.q-tab-nav .q-change-view-header .q-dropdown .svg-icon .q-change-view-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 0.4706rem;
  padding-left: 3.375rem;
  width: 3.375rem;
  background-position: calc(100% - 1rem) center, 100% 0;
  margin-top: 1.0588rem;
}
.q-tab-nav .q-change-view-header > * {
  position: absolute;
}
.q-tab-nav .q-change-view-header-dropdown {
  background: #ffffff;
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  float: right;
  margin: 0;
  margin-top: 3.1765rem;
  max-height: 100%;
  max-height: calc(100vh - 54px);
  overflow: hidden;
  visibility: visible;
  width: 17.6471rem;
  z-index: 110;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item {
  background-color: #ffffff;
  content: " ";
  float: right;
  height: 1rem;
  padding: 1.75rem 1.875rem;
  width: 100%;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item.active {
  background-color: #ffffff;
  border-left: 3px solid #cd9834;
}
.q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label {
  color: #262626;
  display: inline-block;
  font-size: 1.0588rem;
  line-height: 1.7647rem;
  transform: translate(0, -50%);
}
.q-tab-nav .q-change-view-header-dropdown.q-dropdown-padding {
  padding-bottom: 3.375rem;
}
.q-tab-nav .q-change-view-header-dropdown.q-overflow-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
@media print, only screen and (min-width:26.25em) {
  .q-tab-nav .q-change-view-header {
    padding-right: 0;
  }
}
@media print, only screen and (min-width:37.5em) {
  .q-tab-nav .q-change-view-header .q-dropdown .q-category-title {
    margin: 0.3125rem 1.438rem 0 0;
  }
  .q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item {
    border-bottom: 1px solid #b3b3b3;
    content: " ";
    float: left;
    padding: 1.75rem 1.875rem;
  }
  .q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label {
    color: #262626;
  }
}
@media only screen and (min-width:60em) {
  .q-tab-nav {
    max-width: none;
  }
  .q-tab-nav .q-dropdown {
    height: 3.813rem;
  }
  .q-tab-nav .q-change-view-header {
    display: flex;
    height: 3.6rem;
    justify-content: flex-end;
    max-width: none;
  }
  .q-tab-nav .q-change-view-header .q-dropdown {
    height: 3.6rem;
    min-width: 15.5rem;
    padding: 0.3rem 0.3rem 0.3rem 1.1rem;
    width: 15.5rem;
  }
  .q-tab-nav .q-change-view-header .q-dropdown .q-category-title {
    font-size: 1.3rem;
    line-height: 1.5rem;
    margin: 0.75rem 0;
  }
  .q-tab-nav .q-change-view-header .q-dropdown .svg-icon {
    height: 3rem;
    width: 3.5rem;
  }
  .q-tab-nav .q-change-view-header .q-dropdown .svg-icon .q-change-view-carat {
    background-position: calc(100% - 1.35rem) center, 100% 0;
    height: 0.4rem;
    margin-top: 1.3rem;
  }
  .q-tab-nav .q-change-view-header-dropdown {
    margin-top: 3.6rem;
    width: 15.5rem;
  }
  .q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item .q-button-label {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
  .q-tab-nav .row {
    max-width: none;
  }
  .no-touch .q-tab-nav .q-change-view-header .q-change-view-header-nav .q-dropdown:hover .svg-icon .q-change-view-carat, .no-touch .q-tab-nav .q-change-view-header .q-change-view-header-nav .q-dropdown.focus .svg-icon .q-change-view-carat {
    background-position: calc(100% - 1.35rem) center, 100% 0;
    height: 0.4rem;
    margin-top: 1.3rem;
  }
  .no-touch .q-tab-nav .q-change-view-header .q-change-view-header-nav .q-dropdown.open .svg-icon .q-change-view-carat {
    height: 0.4rem;
    margin-top: 1.3rem;
    transform: translateX(-1.35rem) rotate(180deg);
  }
  .no-touch .q-tab-nav .q-change-view-header .q-change-view-header-nav .q-dropdown.open:hover .svg-icon .q-change-view-carat {
    transform: translateX(0.2rem) rotate(180deg);
  }
}
.no-touch .q-tab-nav .q-change-view-header .q-dropdown:hover .svg-icon, .no-touch .q-tab-nav .q-change-view-header .q-dropdown.focus .svg-icon {
  background-color: #262626;
}
.no-touch .q-tab-nav .q-change-view-header .q-dropdown:hover .svg-icon .q-change-view-carat, .no-touch .q-tab-nav .q-change-view-header .q-dropdown.focus .svg-icon .q-change-view-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 0.4706rem;
  padding-left: 3.375rem;
  width: 3.375rem;
  background-position: calc(100% - 1rem) center, 100% 0;
  margin-top: 1.0588rem;
  padding-left: 3.375rem;
}
.no-touch .q-tab-nav .q-change-view-header .q-dropdown.open .svg-icon {
  background-color: #262626;
}
.no-touch .q-tab-nav .q-change-view-header .q-dropdown.open .svg-icon .q-change-view-carat {
  background: url("/etc.clientlibs/clientlibs/chevrolet/resources/img/ui_arrow-small-down-white.svg") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  height: 0.4706rem;
  padding-left: 3.375rem;
  width: 3.375rem;
  margin-top: 1.0588rem;
  padding-left: 3.375rem;
  transform: translateX(-1rem) rotate(180deg);
}
.no-touch .q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item:hover {
  background-color: #ffffff;
  border-left: 3px solid #cd9834;
  padding-left: 1.688rem;
}
.no-touch .q-tab-nav .q-change-view-header-dropdown .q-change-view-dropdown-item.active:hover {
  padding-left: 1.875rem;
}

.q-trailering-towing-search-results .q-main-title {
  text-align: center;
}
.q-trailering-towing-search-results .q-main-car-tile {
  padding: 0 2.5rem;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-year {
  margin-bottom: 0.2rem;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-nameplate-trim {
  text-transform: uppercase;
}
.q-trailering-towing-search-results .q-main-car-tile .q-car-details .q-specs ul {
  margin-left: 0;
  padding-left: 1.25rem;
}
.q-trailering-towing-search-results hr {
  margin: 0.667rem 1rem 1.334rem;
}
@media only screen and (min-width:60em) {
  .q-trailering-towing-search-results .q-specs {
    display: table;
  }
  .q-trailering-towing-search-results .q-specs .q-specs-left,
.q-trailering-towing-search-results .q-specs .q-specs-right {
    display: table-cell;
    vertical-align: middle;
  }
}

.q-trailering-towing-vehicle-capability-tile {
  padding: 0 1rem;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section {
  float: left;
  width: 100%;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-title {
  padding-top: 0.667rem;
  text-transform: uppercase;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-number {
  text-transform: uppercase;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-unit span:hover {
  color: #e6ac39;
  cursor: pointer;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section .q-secondary-title .q-unit p {
  display: inline;
}
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-description {
  margin-bottom: 2.667rem;
}
@media print, only screen and (min-width:37.5em) {
  .q-trailering-towing-vehicle-capability-tile .q-content-section .q-description {
    padding-top: 3rem;
  }
  .q-trailering-towing-vehicle-capability-tile .q-content-section .q-title-section,
.q-trailering-towing-vehicle-capability-tile .q-content-section .q-description {
    float: left;
    vertical-align: middle;
    width: 50%;
  }
}
.q-trailering-towing-vehicle-capability-tile .q-img-container img {
  min-width: 100%;
}

.q-trailering-towing-form .q-vin-form-box {
  padding: 2.66rem 1.33rem;
}
.q-trailering-towing-form .q-input {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
  font-size: 1.125rem;
  height: 3.33rem;
  line-height: 1.875rem;
  padding-left: 2rem;
  transition: border 0.25s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.q-trailering-towing-form .error {
  color: #e25959;
}
.q-trailering-towing-form .q-hide {
  display: none;
  height: 0;
}
.q-trailering-towing-form .q-submit-box {
  display: flex;
  justify-content: flex-end;
}
.q-trailering-towing-form .q-button {
  flex: 0 1 100%;
  min-width: 200px;
}
@media print, only screen and (min-width:37.5em) {
  .q-trailering-towing-form .q-button {
    flex: 0 0 50%;
  }
}
@media only screen and (min-width:60em) {
  .q-trailering-towing-form .q-button {
    flex: 0 0 25%;
  }
}

/* endinject */
/* Styles for the print header that is added to the top of the body for each page loaded */
.q-print-header {
  display: none;
}
.q-print-header .q-print-header-container {
  align-items: center;
  border-bottom: 2px solid #e6e6e6;
  display: flex;
  margin-bottom: 2.5rem;
  padding-bottom: 0.313rem;
}
.q-print-header .q-print-header-container .title {
  color: #262626;
  display: flex;
  flex: 1 1 0;
  padding-left: 2.5rem;
  text-transform: uppercase;
}
.q-print-header .q-print-header-container .logo-container {
  display: flex;
  flex: 1 1 0;
  max-width: 5.625rem;
}
.q-print-header .q-print-header-container .logo-container .logo {
  height: 100%;
  width: 100%;
}
.q-print-header .q-print-disclaimer {
  margin: 0 1rem 1rem;
}

/* Specific print CSS rules go here */
@media print {
  /* sass-lint:disable-block no-important */
  body main#q-main-content {
    display: none;
  }
  body.disclosures-loaded main#q-main-content {
    display: block;
  }

  footer,
.q-nav-anchor,
.q-nav-primary,
.sticky-wrapper,
.q-disclaimer-footer,
.gmit-gf-progress-bar,
.q-legal-notification,
.q-sticky-notification,
.LPMcontainer,
.q-disclosure-modal-toast {
    display: none !important;
  }

  .q-hide-for-print,
.q-geoip-notification-container {
    display: none !important;
  }

  .q-print-sequence-data {
    display: block;
  }

  .q-disclosure-print-number {
    display: inline-block;
  }

  /* This is the container for the disclosures at the bottom of the printed page */
  .q-disclosure-data-content {
    display: block;
    margin-top: 5rem;
  }
  .q-disclosure-data-content .q-text .q-body2 a:link {
    color: #808080;
    font-weight: 400;
  }
  .q-disclosure-data-content .q-print-list-style {
    list-style-type: decimal;
  }
  .q-disclosure-data-content .q-disclaimer-body .q-print-sequence-data p {
    margin-bottom: 0;
  }

  .show-disclosure .q-print-special-char {
    display: none;
  }

  .headline .show-disclosure {
    text-decoration: none;
  }
  .headline .show-disclosure sup span {
    text-decoration: none;
  }

  .q-print-reference {
    display: inline-block;
  }

  .q-print-header {
    display: block;
  }

  .q-separator .q-separator-combined {
    height: inherit !important;
  }

  .q-dealer-locator .q-search-column div {
    margin: 0 !important;
  }
  .q-dealer-locator .dealer-dropdown,
.q-dealer-locator .q-pagination,
.q-dealer-locator .q-shopping-links,
.q-dealer-locator .q-dealer-info .q-dealer-links .q-contact.q-location,
.q-dealer-locator .q-dealer-info .q-dealer-links .q-contact img,
.q-dealer-locator .q-checkbox-label,
.q-dealer-locator .q-print-button,
.q-dealer-locator .q-dealer-search,
.q-dealer-locator .hide-large-up {
    display: none !important;
  }
  .q-dealer-locator .q-descriptive1,
.q-dealer-locator .q-dealer-info .q-dealer-address,
.q-dealer-locator .q-dealer-info .q-dealer-links .q-contact a {
    font-size: 0.75rem !important;
    font-weight: normal !important;
  }
  .q-dealer-locator .q-dealer-info .q-dealer-links .q-contact.q-url a::after {
    content: " (" attr(href) ")";
  }
  .q-dealer-locator .q-flex-container {
    display: block;
  }
  .q-dealer-locator .filter-tags .inline-list > li .button {
    border: 1px solid #000000;
    font-size: 0.5625rem !important;
    font-weight: normal !important;
  }
  .q-dealer-locator .q-map-column {
    flex: 1 0 100%;
    margin: 0 0 0.5rem 0.5rem;
    order: 0;
    padding-left: 0.5rem;
    padding-top: 1.25rem;
    position: relative;
    zoom: 70%;
  }
  .q-dealer-locator .q-map-column .q-map-container {
    height: 100%;
    min-height: 36.25rem;
    padding-bottom: 2rem;
  }
  .q-dealer-locator .q-map-column .q-map-container .dealer-map {
    height: 100%;
    min-height: 36.25rem;
  }
  .q-dealer-locator .q-search-column {
    flex: 1 1 100%;
  }
  .q-dealer-locator .q-open-hours {
    flex: 1;
  }
  .q-dealer-locator .q-text.q-body2 {
    padding-top: 0;
  }
  .q-dealer-locator .q-dealer-links {
    flex: 1 0 100%;
  }
  .q-dealer-locator .q-dealer-info .q-dealer-links .q-contact {
    border: 0;
    padding: 0.5rem 0;
  }

  .q-gallery-layer .q-gallery-video-icon {
    display: none !important;
  }
  .q-gallery-layer .q-multimedia-scroller .slick-arrow {
    display: none !important;
  }

  .gmst-co-offer-item-offer-disclosure {
    display: block;
  }

  .q-animate {
    visibility: visible;
  }
}