//Variables for font styles
$font-style-italic: italic;
$font-style-normal: normal;
$font-style-inherit: inherit;

//Variables for font weights
$font-weight-lighter: 100;
$font-weight-light: 200;
$font-weight-book: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-demi: 600;
$font-weight-bold: 700;
$font-weight-ultra: 800;
$font-weight-xbold: 900;
