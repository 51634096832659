
// Separator
//
// This is the documentation for Separator. Edit separator.scss to update this documentation.
//
// markup:
// <p>Sample markup for Separator. Replace me with the real deal once you are done editing <strong>separator.html</strong> and <em>optionally</em> the separator.js file.</p>
//
// Styleguide 99.99
$separator-color: $grey !default;
$separator-margin: .5rem !default;
$separator-horizontal-margin: $separator-margin !default;
$separator-primary: $black !default;
$separator-secondary: $dark-grey !default;
$separator-tertiary: $dusty-grey !default;

.q-separator {
	hr {
		margin: 0 auto;

		&.separator-primary {
			border-color: $separator-primary;
		}

		&.separator-secondary {
			border-color: $separator-secondary;
		}

		&.separator-tertiary {
			border-color: $separator-tertiary;
		}

		&.invisible {
			visibility: hidden;
		}
	}
}

.q-separator-horizontal,
.q-separator-vertical,
.q-separator-combined {
	overflow: hidden;
	position: relative;
	text-align: center;

	span {
		color: $separator-color;
		display: inline-block;
		position: relative;

		&::before,
		&::after {
			border-top: 1px solid $separator-color;
			content: '';
			position: absolute;
		}
	}
}

@mixin q-separator-horizontal {
	span {
		&::before,
		&::after {
			height: .0625rem;
			top: 50%;
			width: 624.9375rem;
		}

		&::before {
			margin-right: $separator-horizontal-margin;
			right: 100%;
		}

		&::after {
			left: 100%;
			margin-left: $separator-horizontal-margin;
		}
	}
}

@mixin q-separator-vertical {
	span {
		top: 50%;
		transform: translateY(-50%);

		&::before,
		&::after {
			border-left: 1px solid $separator-color;
			height: 624.9375rem;
			left: 50%;
		}

		&::before {
			bottom: 100%;
			margin-bottom: $separator-margin;
		}

		&::after {
			margin-top: $separator-margin;
			top: 100%;
		}
	}
}

.q-separator-horizontal {
	@include q-separator-horizontal;
}

.q-separator-vertical {
	@include q-separator-vertical;
}

@media #{$large-down} {
	.q-separator-combined {
		@include q-separator-horizontal;
	}
}

@media #{$xlarge-up} {
	.q-separator-combined {
		@include q-separator-vertical;
	}
}
