// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

@import 'global';

//
// @variables
//
$include-html-grid-classes: $include-html-classes !default;
$include-xl-html-grid-classes: false !default;

$row-width: rem-calc(1000) !default;
$total-columns: 12 !default;

$last-child-float: $opposite-direction !default;

//
// Grid Functions
//

// Deprecated: We'll drop support for this in 5.1, use grid-calc()
@function gridCalc($colNumber, $totalColumns) {
  @warn "gridCalc() is deprecated, use grid-calc()";
  @return grid-calc($colNumber, $totalColumns);
}

// @FUNCTION
// $colNumber - Found in settings file
// $totalColumns - Found in settings file
@function grid-calc($colNumber, $totalColumns) {
  $result: percentage(($colNumber / $totalColumns));
  @if $result == 0% { $result: 0; }
  @return $result;
}

@function grid-calc-w-pad($colNumber, $totalColumns, $padding) {
  $result: percentage(($colNumber / $totalColumns) - ($padding / ($total-columns / $colNumber)));
  @if $result == 0% { $result: 0; }
  @return $result;
}

//
// @mixins
//

// For creating container, nested, and collapsed rows.
//
//
// $behavior - Any special behavior for this row? Default: false. Options: nest, collapse, nest-collapse, false.
@mixin grid-row($behavior: false) {

  // use @include grid-row(nest); to include a nested row
  @if $behavior == nest {
    margin: 0 (-($column-gutter/2));
    max-width: none;
    width: auto;
  }

  // use @include grid-row(collapse); to collapsed a container row margins
  @else if $behavior == collapse {
    margin: 0;
    max-width: $row-width;
    width: 100%;
  }

  // use @include grid-row(nest-collapse); to collapse outer margins on a nested row
  @else if $behavior == nest-collapse {
    margin: 0;
    max-width: none;
    width: auto;
  }

  // use @include grid-row; to use a container row
  @else {
    margin: 0 auto;
    max-width: $row-width;
    width: 100%;
  }

  // Clearfix for all rows
  @include clearfix();
}

// Creates a column, should be used inside of a media query to control layouts
//
// $columns - The number of columns this should be
// $last-column - Is this the last column? Default: false.
// $center - Center these columns? Default: false.
// $offset - # of columns to offset. Default: false.
// $push - # of columns to push. Default: false.
// $pull - # of columns to pull. Default: false.
// $collapse - Get rid of gutter padding on column? Default: false.
// $float - Should this float? Default: true. Options: true, false, left, right.
@mixin grid-column(
  $columns:false,
  $last-column:false,
  $center:false,
  $offset:false,
  $push:false,
  $pull:false,
  $collapse:false,
  $float:true,
  $position:false) {

  // If positioned for default .column, include relative position
  // push and pull require position set
  @if $position or $push or $pull {
    position: relative;
  }

  // If collapsed, get rid of gutter padding
  @if $collapse {
    padding-left: 0;
    padding-right: 0;
  }

  // Gutter padding whenever a column isn't set to collapse
  // (use $collapse:null to do nothing)
  @else if $collapse == false {
    padding-left: ($column-gutter / 2);
    padding-right: ($column-gutter / 2);
  }

  // If a column number is given, calculate width
  @if $columns {
    width: grid-calc($columns, $total-columns);

    // If last column, float naturally instead of to the right
    @if $last-column { float: $opposite-direction; }
  }

  // Source Ordering, adds left/right depending on which you use.
  @if $push { #{$default-float}: grid-calc($push, $total-columns); #{$opposite-direction}: auto; }
  @if $pull { #{$opposite-direction}: grid-calc($pull, $total-columns); #{$default-float}: auto; }

  @if $float and $last-column == false {
    @if $float == left or $float == true { float: $default-float; }
    @else if $float == right { float: $opposite-direction; }
    @else { float: none; }
  }

  // If centered, get rid of float and add appropriate margins
  @if $center {
    margin-#{$default-float}: auto;
    margin-#{$opposite-direction}: auto;
    float: none;
  }

  // If offset, calculate appropriate margins
	@if $offset { margin-#{$default-float}: grid-calc($offset, $total-columns) !important; }
}

// Create presentational classes for grid
//
// $size - Name of class to use, i.e. "large" will generate .large-1, .large-2, etc.
@mixin grid-html-classes($size) {

  @for $i from 0 through $total-columns - 1 {
    .#{$size}-push-#{$i} {
      @include grid-column($push:$i, $collapse:null, $float:false);
    }
    .#{$size}-pull-#{$i} {
      @include grid-column($pull:$i, $collapse:null, $float:false);
    }
  }

  .column,
  .columns { @include grid-column($columns:false, $position:true); }


  @for $i from 1 through $total-columns {
    .#{$size}-#{$i} { @include grid-column($columns:$i, $collapse:null, $float:false); }
  }

  @for $i from 0 through $total-columns - 1 {
    .#{$size}-offset-#{$i} { @include grid-column($offset:$i, $collapse:null, $float:false); }
  }

  .#{$size}-reset-order {
    float: $default-float;
    left: auto;
    margin-#{$default-float}: 0;
    margin-#{$opposite-direction}: 0;
    right: auto;
  }

  .column.#{$size}-centered,
  .columns.#{$size}-centered { @include grid-column($center:true, $collapse:null, $float:false); }

  .column.#{$size}-uncentered,
  .columns.#{$size}-uncentered {
    float: $default-float;
    margin-#{$default-float}: 0;
    margin-#{$opposite-direction}: 0;
  }

  // Fighting [class*="column"] + [class*="column"]:last-child
  .column.#{$size}-centered:last-child,
  .columns.#{$size}-centered:last-child{
    float: none;
  }

  // Fighting .column.<previous-size>-centered:last-child
  .column.#{$size}-uncentered:last-child,
  .columns.#{$size}-uncentered:last-child {
    float: $default-float;
  }

  .column.#{$size}-uncentered.opposite,
  .columns.#{$size}-uncentered.opposite {
    float: $opposite-direction;
  }

  .row {
    &.#{$size}-collapse {
      > .column,
      > .columns { @include grid-column($collapse:true, $float:false); }

      .row {margin-left:0; margin-right:0;}
    }
    &.#{$size}-uncollapse {
      > .column,
      > .columns {
        @include grid-column;
      }
    }
  }
}

@mixin grid-flex-padding($gridpadding, $dir: false) {
	@if $dir == false {
		padding: $gridpadding;
	} @else {
		padding-#{$dir}: $gridpadding;
	}
}

@mixin grid-flex-border($type, $breakpoint) {
  @each $dir-key-vertical, $dir-val-vertical in $advgrid-simple-directions-vertical {
    @each $dir-key-horizontal, $dir-val-horizontal in $advgrid-simple-directions-horizontal{
      @each $size-key, $size-val in $advgrid-border-radius-sizes{
        &.#{$type}-#{$breakpoint}-br-#{$dir-val-vertical}-#{$dir-val-horizontal}-#{$size-key} {
          border-#{$dir-key-vertical}-#{$dir-key-horizontal}-radius: $size-val;
          overflow: hidden;
        }
      }
    }
  }

	@each $dir-key, $dir-val in $advgrid-simple-directions {
		@each $size-key, $size-val in $advgrid-border-sizes {
			&.#{$type}-#{$breakpoint}-bw-#{$dir-val}-#{$size-key} {
				border-#{$dir-key}-width: $size-val;
			}
		}

		@each $style-key, $style-value in $advgrid-border-style {
			&.#{$type}-#{$breakpoint}-bs-#{$dir-val}-#{$style-key} {
				border-#{$dir-key}-style: $style-value;
			}
		}
	}
}

@mixin grid-flex-column-size($columns: false) {
	@if $columns {
		width: grid-calc($columns, $total-columns);
	}
}

@mixin grid-flex-column-size-for-scroller($columns: false) {
	@if $columns {
		flex: 0 0 grid-calc($columns, $total-columns);
	}
}

@mixin grid-flex-column-guttergroup($breakpoint) {
	@each $pad-key, $pad-val in $advgrid-gutter-group-sizes {
		&.col-#{$breakpoint}-gut-#{$pad-key} {
			@include grid-flex-padding($pad-val);
		}
	}
}

@mixin grid-flex-column-paddinggroup($breakpoint) {
	@each $dir-key, $dir-val in $advgrid-simple-directions {
		@each $pad-key, $pad-val in $advgrid-padding-group-sizes {
			&.col-#{$breakpoint}-pad-#{$dir-val}-#{$pad-key} {
				> .col-con {
					@include grid-flex-padding($pad-val, $dir-key);
				}
			}
		}
	}
}

@mixin grid-flex-column-size-classes($breakpoint, $forScroller: false) {
	@for $i from 1 through $total-columns {
		&.col-#{$breakpoint}-#{$i} {

		@if $forScroller {
			@include grid-flex-column-size-for-scroller($columns: $i)
		}
		@else {
			@include grid-flex-column-size($columns: $i);
		}
	}

		@if $breakpoint != sm {
			&.col-#{$breakpoint}-o-#{$i} {
				order: $i;
			}
		}
	}
}


@mixin grid-flex-column-classes($breakpoint) {
	&.col-#{$breakpoint}-as-start {
		align-self: flex-start;
	}

	&.col-#{$breakpoint}-as-end {
		align-self: flex-end;
	}

	&.col-#{$breakpoint}-as-center {
		align-self: center;
	}

	&.col-#{$breakpoint}-as-stch {
		align-self: stretch;
	}

	&.col-#{$breakpoint}-ca-lt {
		text-align: left;
	}

	&.col-#{$breakpoint}-ca-rt {
		text-align: right;
	}

	&.col-#{$breakpoint}-ca-c {
		text-align: center;
	}
}

@mixin grid-flex-grid-guttergroup($breakpoint) {
	@each $pad-key, $pad-val in $advgrid-gutter-group-sizes {
		&.grid-#{$breakpoint}-col-gut-#{$pad-key} > adv-col,
        &.grid-#{$breakpoint}-col-gut-#{$pad-key} > adv-scrl > adv-slides > adv-col, {
			@include grid-flex-padding($pad-val);
		}
	}
}

@mixin grid-flex-grid-paddinggroup($breakpoint) {
	@each $k, $v in $advgrid-padding-group-sizes {
		&.grid-#{$breakpoint}-col-pad-#{$k} > adv-col > .col-con,
        &.grid-#{$breakpoint}-col-pad-#{$k} > adv-scrl > adv-slides > adv-col > .col-con{
			@include grid-flex-padding($v);
		}
	}
}

@mixin grid-flex-grid-scroller-classes($breakpoint) {
  &.scrl-#{$breakpoint}-dir-hoz > adv-slides {
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;

    > .scrl-btn {
        display: flex;
    }
  }

	// don't need this at small as none is the default.
	@if $breakpoint != sm {
		&.scrl-#{$breakpoint}-ali-no > adv-slides > adv-col {
			scroll-snap-align: none;
		}
	}

  &.scrl-#{$breakpoint}-ali-s > adv-slides > adv-col {
    scroll-snap-align: start;
  }

  &.scrl-#{$breakpoint}-ali-c > adv-slides > adv-col {
    scroll-snap-align: center;
  }

  &.scrl-#{$breakpoint}-ali-e > adv-slides > adv-col {
    scroll-snap-align: end;
  }

  &.scrl-#{$breakpoint}-pgn-vis-bar-hide  > adv-slides {
    // Firefox supported scrollbar
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &.scrl-#{$breakpoint}-pgn-vis-bar-show  > adv-slides::-webkit-scrollbar {
    height: $advgrid-scroller-trackbar-size-webkit;
  }

  &.scrl-#{$breakpoint}-btn-vis-hide > .scrl-btn {
    display: none !important; // sass-lint:disable-line no-important
  }

  &.scrl-#{$breakpoint}-btn-vis-show > .scrl-btn:not(.scrl-disabled) {
    display: inline-block !important; // sass-lint:disable-line no-important
  }

  &.scrl-#{$breakpoint}-dir-no {
    > adv-slides {
      overflow: hidden;
      scroll-padding: 0;
      scroll-snap-type: none;
      flex-wrap: wrap;
      //if we want to override to disable the scroller at a higher breakpoint
      //we need to override the min-width setting :(
      > adv-col {
        min-width: auto;
        scroll-snap-align: none;
      }
    }

    > .scrl-btn {
      display: none !important;
    }
  }
}

@mixin grid-flex-grid-classes($breakpoint) {
	&.grid-#{$breakpoint}-fw,
	&.grid-#{$breakpoint}-fw > adv-scrl > adv-slides {
		padding: 0;
	}

	&.grid-#{$breakpoint}-jc-end,
	&.grid-#{$breakpoint}-jc-end > adv-scrl > adv-slides {
		justify-content: flex-end;
	}

	&.grid-#{$breakpoint}-jc-center,
	&.grid-#{$breakpoint}-jc-center > adv-scrl > adv-slides {
	  justify-content: center;
	}

	&.grid-#{$breakpoint}-jc-between,
	&.grid-#{$breakpoint}-jc-between > adv-scrl > adv-slides {
		justify-content: space-between;
	}

	&.grid-#{$breakpoint}-jc-around,
	&.grid-#{$breakpoint}-jc-around > adv-scrl > adv-slides {
		justify-content: space-around;
	}

	&.grid-#{$breakpoint}-jc-evenly,
	&.grid-#{$breakpoint}-jc-evenly > adv-scrl > adv-slides {
		justify-content: space-evenly;
	}

	&.grid-#{$breakpoint}-jc-no,
	&.grid-#{$breakpoint}-jc-no > adv-scrl > adv-slides {
	  justify-content: initial;
	}

	&.grid-#{$breakpoint}-ai-top,
	&.grid-#{$breakpoint}-ai-top > adv-scrl > adv-slides {
		align-items: flex-start;
	}

	&.grid-#{$breakpoint}-ai-bottom,
	&.grid-#{$breakpoint}-ai-bottom > adv-scrl > adv-slides {
		align-items: flex-end;
	}

	&.grid-#{$breakpoint}-ai-center,
	&.grid-#{$breakpoint}-ai-center > adv-scrl > adv-slides {
		align-items: center;
	}

	&.grid-#{$breakpoint}-ai-stch,
	&.grid-#{$breakpoint}-ai-stch > adv-scrl > adv-slides {
		align-items: stretch;
	}

	&.grid-#{$breakpoint}-ca-lt,
	&.grid-#{$breakpoint}-ca-lt > adv-scrl > adv-slides {
		text-align: left;
	}

	&.grid-#{$breakpoint}-ca-rt,
	&.grid-#{$breakpoint}-ca-rt > adv-scrl > adv-slides {
		text-align: right;
	}

	&.grid-#{$breakpoint}-ca-c,
	&.grid-#{$breakpoint}-ca-c > adv-scrl > adv-slides {
		text-align: center;
	}
}

@include exports("grid") {
  @if $include-html-grid-classes {
    .row {
      @include grid-row;

      &.collapse {
         > .column,
         > .columns { @include grid-column($collapse:true, $float:false); }

        .row {margin-left:0; margin-right:0;}
      }

      .row { @include grid-row($behavior:nest);
        &.collapse { @include grid-row($behavior:nest-collapse); }
      }
    }

    .column,
    .columns { @include grid-column($columns:$total-columns); }

    .column,
    .columns {
      & + &:last-child {
        float: $last-child-float;
      }
      & + &.end {
        float: $default-float;
      }
    }

    @media #{$small-up} {
      @include grid-html-classes($size:small);
    }

    @media #{$medium-up} {
      @include grid-html-classes($size:medium);
      // Old push and pull classes
      @for $i from 0 through $total-columns - 1 {
        .push-#{$i} {
          @include grid-column($push:$i, $collapse:null, $float:false);
        }
        .pull-#{$i} {
          @include grid-column($pull:$i, $collapse:null, $float:false);
        }
      }
    }
    @media #{$large-up} {
      @include grid-html-classes($size:large);
      @for $i from 0 through $total-columns - 1 {
        .push-#{$i} {
          @include grid-column($push:$i, $collapse:null, $float:false);
        }
        .pull-#{$i} {
          @include grid-column($pull:$i, $collapse:null, $float:false);
        }
      }
    }
  }

  // GM-specific code to support advanced grid
  @if $include-html-grid-classes {
    adv-grid {
      display: flex;
      flex-wrap: wrap;
      padding: 0 $advgrid-page-gutter;

			&.legacy-grid-shim {
				&.grid-bg-transparent {
					background-color: $grid-bg-transparent;
				}
				&.grid-bg-color-two {
					background-color: $grid-bg-color-two;
				}
				&.grid-bg-color-three {
					background-color: $grid-bg-color-three;
				}
				&.grid-bg-color-four {
					background-color: $grid-bg-color-four;
				}
				&.grid-bg-color-five {
					background-color: $grid-bg-color-five;
				}
				&.grid-bg-color-six {
					background-color: $grid-bg-color-six;
				}
				&.legacy-grid-shim,
				.legacy-grid-shim.collapse > adv-col{
					padding-left:0;
					padding-right:0;
				}
				&.grid-sm-col-pad-lt-rt-no > adv-col > .col-con{
					padding-left:0 !important;
					padding-right:0 !important;
				}
				&.grid-sm-col-pad-up-dn-no > adv-col > .col-con{
					padding-top:0 !important;
					padding-bottom:0 !important;
				}
				> adv-col {
					> .col-con, .q-button.q-link {
						display: block;
					}
					&:last-of-type {
						margin-left: auto;
					}
				}
			}

			> adv-scrl {
        display: flex;
        position: relative;
        width: 100%;

				> .scrl-btn{
					&.scrl-disabled {
						display: none !important;  // sass-lint:disable-line no-important
					}
				}

        > adv-slides {
          display: flex;
          width: 100%;

          // Firefox supported scrollbar
          scrollbar-color: $advgrid-scroller-thumb-bg $advgrid-scroller-trackbar-bg;
          scrollbar-width: $advgrid-scroller-trackbar-size;

          &:hover{
            scrollbar-color: $advgrid-scroller-thumb-bg-hover $advgrid-scroller-trackbar-bg-hover;
          }

          // And everyone else
          &::-webkit-scrollbar {
            height: $advgrid-scroller-trackbar-size-webkit;
          }

          &::-webkit-scrollbar-track {
            background: $advgrid-scroller-trackbar-bg;
          }

          &::-webkit-scrollbar-thumb {
            background: $advgrid-scroller-thumb-bg;
            border: $advgrid-scroller-thumb-border;
            border-radius: $advgrid-scroller-thumb-radius;
            &:hover{
              background: $advgrid-scroller-thumb-bg-hover;
            }
          }
        }

        @media #{$small-up} {
          @include grid-flex-grid-scroller-classes(sm);

          &.scrl-sm-dir-no > adv-slides > adv-col {
            min-width:auto;
          }
        }

        @media #{$medium-up} {
          @include grid-flex-grid-scroller-classes(md);

          &.scrl-md-dir-no > adv-slides > adv-col {
            min-width:auto !important; // sass-lint:disable-line no-important
          }
        }

        @media #{$xlarge-up} {
          @include grid-flex-grid-scroller-classes(xl);

          &.scrl-xl-dir-no > adv-slides > adv-col {
            min-width:auto !important; // sass-lint:disable-line no-important
          }
        }
      }

      @media #{$small-up} {
        @include grid-flex-grid-classes(sm);
        @include grid-flex-grid-paddinggroup(sm);
        @include grid-flex-grid-guttergroup(sm);
        @include grid-flex-border(grid, sm);
      }

      @media #{$medium-up} {
        @include grid-flex-grid-classes(md);
        @include grid-flex-grid-paddinggroup(md);
        @include grid-flex-grid-guttergroup(md);
        @include grid-flex-border(grid, md);
      }

      @media #{$xlarge-up} {
        @include grid-flex-grid-classes(xl);
        @include grid-flex-grid-paddinggroup(xl);
        @include grid-flex-grid-guttergroup(xl);
        @include grid-flex-border(grid, xl);
      }

      > adv-col,
      > adv-scrl > adv-slides > adv-col{
        padding: 0 ($column-gutter / 2);
        -webkit-overflow-scrolling: touch;

        > .col-con {
          height: 100%;
          position: relative;
        }

        @media #{$small-up} {
          @include grid-flex-column-classes(sm);
          @include grid-flex-column-paddinggroup(sm);
          @include grid-flex-column-guttergroup(sm);
          @include grid-flex-border(col, sm);
        }

        @media #{$medium-up} {
          @include grid-flex-column-classes(md);
          @include grid-flex-column-paddinggroup(md);
          @include grid-flex-column-guttergroup(md);
          @include grid-flex-border(col, md);
        }

        @media #{$xlarge-up} {
          @include grid-flex-column-classes(xl);
          @include grid-flex-column-paddinggroup(xl);
          @include grid-flex-column-guttergroup(xl);
          @include grid-flex-border(col, xl);
        }
      }

		> adv-col {
			@media #{$small-up} {
				@include grid-flex-column-size-classes(sm);
			}

			@media #{$medium-up} {
				@include grid-flex-column-size-classes(md);
			}

			@media #{$xlarge-up} {
				@include grid-flex-column-size-classes(xl);
			}
		}

		> adv-scrl > adv-slides > adv-col {
			overflow: hidden;

			@media #{$small-up} {
				@include grid-flex-column-size-classes(sm, true);
			}

			@media #{$medium-up} {
				@include grid-flex-column-size-classes(md, true);
			}

			@media #{$xlarge-up} {
				@include grid-flex-column-size-classes(xl, true);
			}
		}
    }
  }

  @if $include-xl-html-grid-classes {
    @media #{$xlarge-up} {
      @include grid-html-classes($size:xlarge);
    }
    @media #{$xxlarge-up} {
      @include grid-html-classes($size:xxlarge);
    }
  }
}
