// This file is automatically generated. Any modification should be done to "{{brandUi}}/scss/main.scss.mustache" instead.

// Vendor specific SCSS files
@import 'vendor';
@import '../../../../solar/frontend/solar-aem-ui/scss/functions';
@import 'variables';
@import 'settings';

// Setting Up Foundation
@import '../../../../solar/frontend/solar-aem-ui/scss/foundation';

// Everything below this is Application Specific

// Import Global Brand SCSS not covered in Foundation 5
// This would include styles that are used globally throughout the site
@import 'global';

// Import all icons
@import '../../../../solar/frontend/solar-aem-ui/scss/icons';
@import 'icons';
@import '../../../../solar/frontend/solar-aem-ui/scss/icons_extend';

// Import Common Brand Specific Pattern SCSS
// Patterns are reused throughout the rest of the components
// These are brand specific and are used to cover areas that Foundation 5 does not cover
/* inject:patterns */
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/animations';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/disclosure';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/font_styles';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/fonts';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/footer';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/gbds_dealer_locator';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/layout';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/navigation';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/responsive';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/typography';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/vehicle_info';
@import '../../../../solar/frontend/solar-aem-ui/scss/patterns/visibility';
@import 'patterns/colors';
@import 'patterns/font_styles';
@import 'patterns/fonts';
@import 'patterns/footer';
@import 'patterns/responsive';
@import 'patterns/typography';
@import 'patterns/vehicle_info';
/* endinject */


// Import SCSS for AEM Components
/* inject:components */
@import '../../../../solar/frontend/solar-aem-ui/components/content/button/button';
@import '../../../../solar/frontend/solar-aem-ui/components/content/button/placeholders';
@import '../../../../solar/frontend/solar-aem-ui/components/content/content_overlay/content_overlay';
@import '../../../../solar/frontend/solar-aem-ui/components/content/disclosure_modal_toast/disclosure_modal_toast';
@import '../../../../solar/frontend/solar-aem-ui/components/content/expander/expander';
@import '../../../../solar/frontend/solar-aem-ui/components/content/headline/headline';
@import '../../../../solar/frontend/solar-aem-ui/components/content/legal_notification/legal_notification';
@import '../../../../solar/frontend/solar-aem-ui/components/content/progress_bar/progress_bar';
@import '../../../../solar/frontend/solar-aem-ui/components/content/rep_scroller/rep_scroller';
@import '../../../../solar/frontend/solar-aem-ui/components/content/rte_launch_gallery/rte_launch_gallery';
@import '../../../../solar/frontend/solar-aem-ui/components/content/rte_launch_modal/rte_launch_modal';
@import '../../../../solar/frontend/solar-aem-ui/components/content/separator/separator';
@import '../../../../solar/frontend/solar-aem-ui/components/content/sticky_notification/sticky_notification';
@import '../../../../solar/frontend/solar-aem-ui/components/content/vertical_spacing/vertical_spacing';
@import '../../../../solar/frontend/solar-aem-ui/components/content/wrapper/wrapper';
@import '../../../../solar/frontend/solar-aem-ui/components/media/gallery_layer/gallery_layer';
@import '../../../../solar/frontend/solar-aem-ui/components/media/gallery_preview/gallery_preview';
@import '../../../../solar/frontend/solar-aem-ui/components/media/image_svg/image_svg';
@import '../../../../solar/frontend/solar-aem-ui/components/media/multimedia_scroller/multimedia_scroller';
@import '../../../../solar/frontend/solar-aem-ui/components/media/responsive_image/responsive_image';
@import '../../../../solar/frontend/solar-aem-ui/components/media/slide/slide';
@import '../../../../solar/frontend/solar-aem-ui/components/media/static_image/static_image';
@import '../../../../solar/frontend/solar-aem-ui/components/navigation/nav_primary/nav_primary';
@import '../../../../solar/frontend/solar-aem-ui/components/navigation/nav_secondary/nav_secondary';
@import '../../../../solar/frontend/solar-aem-ui/components/navigation/nav_segment/nav_segment';
@import '../../../../solar/frontend/solar-aem-ui/components/navigation/tab_nav/tab_nav';
@import '../../../../solar/frontend/solar-aem-ui/components/vehicle/vehicle_info_row/vehicle_info_row';
@import '../../../../solar/frontend/solar-aem-ui/components/vehicle/vehicle_selector/vehicle_selector';
@import '../components/content/button/button';
@import '../components/content/disclosure_modal_toast/disclosure_modal_toast';
@import '../components/content/expander/expander';
@import '../components/content/headline/headline';
@import '../components/content/legal_notification/legal_notification';
@import '../components/content/progress_bar/progress_bar';
@import '../components/content/sticky_notification/sticky_notification';
@import '../components/content/wrapper/wrapper';
@import '../components/media/gallery_layer/gallery_layer';
@import '../components/media/multimedia_scroller/multimedia_scroller';
@import '../components/media/responsive_image/responsive_image';
@import '../components/navigation/nav_primary/nav_primary';
@import '../components/navigation/nav_secondary/nav_secondary';
@import '../components/navigation/nav_segment/nav_segment';
@import '../components/navigation/tab_nav/tab_nav';
@import '../components/vehicle/trailering_towing_search_results/trailering_towing_search_results';
@import '../components/vehicle/trailering_towing_vehicle_capability_tile/trailering_towing_vehicle_capability_tile';
@import '../components/vehicle/trailering_towing_vin_form/trailering_towing_vin_form';
/* endinject */

// Import Print-specific styles.  Added at bottom to override any styles in component code
@import 'print';
