// Expander styles
$expander-content-selector: '> .q-expander-content, > div > .q-expander-content';
$expander-button-selector: '> .q-expander-button, > div > .q-expander-button';

.q-expander {
	@include expander-icon($expander-icon-medium-up);

	margin-bottom: $expander-margin;

	#{$expander-button-selector} {
		@if $expander-toggle-animation {
			@include hard-easeout(background, $expander-button-anim-speed);
		}

		background-color: $expander-background-color;
		border: $expander-border;
		padding: $expander-button-padding;
		position: relative;
		text-align: left;

		&::before {
			background: get-element($expander-icon-plus);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			content: '';
			display: block;
			margin-left: $expander-icon-margin;
			margin-right: $expander-icon-margin;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			background-color: $expander-background-active;
			border: $expander-border-active;
			cursor: pointer;

			&::before {
				background: get-element($expander-icon-plus-hover);
				background-size: 100% 100%;
			}
		}

		.q-button-text {
			color: $expander-button-font-color;
			font-family: $expander-button-font-family;
			font-size: $expander-button-font-size;
			font-weight: $expander-button-font-weight;
			letter-spacing: $expander-button-letter-spacing;
			line-height: $expander-button-line-height;
			margin: 0;
		}

		.q-headline-text {
			margin-left: 2.813rem;
		}

		&.hide-icon {
			&::before {
				content: none !important; // sass-lint:disable-line no-important
			}

			.q-headline-text {
				margin-left: 0;
			}
		}
	}

	#{$expander-content-selector} {
		@include gmwp-transition(height, $expander-expand-anim-speed, linear);

		height: 0;
		overflow: hidden;
	}

	&:active:focus {
		outline: none;
	}

	&.active,
	&.open {
		#{$expander-content-selector} {
			@include gmwp-transition(height, $expander-collapse-anim-speed, linear);

			height: auto;
		}
	}

	&.q-show-border {
		#{$expander-button-selector} {
			border-bottom: 0;
			border-top: 0;
		}

		&.active,
		&.open {
			#{$expander-button-selector} {
				border-bottom: $expander-border;

				&.q-button-b {
					border-bottom: 0;
					border-top: $expander-border;
				}
			}
		}
	}

	.grid-padding-small,
	.grid-padding-medium,
	.grid-padding-large & {
		margin-left: 0;
		margin-right: 0;
	}

	&.q-button-active {
		#{$expander-button-selector} {
			background-color: $expander-background-active;
			border: $expander-border-active;

			&::before {
				background: get-element($expander-icon-minus);
				background-size: 100% 100%;
			}
		}
	}

	.collapse & {
		@media #{$medium-down} {
			margin-left: -.0625rem;
			margin-left: -2.5vw;
			margin-right: -.0625rem;
			margin-right: -2.5vw;
		}
	}

	.grid-padding-small,
	.grid-padding-medium,
	.grid-padding-large & {
		@media #{$medium-down} {
			&.q-show-border,
			#{$expander-button-selector} {
				border-left: $expander-border;
				border-right: $expander-border;
			}
		}
	}

	@media #{$medium-up} {
		&.q-show-border {
			#{$expander-button-selector} {
				border: $expander-border;
			}
		}
	}

	@media #{$large-down} {
		@include expander-icon($expander-icon-medium-down);

		&.active {
			#{$expander-button-selector} {
				border-left: 0;
				border-right: 0;
			}
		}

		#{$expander-button-selector} {
			border-left: 0;
			border-right: 0;

			&:hover {
				border-left: 0;
				border-right: 0;
			}

			&::before {
				right: $expander-icon-right;
			}

			.q-headline-text {
				margin-left: 0;
				margin-right: $expander-margin-right;
			}
		}
	}

	@each $key, $value in $media-query-map {
		@media #{$value} {
			&.q-disabled-#{$key} {
				@include expander-disable;
			}
		}
	}
}
