// DISCLAIMER - REMOVE BEFORE COMPLETING CODE
//
// This file should only include documentation and SCSS for this specific component
// Any global patterns should be created in /sass/patterns/ and overwritten/extended here
//
// DISCLAIMER - REMOVE BEFORE COMPLETING CODE

// ResponsiveImage
//
// This is the documentation for ResponsiveImage. Edit responsiveImage.scss to update this documentation.
//
// markup:
// <p>Sample markup for ResponsiveImage. Replace me with the real deal once you are done editing <strong>responsiveImage.html</strong> and <em>optionally</em> the responsiveImage.js file.</p>
//
// Styleguide 99.99

.q-responsive-image {
	position: relative;
	width: 100%;

	.q-responsive-image-container {
		overflow: hidden;
	}

	.q-round-image-responsive {
		@extend %crop-circle;
	}

	.q-rectangle-image-responsive {
		height: auto;
		margin: 0;
		padding: 0;
		position: relative;
		width: 100%;

		&.q-center-transform {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}

		&.q-full-height {
			height: 100%;
			max-width: none;
			width: auto;

			&.q-center-transform {
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
		}
	}

	.q-fadeout {
		background-color: $black;
		opacity: .4;
	}

	.q-space {
		flex-shrink: 0;
		position: relative;
	}

	.q-index {
		z-index: -1;
	}

	.q-responsive-image-disclosure {
		bottom: 0;
		position: absolute;
		width: 100%;
		z-index: 2;

		.q-responsive-image-disclosure-text {
			position: absolute;

			&.q-responsive-image-disclosure-text-left {
				bottom: $responsive-image-disclosure-bottom-padding;
				left: $responsive-image-disclosure-side-padding;
				padding-right: $responsive-image-disclosure-side-padding;
				text-align: left;
			}

			&.q-responsive-image-disclosure-text-center {
				bottom: $responsive-image-disclosure-bottom-padding;
				left: 50%;
				text-align: center;
				transform: translateX(-50%);
				width: 100%;
			}

			&.q-responsive-image-disclosure-text-right {
				bottom: $responsive-image-disclosure-bottom-padding;
				padding-left: $responsive-image-disclosure-side-padding;
				right: $responsive-image-disclosure-side-padding;
				text-align: right;
			}
		}
	}
}


@media #{$xlarge-up} {
	.q-multimedia-scroller {
		[data-show-contentbar='true'] {
			.q-responsive-image {
				.q-responsive-image-disclosure {
					.q-responsive-image-disclosure-text {
						padding-bottom: $slick-semi-opaque-bar-height;
					}
				}
			}
		}

		[data-show-contentbar='false'] {
			.q-responsive-image {
				.q-responsive-image-disclosure {
					.q-responsive-image-disclosure-text {
						padding-bottom: $responsive-image-disclosure-mms-dots-offset;
					}
				}
			}
		}
	}
}
