.q-trailering-towing-form {
	.q-vin-form-box {
		padding: 2.66rem 1.33rem;
	}

	.q-input {
		background-color: $white;
		border: 1px solid $grey-mid;
		font-size: 1.125rem;
		height: 3.33rem;
		line-height: 1.875rem;
		padding-left: 2rem;
		transition: border .25s;
		transition-timing-function: cubic-bezier(0, 1, .5, 1);
	}


	.error {
		color: $red;
	}

	.q-hide {
		display: none;
		height: 0;
	}

	.q-submit-box {
		display: flex;
		justify-content: flex-end;
	}

	.q-button {
		flex: 0 1 100%;
		min-width: 200px;
	}

	@media #{$large-up} {
		.q-button {
			flex: 0 0 50%;
		}
	}

	@media #{$xlarge-up} {
		.q-button {
			flex: 0 0 25%;
		}
	}
}
