// Content Overlay styles

$content-overlay-gradient-positions: ('bottom', 'top') !default;

$content-overlay-gradient-position-direction-map: (
	bottom: to top,
	top: to bottom
);

$content-overlay-gradient-colors: (
	'neutral': ($content-overlay-gradient-neutral-one, $content-overlay-gradient-neutral-two),
	'warm': ($content-overlay-gradient-warm-one, $content-overlay-gradient-warm-two),
	'cool': ($content-overlay-gradient-cool-one, $content-overlay-gradient-cool-two),
	'white': ($content-overlay-gradient-white-one, $content-overlay-gradient-white-two)
) !default;

$content-overlay-breakpoint-alignments: (
	'xs': ($small-only, xsmall),
	's': ($medium-only, small),
	'm': ($large-only, medium),
	'l': ($xlarge-only, large),
	'xl': ($xxlarge-up, xlarge),
) !default;

%content-overlay-gradient-generic {
	content: '';
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 1;
};

.q-content-overlay-container {
	position: relative;
}

.q-overlay {
	// Generate each selector for classes with 'position' and 'gradient'
	@each $position in $content-overlay-gradient-positions {
		@each $color-name, $gradient in $content-overlay-gradient-colors {
			&.q-content-overlay-gradient-generic-type-on-#{$position}-#{$color-name} {
				.q-static-image-container,
				.q-responsive-image-container {
					&::before {
						@extend %content-overlay-gradient-generic;
						background-image: linear-gradient(map-get($content-overlay-gradient-position-direction-map, #{$position}),
							nth($gradient, 1), nth($gradient, 2) 50%);
					}
				}
			}
		}
	}
}

.q-content-overlay {
	/* Adds padding to first grid nested within content overlay */
	> .q-gridbuilder {
		@include grid-right-gutter;
		@include grid-left-gutter;
	}

	&.q-layer,
	.q-layer {
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 2;

		.q-layer-width {
			width: 100%;
		}

		.q-layer-height {
			height: 100%;
		}
	}

	.content-overlay-bg-color-one {
		background-color: $content-overlay-bg-color-one;
	}

	.content-overlay-bg-color-two {
		background-color: $content-overlay-bg-color-two;
	}

	.content-overlay-bg-color-three {
		background-color: $content-overlay-bg-color-three;
	}

	.content-overlay-bg-color-four {
		background-color: $content-overlay-bg-color-four;
	}
}

@each $postfix, $sizes in $content-overlay-breakpoint-alignments {
	$media: nth($sizes, 1);
	$breakpoint: nth($sizes, 2);

	// Generate each selector for the content overlay alignment
	// against each media query
	@media #{$media} {
		@if not index('l' 'xl', $postfix) {
			.q-overlay-below-#{$postfix} {
				display: flex;
				flex-direction: column-reverse;
				position: relative;

				.q-content-overlay {
					&.q-layer,
					.q-layer {
						position: relative;
					}
				}
			}
		}

		.q-content-overlay {
			&.q-left-#{$postfix},
			&.q-right-#{$postfix},
			&.q-top-#{$postfix} {
				top: 0;
				width: 100%;
			}

			&.q-left-#{$postfix} {
				@include content-overlay-set-leftalignment-width-for-breakpoint($breakpoint);
			}

			&.q-right-#{$postfix} {
				@include content-overlay-set-rightalignment-width-for-breakpoint($breakpoint);
			}

			&.q-bottom-#{$postfix} {
				> div {
					bottom: 0;
					position: absolute;
					width: 100%;
				}
			}

			&.q-above-#{$postfix} {
				display: flex;
				position: relative;
			}
		}
	}
}
