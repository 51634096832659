$gray-dark: #262626;
$blue-darkest: #006295;
$brand-blue: #007dbe;
$error-red: #b50000;
$grey-almost: #cccccc;
$grey-extra-light: #dddddd;
$grey-medium: #656565;
$off-white: #fbfbfb;
$success-green: #0a7305;
$black: #000000;
$grey-very-dark: #1a1a1a;
$grey-mid-dark: #6e6e6e;
$grey: #808080;
$grey-mid: #b3b3b3;
$grey-light: #e6e6e6;
$grey-very-light: #f2f2f2;
$white: #ffffff;
$gold: #cd9834;
$gold-light: #e6ac39;
$red: #e25959;
$green: #61af71;
