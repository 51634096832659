@import '../../../../solar/frontend/solar-aem-ui/scss/global';

// Color classes for RTE Text -- DO NOT USE ANYWHERE ELSE
.q-text-color-gold {
	color: $gold;
}

.q-text-color-white {
	color: $white;
}

// LTR class for RTE Text in Middle East -- DO NOT USE ANYWHERE ELSE
.q-text-ltr {
	direction: ltr;
	display: inline-block;
}

// Per the Chevy styleguide, at XS, the XL bottom margin goes from 120px to 80px
// http://prototypes.mrm-projects.com/quantum/styleguide.html#!%2Fpage%3D%22sg_grid_layout%22
.row.gridspacing-xlarge,
.row.collapse.gridspacing-xlarge  {

	@media #{$small-only} {
		margin-bottom: 5rem;
	}
}

// Apply hover effect (zoom in) to static and responsive images
// If proper class (q-img-hover) is applied to each component
.no-touch {
	.q-static-image .q-img-hover,
	.q-responsive-image.q-img-hover {
		display: block;
		overflow: hidden;
		position: relative;

		img {
			transition: #{$anim-slow}s ease;

			&:hover {
				cursor: pointer;
				transform: $gp-img-transform-scale;
			}
		}
	}
}

html {
	@media #{$xlarge-up} {
		font-size: $base-font-size-m;
	}

	@media #{$xxlarge-up} {
		font-size: $base-font-size-xl;
	}
}
