@import 'placeholders';

// Button
//
// This is the documentation for Button. Edit button.scss to update this documentation.
//
// Styleguide REV: 2016-04-14
//IE Prevent button from shifting on active/click

// Button Styling
.q-button {
	@extend %button;

	cursor: pointer;

	&:active div,
	&:focus div {
		left: 0;
		position: relative;
		top: 0;
	}

	// Primary and Secondary (Main) Buttons
	&.q-primary-button {
		@extend %q-primary-button;
	}

	&.q-secondary-button {
		@extend %q-secondary-button;
	}

	&.q-quaternary-button {
		@extend %q-quaternary-button;
	}

	// Links and Icon Buttons
	&.q-link,
	&.q-icon-only {
		@extend %button-no-border;
	}

	&.q-link {
		font-size: $button-q-link-font-size;
		letter-spacing: $button-q-link-letter-spacing;
		line-height: $button-q-link-line-height;
		padding: $button-q-link-padding;

		&:hover,
		&:active,
		&:focus {
			background-color: $button-q-link-background-color-hover;
		}

		&.gb-target-zone-button-active {
			color: $button-q-link-text-hover;
		}

		&.q-invert {
			color: $button-q-link-q-invert-color;

			&.gb-target-zone-button-active {
				color: $button-q-link-q-invert-text-hover;
			}
		}
	}

	// Stacked and Inline Icon Buttons
	&.q-icon-stacked,
	&.q-inline-icon {
		@include button-border($button-icon-inline-border-primary-color);
	}

	&.q-icon-stacked-secondary,
	&.q-inline-icon-secondary {
		@include button-border($button-icon-inline-border-secondary-border-color, $color: $button-icon-inline-border-secondary-color);

		// Inverted Styling
		&.q-invert {
			border-color: $button-icon-inline-border-secondary-invert-border-color;
			color: $button-icon-inline-border-secondary-invert-color;
		}
	}

	&.q-inline-icon-quaternary {
		@extend %q-quaternary-button;

		text-align: start;

		.q-shift-icon {
			.move-icon {
				flex: 0 0 auto;
				margin-inline-end: $button-icon-inline-quaternary-icon-margin;
			}
		}
	}

	// List Item Buttons
	&.q-list-item {
		@extend %q-list-item;
	}

	// SVG Icons
	@for $i from 1 through length($icon-size) {
		.q-svg-icon-#{nth($icon-size, $i)} {
			@include svg-icon-size(nth($icon-size-value, $i));
			margin: auto;
		}
	}

	&.q-icon-stacked,
	&.q-icon-stacked-secondary {
		.q-svg-icon {
			@include svg-icon-size($button-icon-size-stacked, $large-up, $button-icon-size-stacked-large);

			margin-bottom: $button-icon-margin-bottom;
		}

		.icon-font {
			font-size: $button-icon-size-stacked;
			margin-bottom: $button-icon-margin-bottom;

			@media #{$large-up} {
				font-size: $button-icon-size-stacked-large;
			}
		}
	}

	.icon-font {
		font-size: $button-icon-size;
	}

	.q-svg-icon {
		@include svg-icon-size($button-icon-size, $large-up, $button-icon-size-large);

		svg {
			height: 100%;
			width: 100%;
		}
	}

	.q-shift-icon {
		align-items: center;
		display: inline-flex;

		&.q-icon-right {
			.move-text {
				margin-right: $button-icon-margin;
				order: 1;
			}

			.move-icon {
				order: 2;
			}
		}

		&.q-icon-left {
			.move-text {
				order: 2;
			}

			.move-icon {
				margin-right: $button-icon-margin;
				order: 1;
			}
		}
	}

	// Inverted Styling
	&.q-invert {
		@include icon-fill($button-primary-invert-color, $button-primary-invert-color, false);
		color: $white; // FIXME - WTF?
	}

	&.hoverable-icon {
		@include icon-hover(inline, none);

		&:active,
		&:hover,
		&:focus {
			@include icon-hover(none, inline);
		}

		&.gb-target-zone-button-active {
			@include icon-active(none, none, inline);
		}
	}

	.columns > & {
		&.q-link,
		&.q-icon-only {
			display: block;
		}
	}

	&.gb-target-zone-button-active {
		color: $button-hover-color;
	}
}

// Touch Styles
.q-button {
	.no-touch & {
		&:active,
		&:hover,
		&:focus {
			color: $button-hover-color;
		}

		// Primary and Secondary (Main) Buttons
		&.q-primary-button {
			@extend %q-primary-button-touch
		}

		&.q-secondary-button {
			@extend %q-secondary-button-touch;
		}

		&.q-quaternary-button {
			@extend %q-quaternary-button-touch;
		}

		&.q-link,
		&.q-icon-only {
			&:active,
			&:hover,
			&:focus {
				color: $button-q-link-text-hover;
			}

			&.q-invert {
				&:active,
				&:hover,
				&:focus {
					color: $button-q-link-q-invert-text-hover;
				}
			}
		}

		// Stacked and Inline Icon Buttons
		&.q-icon-stacked,
		&.q-inline-icon {
			@include button-hover($button-icon-inline-border-primary-color, $button-icon-inline-border-fill, $fill-color: $button-icon-inline-border-fill-color, $box-shadow: $button-icon-inline-border-box-shadow);

			// Inverted Styling
			&.q-invert {
				&:active,
				&:hover,
				&:focus {
					color: $button-primary-invert-hover-color;
				}
			}
		}

		&.q-icon-stacked-secondary,
		&.q-inline-icon-secondary {
			@include button-hover($button-icon-inline-border-secondary-border-color, $button-icon-inline-border-secondary-fill, $fill-color: $button-icon-inline-border-secondary-fill-color, $hover-color: $button-icon-inline-border-secondary-hover-color, $box-shadow: $button-icon-inline-border-secondary-box-shadow);

			// Inverted Styling
			&.q-invert {
				&:active,
				&:hover,
				&:focus {
					background-color: $button-icon-inline-border-secondary-invert-hover-background-color;
					border-color: $button-icon-inline-border-secondary-invert-hover-border-color;
					color: $button-icon-inline-border-secondary-invert-hover-color;
				}
			}
		}

		&.q-inline-icon-quaternary {
			@extend %q-quaternary-button-touch;
		}

		// List Item Buttons
		&.q-list-item {
			&.q-button-padding {
				&:active,
				&:hover {
					@include hard-easeout(border-color, $anim-fast);
					border-left-color: $button-list-border-left-color;
				}
			}

			&.q-list-item-primary,
			&.q-list-item-icon-primary {
				@extend %q-list-item-primary-no-touch;
			}

			&.q-list-item-secondary,
			&.q-list-item-icon-secondary {
				&:hover {
					background-color: $button-list-secondary-hover-bg-color;
					border-left-color: $button-list-secondary-hover-left-bg-color;
				}
			}

			&.q-list-item-tertiary,
			&.q-list-item-icon-tertiary {
				@include hard-easeout(border-color, $button-list-item-tertiary-anim-speed);

				&:active,
				&:focus,
				&:hover {
					border-left-color: $button-list-item-tertiary-hover-left-bg-color;
					color: $button-list-item-tertiary-hover-color;
				}
			}

			&.q-tertiary-nav-button {
				@extend %tertiary-nav-button;
			}
		}
	}
}
