.q-gallery-preview {
	width: 100%;

	.row {
		display: flex;
		flex-wrap: wrap;
		float: left;
		// Used to override foundation specific styling for grid
		max-width: 100% !important; // sass-lint:disable-line no-important
		width: 100% !important; // sass-lint:disable-line no-important

		&.collapse {
			// Used to override foundation specific styling for grid
			max-width: 100% !important; // sass-lint:disable-line no-important
			width: 100% !important; // sass-lint:disable-line no-important

			&::before,
			&::after {
				// Used to override foundation specific styling for grid
				display: flex !important; // sass-lint:disable-line no-important
			}
		}
	}

	.row {
		&.collapse {
			& > .columns {
				display: flex;
				flex: 0 0 $gp-half-column-width;
			}
		}
	}

	.q-gallery-item-container {
		display: flex;
		flex: 0 0 100%;
		height: 100%;
	}

	.columns {
		padding: 0;
	}

	& > .row {
		max-width: 100%;
	}

	.q-gallery-item {
		background-color: $gp-item-bg-color;
		display: flex;
		float: left;
		width: 100%;

		.small-margin,
		.medium-margin,
		.large-margin {
			margin-bottom: 0;
		}

		.q-responsive-image {
			overflow: hidden;
			position: relative;
			text-align: center;
			transition: #{$anim-slow}s ease;
			width: 100%;

			img {
				border: $gp-img-border-settings;
				transition: #{$anim-slow}s ease;
			}

			.q-gallery-video-icon {
				background: get-element(video-play-button) no-repeat;
				background-size: contain;
				border: 0;
				bottom: 0;
				height: map-get($gp-play-icon-map, small);
				left: 0;
				margin: auto;
				// Matches style guide sizing for play button
				opacity: .75;
				position: absolute;
				right: 0;
				top: 0;
				width: map-get($gp-play-icon-map, small);
			}
		}

		.q-responsive-content {
			align-items: center;
			border: $gp-img-border-settings;
			color: $gp-item-content-color;
			display: flex;
			text-align: center;
			width: 100%;

			img {
				margin-bottom: 10px;
			}

			.svg-icon-gallery {
				width: 100%;
			}

			.svg-icon-gallery,
			.q-gallery-label {
				@include font-settings(
					$family: $font-family-secondary,
					$size: map-get($gp-gallery-label-font-size-map, small),
					$weight: $gp-gallery-label-font-weight
				);
				display: inline-block;
				letter-spacing: $gp-gallery-label-letter-spacing;
				margin-bottom: 0;
				padding: .1875rem 0 .3125rem;
				text-align: center;
				text-transform: $gp-gallery-label-text-transform;
			}

			.q-gallery-count {
				@include font-settings(
					$color: $gp-last-child-color,
					$family: $font-family-secondary,
					$size: map-get($gp-gallery-count-font-size-map, small),
					$weight: $gp-gallery-count-font-weight
				);
				display: map-get($gp-gallery-count-display-map, small);
				letter-spacing: $gp-gallery-label-letter-spacing;
			}
		}

		.q-responsive-content-table {
			display: inline-block;
			vertical-align: middle;
			width: 100%;

			img {
				width: 2.5rem;
			}
		}
	}

	.row {
		& > .columns {
			&:last-child {
				float: left;

				.q-gallery-item {
					background-color: $gp-last-child-bg-color;
				}
			}
	 	}
	}

	@media #{$medium-up} {
		.q-gallery-item {
			.q-responsive-content {
				.q-gallery-count {
					display: map-get($gp-gallery-count-display-map, medium);
					font-size: map-get($gp-gallery-count-font-size-map, medium);
					padding: map-get($gp-gallery-count-padding-map, medium);
				}

				.q-responsive-content-table {
					.svg-icon-gallery,
					.q-gallery-label {
						@include font-settings(
							$family: $font-family-secondary,
							$size: map-get($gp-gallery-label-font-size-map, small),
							$weight: $gp-gallery-label-font-weight
						);
						margin-bottom: .6875rem;
						padding: .1875rem 0 .3125rem;
					}
				}

				&:hover {
					.q-gallery-label {
						border-bottom: $gp-label-underline;
						margin-bottom: $gp-label-margin-bottom;
						padding: $gp-label-padding-bottom;
					}
				}
			}
		}
	}

	@media #{$large-up} {
		&.focus {
			.q-gallery-item {
				.q-responsive-image {
					@include hard-easeinout($gp-responsive-img-transition, $gp-responsive-img-duration);
					opacity: $gp-responsive-img-opacity-all;
				}

				&:focus {
					.q-responsive-image {
						opacity: $gp-responsive-img-opacity-single;
					}
			}
		}
	 }

		.q-gallery-item {
			&:focus {
				.q-responsive-image {
					opacity: $gp-responsive-img-opacity-single;

					img {
						cursor: pointer;
						transform: $gp-img-transform-scale;
					}
				}

				.q-responsive-content {
					.q-gallery-label {
						border-bottom: $gp-label-underline;
						margin-bottom: $gp-label-margin-bottom;
						padding: $gp-label-padding-bottom;
					}
				}
			}

			.q-responsive-content {
				.q-gallery-count {
					display: map-get($gp-gallery-count-display-map, large);
					font-size: map-get($gp-gallery-count-font-size-map, large);
					text-align: center;
					width: 100%;
				}

				.q-responsive-content-table {
					.svg-icon-gallery,
					.q-gallery-label {
						font-size: map-get($gp-gallery-label-font-size-map, large);
					}
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.row {
			&.collapse {
				& > .columns {
					flex: 0 0 $gp-third-column-width;
				}
			}
		}

		.q-gallery-item {
			.q-responsive-image {
				.q-gallery-video-icon {
					height: map-get($gp-play-icon-map, large);
					width: map-get($gp-play-icon-map, large);
				}
			}
		}
	}
}

.q-body-gallery-preview {
	inset-block-start: 0;
	inset-inline-start: 0;
	overflow: hidden;
	position: fixed;
	width: 100%;
}

// Touch Styles
.q-gallery-preview {
	.no-touch & {
		.q-responsive-image {
			&:hover {
				img {
					cursor: pointer;
					transform: $gp-img-transform-scale;
				}
			}

			&:hover {
				.q-gallery-video-icon {
					opacity: 1;
					transform: translateZ(0) scale(1);
				}
			}
		}

		.q-responsive-content {
			&:hover {
				background-color: $black;
				cursor: pointer;

				.q-gallery-label {
					border-bottom: $gp-label-underline;
					margin-bottom: -.125rem;
					padding-bottom: .3125rem;
				}
			}
		}

		.row {
			& > .columns {
				&:last-child {
					&:hover,
					&:focus-within {
						.q-gallery-count {
							color: $gp-gallery-count-color;
						}
					}
				}
		 	}
		}

		&:hover {
			.q-responsive-image {
				@include hard-easeinout($gp-responsive-img-transition, $gp-responsive-img-duration);
				opacity: $gp-responsive-img-opacity-all;

				&:hover {
					opacity: $gp-responsive-img-opacity-single;
				}
			}
		}

		@media #{$medium-up} {
			.q-gallery-item {
				.q-responsive-content {
					&:hover {
						.q-gallery-label {
							border-bottom: $gp-label-underline;
							margin-bottom: $gp-label-margin-bottom;
							padding: $gp-label-padding-bottom;
						}
					}
				}
			}
		}
	}
}

// sass-lint:disable no-qualifying-elements
html[dir='rtl'] {
	.q-gallery-preview {
		.row,
		.q-gallery-item {
			float: right;

			.q-responsive-content {
				.q-responsive-content-table {
					.q-gallery-count,
					.q-gallery-label {
						letter-spacing: $arabic-letter-spacing;
					}
				}
			}
		}

		& > .columns {

			&:last-child {
				float: right;
			}
		}
	}
}
// sass-lint:enable no-qualifying-elements
