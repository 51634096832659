// This is namespaced to prevent collision - Mobile Styles go Here
.q-gallery-layer {
	background-color: $gl-background-color;

	.edit-mms {
		display: none;
	}

	.q-filmstrip {
		.slick-list,
		.slick-slide {
			margin: 0;
		}

		.ui-control {
			border: 0;
		}
	}

	.q-multimedia-scroller {
		.slick-prev,
		.slick-next {
			border-radius: $gl-slider-arrow-border-radius;
			height: map-get($gl-slider-arrow-height-map, small);
			padding: $gl-slider-arrow-padding;
			width: map-get($gl-slider-arrow-width-map, small);

			&::before {
				background-position: $gl-slider-arrow-bg-position;
				background-repeat: no-repeat;
				background-size: contain;
				height: map-get($gl-slider-arrow-height-before-map, small);
				width: map-get($gl-slider-arrow-width-before-map, small);
			}
		}
	}

	.q-gallery-container {
		height: 100%;
		height: calc(100vh - 54px);
		overflow-y: scroll;
		position: relative;
		top: map-get($gl-top-position-map, small);

		.q-gallery-full-scroller {
			position: relative;
			top: 0;
			z-index: 999;

			.slick-list {
				height: 100%;
				overflow: hidden;
			}
		}

		.q-multimedia-scroller {
			clear: both;
			display: inline-block;
			float: left;
			width: 100%;
			z-index: 999;

			.q-separator hr {
				display: none;
			}

			&.fixed-position {
				position: fixed;
				z-index: 999;
			}

			.q-slider-item {
				gb-video-brightcove,
				.q-video {
					.q-autosize,
					.q-ps-autosize {
						padding: 0;
					}
				}

				gb-video-youtube {
					.gb-autosize {
						padding: 0;
					}
				}

				.q-layer,
				.q-responsive-image-disclosure {
					visibility: hidden;
				}

				&.slick-active {
					.q-layer {
						visibility: visible;
					}

					.q-responsive-image-disclosure {
						height: 100%;
						position: absolute;
						top: 0;
						visibility: visible;
						z-index: 3;

						.q-responsive-image-disclosure-text {
							bottom: 0;
							color: $gl-disclosure-link-color;
							padding: $gl-disclosure-padding;
							position: absolute;
							right: $gl-disclosure-right-position;
						}
					}
				}

				.video-info-container,
				.q-video-info-container,
				.gb-video-info-container {
					display: none;
				}
			}
		}

		.q-slider {
			.slick-list {
				height: auto;
				top: 0;
			}

			.q-video {
				.q-autosize {
					background: $video-background-color;
					background-size: cover;

					&.q-has-play-button {
						&:not(.has-posterframe) {
							.q-video-player {
								display: block;
							}
						}
					}
				}

				&.has-posterframe,
				&.has-modal {
					&::after {
						top: 50%;
					}
				}
			}

			gb-brightcove-video {
				&.q-start-button-position-override {
					&::after {
						top: 50%;
					}
				}

				.q-content-overlay {
					.q-layer {
						height: 100%;
					}
				}
			}

			.q-video-caption {
				display: none;
			}
		}

		.q-gallery-full-scroller {
			.small-margin {
				margin: 0;
			}
		}

		.q-gallery-thumbnail-scroller {

			.q-gallery-thumbnail-scroller-item {
				.small-margin {
					margin: 0;
				}
			}
		}

		&.q-gallery-layer-edit {
			// corrects for error with 100vh in aem edit mode
			height: 100%;
		}

		.q-layer {
			.q-layer-width {
				position: absolute;

				.q-text {
					font-weight: $gl-caption-font-weight;
				}
			}
		}

		.q-slider-item {
			&.slick-active {
				.q-layer-width {
					background-color: $gl-caption-background-color;
				}
			}
		}
	}

	.q-gallery-header {
		background-color: $gl-header-bg-color;
		height: auto;
		max-width: $gl-max-width;
		padding: $gl-header-padding;
		position: absolute;
		top: 0;
		z-index: 9999;

		.q-gallery-header-nav {
			display: inline-block;
			float: left;

			&.disabled {
				.q-dropdown:hover {
					cursor: default;
				}
			}
		}

		.q-dropdown {
			border: $gl-dropdown-border;
			cursor: pointer;
			display: inline-block;
			padding: map-get($gl-dropdown-padding-map, small);
			text-align: left;
			width: auto;

			&.open {
				background-color: $gl-dropdown-button-open-color;

				.q-category-title {
					color: $gl-category-title-open-color;
				}

				.svg-icon {
					.q-gallery-carat {
						@include insert-icon($gl-dropdown-carat-open, $gl-dropdown-carat-size, $gl-dropdown-carat-size);
						margin-bottom: 0;
						transform: rotate($gl-dropdown-carat-open-rotate);
					}
				}
			}

			.q-category-title {
				display: inline-block;
				font-size: $gl-category-title-font-size;
				padding: $gl-category-title-padding;
			}

			.svg-icon {
				display: inline-block;
				float: right;
				line-height: 0;
				margin-top: .625rem;

				&.hide {
					display: none;
				}

				.q-gallery-carat-container {
					height: 2rem;
					width: 2rem;
				}

				.q-gallery-carat {
					@include insert-icon($gl-dropdown-carat, $gl-dropdown-carat-size, $gl-dropdown-carat-size);
					margin-bottom: 0;
				}
			}
		}

		.q-gallery-header-close {
			margin: map-get($gl-close-margin-map, small);
			position: absolute;
			right: map-get($gl-header-close-position-map, small);
			z-index: 999;

			.q-gallery-close-icon {
				@include insert-icon($gl-close-icon, $gl-close-icon-size, $gl-close-icon-size, null, $gl-close-icon-hover);
			}

			.close-reveal-modal {
				color: $brand-primary-color;
				line-height: 0;
				padding: $gl-close-reveal-padding;
				position: relative;
				right: $gl-close-reveal-right;
				top: $gl-close-reveal-top;
			}
		}

		&.q-gallery-header-edit {
			position: initial;
		}
	}

	.q-gallery-header-dropdown {
		background: $gl-dropdown-bg-color;
		background-color: transparent;
		margin: $gl-dropdown-margin-shift;
		max-height: 100%;
		max-height: calc(100vh - 54px);
		max-width: map-get($gl-dropdown-max-width-map, small);
		overflow: hidden;
		position: absolute;
		top:  map-get($gl-top-position-map, small);
		visibility: visible;
		width: map-get($gl-dropdown-width-map, small);
		z-index: 9999;

		.q-gallery-dropdown-item {
			&.active {
				background-color: $gl-dropdown-hover-color;
				border-left: $gl-dropdown-item-active-border;
				padding: $gl-dropdown-item-padding;
			}

			.q-button-label {
				display: inline-block;
				font-size: $gl-button-label-font-size;
				line-height: 1.25rem;
			}
		}

		&.q-dropdown-padding {
			padding-bottom: 3.375rem;
		}

		&.q-overflow-scroll {
			overflow-x: hidden;
			overflow-y: auto;
		}
	}

	.q-multimedia-teaser {
		margin-bottom: 0;
	}

	.q-multimedia-scroller {
		.q-slider-counter {
			bottom: 0;
			color: $white;
			position: absolute;
			right: 0;
			text-align: center;
			width: 3.75rem;
			z-index: 5;
		}

		.q-caption-scroller.q-multimedia-teaser {
			.q-teaser-content-container {
				background: transparent;
				display: block !important; // sass-lint:disable-line no-important

				.q-gallery-title {
					font-size: .875rem;
					padding: 0;
				}
			}
		}
	}

	.q-gallery-thumbnail-scroller {
		background: $gl-thumbnails-background-color;
		bottom:  0;
		display: none;
		margin-top: 0;
		max-width: $gl-max-width;
		visibility: hidden;
		z-index: 999;

		.q-filmstrip {
			min-height: auto;
			overflow: hidden;
			padding: 1rem $gl-filmstrip-padding-left;

			.q-caption-scroller.q-multimedia-teaser {
				.q-teaser-content-container {
					padding-left: 2.5rem;
					padding-right: 2.5rem;
				}
			}

			.slick-prev,
			.slick-next {
				background-color: $gl-filmstrip-arrow-bg-color;
				border: 0;
				font-size: 0;
				height: $gl-filmstrip-arrow-height;
				margin: 0;
				opacity: $gl-filmstrip-arrow-opacity;
				padding: $gl-filmstrip-arrow-padding;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: $gl-filmstrip-arrow-width;
				z-index: 100;

				&::before {
					background: get-element($gl-next-prev-arrow);
					background-position: center center;
					background-repeat: no-repeat;
					background-size: $gl-filmstrip-background-size;
					content: '';
					height: $gl-filmstrip-arrow-before-height;
					left: 0;
					margin: auto;
					opacity: $gl-filmstrip-arrow-before-opacity;
					position: absolute;
					right: 0;
					top: 0;
					width: $gl-filmstrip-arrow-before-width;
				}

				.icon {
					display: none;
				}
			}

			.slick-prev {
				left: 0;

				&::before {
					transform: rotate(180deg);
				}
			}

			.slick-next {
				right: 0;
			}

			.q-slick-current {
				position: relative;

				&::after {
					border: $gl-filmstrip-item-border;
					bottom: 0;
					content: '';
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
				}
			}

			.q-slider-item {
				position: relative;

				&:active,
				&.active {
					&::after {
						border: $gl-filmstrip-item-border;
						bottom: 0;
						content: ' ';
						cursor: $gl-hover-cursor;
						height: 100%;
						position: absolute;
						width: 100%;
					}
				}

				.q-gallery-video-icon {
					background: get-element(video-play-button) no-repeat;
					background-size: contain;
					border: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					top: 0;
					transform: scale($gl-play-icon-scale);
					width: 50%;
				}
			}
		}
	}

	.q-gallery-thumbnails {
		clear: both;
		display: block;
		float: left;
		padding: 1rem .625rem 9rem;
		visibility: visible;
		width: 100%;
		z-index: 0;

		.q-gallery-headline {
			@extend %#{$gl-thumbnail-headlines};
			clear: both;
			color: $gl-thumbnail-headline-color-override;
			float: left;
			margin-bottom: $gl-thumbnail-headline-margin-bottom;
			text-transform: $gl-thumbnail-headline-transform;
			width: 100%;
		}

		.q-thumbnail-block {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin: 0;
			padding: 2rem;

			.q-slider-item {
				display: block;
				float: left;
				height: auto;
				list-style: none;
				margin: map-get($gl-thumbnail-margin-map, small);
				padding: 0;
				position: relative;
				width: map-get($gl-thumbnail-width-map, small);

				img {
					display: block;
					float: left;
				}

				&:active,
				&.active {
					.q-gallery-thumbnail-item {
						&:first-child {
							cursor: $gl-hover-cursor;
							float: left;
							outline: $gl-thumbnail-item-border;
							outline-offset: $gl-border-offeset;
							width: 100%;
						}
					}
				}

				.q-gallery-video-icon {
					background: get-element(video-play-button);
					background-size: contain;
					border: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					top: 0;
					transform: scale($gl-play-icon-scale);
					width: 50%;
				}
			}
		}
	}

	.q-thumbnail-container {
		padding: 0;
		padding-left: .5rem;
	}

	.mms-text,
	.gallerylayer-text {
		display: none;
	}

	.q-content-bar {
		display: none;
	}

	.q-hide-util {
		overflow: hidden;
	}

	@media #{$medium-down} {
		.q-gallery-thumbnails {
			.q-thumbnail-block {
				justify-content: flex-end;
			}
		}
	}

	@media #{$landscape} {
		.q-gallery-thumbnail-scroller {
			.q-filmstrip {
				.q-slider-item {
					.q-gallery-video-icon {
						transform: scale($gl-play-icon-scale);
						width: 50%;
					}
				}
			}
		}
	}

	@media #{$medium-up} {
		.q-gallery-thumbnails {
			padding-bottom: 9rem;

			.q-thumbnail-block {
				.q-slider-item {
					margin: map-get($gl-thumbnail-margin-map, medium);
					width: map-get($gl-thumbnail-width-map, medium);
				}
			}
		}

		.q-gallery-header-dropdown {
			max-width: map-get($gl-dropdown-max-width-map, large);
			top: map-get($gl-top-position-map, small);
			width: map-get($gl-dropdown-width-map, large);
		}
	}

	@media #{$large-up} {
		.q-multimedia-scroller {
			.slick-next,
			.slick-prev {
				height: map-get($gl-slider-arrow-height-map, large);
				width: map-get($gl-slider-arrow-width-map, large);

				&::before {
					height: map-get($gl-slider-arrow-height-before-map, large);
					width: map-get($gl-slider-arrow-width-before-map, large);
				}
			}
		}

		.q-gallery-container {
			height: calc(100vh - 64px);
			top: map-get($gl-top-position-map, large);

			.q-slider .slick-list {
				height: 100%;
				overflow: hidden;
			}
		}

		.q-gallery-thumbnail-scroller {
			.q-filmstrip {
				.q-slider-item {
					float: left;

					&.q-slider-item-low-count {
						/* When > 6 items in thumbnail scroller this ensures correct aspect ratio. */
						/* !important is needed in order to override style attribute set by Slick Slider. */
						width: 16.666% !important; // sass-lint:disable-line no-important
					}
				}
			}
		}


		.q-gallery-header {
			position: fixed;

			.q-dropdown {
				border: $gl-dropdown-border;
				color: $gl-dropdown-color;
				padding: map-get($gl-dropdown-padding-map, large);
			}

			.q-gallery-header-close {
				margin: map-get($gl-close-margin-map, large);
				right: map-get($gl-header-close-position-map, large);

				.q-gallery-close-icon {
					@include insert-icon($gl-close-icon, $gl-close-icon-size, $gl-close-icon-size, null, $gl-close-icon-hover);
				}
			}
		}

		.q-gallery-header-dropdown {
			max-height: calc(100vh - 64px);
			max-width: map-get($gl-dropdown-width-map, large);
			top: map-get($gl-top-position-map, large);
			width: map-get($gl-dropdown-width-map, large);
		}
	}

	@media #{$large-only} {
		.q-gallery-thumbnails {
			.q-thumbnail-block {
				.q-slider-item {
					.q-gallery-video-icon {
						transform: translateZ(0) scale($gl-play-icon-scale);
					}
				}
			}
		}

		.q-gallery-thumbnail-scroller {
			.q-filmstrip {
				.q-slider-item {
					.q-gallery-video-icon {
						transform: scale($gl-play-icon-scale);
						width: 50%;
					}
				}
			}
		}
	}

	@media #{$xlarge-up} {
		margin: 0 auto;
		max-width: $gl-max-width;

		.q-multimedia-scroller {
			.slick-next,
			.slick-prev {
				height: map-get($gl-slider-arrow-height-map, xlarge);
				width: map-get($gl-slider-arrow-width-map, xlarge);

				&::before {
					height: map-get($gl-slider-arrow-height-before-map, xlarge);
					width: map-get($gl-slider-arrow-width-before-map, xlarge);
				}
			}
		}

		.q-gallery-container {
			background-color: $gl-background-color;
			height: calc(100vh - 74px);
			overflow-y: hidden;
			top: map-get($gl-top-position-map, xlarge);

			.q-slider-item {
				&.slick-active {
					.q-layer-width {
						bottom: 0;
						position: absolute;
						width: 100vw;
						z-index: 999;
					}
				}
			}
		}

		.q-gallery-header {
			position: fixed;

			.q-dropdown {
				border: $gl-dropdown-border;
				padding: map-get($gl-dropdown-padding-map, xlarge);

				.q-category-title {
					font-size: $gl-category-title-font-size;
					min-width: 10.94rem;
				}
			}

			.q-gallery-header-close {
				margin: map-get($gl-close-margin-map, xlarge);
				right: map-get($gl-header-close-position-map, xlarge);
			}
		}

		.q-gallery-header-dropdown {
			max-height: calc(100vh - 74px);

			max-width: map-get($gl-dropdown-max-width-map, xlarge);
			top: map-get($gl-top-position-map, xlarge);
			width: map-get($gl-dropdown-width-map, xlarge);

			&.q-dropdown-padding {
				padding-bottom: 4.625rem;
			}
		}

		.q-gallery-thumbnail-scroller {
			display: inherit;
			visibility: visible;

			.q-filmstrip {
				.q-slider-item {
					float: left;
				}
			}
		}

		.q-gallery-thumbnails {
			display: none;
			padding-bottom: 9.625rem;

			.q-thumbnail-block {
				.q-slider-item {
					.q-gallery-video-icon {
						transform: translateZ(0) scale(1);
					}
				}
			}
		}
	}

	.no-touch & {
		.q-multimedia-scroller {
			.q-slider {
				&:hover {
					.slick-arrow {
						opacity: $gl-mms-hover-slider-arrow-opacity;

						&:hover {
							opacity: $gl-slider-arrow-opacity;
						}
					}
				}

				.slick-arrow {
					&:focus {
						opacity: $gl-slider-arrow-opacity;
					}
				}
			}
		}

		.q-gallery-header {
			.q-dropdown {
				&:hover,
				&.focus {
					background-color: $gl-dropdown-hover-bg-color;
					color: $button-secondary-invert-hover-color;

					.svg-icon {
						.q-gallery-carat {
							@include insert-icon($gl-dropdown-carat-hover, $gl-dropdown-carat-size, $gl-dropdown-carat-size);
							margin-bottom: 0;
						}
					}
				}

				&.open {
					.svg-icon {
						.q-gallery-carat {
							@include insert-icon($gl-dropdown-carat-open-hover, $gl-dropdown-carat-size, $gl-dropdown-carat-size);
						}
					}
				}
			}
		}

		.q-gallery-header-dropdown {
			.q-gallery-dropdown-item {
				&:hover {
					background-color: $gl-dropdown-hover-color;
				}
			}
		}

		.q-gallery-thumbnail-scroller {
			.q-filmstrip {
				.slick-prev,
				.slick-next {
					&:hover {
						opacity: 1;

						&::before {
							opacity: 1;
						}
					}
				}

				.q-slider-item {
					&:hover {
						&::after {
							border: $gl-filmstrip-item-border;
							bottom: 0;
							content: ' ';
							cursor: $gl-hover-cursor;
							height: 100%;
							left: 0;
							position: absolute;
							width: 100%;
						}
					}
				}
			}
		}

		.q-gallery-thumbnails {
			.q-thumbnail-block {
				.q-slider-item {
					&:hover {
						.q-gallery-thumbnail-item {
							&:first-child {
								cursor: $gl-hover-cursor;
								float: left;
								outline: $gl-thumbnail-item-border;
								outline-offset: $gl-border-offeset;
								width: 100%;
							}
						}
					}

					.q-gallery-video-icon {
						&:hover {
							cursor: $gl-hover-cursor;
						}
					}
				}
			}
		}
	}

	// Touch Styling to override MMS inline styling of arrows
	.touch & {
		button {
			&.slick-arrow {
				&.gallery-image-arrow {
					opacity: $gl-mms-hover-slider-arrow-opacity !important; // sass-lint:disable-line no-important
				}
			}
		}

		.q-multimedia-scroller {
			&.fixed-position {
				overflow: hidden;

				.slick-arrow {
					display: none !important; // sass-lint:disable-line no-important
				}
			}
		}
	}
}

.gallery-reveal-modal {
	border: 0;
	max-height: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
	padding: 0;

	// Needed to override Reveal Modal header
	.q-modal-header {
		display: none;
	}

	&.open {
		background-color: $gl-background-color;

		.q-modal-content {
			left: 0;
			margin: 0;
			top: 0;
			width: 100%;
		}
	}
}

.q-hidden {
	visibility: hidden;
}

.q-visible {
	visibility: visible;
}
