// Legal Notification
// This is namespaced to prevent collision - Mobile Styles go Here

.q-legal-notification {
	@include hard-easeout(max-height, $anim-slow);
	background-color: $black;
	bottom: 0;
	overflow: hidden;
	position: fixed;
	width: 100%;
	z-index: 1199;

	.float-left {
		float: left;
	}

	&.q-minimize {
		.q-content-container {
			visibility: hidden;
		}

		.q-minimize-text {
			visibility: visible;
		}
	}

	&.q-disclaimer-none {
		display: none;
	}

	.q-disclaimer-none {
		display: none;
	}

	.q-content-container {
		.q-button-container {
			float: right;
		}

		.silent-consent {
			@include absolute-position(top, right);
			position: absolute;
		}

		.q-close-icon {
			@include insert-icon($legal-notification-close-icon, map-get($legal-notification-close-icon-map, small),
				map-get($legal-notification-close-icon-map, small), $legal-notification-close-icon-mq-use, $legal-notification-close-hover-icon);

			pointer-events: none;
			vertical-align: baseline;
		}

		.q-title {
			.q-close-button {
				.icon-minus {
					background: get-element($legal-notification-consent-minus) no-repeat center;
					float: right;
					height: $legal-notification-consent-close-size;
					margin-top: $legal-notification-consent-icon-margin-top;
					width: $legal-notification-consent-close-size;
				}
			}

			.q-minimize-text {
				.icon-plus {
					background: get-element($legal-notification-consent-plus) no-repeat center;
					float: right;
					height: $legal-notification-consent-close-size;
					margin-top: $legal-notification-consent-icon-margin-top;
					width: $legal-notification-consent-close-size;
				}
			}
		}
	}

	.q-close-button {
		cursor: pointer;
	}

	.q-minimize-text {
		@include absolute-position(top, left);
		cursor: pointer;

	}

	@media #{$medium-up} {
		.float-left {
			margin-bottom: 0;
		}

		.q-content-container {
			.q-close-icon {
				height: map-get($legal-notification-close-icon-map, large);
				width: map-get($legal-notification-close-icon-map, large);
			}
		}
	}

	@media #{$large-up} {
		.q-content-container {
			.q-title {
				.q-title-label {
					display: inline-block;
				}
			}
		}
	}

	@media #{$xlarge-up} {

		.q-content-container {
			.q-title {
				.q-close-button {
					.icon-minus {
						float: left;
					}
				}

				.q-minimize-text {
					.icon-plus {
						float: left;
					}
				}
			}
		}
	}
}
