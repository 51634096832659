$filter-white: #ffffff;
$filter-black: #000000;
$filter-grey: #cccccc;

.gb-dl-filter-modal {
	background-color: $filter-white;
	margin: 1rem 0 0 1rem;
	max-width: 27.13rem;
	padding: 1.25rem;
	z-index: 999;

	.gb-brand-container {
		display: flex;
		padding: 0 0 1.4rem;
	}

	.gb-brand-container .gb-brand {
		border: 1px solid $filter-black;
		cursor: pointer;
		font-size: .63rem;
		margin-right: .9375rem;
		text-align: center;
		width: 100%;
	}

	.gb-dl-h2 {
		font-size: 100%;
		font-weight: 900;
	}

	.gb-dl-filter-back {
		color: $filter-black;
		font-family: inherit;
		font-weight: 900;
	}

	li {
		grid-column: 1;
		list-style: none;
	}

	.db-dl-filter-modal-apply-button {
		border: 0;
		color: $filter-white;
		width: 100%;
	}

	.db-dl-filter-modal-apply-button:hover {
		color: $filter-white;
	}

	.gb-dl-filter-modal-apply-button-container {
		border-top: 1px solid $filter-grey;
		display: flex;
		grid-column: 1;
		padding-top: .75rem;
		width: 100%;
	}

	.gb-dl-filter-modal-return {
		border-bottom: 1px solid $filter-grey;
		margin-bottom: .75rem;
		padding-bottom: .75rem;
	}

	.gb-checkbox {
		display: flex;
	}

	.gb-checkbox input {
		appearance: none;
		background-color: $filter-white;
		border: 1px solid $filter-black;
		cursor: pointer;
		display: inline-block;
		flex: 0 0 1.5rem;
		float: left;
		height: 1.5rem;
		margin-right: 1rem;
		position: relative;
		width: 1.5rem;
	}

	.gb-checkbox input:checked::after {
		background-image: get-element(checkmark);
		left: .25rem;
		position: relative;
		top: .25rem;
	}
}
