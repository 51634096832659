$media-query-map-visibility: (
	small: $small-only,
	medium: $medium-only,
	large: $large-only,
	xlarge: $xlarge-only,
	xxlarge: $xxlarge-only
);

$media-query-map-visibility-hide: (
	small-up: $small-up,
	medium-up: $medium-up,
	large-up: $large-up,
	xlarge-up: $xlarge-up,
	xxlarge-up: $xxlarge-up,
	small-down: $small-down,
	medium-down: $medium-down,
	large-down: $large-down,
	xlarge-down: $xlarge-down,
	xxlarge-down: $xxlarge-down
);

$media-query-map-visibility-show: (
	medium-up: $small-down,
	large-up: $medium-down,
	xlarge-up: $large-down,
	medium-down: $large-up,
	large-down: $xlarge-up,
	xlarge-down: $xxlarge-up
);

@function all-but($size) {
	@each $key, $value in $media-query-map-visibility {
		$map: map-remove($media-query-map-visibility, $size);
		@return $map;
	}
}

// Hide for size 
// Hide for size only
// Hide for size up
// Hide for size down

@each $key, $value in $media-query-map-visibility {
	@media #{$value} {
		.hide-for-#{$key} {
			display: none !important; //sass-lint:disable-line no-important
		}
	}
}

@each $key, $value in $media-query-map-visibility-hide {
	@media #{$value} {
		.hide-for-#{$key} {
			display: none !important; //sass-lint:disable-line no-important
		}
	}
}

// Show for size only
// Show for size up
// Show for size down

@each $size, $val in $media-query-map-visibility {
	@each $key, $value in all-but($size) {
		@media #{$value} {
			.show-for-#{$size}-only {
				display: none !important; //sass-lint:disable-line no-important
			}
		}
	}
}

@each $key, $value in $media-query-map-visibility-show {
	@media #{$value} {
		.show-for-#{$key} {
			display: none !important; //sass-lint:disable-line no-important
		}
	}
}
