// Multimedia Scroller Component
//
// This is the documentation for Multimedia Scroller component.

.q-multimedia-scroller,
adv-grid > adv-scrl {
	@extend %hide-video-caption;
	position: relative;

	.q-with-caption,
	.q-slider-tabs {
		.slider-pagination {
			top: auto;
		}
	}

	.q-with-caption {
		.slider-pagination {
			bottom: 0;
			position: absolute;
		}
	}

	.q-caption-scroller {
		.q-teaser-content-container {
			background-color: $yellow;
		}

		.q-button {
			display: none;
			font-weight: $font-weight-bold;
			margin-top: 0;

			@media #{$medium-up} {
				display: block;
			}
		}
	}

	/* Arrows */
	.slick-arrow,
	.scrl-btn {
		@include hard-easeout(opacity, $anim-fast);

		background-color: $slick-arrow-opacity;

		&.slick-disabled {
			// !important used to override inline style added by slick
			display: none !important; // sass-lint:disable-line no-important
		}
	}

	.q-link-icon-inline {
		@include hard-easeout(opacity, $anim-fast);
		opacity: 0;
	}

	&.q-slide-complete {
		.q-link-icon-inline {
			opacity: 1;
		}
	}

	&.q-circle-arrow {
		@include insert-icon(circle-arrow, $mms-svg-icon-size, $mms-svg-icon-size);
	}

	.q-svg-icon {
		display: inline-block;
		height: $mms-svg-icon-size;
		width: $mms-svg-icon-size;
	}

	.q-svg-icon-large {
		height: $mms-svg-icon-size;
		width: $mms-svg-icon-size;
	}

	.q-indicator-numbers {
		color: $white;
		left: $slick-numbers-left;
		position: relative;
		text-rendering: geometricPrecision;
		top: $slick-numbers-top;
		vertical-align: middle;
		z-index: $position-z-mid;
	}

	.q-dots-to-numbers-numbers {
		display: none;
	}

	.q-dots-to-numbers-dots {
		display: inline;
	}

	.q-content-title {
		font-family: $mms-title-font;
		font-size: $content-title-font-size-large;
		font-weight: $mms-title-font-weight;
		letter-spacing: $content-title-letter-spacing;
		line-height: $content-title-line-height;
	}

	.q-indicator-separator {
		display: none;
	}

	.touch-arrow {
		display: none;
	}

	.q-content-bar {
		bottom: 0;
		position: absolute;
		width: 100%;

		&.q-dots-below {
			position: relative;

			.slick-indicator-container {
				padding: 0 .625rem;
			}
		}

		&.q-content-below {
			.q-link-label {
				color: $black;
			}

			&.q-dots-below {
				margin-top: 0;
			}
		}

		&.q-dots-above {
			&.q-content-below {
				position: relative;
			}
		}

		&.q-content-above {
			.q-scroller-overlay {
				z-index: 2;
			}
		}
	}

	.q-semi-opaque-bar {
		background-color: $black;
		display: block;
		height: $slick-semi-opaque-bar-height;
		margin-top: $slick-semi-opaque-bar-offset;
		opacity: $scroller-opacity-half;
		z-index: 101;

		&.q-dots-below {
			margin-bottom: 3.75rem;
			margin-top: -7.5rem;
		}

		&.q-content-below {
			background-color: transparent;
			margin-top: .25rem;

			&.q-dots-below {
				margin-bottom: 0;
				margin-top: -3.75rem;
			}
		}
	}

	/* Custom styles for slider elements */
	.q-slider {
		min-height: $slider-height-min;
		overflow: hidden;

		.q-slider-item {
			height: auto;

			// Hide images while Reveal fires
			&:not(:first-child) {
				opacity: $scroller-opacity-none;
			}

			// TODO: got to fix this at some point
			// On infinite scroll mm scrollers, this will keep the 'wrap-around' (ie cloned) slides from
			// being invisible and therefore creating a blank container until the scroll transition finishes
			&.slick-cloned {
				.q-mod-responsive-image {
					visibility: visible !important; // sass-lint:disable-line no-important
				}
			}

			> .q-slide-link-container {
				display: block;
			}
		}

		.slick-track {
			height: $scroller-percent-full;

			// Display images after Reveal fires
			.q-slider-item:not(:first-child) {
				opacity: $scroller-opacity-full;
			}
		}


		&.q-slider-hover-arrow {
			.slider-control-prev {
				left: $scroller-position-out-of-view;
			}

			.q-slider-control-next {
				right: $scroller-position-out-of-view;
			}

			&:hover {
				.q-slider-control-prev {
					left: $scroller-position-zero;
				}

				.q-slider-control-next {
					right: $scroller-position-zero;
				}
			}
		}

		.slick-slide {
			margin: $slider-pagination-padding; // 0
		}

		.slick-list {
			height: $scroller-percent-full;
		}
	}

	// This will disable the fade animation for arrows when the slider
	// is being shown on a touch device.
	.touch {
		.q-slider {
			&.slider-hover-arrow {
				.slider-control-prev {
					left: $scroller-position-zero;
				}

				.slider-control-next {
					right: $scroller-position-zero;
				}
			}
		}
	}

	/* Arrows */
	.slick-arrow,
	.scrl-btn {
		@include hard-easeout(opacity, $anim-fast);

		background-color: $slick-arrow-opacity;
		border: transparent;
		color: transparent;
		cursor: pointer;
		font-size: $slick-arrow-removed;
		height: $slick-arrow-outer-height;
		line-height: $slick-arrow-removed;
		margin: 0;
		margin-top: map-get($slick-arrow-top-offset, large);
		opacity: $scroller-opacity-none;
		outline: none;
		padding: $slick-arrow-padding;
		position: absolute;
		top: $slick-arrow-top;
		width: $slick-arrow-outer-width;
		z-index: $position-z-front;

		&.slick-disabled {
			display: none;
			opacity: $scroller-opacity-full !important; // sass-lint:disable-line no-important

			&::before {
				opacity: $scroller-opacity-quarter;
			}
		}

		&::before {
			background: get-element(slick-arrow);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			bottom: $scroller-position-zero;
			content: '';
			height: $slick-arrow-height;
			left: $scroller-position-zero;
			margin: auto;
			position: absolute;
			right: $scroller-position-zero;
			top: $scroller-position-zero;
			transform: scaleX(1);
			width: $slick-arrow-width;
		}

		&.invert-arrow {
			&::before {
				background: get-element(slick-arrow-invert);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: contain;
				bottom: $scroller-position-zero;
				content: '';
				filter: FlipH;
				height: $slick-arrow-height;
				left: $scroller-position-zero;
				margin: auto;
				position: absolute;
				right: $scroller-position-zero;
				top: $scroller-position-zero;
				transform: scaleX(-1);
				width: $slick-arrow-width;
			}
		}
	}

	.slick-prev,
	.scrl-prev {
		left: $scroller-position-zero;

		&::before {
			/* flip right arrow to face left */
			filter: FlipH;
			transform: scaleX(-1);
		}

		&.invert-arrow {
			&::before {
				transform: scaleX(1);
			}
		}
	}

	.slick-next,
	.scrl-next {
		right: $scroller-position-zero;
	}

	.slick-slide {
		height: $scroller-percent-full;

		.q-center-transform {
			display: inline-block;
		}
	}

	.slick-indicator-container {
		bottom: $scroller-none;
		height: map-get($slick-dot-height, large);
		margin: $scroller-none;
		overflow: hidden;
		width: $scroller-percent-full;
		z-index: $position-z-mid;
	}

	.slick-dots {
		margin-left: $slick-dot-margin-left;
		margin-top: map-get($slick-dot-margin-top, large);
		position: absolute;
		z-index: $position-z-mid;

		li {
			cursor: pointer;
			display: inline-block;
			height: $slick-dot-li-size;
			margin: $slick-dot-margin;
			position: relative;
			width: $slick-dot-li-size;

			button {
				background: $scroller-none;
				background-color: $mms-indicator-background;
				border-color: $mms-indicator-border;
				border-radius: $scroller-percent-half;
				border-style: solid;
				border-width: $slick-dot-border-width;
				bottom: $scroller-position-zero;
				color: transparent;
				display: block;
				height: $slick-dot-diameter;
				left: $scroller-position-zero;
				margin: auto;
				padding: $scroller-none;
				position: absolute;
				right: $scroller-position-zero;
				top: $scroller-position-zero;
				width: $slick-dot-diameter;

				/* for Chrome: no blue box on click */
				&:focus {
					outline: $scroller-none;
				}
			}

			&.slick-active {
				button {
					background-color: map-get($mms-slick-dots-active-fill, large);
					border-color: map-get($mms-slick-dots-active-border, large);
					height: $slick-dot-diameter-active;
					width: $slick-dot-diameter-active;
				}
			}

			&:first-child {
				margin-left: $scroller-none;
			}

			&:last-child {
				margin-right: $scroller-none;
			}
		}

		&.q-dots-align-center {
			position: relative;
			text-align: center;
		}

		&.q-dots-align-left {
			position: relative;
			text-align: left;
		}

		&.q-dots-align-right {
			position: relative;
			text-align: right;
		}
	}

	.scroller-item {
		position: relative;
	}

	.scroller-background-image {
		height: auto;
		width: $scroller-percent-full;
	}

	.q-scroller-overlay {
		display: block;
		position: absolute;
		visibility: visible;
		z-index: $position-z-front;

		&.q-content-bar-align-left {
			right: auto;
			text-align: left;
		}

		&.q-content-bar-align-center {
			left: 50%;
			right: auto;
			text-align: center;
			transform: translate(-50%);
		}

		&.q-content-bar-align-right {
			text-align: right;
		}
	}

	.q-scroller-overlay-header {
		padding: $scroller-common-padding;
		top: $scroller-position-zero;
	}

	.q-scroller-overlay-more {
		bottom: $scroller-overlay-more-bottom;
		right: $scroller-overlay-more-right;
		z-index: $position-z-top;

		.q-link-label {
			color: $white;
		}
	}

	.q-link-icon-inline {
		.q-link-label {
			display: inline-block;
			margin: $scroller-label-margin;
			text-rendering: geometricPrecision;
			vertical-align: middle;
		}

		.q-link-icon {
			vertical-align: $mms-icon-align;
		}
	}

	.q-scroller-item {
		z-index: $position-z-back;

		.slick-current {
			z-index: $position-z-mid;
		}
	}

	/* Keep this class below .slick-next and .slick-prev */
	.slick-arrow-no-content-bar {
		margin-top: $slick-arrow-top-offset-no-content-bar;
	}
}

adv-grid > adv-scrl > .scrl-btn {
	align-self: center;
	margin: 0 !important; // sass-lint:disable-line no-important
	top: auto !important; // sass-lint:disable-line no-important
}

@media #{$large-up} {
	.q-multimedia-scroller {
		.slick-dots {
			&.q-dots-align-left {
				margin-left: $scroller-overlay-more-right;
			}

			&.q-dots-align-right {
				margin-left: -3.75rem;
				margin-right: $slick-dot-margin-left;
			}
		}

		.q-content-bar {
			&.q-content-below {
				&.q-dots-below {
					.q-scroller-overlay-more {
						background-color: $scroller-overlay-more-bg-color;
						border-bottom: $scroller-overlay-more-border-bottom solid $grey-light;
						bottom: auto;
						display: block;
						overflow: hidden;
						padding: $scroller-overlay-padding;
						position: relative;

						&.q-content-align-right {
							right: 0;
						}
					}
				}
			}

			&.q-dots-above {
				.slick-indicator-container {
					padding: 0 .625rem;
					position: absolute;
					top: -3.75rem;
				}
			}
		}

		.q-scroller-overlay {
			&.q-content-align-right {
				margin-right: 3.75rem;
			}

			&.q-content-bar-align-left {
				margin-left: 0;
				padding: 0 .625rem;

				.q-link-icon-inline {
					margin-left: 3.75rem;
				}
			}
		}

		.q-link-icon-inline {
			&:hover {
				.q-link-label {
					color: $mms-title-hover-color;
				}
			}
		}
	}
}

@media #{$xlarge-up} {
	.q-multimedia-scroller {
		.q-content-bar {
			&.q-content-below {
				&.q-dots-below {
					.q-scroller-overlay-more {
						padding: 1.25rem 3.75rem;
					}
				}
			}
		}
	}
}

@media #{$large-down} {
	.q-multimedia-scroller {
		.q-indicator-numbers {
			color: $grey-dark;
			left: 0;
			width: $scroller-percent-full;
		}

		.q-dots-to-numbers-numbers {
			display: inline;
		}

		.q-dots-to-numbers-dots {
			/* !important needed to override element style attribute */
			display: none !important; // sass-lint:disable-line no-important
		}

		.q-content-bar {
			position: relative;

			&.q-dots-above {
				.slick-indicator-container {
					padding: 0 .625rem;
					position: absolute;
					top: -3.75rem;
				}

				&.q-content-above {
					.slick-indicator-container {
						top: 0;
					}
				}
			}

			&.q-content-above {
				position: relative;

				.q-scroller-overlay {
					background-color: transparent;
					border-bottom: transparent;
					bottom: .625rem;
					height: auto;
					padding: 0 .625rem;
					position: absolute;

					&.q-content-bar-align-right {
						right: 0;
					}

					.q-link-label {
						color: $white;
					}
				}
			}

			&.q-content-below {
				&.q-dots-below {
					.q-scroller-overlay-more {
						background-color: $scroller-overlay-more-bg-color;
						border-bottom: $scroller-overlay-more-border-bottom solid $grey-light;
						bottom: auto;
						display: block;
						overflow: hidden;
						padding-top: $scroller-common-padding;
						position: relative;

						.q-link-label {
							color: $mms-link-label-color;
						}
					}

					.slick-list {
						overflow: visible;
					}

					.slick-dots {
						margin-top: map-get($slick-dot-margin-top, small);
						opacity: $scroller-opacity-full;
						padding: $slick-dot-padding-small;
						top: $slick-dot-top-small;
						width: $scroller-percent-full;

						li {
							button {
								border-color: $mms-slick-dots-color;
							}

							&.slick-active {
								button {
									background-color: map-get($mms-slick-dots-active-fill, medium);
									border-color: map-get($mms-slick-dots-active-border, medium);
								}
							}
						}
					}

					.slick-indicator-container {
						background-color: $white;
						opacity: $scroller-opacity-full;
						position: relative;
						text-align: center;
						width: $scroller-percent-full;

						&.q-single-slide {
							display: none;
						}
					}
				}
			}
		}

		.q-semi-opaque-bar {
			display: none;

			&.q-content-above {
				display: block;
			}
		}


		.q-scroller-overlay-more {
			background-color: $scroller-overlay-more-bg-color;
			border-bottom: $scroller-overlay-more-border-bottom solid $grey-light;
			bottom: auto;
			display: block;
			height: $scroller-overlay-more-height-medium;
			overflow: hidden;
			padding-top: $scroller-common-padding;
			position: relative;
			right: auto;
			text-align: $mms-content-text-align;
		}

		.slick-indicator-container {
			height: map-get($slick-dot-height, medium);
		}

		.q-scroller-overlay-more {
			.q-link-label {
				color: $mms-link-label-color;
			}
		}

		.q-scroller-overlay {
			padding: $scroller-overlay-padding;
		}

		.slick-list {
			overflow: visible;
		}

		.slick-dots {
			background-color: $white;
			margin: $slick-dot-margin-small;
			margin-top: map-get($slick-dot-margin-top, small);
			opacity: $scroller-opacity-full;
			padding: $slick-dot-padding-small;
			position: relative;
			text-align: center;
			top: $slick-dot-top-small;
			width: $scroller-percent-full;

			li {
				button {
					border-color: $mms-slick-dots-color;
				}

				&.slick-active {
					button {
						background-color: map-get($mms-slick-dots-active-fill, medium);
						border-color: map-get($mms-slick-dots-active-border, medium);
					}
				}
			}
		}

		.slick-indicator-container {
			background-color: $white;
			opacity: $scroller-opacity-full;
			padding: $scroller-padding-none;
			position: relative;
			text-align: center;
			width: $scroller-percent-full;

			&.q-single-slide {
				display: none;
			}
		}

		.grid-bg-grey-very-light,
		.grid-bg-grey-light,
		.grid-bg-grey-dark,
		.grid-bg-grey-very-dark {
			.slick-dots,
			.slick-indicator-container {
				background-color: transparent;
			}
		}

		.slick-arrow,
		.scrl-btn {
			margin-top: map-get($slick-arrow-top-offset, small);
		}
	}
}

// Touch Styles
.q-multimedia-scroller,
adv-grid {
	.no-touch & {
		&:hover {
			.slick-arrow,
			.scrl-btn {
				opacity: $scroller-arrow-hover-opacity;
			}
		}

		/* Arrows */
		.slick-arrow,
		.scrl-btn {
			&:hover,
			&.focus {
				opacity: $scroller-opacity-full;
			}
		}
	}

	/* Authorable properties - Arrows */
	.slick-arrow,
	.scrl-btn {
		&.fixed-arrow {
			opacity: $scroller-arrow-hover-opacity;

			&.focus {
				opacity: $scroller-opacity-full;
			}
		}
	}

	.q-semi-opaque-bar {
		&.inside-image {
			margin-top: $slick-semi-opaque-bar-offset;
		}

		&.outside-image {
			margin-bottom: $slick-semi-opaque-bar-offset;
		}

		&.fixed-arrow-touch {
			opacity: $scroller-arrow-hover-opacity;

			&:hover,
			&.focus  {
				opacity: $scroller-opacity-full;
			}
		}

		&.no-arrow-touch {
			opacity: $scroller-opacity-none;

			&:hover,
			&.focus  {
				opacity: $scroller-opacity-none;
			}
		}
	}
}
