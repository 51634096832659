.wrapper-underline-hover-xs {
	&::after {
		background-color: $gold;
		content: '';
		display: block;
		height: 3px;
		transform: scaleX(0);
		transition: transform 250ms linear;
		width: 100%;
	}

	&:hover {
		&::after {
			transform: scaleX(1);
		}
	}
}

.wrapper-list-hover-xs {
	border-left: 3px solid transparent;
	transition: cubic-bezier(0, 1, .5, 1) .25s;

	&:hover {
		border-color: $gold;
	}
}

@media #{$large-up} {
	.wrapper-underline-hover-m {
		border: 0;

		&::after {
			background-color: $gold;
			content: '';
			display: block;
			height: 3px;
			transform: scaleX(0);
			transition: transform 250ms linear;
			width: 100%;
		}

		&:hover {
			&::after {
				transform: scaleX(1);
			}
		}
	}

	.wrapper-list-hover-m {
		border-left: 3px solid transparent;
		transition: cubic-bezier(0, 1, .5, 1) .25s;

		&::after {
			content: none;
		}

		&:hover {
			border-color: $gold;
		}
	}

	.wrapper-no-hover-m {
		background: none;
		border: 0;
		transition: none;

		&::after {
			content: none;
		}
	}
}
