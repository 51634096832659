.q-vehicle-information-row {
	padding: 0;
	width: 100%;

	.q-vehicle-info-text-price {
		@include vehicle-info-price-style;
		color: $vehicle-info-row-price-color;

		.q-from-label {
			@extend %#{$vehicle-info-row-from-label-typography};
			color: $vehicle-info-row-price-color;
			display: inline-block;

			p {
				color: inherit;
				display: inline;
				text-transform: none;
			}
		}

		.q-vehicle-info-as-shown-price {
			@extend %#{$vehicle-info-row-price-as-shown-extend};
			color: $vehicle-info-row-price-as-shown-color;
			font-weight: $vehicle-info-row-price-as-shown-font-weight;
			line-height: $vehicle-info-row-price-as-shown-line-height;
			margin-bottom: $vehicle-info-row-price-as-shown-margin-bottom;

			p {
				color: inherit;
				display: inline;
				font-size: inherit;
				font-weight: $vehicle-info-row-price-as-shown-font-weight;
			}
		}
	}

	.q-vehicle-image {
		@include grid-column(6);
	}

	.q-vehicle-year-title-msrp {
		@include grid-column(6);

		.q-vehicle-info-row-year {
			@extend %#{$vehicle-info-row-year-typography};
			color: $vehicle-info-row-year-color;
			margin-bottom: 0;
		}

		.q-vehicle-info-row-title {
			@extend %#{$vehicle-info-row-title-typography};
			margin-bottom: 0;
			padding-top: .125rem;
			text-transform: uppercase;
		}
	}

	.q-vehicle-body-description {
		@extend %#{$vehicle-info-row-description-typography};
		@include grid-column(12);
	}

	.q-vir-first-button {
		@include grid-column(6);

		float: left;
		margin: .625rem 0;
	}

	.q-vir-second-button {
		@include grid-column(6);

		float: left;
		margin: .625rem 0;
	}

	@media #{$large-up} {
		.q-vehicle-info-image-container {
			padding-left: 0;
			padding-right: 0;
		}

		.q-descriptive2 {
			margin-bottom: 0;
		}

		.q-vehicle-description {
			margin-top: 1.25rem;
		}

		.q-vehicle-image {
			@include grid-column(6);
		}

		.q-vehicle-year-title-msrp {
			@include grid-column(6);
		}

		.q-vehicle-body-description {
			@include grid-column(8);
			margin-top: 1.25rem;
		}

		.q-vir-first-button {
			@include grid-column(4);
			margin-top: 1.25rem;
		}

		.q-vir-second-button {
			@include grid-column(4);
			margin-right: 0;
			margin-top: 0;
		}
	}

	@media #{$xlarge-up} {
		.q-vehicle-body-description {
			@include grid-column(5);
			padding-left: 3.75rem;
		}

		.q-vehicle-image {
			@include grid-column(3);
		}

		.q-vehicle-year-title-msrp {
			@include grid-column(2);
		}

		.q-vir-first-button {
			@include grid-column(2);
			margin-bottom: .75rem;
		}

		.q-vir-second-button {
			@include grid-column(2);
			margin-bottom: .75rem;
		}
 	}
}
