@import 'placeholders/typography';

//This file contains the following items
//1. Headline
//2. Text
//3. Individual

.q-invert {
	color: $white;
}

//1. Headline
.q-bold1 {
	@extend %q-bold1;
}

.q-bold2 {
	@extend %q-bold2;
}

.q-descriptive1 {
	@extend %q-descriptive1;
}

.q-descriptive2 {
	@extend %q-descriptive2;
}

.q-descriptive3 {
	@extend %q-descriptive3;
}

.q-display1 {
	@extend %q-display1;
}

.q-display2 {
	@extend %q-display2;
}

.q-display3 {
	@extend %q-display3;
}

.q-display4 {
	@extend %q-display4;
}

.q-headline1 {
	@extend %q-headline1;
}

.q-headline2 {
	@extend %q-headline2;
}

.q-headline3 {
	@extend %q-headline3;
}

.q-headline4 {
	@extend %q-headline4;
}

.q-headline5 {
	@extend %q-headline5;
}

.q-headline6 {
	@extend %q-headline6;
}

.q-heavy {
	@extend %q-heavy;
}

.q-headline {
	.q-scroller-indicator-text {
		@extend %q-descriptive1;
	}

	@media #{$xlarge-up} {
		.q-scroller-indicator-text {
			color: $white;
		}
	}
}

//2. Text
.q-body1 {
	@extend %q-body1;
}

.q-body2 {
	@extend %q-body2;
}

//3. Individual
.q-caps {
	@extend %q-caps;
}

.q-label1 {
	@extend %q-label1;
}

.q-label2 {
	@extend %q-label2;
}
