$media-query-map: (
	xs: $small-only,
	sm: $medium-only,
	med: $large-only,
	lg: $xlarge-only,
	xl: $xxlarge-only
);

// Images
%crop-circle {
	border-radius: 50%;
	margin: 0;
	padding: 0;
}

%spinner {
	animation: rotate-360 .75s infinite linear;
	border: $spinner-border-width solid $spinner-border;
	border-radius: 100%;
	height: $spinner-height;
	margin: 0 auto;
	transform: translateZ(0);
	width: $spinner-width;

	&::before {
		border-bottom: $spinner-border-width solid transparent;
		border-left: $spinner-border-width solid transparent;
		border-radius: 100% !important; // sass-lint:disable-line no-important
		border-right: $spinner-border-width solid transparent;
		border-top: $spinner-border-width solid $spinner-color;
		box-sizing: content-box;
		content: '';
		display: block;
		height: 100% !important; // sass-lint:disable-line no-important
		left: -$spinner-border-width;
		position: absolute;
		top: -$spinner-border-width;
		width: 100% !important; // sass-lint:disable-line no-important
	}
}

%spinner-rtl {
	@extend %spinner;

	&::before {
		left: unset;
		right: -$spinner-border-width;
	}
}

%transition-opacity {
	transition: opacity #{$anim-fast}s cubic-bezier(0, 1, .5, 1);
}

%q-radio-button {
	border: 0;
	display: inline-block;
	vertical-align: baseline;

	&::before {
		border: $radio-button-border-width solid $radio-button-border-color;
		border-radius: 50%;
		content: '';
		cursor: pointer;
		display: inline-block;
		height: 1.25rem;
		margin: $radio-button-label-margin;
		vertical-align: top;
		width: 1.25rem;
	}
}

%q-radio-button-rtl {
	@extend %q-radio-button;

	&::before {
		margin: $radio-button-label-margin-rtl;
	}
}

%q-radio-button-active {
	position: relative;

	&::after {
		background-color: $radio-button-bg-color;
		border-radius: 50%;
		content: '';
		display: inline-block;
		height: .5rem;
		left: .625rem;
		position: absolute;
		top: .75rem;
		transform: translate(-50%, -50%);
		width: .5rem;
	}
}

%q-radio-button-active-rtl {
	@extend %q-radio-button-active;

	&::after {
		right: .125rem;
		//? transform: translate(-50%, -50%);
	}
}

%q-form-dropdown {
	background-color: $form-background;
	background-image: get-element('carat-down-white'), linear-gradient(to right, $form-interactive, $form-interactive);
	background-position: calc(100% - 1.75rem) calc(1em + .4375rem), 100% 0;
	background-repeat: no-repeat;
	background-size: .625rem .625rem, 4.375rem 100%;
	border-color: $form-border-primary;
	box-shadow: none;
	font-size: 1.125rem;
	height: 3.875rem;
	line-height: 1.875rem;
	margin-bottom: 0;
	padding: .9375rem 4.375rem .9375rem 1.875rem;

	&:hover {
		background-color: $form-background;
		background-image: get-element('carat-down-white'), linear-gradient(to right, $form-interactive-hover, $form-interactive-hover);
	}

	&:focus {
		background-color: $form-background;
	}

	&:disabled {
		background-color: $form-background;
		background-image: get-element('carat-down-white'), linear-gradient(to right, $form-interactive-disabled, $form-interactive-disabled);
		border-color: $form-interactive-disabled;
		color: $form-interactive-disabled;
	}
}

%q-input {
	border-color: $grey-mid;
	box-shadow: none;
	height: 3.125rem;
	margin-bottom: 0;
	padding: .0625rem .0625rem .0625rem .625rem;

	&:focus {
		background-color: $white;
		border-color: $grey;
	}
}

%hmf-results-tag-label {
	font-size: $hmf-results-tag-label-font-size;
	font-weight: $font-weight-bold;
}

%hmf-check-box {
	&::before {
		appearance: none;
		background-color: $hmf-check-box-bg-color;
		border-radius: .0625rem;
		content: '';
		cursor: pointer;
		float: left;
		height: $hmf-checkbox-size;
		margin: $hmf-label-margin;
		padding: 0;
		vertical-align: middle;
		width: $hmf-checkbox-size;
	}
}

%co-check-box-checkmark {
	left: .25rem;
	position: absolute;
	top: -.1875rem;
}

// Hide BrightCove/YoutTube caption
%hide-video-caption {
	[class*='q-video-caption']	{
		display: none !important; // sass-lint:disable-line no-important
	}
}

%forms-radio-checkbox-label {
	color: $grey;
	font: $font-weight-bold .75rem $font-family-secondary;
	line-height: 1.25rem;
}

%gf-form-input {
	background-color: $gf-dropdown-bg-color;
	background-image: get-element($gf-dropdown-icon), linear-gradient(to right, $gf-dropdown-square-color, $gf-dropdown-square-color);
	background-position: unquote(map-get($gf-dropdown-position-map, small));
	background-repeat: no-repeat;
	background-size: unquote(map-get($gf-dropdown-size-map, small));
	border: $gf-dropdown-border;
	color: $gf-dropdown-text-color;
	font-family: $gf-input-font-family;
	font-size: map-get($gf-input-font-size-map, small);
	height: map-get($gf-input-height-map, small);
	line-height: $gf-input-line-height;
	padding: map-get($gf-dropdown-padding-map, small);

	&:disabled {
		background-color: $gf-dropdown-bg-color;
		cursor: initial;
		opacity: .3;
	}

	&:hover:not(:disabled) {
		background-image: get-element($gf-dropdown-hover-icon), linear-gradient(to right, $gf-dropdown-square-hover, $gf-dropdown-square-hover);
		cursor: pointer;
	}

	&:focus {
		font-size: $gf-mobile-focus-font-size;
	}

	// Removes Chrome autofill styling of forms
	&:-webkit-autofill { // sass-lint:disable-line no-vendor-prefixes
		box-shadow: 0 0 0 4rem $white inset;

		&:focus,
		&:active {
			box-shadow: 0 0 0 4rem $white inset;
		}
	}
}

%gf-form-checkbox {
	background: $gf-checkbox-background-color;
	border: $gf-checkbox-border;
	border-radius: .0625rem;
	content: '';
	cursor: pointer;
	height: $gf-checkbox-height;
	left: 0;
	margin: $gf-checkbox-margin;
	position: absolute;
	top: $gf-checkbox-top-position;
	width: $gf-checkbox-width;
}

%gf-form-checkmark {
	content: get-element($gf-check-icon);
	left: $gf-checkbox-check-left-position;
	position: absolute;
	top: $gf-checkbox-check-top-position;
	width: $gf-checkbox-check-width;
}

%no-animation {
	// SASS linter throws an error for the line, however
	// this is required to prevent AEM's YUI compression
	// from stripping out the unit and making it invalid
	transition-duration: 0.00s !important; // sass-lint:disable-line leading-zero no-important
}

%list-item-tertiary {
	background-color: $tertiary-mobile-background;
	border: 0;
	border-left: $tertiary-list-item-border-left;
	border-top: $tertiary-list-item-border-top;
	color: $tertiary-list-item-color;
	width: 100%;

	&:hover,
	&:focus {
		border-left-color: $tertiary-list-item-hover-border-left-color;
	}

	&.active {
		border-left-color: $tertiary-list-item-hover-border-left-color;
		color: $tertiary-nav-list-item-active-color;
	}

	span {
		color: $tertiary-nav-list-item-color;
	}
}
