.q-trailering-towing-search-results {

	.q-main-title {
		@extend %q-display3;
		text-align: center;
	}

	.q-main-car-tile {
		padding: 0 2.5rem;

		.q-car-details {
			.q-year {
				@extend %q-descriptive2;
				margin-bottom: .2rem;
			}

			.q-nameplate-trim {
				@extend %q-headline2;
				text-transform: uppercase;
			}

			.q-specs {
				@extend %q-body2;

				ul {
					margin-left: 0;
					padding-left: 1.25rem;
				}
			}
		}
	}

	hr {
		margin: .667rem 1rem 1.334rem;
	}

	@media #{$xlarge-up} {
		.q-specs {
			display: table;

			.q-specs-left,
			.q-specs-right {
				display: table-cell;
				vertical-align: middle;
			}
		}
	}
}
