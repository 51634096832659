/* Extend solar nav_primary for Chevrolet-specific styles */

.q-nav-primary {
	.q-nav-collapsed {
		.has-flyout {
			.q-nav-collapsed-link {
				&::after {
					@include vertical-align;
					@include icon-triangle(right, .3125rem, .4375rem, $nav-primary-link-label-color);

					position: absolute;
					right: 1.875rem;
				}

				&:hover::after {
					@include icon-triangle(right, .3125rem, .4375rem, $nav-primary-link-label-hover-color);
				}
			}
		}
	}

	.q-button-back {
		&::after {
			@include vertical-align(absolute);
			@include icon-triangle(left, .3125rem, .4375rem, $grey-mid);
			left: 1.875rem;
		}

		// Override button hover style that has a gold left border
		&:hover {
			border-left-color: transparent !important; // sass-lint:disable-line no-important
		}
	}

	.q-top-bar-section {
		flex-wrap: wrap;
		max-width: none;
	}

	.nav-flyout {
		.flyout-nav-content {
			.flyout-content {
				.q-text {
					margin-bottom: 0;
				}
			}
		}
	}

	.q-nav-logo {
		order: 1;
	}

	.q-nav-list-container {
		order: 3;
	}

	.q-nav-dealername-wrapper {
		@extend %q-headline2;
		margin: 0;
		order: 4;
		width: 100%;
	}

	.q-top-bar {
		padding: 0;
	}

	.q-eyebrow-on {
		.q-top-bar-section {
			padding: .5rem .95rem;

			.q-nav-logo {
				padding: 0;
				width: 5.88rem;
			}

			.q-nav-primary-link {
				color: $grey-mid-dark;
				line-height: .87;
			}
		}

		.q-eyebrow-nav {
			padding-left: .95rem;
			padding-right: .95rem;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;

			.q-eyebrow-list {
				display: flex;
				justify-content: flex-end;
				width: 100%;

				.q-nav-list-item {
					.q-nav-primary-link {
						display: flex;
						justify-content: flex-end;
						line-height: .87;
						padding: .43rem 1rem .5rem 0;

						.q-icon-svg {
							bottom: .07rem;
							height: .75rem;
							padding: 0 .22rem 0 0;
							position: relative;
						}

						.q-nav-primary-link-label {
							color: $grey-mid-dark;
							font-size: .6rem;
							font-weight: $font-weight-normal;
							margin: 0;
							width: auto;
						}

						&:hover {
							.q-icon-svg {
								filter: invert(10%) sepia(0%) saturate(208%) hue-rotate(146deg) brightness(98%) contrast(87%);
							}

							.q-nav-primary-link-label {
								color: $grey-dark;
							}
						}
					}
				}
			}
		}

		@media #{$small-down} {
			.q-top-bar-section {
				.q-nav-list-container {
					.q-nav-list-item {
						.q-nav-primary-link-label {
							font-size: .7rem;
						}
					}
				}
			}
		}

		@media #{$large-up} {
			.q-top-bar-section {
				.q-nav-logo {
					width: 5rem;
				}

				.q-nav-list-container {
					.q-nav-list-item {
						.q-nav-primary-link-label {
							font-size: .8rem;
						}
					}
				}
			}
		}

		@media #{$xlarge-up} {
			.q-top-bar-section {
				padding: .5rem .625rem;

				.q-nav-logo {
					width: 6.5rem;
				}

				.q-nav-list-container {
					padding: .5rem 0 0;

					.q-nav-list {
						padding: .25rem 1.25rem;

						&.q-utility-nav {
							padding-right: 0;
						}

						.q-nav-list-item {
							.q-icon-svg {
								padding-right: .475rem;
							}

							.q-nav-primary-link-label {
								font-size: .8rem;
							}

							&.has-flyout {
								.q-nav-primary-link-label {

									&::before {
										@include icon-triangle(down, .4rem, .25rem, $nav-primary-link-label-color);

										right: -.75rem;
										top: .2rem;
									}
								}
							}

							&.flyout-opened {
								.q-nav-primary-link-label {
									&::before {
										@include icon-triangle(up, .4rem, .25rem, $brand-primary-color);
									}
								}
							}
						}
					}
				}
			}
		}

		@media #{$xxxlarge-up} {
			.q-top-bar-section {

				.q-nav-logo {
					width: 5.65rem;
				}

				.q-nav-list-container {
					padding: .7rem 0 .2rem;

					.q-nav-list {
						padding: .25rem .75rem;

						&.q-utility-nav {
							padding-right: .15rem;
						}

						.q-nav-list-item {
							.q-icon-svg {
								padding-right: .32rem;
							}

							&.has-flyout {
								.q-nav-primary-link-label {

									&::before {
										@include icon-triangle(down, .435rem, .305rem, $nav-primary-link-label-color);

										right: -.85rem;
										top: .25rem;
									}
								}
							}

							&.flyout-opened {
								.q-nav-primary-link-label {
									&::before {
										@include icon-triangle(up, .435rem, .305rem, $brand-primary-color);
									}
								}
							}
						}
					}
				}
			}

			.q-eyebrow-nav {
				.q-eyebrow-list {
					.q-nav-list-item {
						.q-nav-primary-link {
							padding: .43rem 1rem .5rem 0;
						}
					}
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.q-top-bar-section {
			.q-nav-list-item {
				&.has-flyout {
					.q-nav-primary-link-label {
						&::before {
							@include icon-triangle(down, .375rem, .25rem, $nav-primary-link-label-color);

							bottom: .375rem;
							right: -.75rem;
						}
					}
				}

				&.flyout-opened {
					.q-nav-primary-link-label {
						&::before {
							@include icon-triangle(up, .375rem, .25rem, $brand-primary-color);
						}
					}
				}
			}
		}

		.q-nav-vehicle-selector {
			padding: 1.25rem;
		}

		.q-nav-dealername-wrapper {
			font-size: 1.4rem;
			max-width: 37%;
			order: 2;
			padding: 0 .5rem 0 .2rem;
			width: auto;
		}
	}

	@media #{$xxlarge-up} {
		.q-nav-dealername-wrapper {
			max-width: 50%;
		}
	}
}
