.q-legal-notification {
	.float-left {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.q-content-container {
		.q-title {
			.q-title-label {
				@extend %q-descriptive2;
				font-size: .875rem;
				line-height: 1.25rem;
				margin-bottom: 0;
				margin-right: 1.25rem;
			}
		}
	}

	.q-close-button {
		font-size: 1.5rem;
		line-height: $legal-notification-consent-close-size;
	}

	.q-content-container {
		.q-title,
		.q-content {
			color: $white;
		}

		.q-title {
			padding: 1.125rem 1.25rem;

			.q-title-label {
				color: $white;
			}
		}

		.q-content {
			padding: 1.25rem;
		}

		.q-content {
			&.q-silent-consent {
				@extend %q-descriptive2;
				color: $white;
				font-size: .875rem;
				line-height: 1.25rem;
				margin-bottom: 0;
				padding: 1.25rem;

				a {
					color: $white;
					text-decoration: underline;

					&:hover {
						color: $gold;
						text-decoration: underline;
					}
				}
			}
		}

		.q-content {
			&.q-required-consent {
				@extend %q-body2;
				color: $white;
				font-size: .875rem;
				line-height: 1.25rem;
				margin-bottom: 0;
				padding: 1.25rem;

				a {
					color: $white;

					&:hover {
						color: $gold;
					}
				}
			}
		}

		.q-button-container {
			margin-bottom: 1.25rem;

			.q-button {
				@include centered;
				color: $white;
				line-height: 1.25rem;
				padding-bottom: .75rem;
				padding-top: .75rem;
				width: 85%;
			}
		}

		.silent-consent {
			padding: 1rem;
		}
	}

	.q-minimize-text {
		text-decoration: none;
		width: 100%;
	}

	@media #{$medium-up} {
		.float-left {
			margin-bottom: 0;
		}
	}

	@media #{$large-up} {

		.q-content-container {
			.q-title {
				.q-title-label {
					margin: 0 1.25rem;
				}

				.icon {
					float: none;
				}
			}

			.q-button-container {
				.q-button {
					padding-bottom: 1.0625rem;
					padding-top: 1.0625rem;
				}
			}
		}
	}
}
