.q-tab-nav {
	.q-floating-tab {
		&.q-swap-tabs {
			border: $tab-nav-border;

			.q-tabs {
				.q-tab-title {
					border: 0;

					.q-tab-button {
						background: $tab-nav-title-background-color;
					}
				}
			}
		}

		.q-tab-title {
			.q-tab-button {
				&.q-icon-stacked {
					padding: .3125rem .3125rem 0;

					.q-button {
						.q-move-text {
							margin-top: .625rem;
						}
					}
				}

				&.q-icon-only {
					padding: 0 .3125rem;
				}
			}

			.no-touch & {
				&.active {
					.q-tab-button {
						&:hover {
							.q-button {
								color: $tab-nav-font-color-hover;
							}
						}
					}
				}
			}
		}
	}

	.q-tabs-content {
		&.colorizer-tabs-content {
			border: 0;

			.brightcove-player-video,
			video-js {
				background: transparent;

				.video-js {
					background: transparent;

					.vjs-tech {
						background: transparent !important; // sass-lint:disable-line no-important
					}
				}
			}
		}
	}

	//360 Colorizer
	.q-360-colorizer {
		.q-colorizer-category {
			@extend %q-descriptive2;
			align-items: center;
			margin-bottom: 0;
			text-align: center;
			text-transform: uppercase;

			.q-category-name {
				.q-colorizer-chip {
					border: 2px solid transparent;
					display: flex;
					margin: .31rem 0 0;

					&:hover {
						border-color: $grey-mid;
					}
				}
			}
		}

		.q-colorizer-tabs {
			justify-content: center;

			.tab-title {
				.q-colorizer-panel {
					&:hover {
						.q-tooltip-text {
							display: none;
						}
					}
				}

				&.active {
					.q-colorizer-chip {
						margin: .31rem 0 0;
					}
				}

				.q-tooltip-text {
					@extend %q-body2;
					background-color: $colorizer-360-tooltip-background-color;
					border: hidden;
					color: $colorizer-360-tooltip-text-color;
					text-transform: capitalize;
					z-index: 2;

					&::after,
					&::before {
						border-top: 10px solid $colorizer-360-tooltip-background-color;
					}
				}
			}
		}

		@media #{$large} {
			.q-colorizer-category {
				.q-category-name {
					.tab-title.active {
						.q-colorizer-chip {
							height: 2.18rem;
							width: 2.18rem;

							&:hover {
								height: 2.18rem;
								width: 2.18rem;
							}
						}
					}

					.q-colorizer-chip {
						height: 2.18rem;
						width: 2.18rem;

						&:hover {
							height: 2.18rem;
							width: 2.18rem;
						}

						.q-colorizer-select {
							height: 1.5rem;
							width: 1.5rem;
						}
					}
				}
			}
		}

		@media #{$large-up} {
			.q-colorizer-tabs {
				justify-content: left;
			}

			.q-colorizer-category {
				align-items: inherit;
				text-align: inherit;

				.q-colorizer-panel {
					.tab-title {
						&:hover {
							.q-tooltip-text {
								transform: translate(-50%, 1.25rem);
								visibility: visible;
							}
						}
					}

					&.tooltip-leftmost {
						.tab-title {
							.q-tooltip-text {
								transform: translate(-.75rem, 1.25rem);
							}
						}
					}
				}

				.q-category-name:nth-child(2) {
					border-left: 0;
				}
			}

			.q-bottom-headline {
				.columns {
					padding: 0;
				}
			}
		}

		@media #{$xlarge-up} {
			.q-colorizer-category {
				.q-category-name {
					.q-colorizer-panel {
						.tab-title {
							&:hover {
								.q-tooltip-text {
									display: block;
									padding-top: .4rem;
								}
							}
						}
					}
				}
			}
		}

		@media #{$xxlarge-up} {
			.q-colorizer-category {
				.q-category-name {
					.tab-title.active {
						.q-colorizer-chip {
							height: 1.75rem;
							width: 1.75rem;

							&:hover {
								height: 1.75rem;
								width: 1.75rem;
							}
						}
					}

					.q-colorizer-chip {
						height: 1.75rem;
						width: 1.75rem;

						&:hover {
							height: 1.75rem;
							width: 1.75rem;
						}

						.q-colorizer-select {
							height: 1.305rem;
							width: 1.305rem;
						}
					}
				}
			}
		}
	}

	&.q-tabnav-360colorizer {
		.q-tabnav-360colorizer-row {
			border-bottom: 1px solid $grey-light;
			border-width: 0 1px 1px;
			display: none;
			justify-content: left;
			width: 100%;

			.q-tab-title {
				background: none;
				border: 0;
				display: block;
				flex: unset;

				&.active {
					border-left: 0;

					.q-tab-button
					.q-button {
						&::before {
							border-bottom: 5px solid $gold;
							bottom: 0;
							height: .76rem;
						}
					}
				}

				.q-tab-button {
					background: none;
					border: 0;
					color: $black;
					padding: 0;

					.q-button {
						border: 0;
						color: $black;
						line-height: 1.76rem;
						margin: 0 .8rem 0 0;
						padding: .5rem 0;

						.q-move-text {
							border: 0;
							color: $black;
							font-size: .7rem;
							font-weight: $font-weight-bold;
							line-height: 1.17rem;
						}
					}
				}
			}
		}

		.q-tabs-content {
			border: 0;
		}
	}

	@media #{$medium-up} {
		&.q-tabnav-360colorizer {
			.q-tabnav-360colorizer-row {

				.q-tab-title {
					.q-tab-button {
						.q-button {
							.q-move-text {
								font-size: .6rem;
							}
						}
					}
				}
			}
		}
	}

	.q-change-view-header {
		background-color: $gl-header-bg-color;
		height: 3.1765rem;
		margin: .5rem auto;
		max-width: 17.65rem;
		padding: $gl-header-padding;
		padding-right: 1rem;
		position: relative;
		top: 0;
		z-index: 110;

		.q-change-view-header-nav {
			display: inline-block;
			float: right;

			&.disabled {
				.q-dropdown:hover {
					cursor: default;
				}
			}
		}

		.q-dropdown {
			border: $gl-dropdown-border;
			border: 1px solid $grey-mid;
			cursor: pointer;
			display: inline-block;
			height: 3.1765rem;
			min-width: 14.69rem;
			padding: .2647rem .3529rem .2647rem .6471rem;
			text-align: left;
			width: 17.6471rem;

			&:hover {
				.svg-icon {
					background-color: $grey-dark;

					.q-change-view-carat {
						@include insert-icon($gl-dropdown-carat-open-hover, $height: .4706rem, $width: 3.375rem);
						background-position: calc(100% - 1rem) center, 100% 0;
						margin-top: 1.0588rem;
					}
				}
			}

			&.open {
				background-color: $gl-dropdown-button-open-color;

				.q-category-title {
					color: $gl-category-title-open-color;
				}

				.svg-icon {
					background-color: $grey-dark;

					.q-change-view-carat {
						@include insert-icon($gl-dropdown-carat-open-hover, $height: .4706rem, $width: 3.375rem);
						margin-top: 1.0588rem;
						transform: translateX(-1rem) rotate(180deg);
					}
				}
			}

			.q-category-title {
				@extend %q-bold2;
				display: inline-block;
				font-size: 1.0588rem;
				line-height: 1.7647rem;
				margin: .5rem 0 0;
				padding: 0;
			}

			.svg-icon {
				background-color: $grey;
				display: inline-block;
				float: right;
				height: 2.5883rem;
				line-height: 0;
				margin: 0;
				transition: background .25s;
				transition-timing-function: cubic-bezier(0, 1, .5, 1);
				width: 4.1177rem;

				&.hide {
					display: none;
				}

				.q-change-view-carat-container {
					height: 2rem;
					padding-left: 3.375rem;
					width: 2rem;
				}

				.q-change-view-carat {
					@include insert-icon($gl-dropdown-carat-open-hover, $height: .4706rem, $width: 3.375rem);
					background-position: calc(100% - 1rem) center, 100% 0;
					margin-top: 1.0588rem;
				}
			}
		}
	}

	.q-change-view-header > * {
		position: absolute;
	}

	.q-change-view-header-dropdown {
		background: $gl-dropdown-bg-color;
		background-color: $white;
		border: 1px solid $grey-mid;
		float: right;
		margin: $gl-dropdown-margin-shift;
		margin-top: 3.1765rem;
		max-height: 100%;
		max-height: calc(100vh - 54px);
		overflow: hidden;
		visibility: visible;
		width: 17.6471rem;
		z-index: 110;

		.q-change-view-dropdown-item {
			background-color: $gl-dropdown-bg-color;
			content: ' ';
			float: right;
			height: 1rem;
			padding: 1.75rem 1.875rem;
			width: 100%;

			&.active {
				background-color: $gl-dropdown-hover-color;
				border-left: $gl-dropdown-item-active-border;
				padding: $gl-dropdown-item-padding;
			}

			.q-button-label {
				@extend %q-bold2;
				color: $grey-dark;
				display: inline-block;
				font-size: 1.0588rem;
				line-height: 1.7647rem;
				transform: translate(0, -50%);
			}
		}

		&.q-dropdown-padding {
			padding-bottom: 3.375rem;
		}

		&.q-overflow-scroll {
			overflow-x: hidden;
			overflow-y: auto;
		}
	}



	@media #{$medium-up} {
		.q-change-view-header {
			padding-right: 0;
		}
	}


	@media #{$large-up} {

		.q-change-view-header {
			.q-dropdown {
				.q-category-title {
					margin: .3125rem 1.438rem 0 0;
					padding: $gl-category-title-padding;
				}
			}
		}

		.q-change-view-header-dropdown {
			.q-change-view-dropdown-item {
				border-bottom: 1px solid $grey-mid;
				content: ' ';
				float: left;
				padding: 1.75rem 1.875rem;

				.q-button-label {
					color: $grey-dark;
				}
			}
		}
	}

	@media #{$xlarge-up} {
		max-width: none;

		.q-dropdown {
			height: map-get($gl-header-height-map, xlarge);
		}

		.q-change-view-header {
			display: flex;
			height: 3.6rem;
			justify-content: flex-end;
			max-width: none;

			.q-dropdown {
				height: 3.6rem;
				min-width: 15.5rem;
				padding: .3rem .3rem .3rem 1.1rem;
				width: 15.5rem;

				.q-category-title {
					font-size: 1.3rem;
					line-height: 1.5rem;
					margin: .75rem 0;
				}

				.svg-icon {
					height: 3rem;
					width: 3.5rem;

					.q-change-view-carat {
						background-position: calc(100% - 1.35rem) center, 100% 0;
						height: .4rem;
						margin-top: 1.3rem;
					}
				}
			}
		}

		.q-change-view-header-dropdown {
			margin-top: 3.6rem;
			width: 15.5rem;

			.q-change-view-dropdown-item {
				.q-button-label {
					font-size: 1.3rem;
					line-height: 1.5rem;
				}
			}
		}

		.row {
			max-width: none;
		}

		// Touch Styles
		.no-touch & {
			.q-change-view-header {
				.q-change-view-header-nav {
					.q-dropdown {
						&:hover,
						&.focus {
							.svg-icon {
								.q-change-view-carat {
									background-position: calc(100% - 1.35rem) center, 100% 0;
									height: .4rem;
									margin-top: 1.3rem;
								}
							}
						}

						&.open {
							.svg-icon {
								.q-change-view-carat {
									height: .4rem;
									margin-top: 1.3rem;
									transform: translateX(-1.35rem) rotate(180deg);
								}
							}

							&:hover {
								.svg-icon {
									.q-change-view-carat {
										transform: translateX(.2rem) rotate(180deg);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	// Touch Styles
	.no-touch & {
		.q-change-view-header {
			.q-dropdown {
				&:hover,
				&.focus {
					.svg-icon {
						background-color: $grey-dark;

						.q-change-view-carat {
							@include insert-icon($gl-dropdown-carat-hover, $height: .4706rem, $width: 3.375rem);
							background-position: calc(100% - 1rem) center, 100% 0;
							margin-top: 1.0588rem;
							padding-left: 3.375rem;
						}
					}
				}

				&.open {
					.svg-icon {
						background-color: $grey-dark;

						.q-change-view-carat {
							@include insert-icon($gl-dropdown-carat-open-hover, $height: .4706rem, $width: 3.375rem);
							margin-top: 1.0588rem;
							padding-left: 3.375rem;
							transform: translateX(-1rem) rotate(180deg);
						}
					}
				}
			}
		}

		.q-change-view-header-dropdown {
			.q-change-view-dropdown-item {
				&:hover {
					background-color: $gl-dropdown-hover-color;
					border-left: 3px solid $brand-primary-color;
					padding-left: 1.688rem;
				}

				&.active {
					&:hover {
						padding-left: 1.875rem;
					}
				}
			}
		}
	}

}

