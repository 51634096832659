.q-multimedia-scroller,
adv-grid > adv-scrl {
	.q-content-title {
		letter-spacing: .04rem;
		line-height: 1.5;
	}

	.q-link-icon-inline {
		.q-link-label {
			margin-right: 4px;
		}
	}

	.slick-arrow,
	.scrl-btn {
		opacity: 0;

		&.invert-arrow {
			background-color: $white;

			&::before {
				transform: scaleX(1);
			}
		}

		&.slick-prev {
			&.invert-arrow {
				&::before {
					transform: scaleX(-1);
				}
			}
		}
	}

	.q-content-bar {
		.slick-dots {
			padding: 0 .63rem;

			&.q-dots-to-numbers-dots {
				display: block;
			}
		}
	}

	@media #{$large-down} {
		.q-indicator-separator {
			display: block;
		}

		.slick-dots {
			background-color: transparent;
		}

		.slick-indicator-container {
			background-color: transparent;
		}
	}

	@media #{$large-down} {
		.q-link-icon-inline {
			&:hover {
				.q-link-label {
					color: $grey-dark;
				}
			}
		}
	}

	@media #{$medium-only} {
		.q-content-bar {
			&.q-content-below {
				&.q-dots-below {
					.q-scroller-overlay {
						padding-top: 1rem;
					}

					.slick-dots {
						&.q-dots-align-center {
							margin-top: 1.25rem;
						}

					}
				}
			}
		}

		.q-content-title {
			font-size: 1.176rem;
			letter-spacing: .047rem;
		}

		.q-headline {
			padding: .625rem;
		}
	}

	@media #{$small-only} {
		.q-content-title {
			font-size: $content-title-font-size-small;
		}

		.q-headline {
			padding: .625rem;
		}
	}

	@media #{$small-down} {
		.q-content-title {
			line-height: 1.667;
		}
	}

	@media #{$large-only} {
		.q-content-bar {
			.q-scroller-overlay {
				&.q-content-bar-align-left {
					padding-left: 1rem;

					.q-link-icon-inline {
						margin-left: 0;
					}
				}

				&.q-content-bar-align-right {
					padding-right: 1rem;
				}
			}

			.slick-dots {
				&.q-dots-align-left {
					margin-left: .38rem;
				}

				&.q-dots-align-right {
					margin-left: -.38rem;
					margin-right: .38rem;
				}
			}
		}
	}

	@media #{$large-up} {
		.q-content-bar {
			&.q-content-below {
				&.q-dots-below {
					.q-scroller-overlay {
						background-color: transparent;
						border: 0;
						position: absolute;
						z-index: 2;

						&.q-content-bar-align-right {
							right: 0;
						}
					}

					.slick-indicator-container {
						border-bottom: 1px solid $grey-light;
						height: 4rem;

						.slick-dots {
							&.q-dots-align-right {
								right: 4.25rem;
							}
						}
					}
				}
			}

			.slick-dots {
				li {
					height: 1rem;
					margin: 0 .35rem;
					width: 1rem;

					button {
						height: .8rem;
						width: .8rem;
					}

					&.slick-active {
						button {
							height: 1rem;
							width: 1rem;
						}
					}

					&:first-child {
						margin-left: $scroller-none;
					}

					&:last-child {
						margin-right: $scroller-none;
					}
				}
			}
		}

		.q-content-title {
			font-size: 1rem;
	}
}

	@media #{$xlarge-up} {
		.q-content-bar {
			&.q-content-below {
				&.q-dots-below {
					.q-scroller-overlay-more {
						padding-top: 1rem;
					}
				}
			}

			&.q-dots-below,
			&.q-dots-above {
				.slick-indicator-container {
					.slick-dots {
						&.q-dots-align-center {
							margin-left: 0;
							margin-top: 4.25rem;
						}
					}
				}
			}

			.q-scroller-overlay {
				&.q-content-bar-align-left {
					padding: 0 .63rem;

					.q-link-icon-inline {
						margin-left: 2.38rem;
					}
				}

				&.q-content-bar-align-right {
					bottom: 0;
					padding: 0;
					right: 3rem;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.slick-dots {
				&.q-dots-align-left {
					margin-left: 2.38rem;
				}

				&.q-dots-align-right {
					margin-left: -2.38rem;
					margin-right: 2.38rem;
				}
			}
		}
	}

	@media #{$xxlarge-up} {
		.q-content-bar {
			.q-scroller-overlay {
				&.q-content-bar-align-left {
					.q-link-icon-inline {
						margin-left: 0;
					}
				}

				&.q-content-bar-align-right {
					right: 2.61rem;
				}
			}

			.slick-indicator-container {
				.slick-dots {
					padding: 0 .54rem;

					li {
						height: .87rem;
						margin: 0 .30rem;
						width: .87rem;

						button {
							height: .7rem;
							width: .7rem;
						}

						&.slick-active {
							button {
								height: .87rem;
								width: .87rem;
							}
						}

						&:first-child {
							margin-left: $scroller-none;
						}

						&:last-child {
							margin-right: $scroller-none;
						}
					}

					&.q-dots-align-left {
						margin-left: 2.07rem;
					}

					&.q-dots-align-right {
						margin-left: -2.07rem;
						margin-right: 2.07rem;
					}
				}
			}
		}
	}

	// Touch Styles
	.no-touch & {
		&:hover {
			/* Arrows */
			.slick-arrow,
			.scrl-btn {
				background-color: $black;
				opacity: $gl-mms-hover-slider-arrow-opacity;

				&.invert-arrow {
					background-color: $white;
				}

				&:hover {
					opacity: $gl-slider-arrow-opacity;
				}
			}
		}
	}
}
