footer {
	.q-expander {
		margin: 0;

		.q-button {
			&.q-link {
				&:active,
				&:focus {
					color: $grey;
				}
			}
		}
	}

	.no-touch & {
		.q-button {
			&.q-link {
				&:focus {
					color: $grey;

					&:hover {
						color: $grey-dark;
					}
				}
			}
		}
	}

	.image-svg-container {
		.q-rectangle-image-responsive {
			width: 100%;
		}
	}
}
