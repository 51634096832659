@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes rotate-360 {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}


@keyframes fade-in-left {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fade-in-right {
	from {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes qFadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes slide-right {
	from {
		left: -100%;
	}

	to {
		left: 0;
	}
}

@keyframes slide-left {
	from {
		left: 0;
	}

	to {
		left: -100%;
	}
}

@keyframes prev {
	from {
		transform: translateX(0);
	}

	17% {
		transform: translateX(-3px);
	}

	36% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(0);
	}
}
@keyframes next {
	from {
		transform: translateX(0);
	}

	17% {
		transform: translateX(3px);
	}

	36% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes pulse {
	0% {
		transform: scale3d(1, 1, 1);
	}

	50% {
		transform: scale3d(1.25, 1.25, 1.25);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}
