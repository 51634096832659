@import 'placeholders/icons';

/********
 * NOTE: These variables and mixins MUST be in this file so that this file can be used
 *       on its own for use in AEM dialog.
 ********/

// Icons
// - - - - - - - - - - - - - - - - - - - - - - - - -

$img-path: '/etc.clientlibs/clientlibs/' + $brand-name + '/resources/img';

$element-map: (
	exit: url('#{$img-path}/exit.svg'),
	info-icon: url('#{$img-path}/info-icon.svg'),
	placeholder-clear: url('#{$img-path}/placeholder_clear.png')
);
