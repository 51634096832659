.q-headline {
	.q-headline-icon {
		display: inline-block;
		vertical-align: top;

		&.icon-left {
			margin-inline-end: .5em;
		}

		&.icon-right {
			margin-inline-start: .5em;
		}

		img {
			height: 1.25em;
			width: auto;

			&:nth-child(2) {
				display: none;
			}
		}

		a:hover {
			img {
				&:nth-child(1) {
					display: none;
				}

				&:nth-child(2) {
					display: inline-block;
				}
			}
		}

		.q-headline-icon-text {
			display: inline-block;
			width: calc(100% - 2.312rem);
		}
	}

	.q-headline-icon-block {
		display: inline-block;


		&.icon-left {
			float: left;
			height: 1.875rem;
			margin-inline-end: .5em;
			width: 1.875rem;
		}

		&.icon-right {
			float: right;
			height: 1.875rem;
			margin-inline-start: .5em;
			width: 1.875rem;
		}

		img {
			height: 1.875rem;
			width: 1.875rem;
		}
	}

	@media #{$large-up} {
		.q-headline-icon-block {
			&.icon-left {
				height: 2.5rem;
				width: 2.5rem;
			}

			&.icon-right {
				height: 2.5rem;
				width: 2.5rem;
			}

			img {
				height: 2.5rem;
				width: 2.5rem;
			}

			.q-headline-icon-text {
				display: inline-block;
				width: calc(100% - 2.938rem);
			}
		}
	}

	@media #{$xxlarge-up} {
		.q-headline-icon-block {
			.q-headline-icon-text {
				width: calc(100% - 3.125rem);
			}
		}
	}
}
