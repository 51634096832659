// Shared animation Library for All brands

%q-animate-fade-in {
	animation-fill-mode: forwards;
	animation-name: qFadeIn;
}

.q-animate {
	visibility: hidden;
}

$animation-timing: ease-out;

@mixin slide-animations($percentages) {

	// Short circut the animation piece if user prefers reduced motion
	@media (prefers-reduced-motion: no-preference) {
		&.q-animation-no-movement {
			opacity: 0;
			transition: opacity 250ms $animation-timing;
			visibility: hidden;
			will-change: opacity;
		}

		@each $percentage in $percentages {
			&.q-animation-slide-up-#{$percentage} {
				opacity: 0;
				transform: translateY(#{$percentage * 1%});
				transition: opacity 250ms $animation-timing, transform $animation-timing;
				visibility: hidden;
				will-change: opacity, transform;
			}
		}

		@each $percentage in $percentages {
			&.q-animation-slide-left-#{$percentage} {
				opacity: 0;
				transform: translateX(#{$percentage * -1%});
				transition: opacity 250ms $animation-timing, transform $animation-timing;
				visibility: hidden;
				will-change: opacity, transform;
			}
		}

		@each $percentage in $percentages {
			&.q-animation-slide-right-#{$percentage} {
				opacity: 0;
				transform: translateX(#{$percentage * 1%});
				transition: opacity 250ms $animation-timing, transform $animation-timing;
				visibility: hidden;
				will-change: opacity, transform;
			}
		}

		&.is-visible {
			opacity: 1;
			transform: unset;
			visibility: visible;
		}
	}
}

@mixin animation-durations($durations) {
	@each $duration in $durations {
		&.q-animation-duration-#{$duration}ms {
			transition-duration: #{$duration * 1ms};
		}
	}
}

@mixin animation-delays($delays) {
	@each $delay in $delays {
		&.q-animation-delay-#{$delay}ms {
			transition-delay: #{$delay * 1ms};
		}
	}
}
