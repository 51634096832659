q-progress-bar {
	.q-pb-label {
		color: $qf-progress-bar-label;
		font-size: 1rem;
  	}

	// sass-lint:disable no-vendor-prefixes
	progress {
		appearance: none;
		background-color: $qf-progress-bar-background;
		border-radius: 15px;
		height: 8px;
		margin-bottom: 2.4rem;
		margin-top: .4rem;
		width: 100%;

		&::-webkit-progress-bar {
			background-color: $qf-progress-bar-background;
			border-radius: 15px;
			height: 8px;
		}

		&::-webkit-progress-value {
			background: $qf-progress-bar-value;
			border-radius: 15px;
			height: 8px;
    	}

		&::-moz-progress-bar {
			background: $qf-progress-bar-value;
			border-radius: 15px;
			height: 8px;
		} // sass-lint:enable no-vendor-prefixes
	}
}
