.q-sticky-notification {

	&.q-min-height {
		min-height: 3.3125rem;
	}

	.q-content-container {
		.q-title {
			.q-title-label {
				@extend %q-descriptive1;
				font-size: .9375rem;
				letter-spacing: .085rem;
				line-height: 1.25rem;
				margin-bottom: 0;
				margin-right: 1.88rem;
			}
		}
	}

	.q-close-button {
		font-size: 1.5rem;
		padding: .625rem;
	}

	.q-content-container {
		.q-title,
		.q-content {
			color: $white;
		}

		.q-title {
			padding: 1.125rem 1.25rem;

			.q-title-label {
				color: $white;
			}
		}

	}

	@media #{$medium-up} {

		.q-content-container {
			.q-title {

				.q-title-label {
					font-size: 1.25rem;
					letter-spacing: .1125rem;
					line-height: 1.875rem;
					margin-right: 0;
				}
			}
		}
	}



	@media #{$large-up} {
		.q-content-container {
			.q-title {
				.q-title-label {
					margin: 0 1.25rem;
				}
			}

		}
	}

	@media #{$xlarge-up} {
		.q-content-container {
			.q-title {
				.q-title-label {
					font-size: 1.5625rem;
					letter-spacing: .140625rem;
					line-height: 2.5rem;
				}
			}

		}
	}
}
