// DISCLAIMER - REMOVE BEFORE COMPLETING CODE
//
// This file should only include documentation and SCSS for this specific component
// Any global patterns should be created in /sass/patterns/ and overwritten/extended here
//
// DISCLAIMER - REMOVE BEFORE COMPLETING CODE

// Vertical Spacing
//
// This is the documentation for Vertical Spacing. Edit vertical_spacing.scss to update this documentation.
//
// markup:
// <p>Sample markup for Vertical Spacing. Replace me with the real deal once you are done editing <strong>vertical_spacing.html</strong> and <em>optionally</em> the vertical_spacing.js file.</p>
//
// Styleguide 99.99

.q-vertical-spacing {
	.is-vertical {
		bottom: 0;
		line-height: 0;
		padding: 0;
		vertical-align: text-bottom;
	}

}
