// Vehicle Selector Component
//
// The Vehicle Selector is used to display a list of carlines/bodystyles in a responsive grid
//
// markup:
// 	<div class="q-vehicle-selector">
// 		<div class="q-nav-vehicle-selector">
// 			<div class-"row q-vehicle-selector-row">
// 				<div class="columns q-vehicle-selector-item">
//.					<!-- VEHICLE TILE MARKUP -->
// 				</div>
// 			</div>
// 			<div class-"row q-vehicle-selector-row">
// 				<div class="columns q-vehicle-selector-item">
//.					<!-- VEHICLE TILE MARKUP -->
// 				</div>
// 			</div>
// 		</div>
// 	</div>
//

.q-vehicle-selector {
	flex-flow: row wrap;
	overflow: hidden;
	position: relative;

	.q-button-back,
	.q-vehicle-selector-subtitle {
		display: none;
	}

	.q-vehicle-selector-subtitle {
		background: $grey-dark;
		color: $white;
		font-family: $font-family-primary;
		font-size: .75rem;
		font-weight: $font-weight-lighter;
		line-height: 1.25rem;
		padding: 3%;
		text-transform: uppercase;
	}

	.q-vehicle-selector-row {
		display: flex;
		flex-flow: row wrap;

		.q-vehicle-selector-item {
			display: none;
			padding: 0;

			.q-vehicle-information-tile-wyear-secondarynav {
				display: flex;

				.q-vehicle-info-text {
					white-space: normal;
				}
			}
		}
	}

	@media #{$xlarge-up} {
		.q-vehicle-selector-row {
			.q-vehicle-selector-item {
				padding: 1.25rem;
			}
		}
	}
}
