// chevrolet specific icons
//
// .icon-arrow-left - Arrow Left icon
// .icon-arrow-right - Arrow Right icon
// .icon-arrow-up - Arrow Up icon
// .icon-arrow-down - Arrow Down icon
// .icon-book - Book icon
// .icon-car - Car icon
// .icon-close - Close icon
// .icon-envelope - Envelope icon
// .icon-home - Home icon
// .icon-location - Location icon
// .icon-minus - Minus symbol icon
// .icon-plus - Plus symbol icon
// .icon-request-quote - "Request A Quote" icon
// .icon-search - Search icon
// .icon-slider-next - Next arrow for slider
// .icon-slider-prev - Previous arrow for slider
// .icon-steering-wheel - Steering wheel icon
// .icon-zoom-in - Zoom In icon
// .icon-zoom-out - Zoom Out icon
//
// markup:
// <i class="icon {$modifiers}"></i>
//
// Styleguide 13.1

// chevrolet headers with icons
//
// markup:
// <h1><i class="icon icon-car"></i>Header Text</h1>
// <h2><i class="icon icon-car"></i>Header Text</h2>
// <h3><i class="icon icon-car"></i>Header Text</h3>
// <h4><i class="icon icon-car"></i>Header Text</h4>
// <h5><i class="icon icon-car"></i>Header Text</h5>
// <h6><i class="icon icon-car"></i>Header Text</h6>
//
// Styleguide 13.2

//---------------------------------------------------------------
// Icons that shouldn't be displayed to an author in the dialog
@mixin icon-social($icon-name, $hover-state: false, $responsive: false) {
	.icon-#{$icon-name} {
		height: $share-svg-size;
		width: $share-svg-size;

		&::after {
			content: get-element($icon-name);
		}

		@if $hover-state == true {
			&::before {
				content: get-element($icon-name + '-hover');
				display: none;
			}

			&:hover {
				&::after {
					content: get-element($icon-name + '-hover');
				}
			}
		}

		@if $responsive == true {
			@media #{$medium-up} {
				height: $share-svg-size-large;
				width: $share-svg-size-large;
			}
		}
	}
}

%svg-icon-base {
	content: '';
}

//General Icon Classes
.icon-minus {
	&::before {
		@extend %svg-icon-base;

		background: get-element($generic-icon-minus) no-repeat center;
		height: $generic-icon-minus-size;
		width: $generic-icon-minus-size;
	}

	&:hover {
		&::before {
			@extend %svg-icon-base;

			background: get-element($generic-icon-minus-hover) no-repeat center;
			height: $generic-icon-minus-size;
			width: $generic-icon-minus-size;
		}
	}
}

.icon-plus {
	&::before {
		@extend %svg-icon-base;

		background: get-element($generic-icon-plus) no-repeat center;
		height: $generic-icon-plus-size;
		width: $generic-icon-plus-size;
	}

	&:hover {
		&::before {
			@extend %svg-icon-base;

			background: get-element($generic-icon-plus-hover) no-repeat center;
			height: $generic-icon-plus-size;
			width: $generic-icon-plus-size;
		}
	}
}

.icon-alt::before {
	@extend %svg-icon-base;

	background: get-element($generic-icon-alt) no-repeat center;
	height: $generic-icon-alt-size;
	width: $generic-icon-alt-size;
}

.icon-img-active::before {
	@extend %svg-icon-base;

	background: get-element($generic-icon-img-active) no-repeat center;
	height: $generic-icon-img-active-size;
	width: $generic-icon-img-active-size;
}

.icon-img-inactive::before {
	@extend %svg-icon-base;

	background: get-element($generic-icon-img-inactive) no-repeat center;
	height: $generic-icon-img-inactive-size;
	width: $generic-icon-img-inactive-size;
}

.icon-checkbox::before {
	@extend %svg-icon-base;

	background: get-element($generic-icon-checkbox) no-repeat center;
	height: $generic-icon-checkbox-size;
	width: $generic-icon-checkbox-size;
}

.icon-search::before {
	@extend %svg-icon-base;

	background: get-element($generic-icon-search) no-repeat center;
	height: $generic-icon-search-size;
	width: $generic-icon-search-size;
}

@include icon-social('social-media-email');
@include icon-social('social-media-facebook');
@include icon-social('social-media-instagram');
@include icon-social('social-media-pinterest');
@include icon-social('social-media-twitter');
// @include icon-social('social-media-whatsapp');
@include icon-social('social-media-youtube');

//----------- END ICONS ---------------------

// Header icons
// - - - - - - - - - - - - - - - - - - - - - - - - -
h1 > .icon,
h2 > .icon,
h3 > .icon,
h4 > .icon,
h5 > .icon,
h6 > .icon {
	margin-right: .5rem;
	vertical-align: middle;
}

h1 > .icon {
	font-size: $h1-font-size - $h1-font-reduction;
	line-height: $h1-font-size - $h1-font-reduction;
}

h2 > .icon {
	font-size: $h2-font-size - $h2-font-reduction;
	line-height: $h2-font-size - $h2-font-reduction;
}

h3 > .icon {
	font-size: $h3-font-size - $h3-font-reduction;
	line-height: $h3-font-size - $h3-font-reduction;
}

h4 > .icon {
	font-size: $h4-font-size - $h4-font-reduction;
	line-height: $h4-font-size - $h4-font-reduction;
}

h5 > .icon {
	font-size: $h5-font-size - $h5-font-reduction;
	line-height: $h5-font-size - $h5-font-reduction;
}

h6 > .icon {
	font-size: $h6-font-size - $h6-font-reduction;
	line-height: $h6-font-size - $h6-font-reduction;
}

// Media Queries
// - - - - - - - - - - - - - - - - - - - - - - - - -
@media #{$medium-up} {
	h1 > .icon {

		font-size: $h1-font-size;
		line-height: $h1-font-size;
	}

	h2 > .icon {

		font-size: $h2-font-size;
		line-height: $h2-font-size;
	}

	h3 > .icon {

		font-size: $h3-font-size;
		line-height: $h3-font-size;
	}

	h4 > .icon {

		font-size: $h4-font-size;
		line-height: $h4-font-size;
	}

	h5 > .icon {

		font-size: $h5-font-size;
		line-height: $h5-font-size;
	}

	h6 > .icon {

		font-size: $h6-font-size;
		line-height: $h6-font-size;
	}
}
