// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
	@if length($range) <= 0 {
		@return 0;
	}

	@return nth($range, 1);
}

@function upper-bound($range) {
	@if length($range) < 2 {
		@return 999999999999;
	}

	@return nth($range, 2);
}

// STRIP UNIT
// It strips the unit of measure and returns it
@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

// CONVERT TO REM
@function convert-to-rem($value, $base-value: $rem-base) {
	$value: strip-unit($value) / strip-unit($base-value) * 1rem;

	// Turn 0rem into 0
	@if ($value == 0rem) { // sass-lint:disable-line zero-unit
		$value: 0;
	}

	@return $value;
}

// REM CALC
$rem-base: 16;

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of rem's.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported:
//
//   rem-calc(10 20 30px 40);
//
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
//
//   rem-calc((10, 20, 30, 40px));
//
// Optionally call with a different base (eg: 8px) to calculate rem.
//
//   rem-calc(16px 32px 48px, 8px);
//
// If you require to comma separate your list
//
//   rem-calc((16px, 32px, 48), 8px);

@function rem-calc($values, $base-value: $rem-base) {
	$max: length($values);

	@if $max == 1 {
		@return convert-to-rem(nth($values, 1), $base-value);
	}

	$rem-values: ();
	@for $i from 1 through $max {
		$rem-values: append($rem-values, convert-to-rem(nth($values, $i), $base-value));
	}

	@return $rem-values;
}


@function em-calc($values, $base-value: $rem-base) {
	$rem-values: rem-calc($values, $base-value: $rem-base);

	$max: length($rem-values);

	@if $max == 1 {
		@return strip-unit(nth($rem-values, 1)) * 1em;
	}

	$em-values: ();
	@for $i from 1 through $max {
		$em-values: append($em-values, strip-unit(nth($rem-values, $i)) * 1em);
	}

	@return $em-values;
}

$small-breakpoint: em-calc(419);//Style Guide: Xsmall
$medium-breakpoint: em-calc(599);//Style Guide: Small
$large-breakpoint: em-calc(959);//Style Guide: Medium
$xlarge-breakpoint: em-calc(1499);//Style Guide: Large
$xxlarge-breakpoint: em-calc(2499);//Style Guide: Xlarge

$small-range: (0, $small-breakpoint);
$medium-range: ($small-breakpoint + em-calc(1), $medium-breakpoint);
$large-range: ($medium-breakpoint + em-calc(1), $large-breakpoint);
$xlarge-range: ($large-breakpoint + em-calc(1), $xlarge-breakpoint);
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), $xxlarge-breakpoint);
$xxxlarge-range: ($xxlarge-breakpoint + em-calc(1), em-calc(99999999));

$screen: 'only screen';

$landscape: '#{$screen} and (orientation: landscape)';
$portrait: '#{$screen} and (orientation: portrait)';

$small-up: $screen;
$small-only: '#{$screen} and (max-width: #{upper-bound($small-range)})';
$small-down: 'print, #{$screen} and (max-width:#{upper-bound($small-range)})';

$medium-up: 'print, #{$screen} and (min-width:#{lower-bound($medium-range)})';
$medium-only: 'print, #{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})';
$medium-down: 'print, #{$screen} and (max-width:#{upper-bound($medium-range)})';

$large-up: 'print, #{$screen} and (min-width:#{lower-bound($large-range)})';
$large-only: 'print, #{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})';
$large-down: 'print, #{$screen} and (max-width:#{upper-bound($large-range)})';

$xlarge-up: '#{$screen} and (min-width:#{lower-bound($xlarge-range)})';
$xlarge-only: '#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})';
$xlarge-down: 'print, #{$screen} and (max-width:#{upper-bound($xlarge-range)})';

$xxlarge-up: '#{$screen} and (min-width:#{lower-bound($xxlarge-range)})';
$xxlarge-only: '#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})';
$xxlarge-down: 'print, #{$screen} and (max-width:#{upper-bound($xxlarge-range)})';

$xxxlarge-up: '#{$screen} and (min-width:#{lower-bound($xxxlarge-range)})';
$xxxlarge-only: '#{$screen} and (min-width:#{lower-bound($xxxlarge-range)}) and (max-width:#{upper-bound($xxxlarge-range)})';
$xxxlarge-down: 'print, #{$screen} and (max-width:#{upper-bound($xxxlarge-range)})';
