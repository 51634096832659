.slide {
	.q-slide-separator {
		border: solid $dusty-grey;
		border-width: 1px 0 0;
		margin: 2.813rem 0 2.188rem;
		text-align: center;

		&::after {
			background-color: $white;
			color: $dark-grey;
			content: 'End Slide Component';
			font-family: inherit;
			padding: .625rem;
			position: relative;
			top: -.875rem;
		}
	}
}
