// Layout

$vertical-spacing-sizes: (
	none: 0,
	small: 1.25rem,
	medium: 2.5rem,
	large: 5rem,
	xlarge: 5rem,
	xlarge-up: 7.5rem
) !default;

$vertical-xlarge-up: map-get($vertical-spacing-sizes, xlarge-up);

@mixin vertical-spacing-classes {
	@each $size, $value in $vertical-spacing-sizes {
		@if ($size != xlarge-up) {
			.#{$size}-margin, // legacy class support
			.margin-#{$size} {
				margin-bottom: $value !important; // sass-lint:disable-line no-important

				// Chevy's xlarge vertical spacing is larger
				// for breakpoints larger than small. This will
				// allow others brands to do the same if needed.
				@if ($size == xlarge and $vertical-xlarge-up) {
					@media #{$medium-up} {
						margin-bottom: $vertical-xlarge-up !important; // sass-lint:disable-line no-important
					}
				}
			}
		}
	}
}

@include vertical-spacing-classes;
