// Sticky Notification
// This is namespaced to prevent collision - Mobile Styles go Here

.q-sticky-notification {
	@include hard-easeinout(height, $anim-xslow);
	background-color: $sticky-notification-bg-color;
	bottom: 0;
	height: 0;
	max-height: 100%;
	overflow: auto;
	position: fixed;
	width: 100%;
	z-index: 150;

	.float-left {
		float: left;
	}

	.q-content-container {

		.q-close-button {
			cursor: pointer;
			inset-block-end: auto;
			inset-block-start: 0;
			inset-inline-end: 0;
			inset-inline-start: auto;
			position: absolute;
			z-index: 15;

			.q-close-icon {
				@include insert-icon($sticky-notification-close-icon, map-get($sticky-notification-close-icon-map, large), map-get($sticky-notification-close-icon-map, large), $sticky-notification-close-icon-mq-use, $sticky-notification-close-hover-icon);
				vertical-align: baseline;
			}
		}

	}

	@media #{$medium-up} {
		.float-left {
			margin-bottom: 0;
		}
	}
}
