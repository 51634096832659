/********
 * NOTE: These variables and mixins MUST be in this file so that this file can be used
 *       on its own for use in AEM dialog.
 ********/

// Empty Element Map reference is intended to prevent compile error
$element-map: ();

@function get-element($key) {
	@return map-get($element-map, $key);
}
