.q-alt-price {
	@extend %q-body2;
	color: $grey;
	line-height: 1.25rem;
	margin-bottom: 0;

	@media #{$xlarge-up} {
		font-size: .875rem;
	}
}
