// DISCLAIMER - REMOVE BEFORE COMPLETING CODE
//
// This file should only include documentation and SCSS for this specific component
// Any global patterns should be created in /sass/patterns/ and overwritten/extended here
//
// DISCLAIMER - REMOVE BEFORE COMPLETING CODE

// Tab Nav
//
// This is the documentation for Tab Nav. Edit tab_nav.scss to update this documentation.
//
// markup:
// <p>Sample markup for Tab Nav. Replace me with the real deal once you are done editing <strong>tab_nav.html</strong> and <em>optionally</em> the tab_nav.js file.</p>
//
// Styleguide 99.99

.grid-padding-small,
.grid-padding-medium,
.grid-padding-large {
	.q-tab-nav {
		margin-left: 0;
		margin-right: 0;

		.q-tabs-content {
			border: $tab-nav-border;
			border-width: $tab-nav-border-width;
		}
	}
}

.q-tab-nav {
	.q-tabs-content {
		border: $tab-nav-border;
		border-width: $tab-nav-border-width;
		width: 100%;

		// Foundation Tab replacement
		&::before,
		&::after {
			content: ' ';
			display: table;
		}

		&::after {
			clear: both;
		}

		> .content {
			width: 100%;
		}

	}

	.q-floating-tab {
		width: 100%;

		.q-tabs {
			display: none;
			margin-left: 0;
			width: 100%;

			&::before,
			&::after {
				content: none;
			}
		}

		.q-tab-title {
			border: $tab-nav-button-border;
			border-width: $tab-nav-button-border-width;
			display: flex;
			flex: 1;

			&:first-child {
				border-left: $tab-nav-first-child-button-border;
			}

			.q-tab-button {
				align-items: center;
				background-color: $tab-nav-background-color;
				border: 0;
				display: flex;
				padding: 0 1.25rem;
				position: relative;
				width: 100%;

				&.q-icon-stacked {
					.q-button {
						display: block;
					}
				}

				&.q-icon-only {
					width: 100%;
				}

				&.q-icon-stacked,
				&.q-icon-only,
				&.q-inline-icon {
					.q-svg-icon {
						@include svg-icon-size($tab-nav-button-icon-size);
					}
				}

				.q-button {
					border: 4px solid transparent;
					color: $tab-nav-font-color;
					font-family: $tab-nav-font-family;
					font-size: $tab-nav-font-size;
					font-weight: $tab-nav-font-weight;
					line-height: $tab-nav-title-line-height;
					margin: 0 auto;
					padding: $tab-nav-button-padding;
					position: relative;
					text-transform: $tab-nav-text-transform;
					width: auto;

					&::before {
						@if $tab-nav-use-transition {
							@include easein-middle;
							border-bottom: $tab-nav-title-border-bottom-hover-animate;
							bottom: 0;
							content: '';
							left: 50%;
							position: absolute;
							width: 0;
						}
					}

					.q-move-text {
						border-bottom: $tab-nav-title-border-bottom;
						flex: 1 1 auto;
					}
				}
			}

			&.active {
				background-color: $tab-nav-background-color-active;
				border: $tab-nav-button-border-active;
				border-width: $tab-nav-button-border-width-active;

				&:first-child {
					border-left: $tab-nav-first-child-button-border-active;
				}

				.q-tab-button {
					background-color: transparent;

					.q-button::before {
						@include easein-middle;

						left: 0;
						width: 100%;
					}

					.q-button {
						color: $tab-nav-font-color-active;

						.q-move-text {
							border-bottom: $tab-nav-title-border-bottom-active;
						}
					}

					&:focus {
						background-color: $tab-nav-background-color-active;
					}
				}

				.icon-hover {
					display: inline;
				}

				.icon-normal {
					display: none;
				}
			}
		}

		&.q-swap-tabs {
			.q-tabs {
				display: flex;

				.q-tab-title {
					.q-tab-button {
						.q-button {
							border: 0;
						}
					}
				}

				&.active {
					border-bottom: 0;
				}
			}
		}

		&.q-large-impact-tabs {
			align-items: center;
			border-width: 0;
			display: flex;
			flex-direction: column;
			overflow: auto;

			//sass-lint:disable no-vendor-prefixes
			&::-webkit-scrollbar {
				display: none;
			}

			.q-tabs {
				display: flex;
				justify-content: space-around;
				margin: auto;
				width: auto;

				.q-tab-title {
					border: 0;
					display: flex;
					justify-content: space-around;
					margin: auto;

					.q-tab-button {
						.q-button {
							border: 0;
						}
					}
				}
			}
		}

		&.q-large-impact-invert-tabs {
			align-items: center;
			border-width: 0;
			display: flex;
			flex-direction: column;
			overflow: auto;

			//sass-lint:disable no-vendor-prefixes
			&::-webkit-scrollbar {
				display: none;
			}

			.q-tabs {
				background-color: $tab-nav-background-invert-color;
				display: flex;
				justify-content: space-around;
				margin: auto;
				width: auto;

				.q-tab-title {
					background-color: $tab-nav-background-invert-color;
					border: 0;
					display: flex;
					justify-content: space-around;
					margin: auto;

					.q-tab-button {
						background-color: $tab-nav-background-invert-color;

						.q-button {
							background-color: $tab-nav-background-invert-color;
							border: 0;
						}
					}
				}
			}
		}

		&.hide {
			border-top: $tab-nav-border;

			.q-tabs {
				display: none;
			}
		}
	}

	.q-tabs-content {
		margin-bottom: 0;

		.q-expander-content {
			padding-top: 0;

			> .q-gridbuilder {
				margin: 0;
			}
		}

		> .q-content {
			padding: 0;

			&.active.active-override {
				visibility: visible;
				z-index: 1;
			}

			&.active-override {
				display: inline-block;
				float: left;
				position: relative;
				visibility: hidden;
				z-index: 0;
			}
		}
	}

	.q-expander {
		margin-left: auto;
		margin-right: auto;

		.q-expander-button {

			div {
				color: $grey-very-dark;
				font-size: .75rem;
				font-weight: $font-weight-bold;
			}

			&.disable:hover {
				div {
					color: $grey-dark;
				}
			}

			.expander-icon {
				margin-right: 1.25rem;
			}

			.tab-icon {
				display: inline-block;
				width: 1rem;
			}

			@media #{$medium-down} {
				border-left: 0;
				border-right: 0;
			}
		}

		.q-expander-content {
			overflow-x: hidden;
		}

		&.active {
			margin-bottom: $tab-nav-expander-margin-active;
		}

		// expander class with no functionality
		&.q-disable {
			margin-left: auto;
			margin-right: auto;

			.q-expander-button {
				border: $tab-nav-border;
				cursor: default;
				padding: 1.125rem 1.875rem;
				text-align: center;

				div {
					color: $grey;
				}

				&::before {
					content: none;
				}
			}
		}
	}

	&.q-swap-tab {
		.q-expander-button {
			border: 0;
			font: $button-font;

			&::before {
				content: none;
			}

			.q-button-text {
				color: $tab-nav-swap-title-color;
			}

			.tab-icon {
				display: none;
			}
		}
	}

	&.q-tabnav-360colorizer {
		> div > .tabs-content {
			> .q-content:not(.active) {
				.left-arrow,
				.right-arrow {
					display: none !important; // sass-lint:disable-line no-important
				}

				.q-expander:not(.q-360colorizer) {
					&.active {
						.left-arrow,
						.right-arrow {
							display: initial !important; // sass-lint:disable-line no-important
						}
					}
				}
			}

			> .q-content {
				.q-360colorizer {
					> div > .tabs-content {
						.q-content:not(.active) {
							.left-arrow,
							.right-arrow {
								display: none !important; // sass-lint:disable-line no-important
							}
                        }
                    }
                }
            }
		}
	}

	&.q-360colorizer {
		.q-mod-video-brightcove {
			.q-posterframe {
				display: none !important; // sass-lint:disable-line no-important
			}
		}
	}

	@each $key, $value in $media-query-map {
		@media #{$value} {
			&.q-disabled-#{$key} {
				@include expander-disable;
			}

			&.q-floating-#{$key} {

				// Keeps the vertical position of hidden tabs the same.  Mainly done
				// for maintaining vertical position for visibility detecting for
				// lazy loading
				.content:not(.active) {
					left: 0;
					@if $key == lg {
						position: absolute;
					} @else if $key == xl {
						position: absolute;
					}
				}

				.q-expander {
					margin-bottom: 0;

					.q-expander-button {
						display: none;
					}

					&.active {
						.q-expander-content {
							display: block;
						}
					}
				}

				&.q-two-floating-tabs {
					@if $key == xs {
						.q-floating-tab {
							display: none;
						}
					} @else if $key == sm {
						.q-floating-tab {
							display: none;
						}
					} @else if $key == med {
						.q-floating-tab {
							display: none;
						}
					}

					.q-tabs-content {
						> .q-content {
							&.active-override {
								@each $key, $value in $media-query-map {
									@if $key == xs {
										visibility: visible;
									} @else if $key == sm {
										visibility: visible;
									} @else if $key == med {
										visibility: visible;
									}
								}

								.q-expander {
									margin-bottom: 0;

									.q-expander-button {
										@if $key == lg {
											display: none;
										} @else if $key == xl {
											display: none;
										} @else {
											border: $tab-nav-disable-expander-button-border;
											display: block;
											pointer-events: none;
											text-align: center;

											&::before {
												display: none;
											}

											.q-headline-text {
												margin: auto;

												.tab-icon {
													display: block;
													margin: auto;
													width: 2rem;
												}
											}
										}
									}

									&.active {
										.q-expander-content {
											display: block;
										}
									}

									.q-expander-content {
										@if $key == xs {
											display: block;
											height: auto;
										} @else if $key == sm {
											display: block;
											height: auto;
										} @else if $key == med {
											display: block;
											height: auto;
										}
									}
								}
							}
						}
					}
				}

				.q-tabs {
					display: flex;
					flex-direction: row;
				}

				.q-360colorizer  {
					display: block !important; // sass-lint:disable-line no-important
				}

				@if $tab-nav-remove-content-animation {
					@include tabs-remove-transition;
				}
			}

			&.q-expander-#{$key} {
				.q-tabs-content {
					border: 0;

					> .q-content {
						&.active-override {
							visibility: visible;
						}
					}
				}

				.q-large-impact-tabs {
					display: none;
				}

				&.q-large-impact {
					.q-expander {
						&.active {
							.q-expander-content {
								border-bottom: $tab-nav-expander-border;
							}
						}
					}
				}

				.q-large-impact-invert-tabs {
					display: none;
				}

				&.q-large-impact-invert {
					.q-expander {
						&.active {
							.q-expander-content {
								border-bottom: $tab-nav-expander-border;
							}
						}
					}
				}

				.content {
					float: none;
					padding: 0;
				}

				.q-disable {
					border: $tab-nav-disable-expander-border;
					margin-left: 2.5rem;
					margin-left: 5vw;
					margin-right: 2.5rem;
					margin-right: 5vw;

					&:first-of-type {
						margin-bottom: 1.25rem;
					}

					.q-expander-content {
						display: block;
					}

					.q-expander-button {
						background-color: $tab-nav-disable-background-color;
						border: $tab-nav-disable-expander-button-border;
						margin-bottom: 0;

						.q-button-text {
							border-bottom: $tab-nav-disable-title-border-bottom;
							color: $tab-nav-disable-font-color;
							font-size: $tab-nav-disable-expander-font-size;
							line-height: $tab-nav-disable-expander-line-height;
						}

						.q-headline-text {
							margin: 0;
						}
					}
				}

				.q-expander {
					&.q-disable {
						.q-expander-content {
							border-bottom: 0;
							height: auto;
						}
					}
				}
			}

			&.q-swap-#{$key} {
				.q-swap-tabs {
					.q-tab-button {
						.q-svg-icon {
							margin: 0;
						}

						.q-move-text {
							display: none;
						}

						.q-shift-icon {
							.q-move-icon {
								margin: 0;
							}
						}
					}
				}

				.q-content {
					&.active {
						.q-expander {
							.q-expander-content {
								cursor: default;
								display: block;
								height: auto;
							}

							.q-expander-button {
								display: block;

								.q-headline-text {
									font-size: $swap-tab-font-size;
									line-height: $swap-tab-line-height;
								}
							}
						}
					}

					.q-expander {
						margin-bottom: 0;

						.q-expander-button {
							@if $swap-tab-center-align {
								text-align: center;
							}

							background: $swap-tab-expander-background-color;
							border: $swap-tab-expander-border-color;
							cursor: default;
							display: none;

							.q-headline-text {
								@if $swap-tab-center-align {
									margin: 0;
								}
							}
						}
					}
				}

				@if $tab-nav-remove-content-animation {
					@include tabs-remove-transition;
				}
			}

			&.q-tab-#{$key} {
				.q-expander {
					margin-bottom: 0;

					.q-expander-button {
						display: none;
					}

					&.active {
						.q-expander-content {
							display: block;
						}
					}
				}

				@if $tab-nav-remove-content-animation {
					@include tabs-remove-transition;
				}
			}
		}
	}

	// 360 Colorizer
	.q-360-colorizer {
		padding: 1rem;

		.q-colorizer-tabs {
			justify-content: left;
			padding-bottom: 1rem;
			padding-top: 1rem;

			.tab-title {
				margin: 0;
				padding: 0;

				&.active {
					.q-colorizer-chip {
						.q-colorizer-checkmark {
							display: block;
						}
					}
				}
			}
		}

		.q-colorizer-category {
			display: flex;
			flex-flow: column;
			margin-bottom: 1rem;

			.q-category-name {
				flex: 0 0 100%;
				max-width: 100%;
				padding: 0;
			}
		}

		.q-bottom-headline {
			display: none;

			&.active {
				display: block;
			}

			.row {
				padding: 0;
			}
		}

		@media #{$large-up} {
			.q-colorizer-category {
				display: flex;
				flex-flow: row;

				.q-category-name {
					flex: 0 0 50%;
					max-width: 50%;

					.tab-title {
						.q-colorizer-panel {
							&.tooltip-leftmost {
								.q-tooltip-text {
									left: 0;

									&::before,
									&::after {
										left: 15%;
									}
								}
							}
						}
					}
				}

				.q-category-name:nth-child(2) {
					border-left: 2px solid $grey;
					flex: 0 0 50%;
					max-width: 50%;
					padding-left: 1rem;
				}
			}
		}

		@media #{$xlarge-up} {
			.q-colorizer-category {
				.q-category-name {
					flex: 0 0 50%;
					max-width: 50%;
				}
			}
		}
	}
}

.no-touch {
	.q-tab-title {
		&:hover,
		&.focus {
			background-color: $tab-nav-background-color-hover;
		}

		.q-tab-button {
			&:hover,
			&:focus {
				background-color: transparent;
				box-shadow: none;

				.q-button {
					color: $tab-nav-font-color-hover;

					.q-move-text {
						border-bottom: $tab-nav-title-border-bottom-hover;
					}
				}
			}
		}

		&.active {
			.q-tab-button {
				&:hover {
					background-color: transparent;

					.q-button {
						.q-move-text {
							border-bottom: $tab-nav-title-border-bottom-active-hover;
						}
					}
				}
			}
		}
	}
}
