$font-list: (
	'Louis': (
		('louis-bolditalic-webfont', $font-style-italic, $font-weight-bold),
		('louis-bold-webfont', $font-style-normal, $font-weight-bold),
		('louis-heavyitalic-webfont', $font-style-italic, $font-weight-xbold),
		('louis-heavy-webfont', $font-style-normal, $font-weight-xbold),
		('louis-italic-webfont', $font-style-italic, $font-weight-normal),
		('louis-regular-webfont', $font-style-normal, $font-weight-normal)
	),
	'Roboto': (
		('roboto-bolditalic-webfont', $font-style-italic, $font-weight-bold),
		('roboto-bold-webfont', $font-style-normal, $font-weight-bold),
		('roboto-italic-webfont', $font-style-italic, $font-weight-normal),
		('roboto-lightitalic-webfont', $font-style-italic, $font-weight-lighter),
		('roboto-light-webfont', $font-style-normal, $font-weight-lighter),
		('roboto-mediumitalic-webfont', $font-style-italic, $font-weight-medium),
		('roboto-medium-webfont', $font-style-normal, $font-weight-medium),
		('roboto-regular-webfont', $font-style-normal, $font-weight-normal)
	),
	'NotoSansKR': (
		('NotoSansKR-Bold', $font-style-normal, $font-weight-bold, $font-hangul-unicode-code),
		('NotoSansKR-Medium', $font-style-normal, $font-weight-medium, $font-hangul-unicode-code),
		('NotoSansKR-Regular', $font-style-normal, $font-weight-normal, $font-hangul-unicode-code)
	),
	'NanumGothic' : (
		('NanumGothic-Bold', $font-style-normal, $font-weight-bold, $font-hangul-unicode-code),
		('NanumGothic-Regular', $font-style-normal, $font-weight-normal, $font-hangul-unicode-code)
	)
);

@include generate-font-faces;
