// sass-lint:disable class-name-format
.q-rte-launch-modal,
.q-mod-rte_modal {
	cursor: pointer;
	text-decoration: underline;

	&:hover {
		color: $rte-launch-modal-hover-color;
	}
}
// sass-lint:enable class-name-format

.q-invert {
	.q-rte-launch-modal {
		&:hover {
			color: $brand-invert-link-color-hover;
		}
	}
}
